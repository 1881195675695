import { React, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import ExpiredMemberView from '../../Model/Individual/MemberModel/ExpiredMemberView.js';

import FetchModule from '../../Model/Share/Network/FetchModule.js';

function ExpiredMemberInfoPage() {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const ViewRef = useRef();

  useEffect(() => {
    ViewRef.current.setData(data);
  }, [data])

  useEffect(() => {
    setIsLoading(true);
    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postData('member/expired/', 'POST');

        if (responseData.status === 200) {
          let newData = responseData.data.slice();

          setData(newData);
          setIsLoading(false);
        }
        else if (responseData.status === 401) history.push('/');
        // else alert("회원정보를 불러오는데 실패하였습니다.\n잠시후 다시시도 해주세요");
			}
		)();
  }, [history])

  // useEffect(() => {
  //   setIsLoading(true);

  //   const fetchModule = new FetchModule();
	// 	(
	// 		async () => {
	// 			const responseData = await fetchModule.getDataVersion2('member/expired/', 'GET');
  //       // console.log(responseData);
  //       if (responseData.status === 200) {
  //         // let newData = responseData.data.slice();

  //         setData(responseData.data);
  //         setIsLoading(false);
  //       }
  //       else if (responseData.status === 401) history.push('/');
  //       // else alert("회원정보를 불러오는데 실패하였습니다.\n잠시후 다시시도 해주세요");
	// 		}
	// 	)();
  // }, [history])

  return (
    <TopLevelWrapper>
      <ExpiredMemberView ref={ViewRef} data={data} isLoading={isLoading}/>
    </TopLevelWrapper>
  )
}

export default ExpiredMemberInfoPage;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;