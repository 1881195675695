import React, { useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import styled from "styled-components";

import WhiteGoBackImage from '../../../image/WhiteGoBackButton.png';
import NoteImagePath from '../../../image/Note.png';

export default function NoteNavigationBar(props) {
  const { nowPage, setNowPage } = props;

  const defaultURL = 'https://link-zone.io';

  const history = useHistory();
  let location = useLocation(); // URL 감지용

  // 현재 페이지 최신화 //
  useEffect(() => {
    setNowPage(defaultURL + location.pathname);
  }, [location])

  // 버튼 클릭시 //
  function onClickNavButton(division) {
    if (division === '1:1') history.push('/note/one-on-one');
    else if (division === 'Group') history.push('/note/program');
    else history.push('/home');
  }
  
  return (
    <TopLevelWrapper>
      <GoBackButton>
        <GoBackInnerButton onClick={() => onClickNavButton('/main')}>
          <GoBackImage src={WhiteGoBackImage} />
        </GoBackInnerButton>
      </GoBackButton>
      <NavigatorWrapper>
        <NoteImage src={NoteImagePath}/>
        <NavigatorButtonWrapper>
          <NavigatorButton isClicked={nowPage.includes('/note/one-on-one')}  onClick={() => onClickNavButton('1:1')}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={18} color={nowPage.includes('/note/one-on-one') ? '#FFFFFF' : '#C0C0C0'}>1:1</Text>
          </NavigatorButton>
          <NavigatorButton isClicked={nowPage.includes('/note/program')} onClick={() => onClickNavButton('Group')}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={18} color={nowPage.includes('/note/program') ? '#FFFFFF' : '#C0C0C0'}>그룹</Text>
          </NavigatorButton>
        </NavigatorButtonWrapper>
      </NavigatorWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 100px 0px 100px;

  width: calc(100% - 200px);
  height: 41px;

  gap: 10px;

  // background-color: #FFFFFF;
`;

const GoBackButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 40px;
  background-color: #DFDFDF;

  :hover {
    cursor: pointer;
  }
`;

const GoBackInnerButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 28px;
  height: 28px;

  border: 2px solid #FFFFFF;
  border-radius: 28px;
  background-color: #DFDFDF;
`;

const GoBackImage = styled.img`
  margin-left: -2px;

  width: 9px;
  height: 15px;
`;

const NavigatorWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding-left: 15px;
  
  gap: 21px;

  border-radius: 28px;
  background-color: #FFFFFF;
`;

const NavigatorButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const NavigatorButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 93px;
  height: 40px;

  border-radius: 30px;
  // background-color: #FF8B48;
  background-color: ${props => props.isClicked ? `#FF8B48` : `#FFFFFF`};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const NoteImage = styled.img`
  min-width: 27.68px;
  min-height: 21.9px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;