import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import SearchInput from "../../../Component/MySettingPageComponent/SearchInput";
import FetchModule from "../../Share/Network/FetchModule";

import ExitButtonImage from '../../../image/BlackExitButton.png';
import PhoneImage from '../../../image/PhoneIcon.png';
import LocationImage from '../../../image/LocationIcon.png';
import PrevImage from '../../../image/WhiteLeftTriangle.png';
import NextImage from '../../../image/WhiteRightTriangle.png';
import NonePictureImage from '../../../image/NonePictureImage.png';

export default function CenterFollowModal(props) {
  const { 
    isExercise, 
    isFollow, 
    followStoreIds,
    follows,
    clickData, 
    setClickData, 
    onClickFollow, 
    close 
  } = props;

  const [searchValue, setSearchValue] = useState('');
  const [stores, setStores] = useState([]);
  const [dividedData, setDividedData] = useState([]);
  // const [clickData, setClickData] = useState(undefined);
  const [pictures, setPictures] = useState([]);
  const [nowPictureIndex, setNowPictureIndex] = useState(0);

  const [storeBoxHeight, setStoreBoxHeight] = useState(124);

  const CenterPictureRef = useRef([]);

  // 화면 스크롤 제어 //
  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => document.body.style = `overflow: auto`
  }, [])

  // 업체 리스트 불러오기 //
  useEffect(() => {
    // if (clickData !== undefined) return;

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('store/all_store', 'GET');
        // console.log(responseData);
        if (responseData.status === 200) {
          setStores(responseData.data);
        }
      }
    )();
  }, [])

  // 센터 사진 정렬 //
  useEffect(() => {
    if (clickData === undefined) {
      setPictures([]);
    }
    else {
      let pictureData = clickData.profiles.split(',');

      if (pictureData.length === 1 && pictureData[0] === '') setPictures([]);
      else setPictures(pictureData);

      setStoreBoxHeight(582);
    }
  }, [clickData])

  // 검색 기능 포함 ( 한글 검색 알고리즘 최신화 ) //
  useEffect(() => {
    let storeNameList = [];
    let addressList = [];

    for (let i = 0; i < stores.length; i++) {
      if (stores[i].storeName.toLowerCase().replaceAll(' ', '').includes(searchValue.toLowerCase().replaceAll(' ', ''))) storeNameList.push(stores[i]);
      else if (stores[i].storeAddress.replaceAll(' ', '').includes(searchValue.replaceAll(' ', ''))) addressList.push(stores[i]);
    }

    if (storeNameList.length === 0 && addressList.length === 0) {
      for (let i = 0; i < stores.length; i++) {
        if (match(searchValue.toLowerCase().replaceAll(' ', ''), stores[i].storeName.toLowerCase().replaceAll(' ', ''))) storeNameList.push(stores[i]);
      }
    }


    let totalList = storeNameList.concat(addressList);

    setDividedData(totalList);
  }, [stores, searchValue, match()])

  // 입력된 글자가 한글인지 확인 //
  function is_kr(value) {
    if (value === undefined) return false;

    return ((('ㄱ'.charCodeAt() <= value.charCodeAt()) && (value.charCodeAt() <= 'ㅎ'.charCodeAt()))
      || (('가'.charCodeAt() <= value.charCodeAt()) && value.charCodeAt() <= '힣'.charCodeAt()));
  }

  // 한글 글자 유니코드 추출하여 검색 조건 확인 //
  function kr_num(value) {
    return value.charCodeAt() - '가'.charCodeAt();
  }

  // 초성 중성 종성 구분하여 연산 //
  function kr_list(value) {
    let consonant_list = ['ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ'
      , 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'];

    let res = [value];

    let kr_num_s = kr_num(value);

    if (kr_num_s > 0) {
      // 초성 + 중성일 경우 //
      if (kr_num_s % 28 !== 0) {
        res.push(String.fromCharCode(((parseInt(kr_num_s / 28) * 28) + '가'.charCodeAt())));
      }

      res.push(consonant_list[parseInt(kr_num_s / 588)])
    }

    return res;
  }

  // 한글, 영어 구분하여 검색 처리 //
  function eq_kr(value, data) {
    if (is_kr(value) && is_kr(data)) {
      return kr_list(data).includes(value);
    }
    else return value === data;
  }

  // 받침 확인하기 //
  function eq_kr_pos(value, data, nextData) {
    let consonant_list = ['ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ'
      , 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'];

    let kr_pos = [
      [0, 0], [1, 0], [2, 1], [2, 9], [4, 2], [1, 12], [2, 18],
      [7, 3], [8, 5], [1, 0], [2, 6], [3, 7], [4, 9], [5, 16],
      [6, 17], [7, 18], [16, 6], [17, 7], [1, 9], [19, 9], [20, 10],
      [21, 11], [22, 12], [23, 14], [24, 15], [25, 16], [26, 17], [27, 18]
    ]

    if (!(is_kr(value) && is_kr(data) && is_kr(nextData))) return false;
    if (kr_num(value) < 0) return false;

    let s_pos = String.fromCharCode(value.charCodeAt() - kr_pos[kr_num(value) % 28][0])
    let s_pos_consonant = consonant_list[kr_pos[kr_num(value) % 28][1]]

    return (s_pos === data) && (kr_list(nextData).includes(s_pos_consonant));
  }

  // 일치하는 글자가 존재하는지 확인 //
  function match(value, data) {
    if (value === undefined || data === undefined) return false;

    let valueSize = value.length;
    let dataSize = data.length;

    if (dataSize < valueSize) return false;

    for (let i = 0; i < (dataSize - valueSize + 1); i++) {
      let res = true;

      for (let j = 0; j < valueSize; j++) {
        if (j === (valueSize - 1)) {
          if (!eq_kr(value[j], data[i + j])) {
            if (i + j + 1 < dataSize) {
              if (eq_kr_pos(value[j], data[i + j], data[i + j + 1])) {
                break;
              }
            }
            res = false;
            break;
          }
        }
        else {
          if (value[j] !== data[i + j]) {
            res = false;
            break;
          }
        }
      }

      if (res) return true;
    }

    return false;
  }

  // 업체 전화번호 정리 //
  function calcGymTel(gymTel) {
    let calcTel = gymTel.replaceAll('-', '');

    if (calcTel.length === 12) return calcTel.slice(0, 4) + '-' + calcTel.slice(4, 8) + '-' + calcTel.slice(8, 12);
    else if (calcTel.slice(0, 3) === '010') return calcTel.replace(/[^0-9]/, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
    else return (calcTel.slice(0, 3) + '-' + calcTel.slice(3, 6) + '-' + calcTel.slice(6, 10));
  }

  // 닫기 클릭시 //
  function onClickExitButton() {
    // setClickData(undefined);
    // setStoreBoxHeight(124);
    setClickData(undefined);
    close();
  }

  // 업체 클릭시 //
  function onClickStore(data) {
    if (clickData !== undefined) return;
    
    let index = follows.findIndex(e => e.storeId === data.storeId);

    if (index !== -1) {
      console.log(follows[index]);
      if (follows[index].shareId !== undefined) {
        data.shareId = follows[index].shareId;
        data.status = follows[index].status;
      }
      else if (follows[index].partnerId !== undefined) {
        data.partnerId = follows[index].partnerId;
        data.status = follows[index].status;
      }
      else data.status = follows[index].status;
    }
    // console.log(data);
    setClickData(data);
    // setStoreBoxHeight(566);
    setStoreBoxHeight(582);
  }

  // 업체 사진 이전버튼 클릭시 //
  function onClickPrevPicture(index) {
    if (nowPictureIndex <= 0) return;

    // CenterPictureRef.current[index].scrollTo(342 * (nowPictureIndex - 1), 0);
    CenterPictureRef.current[index].scrollTo({ left: 342 * (nowPictureIndex - 1), behavior: "smooth" });
    setNowPictureIndex(nowPictureIndex - 1);
  }

  // 업체 사진 다음 버튼 클릭시 //
  function onClickNextPicture(index) {
    if (nowPictureIndex >= pictures.length - 1) return;

    CenterPictureRef.current[index].scrollTo({ left: 342 * (nowPictureIndex + 1), behavior: "smooth" });
    setNowPictureIndex(nowPictureIndex + 1);
  }

  // 팔로우 신청하기 버튼 클릭시 //
  function onClickFollowButton() {
    onClickFollow(clickData);
    setClickData(undefined);
    setStoreBoxHeight(124);
  }

  // 팔로우 신청버튼 색상 정리 //
  function onCalcFollowButtonColor() {
    if (clickData === undefined) return;
    else if (clickData.status === undefined) return '#6DD49E';
    else if (!clickData.status) return '#FF8B48';
    else if (clickData.status) return '#FF3131';
  }

  // 팔로우 신청버튼 텍스트 정리 //
  function onCalcFollowButtonText() {
    if (clickData === undefined) return;
    else {
      // 팔로우 창에서 열었을 경우 //
      if (isFollow) {
        if (clickData.status === undefined) return '팔로우 신청하기';
        else if (!clickData.status) return '팔로우 신청 취소';
        else if (clickData.status) return '팔로우 끊기';
      }
      // 팔로워 창에서 열었을 경우 //
      else {
        if (!clickData.status) return '팔로우 승인';
        else if (clickData.status) return '팔로우 끊기';
      }
    }
  }

  return (
    <TopLevelWrapper>
      <Modal>
        <ModalInnerWrapper>
          <TopTextWrapper>
            <TitleWrapper>
              <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' hover='default'>{isExercise ? `프로그램 팔로우` : `파트너 체육관 팔로우`}</Text>
              <ExitButtonWrapper onClick={() => onClickExitButton()}>
                <ExitImage src={ExitButtonImage} />
              </ExitButtonWrapper>
            </TitleWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777' hover='default'>링크존에서 다른 체육관의 프로그램을 받아볼 수 있어요</Text>
          </TopTextWrapper>
          <SearchBox isClicked={clickData !== undefined}>
            <SearchInput value={searchValue} setValue={setSearchValue} placeholder='센터의 이름이나 주소를 검색해주세요' />
          </SearchBox>
        </ModalInnerWrapper>
        <ScrollBoxWrapper isClicked={clickData !== undefined}>
          <ScrollBox isClicked={clickData !== undefined}>
            {
              dividedData.map((data, index) => (
                (clickData === undefined || clickData.storeId === data.storeId) &&
                <StoreBox
                  key={index}
                  height={storeBoxHeight}
                  isClicked={clickData !== undefined}
                  onClick={() => onClickStore(data)}>
                  <StoreInfoWrapper>
                    <InfoWrapper>
                      <StoreNameWrapper>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#333333' hover={clickData === undefined ? 'pointer' : 'default'}>{data.storeName}</Text>
                      </StoreNameWrapper>
                      <IconTextWrapper>
                        <Icon src={PhoneImage} />
                        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F' hover={clickData === undefined ? 'pointer' : 'default'}>{calcGymTel(data.storeTel)}</Text>
                      </IconTextWrapper>
                      <IconTextWrapper>
                        <Icon src={LocationImage} />
                        <AddressTextWrapper>
                          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F' hover={clickData === undefined ? 'pointer' : 'default'}>{data.storeAddress}</Text>
                        </AddressTextWrapper>
                      </IconTextWrapper>
                    </InfoWrapper>
                    <Profile src={data.storeLogo} />
                  </StoreInfoWrapper>
                  <FollowButton backgroundColor={onCalcFollowButtonColor()} onClick={onClickFollowButton}>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='pointer'>{onCalcFollowButtonText()}</Text>
                  </FollowButton>
                  <CenterPictureWrapper>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>센터 사진</Text>
                    {
                      pictures.length === 0 ?
                        <NoneImageBox>
                          <NoneImage src={NonePictureImage} />
                          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD' hover='default'>등록된 이미지가 없습니다</Text>
                        </NoneImageBox>
                        :
                        <CenterPictureBox ref={(element) => CenterPictureRef.current[index] = element} >
                          {
                            pictures.map((data, idx) => (
                              <Picture key={'picture_' + idx} src={data} />
                            ))
                          }
                        </CenterPictureBox>
                    }
                    <MoveButtonWrapper>
                      <MoveButton isView={nowPictureIndex > 0} onClick={() => onClickPrevPicture(index)}>
                        <MoveImage src={PrevImage} />
                      </MoveButton>
                      <MoveButton isView={nowPictureIndex < pictures.length - 1} onClick={() => onClickNextPicture(index)}>
                        <MoveImage src={NextImage} />
                      </MoveButton>
                    </MoveButtonWrapper>
                    <MarkWrapper>
                      {
                        pictures.map((data, idx) => (
                          <Mark key={'Picture2_' + idx} backgroundColor={nowPictureIndex === idx ? '#6DD49E' : '#F2F2F2'} />
                        ))
                      }
                    </MarkWrapper>
                  </CenterPictureWrapper>
                </StoreBox>
              ))
            }
          </ScrollBox>
          </ScrollBoxWrapper>
      </Modal>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;

  background-color: rgba(0, 0, 0, 0.2);
  
  transition: all 0.3s ease-in-out;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  // padding: 18px 21px 18px 21px;
  // padding: 18px 0px 18px 0px;
  padding: 18px 0px 0px 0px;

  width: 430px;
  // height: 800px;
  // width: 388px;
  // height: 764px;
  height: 782px;

  border-radius: 18px;
  background-color: #FFFFFF;

  overflow: hidden;
`;

const ModalInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;
  
  width: 388px;
`;

const ScrollBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding-bottom: 30px;

  width: 100%;

  background-color: ${props => props.isClicked ? `#FFFFFF` : `rgba(0, 0, 0, 0.03)`};

  transition: all 0.3s ease-in-out;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const ExitButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 22px;
  height: 22px;

  :hover {
    cursor: pointer;
  }
`;

const ExitImage = styled.img`
  width: 100%;
  height: 100%;
`;

const TopTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 8px;

  width: 100%;

  background-color: #FFFFFF;
`;

const SearchBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: ${props => props.isClicked ? `0px` : `72px`};

  overflow: hidden;

  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;
`;

const ScrollBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  // width: 100%;
  width: 94.88%;
  height: 632px;

  overflow-y: ${props => props.isClicked ? `none` : `scroll`};
  overflow-x: none;
  
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    background-color: #DADADA;
    border-radius: 200px;
  }

  &::-webkit-scrollbar-track {
    background-color: #F0F0F0;
    border-radius: 200px;
  }
`;

const StoreBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: ${props => props.isClicked ? `24px` : `16px`};

  width: 374px;
  min-height: ${props => props.height}px;
  max-height: ${props => props.height}px;

  border-radius: 12px;
  // box-shadow: ${props => props.isClicked ? `` : `0px 10px 10px 0px rgba(0, 54, 71, 0.02)`};
  background-color: #FFFFFF;

  overflow: hidden;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: ${props => props.isClicked ? 'default' : 'pointer'};
  }
`;

const StoreInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
`;

const FollowButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 48px;
  min-height: 48px;

  background-color: ${props => props.backgroundColor};

  :hover {
    cursor: pointer;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  margin: 16px 12px 0px 12px;

  width: 226px;
  height: 92px;

  gap: 8px;

  overflow: hidden;
`;

const StoreNameWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-left: 3px;

  width: 223px;
  height: 19px;

  overflow: hidden;
`;

const IconTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 4px;
`;

const AddressTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 206px;
  max-height: 38px;

  overflow: hidden;
`;

const Icon = styled.img`
  margin-top: 3px;

  width: 16px;
  height: 16px;
`;

const Profile = styled.img`
  width: 124px;
  height: 124px;

  object-fit: contain;
`;

const CenterPictureWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 12px;

  width: 342px;
  height: 398px;
`;

const CenterPictureBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 12px;

  width: 342px;
  height: 342px;

  border-radius: 6px;

  overflow: hidden;
  // overflow-x: scroll;
  // overflow-y: hidden;
`;

const Picture = styled.img`
  min-width: 342px;
  min-height: 342px;

  object-fit: contain;

  overflow: hidden;
`;

const NoneImageBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;

  width: 342px;
  height: 342px;

  gap: 18px;

  border-radius: 6px;
`;

const NoneImage = styled.img`
  width: 60px;
  height: 60px;

  object-fit: contain;
`;

const MoveButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 12px 0px 12px;
  // margin-top: -157px;
  // margin-top: -180px;
  margin-top: 180px;

  width: 318px;
`;

const MoveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 28px;
  height: 28px;

  border-radius: 28px;
  background-color: rgba(0, 0, 0, 0.60);

  opacity: ${props => props.isView ? 1 : 0};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: ${props => props.isView ? `pointer` : `default`};
  }

  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none
`;

const MoveImage = styled.img`
  width: 12px;
  height: 9px;

  object-fit: contain;
`;

const MarkWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 16px;

  width: 100%;

  gap: 4px;
`;

const Mark = styled.div`
  width: 8px;
  height: 8px;

  border-radius: 8px;
  background-color: ${props => props.backgroundColor};
  
  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;
  
  margin: 0 0 0 0;
  
  color: ${props => props.color};
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: ${props => props.hover};
  }
`;