import React from "react";
import styled from "styled-components";

import URLIcon from '../../image/WhiteUrlIcon.png';
import MainPageMovementViewBox from "./MainPageMovementViewBox";

export default function WodViewBox(props) {
  const { sectionData, onClickURL, isClickedRanking, isMainPage } = props;

  // 한자리 숫자 앞에 0 붙이기 //
  function numberPad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  }

  // For Time Timecap 계산 //
  function decideTimeCap() {
    let min = parseInt(sectionData.timeLimit / 60);
    let sec = parseInt(sectionData.timeLimit % 60);

    if (min > 0 && sec > 0) return 'Timecap ' + min + 'min ' + sec + 'sec';
    else if (min > 0) return 'Timecap ' + min + 'min';
    else if (sec > 0) return 'Timecap ' + sec + 'sec';
    else return 'Timecap 0sec';
  }

  // TABATA ON OFF 계산 //
  function decideTABATAOnOff(division) {
    if (division === 'ON') {
      let min = parseInt(sectionData.exerciseTime / 60);
      let sec = parseInt(sectionData.exerciseTime % 60);

      if (min > 0 && sec > 0) return min + ':' + numberPad(sec, 2);
      else if (min > 0) return min + ':00'
      else if (sec > 0) return '0:' + numberPad(sec, 2);
      else return '0:00';
    }
    else {
      let min = parseInt(sectionData.restTime / 60);
      let sec = parseInt(sectionData.restTime % 60);

      if (min > 0 && sec > 0) return min + ':' + numberPad(sec, 2);
      else if (min > 0) return min + ':00'
      else if (sec > 0) return '0:' + numberPad(sec, 2);
      else return '0:00';
    }
  }

  return (
    <WodBoxWrapper>
      {
        sectionData.tag === 'EMOM' &&
        <TABATASectionWrapper>
          <TABATARoundBox>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{sectionData.round + 'R'}</Text>
          </TABATARoundBox>
          <EMOMOnBox backgroundColor='#6DD49E'>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>{'ON ' + decideTABATAOnOff('ON')}</Text>
          </EMOMOnBox>
        </TABATASectionWrapper>
      }
      {
        sectionData.tag === 'TABATA' &&
        <TABATASectionWrapper>
          <TABATARoundBox>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{sectionData.round + 'R'}</Text>
          </TABATARoundBox>
          <TABATAOnOffBox backgroundColor='#FF8B48'>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>{'ON ' + decideTABATAOnOff('ON')}</Text>
          </TABATAOnOffBox>
          <TABATAOnOffBox backgroundColor='#6DD49E'>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>{'REST ' + decideTABATAOnOff('OFF')}</Text>
          </TABATAOnOffBox>
        </TABATASectionWrapper>
      }
      {
        (sectionData.youtubeLink !== undefined && sectionData.youtubeLink !== '') &&
        <VideoBoxWrapper>
          {
            (sectionData.youtubeLink !== undefined && sectionData.youtubeLink !== '') &&
            <VideoViewWrapper>
              <iframe
                width="100%"
                height="100%"
                src={(sectionData.youtubeLink !== undefined && sectionData.youtubeLink !== '') ? `https://www.youtube.com/embed/` + sectionData.youtubeLink.replace('https://www.youtube.com/watch?v=', '').replace('https://youtube.com/shorts/', '').replace('https://youtu.be/', '') : ''}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube" />
            </VideoViewWrapper>
          }
          {
            (sectionData.youtubeLinks !== undefined) &&
            sectionData.youtubeLinks.map((linkData, idx) => (
              <VideoViewWrapper key={'Video-' + idx}>
                <iframe
                  width="100%"
                  height="100%"
                  src={`https://www.youtube.com/embed/` + linkData.replace('https://www.youtube.com/watch?v=', '').replace('https://youtube.com/shorts/', '').replace('https://youtu.be/', '')}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube" />
              </VideoViewWrapper>
            ))
          }
        </VideoBoxWrapper>
      }
      <DivisionLine style={{ margin: '18px 0px 8px 0px' }} />
      <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#666666' hover='default'>오늘의 와드</Text>
      <WodBox isClickedRanking={isClickedRanking || !isMainPage}>
        {
          sectionData.tag !== 'SINGLE' &&
          <ProgramBox isClickedRanking={isClickedRanking || !isMainPage || sectionData.mainNote === ''}>
            <NoteViewBox>
              <ProgramSectionBox>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#FFFFFF' hover='default'>Program</Text>
              </ProgramSectionBox>
              <NoteTextAreaWrapper isExistURL={sectionData.mainProgramURL.length !== 0}>
                <PreText fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{sectionData.mainProgram}</PreText>
              </NoteTextAreaWrapper>
              {
                sectionData.mainProgramURL.map((data, index) => (
                  <URLBox
                    key={'MainProgramURL-' + index}
                    isLast={(sectionData.mainProgramURL.length === (index + 1)) && !sectionData.isUseTimeLimit}>
                    <URLWrapper onClick={() => onClickURL(data)}>
                      <URLIconBox isLast={(sectionData.mainProgramURL.length === (index + 1)) && !sectionData.isUseTimeLimit} backgroundColor='#969696'>
                        <Icon src={URLIcon} />
                      </URLIconBox>
                      <URLTextWrapper>
                        <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='pointer'>{data}</Text>
                      </URLTextWrapper>
                    </URLWrapper>
                  </URLBox>
                ))
              }
              {
                sectionData.isUseTimeLimit &&
                <TimeLimitBar>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>{decideTimeCap()}</Text>
                </TimeLimitBar>
              }
            </NoteViewBox>
          </ProgramBox>
        }
        {
          sectionData.tag === 'SINGLE' &&
          <ProgramBox isClickedRanking={isClickedRanking || !isMainPage}>
            <SingleWodBox>
              <MainPageMovementViewBox sectionData={sectionData} />
            </SingleWodBox>
          </ProgramBox>
        }
        {
          sectionData.mainNote !== '' &&
          <NoteBox isClickedRanking={isClickedRanking || !isMainPage}>
            <NoteViewBox style={sectionData.tag === 'SINGLE' ? { margin: '20px 0px' } : { marginBottom: '20px' }}>
              <NoteSectionBox>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#FFFFFF' hover='default'>Notes</Text>
              </NoteSectionBox>
              <NoteTextAreaWrapper>
                <PreText fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F'>{sectionData.mainNote}</PreText>
              </NoteTextAreaWrapper>
              {
                sectionData.mainNoteURL.map((data, index) => (
                  <URLBox
                    key={'MainNoteURL-' + index}
                    isLast={sectionData.mainNoteURL.length === (index + 1)} >
                    <URLWrapper onClick={() => onClickURL(data)}>
                      <URLIconBox isLast={sectionData.mainNoteURL.length === (index + 1)} backgroundColor='#FF8B48'>
                        <Icon src={URLIcon} />
                      </URLIconBox>
                      <URLTextWrapper>
                        <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='pointer'>{data}</Text>
                      </URLTextWrapper>
                    </URLWrapper>
                  </URLBox>
                ))
              }
            </NoteViewBox>
          </NoteBox>
        }
      </WodBox>
    </WodBoxWrapper>
  )
}


const WodBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justifyo-content: flex-start;
  align-items: flex-start;

  margin-top: 10px;

  width: 100%;
`;

const TABATASectionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  // margin-top: -15px;
  margin-bottom: 10px; 

  width: 100%;
`;

const TABATARoundBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 18%;
  height: 31px;

  border-radius: 6px;
  background-color: #F9F9F9;
`;

const TABATAOnOffBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 40%;
  height: 31px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
`;

const EMOMOnBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 80%;
  height: 31px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
`;

const VideoBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 10px;

  width: 100%;
  height: 274px;

  gap: 18px;

  overflow-x: scroll;
  overflow-y: hidden;


  &::-webkit-scrollbar {
    width: 1px;
    height: 6px;
  }
  
  &::-webkit-scrollbar-thumb {
    // border: 2px solid transparent;
    height: 6px;
    background-color: #BFBFBF;
    border-radius: 2px;
  }
  
  &::-webkit-scrollbar-track {
    // width: 1px;
    height: 2px;
    background-color: #F9F9F9;
    border-radius: 200px;
    // opacity: 0;D4D4D4
  }
`;

const VideoViewWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  min-width: 489px;
  height: 254px;
  
  border-radius: 10px;

  opacity: 1;
  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const WodBox = styled.div`
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 20px;

  width: 100%;

  gap: ${props => props.isClickedRanking ? '12px' : '0px'};
  
  transition: all 0.3s ease-in-out;
`;


const ProgramBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.isClickedRanking ? `100%` : `60%`};
  
  transition: all 0.3s ease-in-out;
`;

const SingleWodBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: calc(100% + 30px);
  width: 100%;

  background-color: #FFFFFF;
`;

const NoteBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.isClickedRanking ? `100%` : `39%`};
  
  transition: all 0.3s ease-in-out;
`;

const NoteViewBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  // border: 1px solid #E0E0E0;
  // border-radius: 4px;
  background-color: #FFFFFF;
`;

const ProgramSectionBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 5px;

  // width: 92px;
  height: 23px;

  border-radius: 5px 5px 0px 0px;
  background-color: #969696;
`;

const NoteSectionBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 5px;

  // width: 71px;
  height: 23px;

  border-radius: 5px 5px 0px 0px;
  background-color: #FF8B48;
`;

const NoteTextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 8px 15px 14px 15px;
  // margin: 8px 15px 14px 15px;

  width: calc(100% - 30px);
  
  border-radius: ${props => props.isExistURL ? `0px 4px 0px 0px` : `0px 4px 4px 4px`};
  background-color: #F9F9F9;
`;

const PreText = styled.pre`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  width: 100%;

  white-space: pre-wrap;
  word-wrap: break-word;
`;

const DivisionLine = styled.div`
  width: 100%;
  height: 2px;

  background-color: #ECECEC;
`;


const URLBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 28px;

  border-bottom-right-radius: ${props => props.isLast ? '4px' : '0px'};

  background-color: #F9F9F9;
`;

const URLWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: calc(100% - 30px);
  height: 100%;

  gap: 10px;
`;

const URLIconBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 46px;
  height: 100%;

  border-bottom-left-radius: ${props => props.isLast ? '4px' : '0px'};
  background-color: ${props => props.backgroundColor};
`;

const Icon = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const URLTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: calc(100% - 56px);
  height: 20px;

  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const TimeLimitBar = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 24px;

  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #FF3131;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;