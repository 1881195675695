import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import moment from "moment";
import lodash from 'lodash';

import WodDetailBox from "../Model/WodDeatilBox";
import ScaleWodDetaiBox from "../Model/ScaleWodDetailBox";
import FetchModule from "../../Model/Share/Network/FetchModule";
import WodCalender from "../Model/WodCalender";
import UserProfile from "../../Component/Normal/UserProfile";

import { makeUpWorkout, willSaveWorkout } from "../Transaction/Transaction";

import LeftArrow from '../../image/Left.png'//왼쪽 화살표
import RightArrow from '../../image/Right.png'//우측 화살표
import RankingBox from "../Model/RankingBox";
import RankingDetailBox from "../Model/RankingDetailBox";
import DefaultLogoPath from '../../image/DefaultLogo.png';
import OneOnOneMemberSelectModal from "../Model/OneOnOneMemberSelectModal";

export default function OneOnOnePage() {

  const [storeName, setStoreName] = useState('');
  const [storeLogo, setStoreLogo] = useState('');

  const [dates, setDates] = useState([]);
  const [standardDate, setStandardDate] = useState(moment());
  // const [totalWorkoutData, setTotalWorkoutData] = useState([]);

  const [lessonOptions, setLessonOptions] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState({ id: 0, name: '', color: '#FF8B48' });

  const [clickDate, setClickDate] = useState(undefined);
  const [clickData, setClickData] = useState(undefined);
  const [clickRankingData, setClickRankingData] = useState(undefined);
  const [clickDetailRankingData, setClickDetailRankingData] = useState(undefined);
  const [clickUserId, setClickUserId] = useState(undefined);

  const [clickScaleData, setClickScaleData] = useState(undefined);
  const [clickWeekIndex, setClickWeekIndex] = useState(undefined);
  const [clickDayIndex, setClickDayIndex] = useState(undefined);
  const [clickWodIndex, setClickWodIndex] = useState(undefined);

  const [isViewSelectedMemberModal, setIsViewSelectedMemberModal] = useState(false);
  const [members, setMembers] = useState([]);
  const [memberIndex, setMemberIndex] = useState(-1);

  const [prevCalenderScroll, setPrevCalenderScroll] = useState(undefined);

  const [copyWodInfo, setCopyWodInfo] = useState(undefined);
  const [lastCopyWodInfo, setLastCopyWodInfo] = useState(undefined);

  const [nowSection, setNowSection] = useState(0);
  const [nowScaleSection, setNowScaleSection] = useState(0);

  const [wodCalenderWidth, setWodCalenderWidth] = useState(100);
  const [wodDetailBoxWidth, setWodDetailBoxWidth] = useState('0px');
  const [scaleWodDetailBoxWidth, setScaleWodDetailBoxWidth] = useState('0px');
  const [rankingBoxWidth, setRankingBoxWidth] = useState('0px');
  const [rankingDetailBoxWidth, setRankingDetailBoxWidth] = useState('0px');

  const [isSaving, setIsSaving] = useState(false);

  const [movements, setMovements] = useState([]);
  const [recentlySearchHistory, setRecentlySearchHistory] = useState([]);

  const [modifyStartPopUp, setModifyStartPopUp] = useState({
    width: 0,
    marginTop: 35,
    opacity: 0
  });

  const [saveCompletePopUp, setSaveCompletePopUp] = useState({
    width: 0,
    marginTop: 35,
    opacity: 0
  });

  const [openPopUp, setOpenPopUp] = useState({
    width: 0,
    marginTop: 35,
    opacity: 0
  });

  const [lockPopUp, setLockPopUp] = useState({
    width: 0,
    marginTop: 35,
    opacity: 0
  });

  const [maxHeight, setMaxHeight] = useState(window.innerHeight - 20);

  const CalenderScrollRef = useRef(null);

  const today = moment();

  const firstWeek = standardDate.clone().startOf('month').week();
  const lastWeek = standardDate.clone().endOf('month').week() === 1 ? 53 : standardDate.clone().endOf('month').week();

  const EmptySection = {
    title: '',
    isUseTimeLimit: false,
    timeLimit: 0,
    isUseBuyin: false,

    scaleSections: [],

    buyinExerciseList: [],
    buyinScaleOptionExerciseList: [],
    tag: 'ForTime',
    round: 1,
    roundList: [],
    reps: [0],
    exerciseTime: 0,
    restTime: 0,
    mainExerciseList: [],
    mainScaleOptionExerciseList: [],
    emomExerciseList: [],
    emomScaleOptionExerciseList: [],
    isUseBuyout: false,
    buyoutExerciseList: [],
    buyoutScaleOptionExerciseList: [],
    customTitle: '',
    scoreKeyword: '점수 없음',
    scorePriority: '높을 수록',
    description: '',
    buyinNote: '',
    mainProgram: '',
    mainProgramURL: [],
    mainNote: '',
    mainNoteURL: [],
    buyoutNote: '',
    isViewVideo: false,
    isClickedYoutubeButton: false,
    youtubeLink: '',
    youtubeLinks: []
  }

  const EmptyWod = {
    workoutId: undefined,
    title: '',
    storeName: storeName,
    storeLogo: storeLogo,
    userId: 'None',
    scheduleId: 0,
    lessonName: selectedLesson.name,
    lessonId: selectedLesson.id,
    isSendPushNotification: false,
    classDate: today.format('YYYY-MM-DDT00:00:00'),
    sectionList: [EmptySection],
    wodVersion: 2,
    isLock: false,
    isCompleteSaved: false,
  }

  const handleResize = () => {
    // setMaxHeight(window.innerHeight - 229);
    setMaxHeight(window.innerHeight - 38);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      // cleanup
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // 현재 페이지 내의 채널톡 지우기 //
  useEffect(() => {
    setTimeout(() => {
      try {
        document.getElementById('ch-plugin').style.opacity = 0;
        document.getElementById('ch-plugin').style.zIndex = -10;
      }
      catch (e) {
        console.log(e);

        setTimeout(() => {
          try {
            document.getElementById('ch-plugin').style.opacity = 0;
            document.getElementById('ch-plugin').style.zIndex = -10;
          }
          catch (e) {
            console.log(e);
          }
        }, 500)
      }
    }, 100)
  }, [])

  // 회원 불러오기 및 정리 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        let responseData = await fetchModule.getDataOfExercise('one-on-one/member_list');
        // console.log(responseData.data);
        if (responseData.status === 200) {
          let newMembers = responseData.data.slice();

          newMembers.map((data, index) => (
            data.index = index
          ))

          setMembers(newMembers);

          if (responseData.data.length !== 0) setMemberIndex(0);
        }
      }
    )();
  }, [])

  // 달력 날짜 정리 및 API 호출 //
  useEffect(() => {
    if (memberIndex === -1) return;

    let monthDates = [];

    for (let week = firstWeek; week <= lastWeek; week++) {
      let weekDates = [];

      for (let i = 0; i < 7; i++) {
        let date = standardDate.clone().startOf('year').week(week).startOf('week').add(i, 'day');

        let newData = {
          date: date,
          rendering: false,
          wods: []
        }

        weekDates.push(newData);
      }

      monthDates.push(weekDates);
    }


    let requestData = {
      userId: members[memberIndex].linkCoachId,
      startDate: monthDates[0][0].date.format('YYYY-MM-DDT00:00:00'),
      endDate: monthDates[lastWeek - firstWeek][6].date.format('YYYY-MM-DDT23:59:59')
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfExerciseVersion2('one_on_one/monthly', 'POST', requestData);
        // console.log(responseData.data);
        if (responseData.status === 200) {
          setStoreName(responseData.data.storeName);
          setStoreLogo(responseData.data.storeLogo);

          let workoutData = makeUpWorkout(responseData.data.workouts.slice());

          // for (let i = 0; i < workoutData.length; i++) {
          //   for (let j = 0; j < monthDates.length; j++) {
          //     for (let h = 0; h <)
          //   }
          // }

          for (let i = 0; i < monthDates.length; i++) {
            for (let j = 0; j < monthDates[i].length; j++) {
              for (let h = 0; h < workoutData.length; h++) {
                if (moment(workoutData[h].classDate).format('YY.MM.DD') === monthDates[i][j].date.format('YY.MM.DD')) {
                  workoutData[h].isCompleteSaved = true;
                  monthDates[i][j].wods.push(workoutData[h]);
                }
              }
            }
          }
          // console.log(monthDates);
          // setTotalWorkoutData(workoutData);
          setDates(monthDates);
        }
        // else if (responseData.status === 204) setTotalWorkoutData([]);
        else {
          // setTotalWorkoutData([]);
          setDates(monthDates);
        }
      }
    )();
  }, [standardDate, firstWeek, lastWeek, members, memberIndex])

  // 업체 수업 정보 불러오기 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('lesson/zone', 'GET');

        if (responseData.status === 200) {

          if (responseData.data.length > 0) setSelectedLesson(responseData.data[0]);
          setLessonOptions(responseData.data);
        }
      }
    )();
  }, [])

  // Movement Data Setting //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataOfExercise('movement/list', 'GET');
        // console.log(responseData.data)
        if (responseData.status === 200) setMovements(responseData.data);
      }
    )();
  }, [])

  // 저장된 최근 검색어 불러오기 //
  useEffect(() => {
    let resentlyText = window.localStorage.getItem('RecentlySearchText');
    let searchData = [];

    if (resentlyText === null || resentlyText === undefined || resentlyText === '') searchData = [];
    else searchData = resentlyText.split('!@!');

    searchData = searchData.reverse();

    setRecentlySearchHistory(searchData);
  }, [])

  // Delete 키를 사용하기 위한 키보드 이벤트 핸들러 //
  useEffect(() => {
    document.addEventListener('keydown', onKeyDownGroupPage);

    return () => {
      document.removeEventListener('keydown', onKeyDownGroupPage);
    }
  }, [onKeyDownGroupPage])

  // 한자리 숫자 앞에 0 붙이기 //
  function numberPad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  }

  // // 와드 켈린더 스크롤 높이 정리 //
  // function onScrollWodCalender(e) {
  //   setCalenderScroll(e.target.scrollTop);
  // }  

  // 월 단위 날짜 변경 클릭시 //
  function onClickDateArrow(division) {

    setClickDate(undefined);
    setClickData(undefined);
    setClickWeekIndex(undefined);
    setClickDayIndex(undefined);
    setClickWodIndex(undefined);
    setClickRankingData(undefined);
    setClickDetailRankingData(undefined);

    setNowScaleSection(undefined);
    setNowSection(0);

    // 그 이후 달력 및 와드 뷰 크기 정리하는 로직 //
    setWodCalenderWidth(100);
    setWodDetailBoxWidth('0px');
    setScaleWodDetailBoxWidth('0px');
    setRankingBoxWidth('0px');
    setRankingDetailBoxWidth('0px');

    if (division === 'Prev') setStandardDate(standardDate.clone().subtract(1, 'month'));
    else if (division === 'Next') setStandardDate(standardDate.clone().add(1, 'month'));
  }

  // 달력에서 와드 또는 추가하기 클릭시 //
  function onClickCalenderWod(date, data, weekIndex, dayIndex, wodIndex) {
    // 이전에 클릭한 데이터가 있는경우 //
    if (clickWeekIndex !== undefined && clickDayIndex !== undefined && clickWodIndex !== undefined) {
      let wod = dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex];

      if (wod.workoutId === undefined
        && wod.sectionList.length === 1
        && wod.sectionList[0].mainProgram === ''
        && wod.sectionList[0].mainNote === ''
        && (clickWeekIndex !== weekIndex
          || clickDayIndex !== dayIndex
          || clickWodIndex !== wodIndex)) {
        dates[clickWeekIndex][clickDayIndex].wods.splice(clickWodIndex, 1);
        refreshTotalData();
      }
    }

    setNowSection(0);
    setNowScaleSection(undefined);

    setClickDate(date);
    setClickData(data);
    setClickWeekIndex(weekIndex);
    setClickDayIndex(dayIndex);

    setNowScaleSection(undefined);

    // 이미 데이터가 있는경우 //
    if (wodIndex !== undefined) setClickWodIndex(wodIndex);
    // 새로만들기의 경우 //
    else {
      dates[weekIndex][dayIndex].wods.push(EmptyWod);
      refreshTotalData();

      setClickWodIndex(0);
      // setClickWodIndex(dates[weekIndex][dayIndex].wods.length - 1);

      if (dates[weekIndex][dayIndex].wods.length === 0) setClickWodIndex(0);
      else setClickWodIndex(dates[weekIndex][dayIndex].wods.length - 1);

      try {
        let id = ('Wod-' + weekIndex.toString() + '-' + dayIndex.toString() + '-' + (dates[weekIndex][dayIndex].wods.length - 1).toString());

        setTimeout(() => (
          document.getElementById(id).focus()
        ), 250)


        setModifyStartPopUp({
          width: 214,
          marginTop: -60,
          opacity: 1
        })

        setTimeout(() => (
          setModifyStartPopUp({
            width: 0,
            marginTop: 35,
            opacity: 0
          })
        ), 1500)
      }
      catch (e) {
        console.log(e)
      }
    }

    // 그 이후 달력 및 와드 뷰 크기 정리하는 로직 //
    setWodCalenderWidth(60);
    setClickRankingData(undefined);
    setWodDetailBoxWidth('520px');
    setScaleWodDetailBoxWidth('0px');
    setRankingBoxWidth('0px');
    setRankingDetailBoxWidth('0px');
  }

  // 와드박스에서 스케일 옵션 클릭시 //
  function onClickScaleWod(data) {
    setClickScaleData(data);

    setWodCalenderWidth(31);
    // setWodDetailBoxWidth(31);
    // setScaleWodDetailBoxWidth(31);

    // setWodCalenderWidth('calc(100% - 1040px)');
    setWodDetailBoxWidth('520px');
    setScaleWodDetailBoxWidth('520px');

    if (prevCalenderScroll === undefined) setPrevCalenderScroll(CalenderScrollRef.current.scrollTop);
  }

  // 와드 자세히보기에서 뒤로가기 클릭시 //
  function onClickExitWodDetailBox() {
    // 이전에 클릭한 데이터가 있는경우 //
    if (clickWeekIndex !== undefined && clickDayIndex !== undefined && clickWodIndex !== undefined) {
      let wod = dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex];

      if (wod.workoutId === undefined
        && wod.sectionList.length === 1
        && wod.sectionList[0].mainProgram === ''
        && wod.sectionList[0].mainNote === '') {
        dates[clickWeekIndex][clickDayIndex].wods.splice(clickWodIndex, 1);
        refreshTotalData();
      }
    }

    setClickDate(undefined);
    setClickData(undefined);
    setClickWeekIndex(undefined);
    setClickDayIndex(undefined);
    setClickWodIndex(undefined);
    setClickRankingData(undefined);
    setClickDetailRankingData(undefined);

    setNowScaleSection(undefined);
    setNowSection(0);

    // 그 이후 달력 및 와드 뷰 크기 정리하는 로직 //
    setWodCalenderWidth(100);
    // setWodDetailBoxWidth(0);
    // setScaleWodDetailBoxWidth(0);
    // setWodCalenderWidth('100%');
    setWodDetailBoxWidth('0px');
    setScaleWodDetailBoxWidth('0px');
    setRankingBoxWidth('0px');
    setRankingDetailBoxWidth('0px');

    if (prevCalenderScroll !== undefined) {
      setTimeout(() => {
        CalenderScrollRef.current.scrollTo({ top: prevCalenderScroll });
        setPrevCalenderScroll(undefined);
      }, 250)
    }
  }

  // 스케일 와드 자세히보기에서 뒤로가기 클릭시 //
  function onClickExitScaleWodDetailBox() {
    setNowScaleSection(undefined);
    setClickRankingData(undefined);
    setClickDetailRankingData(undefined);

    setWodCalenderWidth(60);

    setWodDetailBoxWidth('520px');
    setScaleWodDetailBoxWidth('0px');
    setRankingBoxWidth('0px');
    setRankingDetailBoxWidth('0px');

    setTimeout(() => {
      CalenderScrollRef.current.scrollTo({ top: prevCalenderScroll });
      setPrevCalenderScroll(undefined);
    }, 250)
  }

  // 와드 수정 또는 저장 클릭시 애니메이션 //
  function onClickEditAndSave() {
    setNowScaleSection(undefined);
    setClickRankingData(undefined);
    setClickDetailRankingData(undefined);

    setWodCalenderWidth(60);

    setWodDetailBoxWidth('520px');
    setScaleWodDetailBoxWidth('0px');
    setRankingBoxWidth('0px');
    setRankingDetailBoxWidth('0px');
  }

  // 섹션 추가 클릭시 //
  function onClickAddSection() {
    let newDates = dates.slice();

    newDates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].sectionList.push(EmptySection);

    setDates(newDates);

    setClickScaleData(undefined);

    setNowScaleSection(undefined);

    setWodCalenderWidth(60);
    setWodDetailBoxWidth('520px');
    setScaleWodDetailBoxWidth('0px');
    setRankingBoxWidth('0px');
    setRankingDetailBoxWidth('0px');
  }

  // 섹션 삭제 클릭시 //
  function onClickDeleteSection(index) {
    // let newDates = dates.slice();
    if (dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].sectionList.length > 1) {
      dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].sectionList.splice(index, 1);

      if (nowSection !== 0) setNowSection(nowSection - 1);

      // setDates(newDates);

      setClickScaleData(undefined);

      setNowScaleSection(undefined);

      setWodCalenderWidth(60);
      setWodDetailBoxWidth('520px');
      setScaleWodDetailBoxWidth('0px');
      setRankingBoxWidth('0px');
      setRankingDetailBoxWidth('0px');

      refreshTotalData();
    }
    else {
      if (dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].workoutId === undefined) {
        dates[clickWeekIndex][clickDayIndex].wods.splice(clickWodIndex, 1);

        setClickDate(undefined);
        setClickData(undefined);
        setClickWeekIndex(undefined);
        setClickDayIndex(undefined);
        setClickWodIndex(undefined);
        setClickRankingData(undefined);
        setClickDetailRankingData(undefined);

        setNowScaleSection(undefined);
        setNowSection(0);

        // 그 이후 달력 및 와드 뷰 크기 정리하는 로직 //
        setWodCalenderWidth(100);
        setWodDetailBoxWidth('0px');
        setScaleWodDetailBoxWidth('0px');
        setRankingBoxWidth('0px');
        setRankingDetailBoxWidth('0px');

        refreshTotalData();
      }
      // 이미 서버에 저장된 데이터면 서버에서 지우기 //
      else {

        // completeDeleteWod();
        let requestData = {
          workoutId: dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].workoutId
        }

        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataOfExerciseVersion2('workout/delete', 'POST', requestData);

            if (responseData.status === 200) {
              // if 
              completeDeleteWod();
            }
            else if (responseData.status === 201) {
              alert('이미 삭제되었거나 존재하지 않는 데이터입니다.');
              completeDeleteWod();
            }
            else if (responseData.status === 202) {
              alert('팔로우중인 운동 데이터는 삭제할 수 없습니다');
            }
            else if (requestData.status === 401) {
              alert('올바르지 않은 권한의 접근입니다.');
            }
          }
        )();
      }
    }
  }

  // 와드 삭제시 진행함수 //
  function completeDeleteWod(originalWod) {
    setClickDate(undefined);
    setClickData(undefined);
    setClickWeekIndex(undefined);
    setClickDayIndex(undefined);
    setClickWodIndex(undefined);
    setClickRankingData(undefined);
    setClickDetailRankingData(undefined);

    setNowScaleSection(undefined);
    setNowSection(0);

    // 그 이후 달력 및 와드 뷰 크기 정리하는 로직 //
    setWodCalenderWidth(100);
    setWodDetailBoxWidth('0px');
    setScaleWodDetailBoxWidth('0px');
    setRankingBoxWidth('0px');
    setRankingDetailBoxWidth('0px');

    if (originalWod === undefined) dates[clickWeekIndex][clickDayIndex].wods.splice(clickWodIndex, 1);
    else {
      // let newWod = [];

      // newWod.push(JSON.parse(JSON.stringify(originalWod)));
      dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex] = undefined;

      let finalWod = makeUpWorkout(JSON.parse(JSON.stringify(originalWod)))[0];
      finalWod.isCompleteSaved = true;
      dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex] = finalWod;
    }

    refreshTotalData();
  }

  // 스케일 섹션 추가 클릭시 //
  function onClickAddScaleSection(index) {
    let newDates = dates.slice();

    // newDates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].sectionList[index].scaleSections.push(EmptySection);

    let newScaleSection = lodash.cloneDeep(newDates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].sectionList[index]);

    newScaleSection.workoutId = undefined;
    newScaleSection.scaleSections = [];

    delete newScaleSection.sectionId;
    delete newScaleSection.sectionIndex;
    delete newScaleSection.createdDate;
    delete newScaleSection.modifiedDate;

    newDates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].sectionList[index].scaleSections.push(newScaleSection);
    // console.log(newScaleSection);

    setDates(newDates);

    // setClickScaleData(EmptySection);
    setClickScaleData(newScaleSection);

    setNowScaleSection(newDates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].sectionList[index].scaleSections.length - 1);

    setWodCalenderWidth(31);
    setWodDetailBoxWidth('520px');
    setScaleWodDetailBoxWidth('520px');
    setRankingBoxWidth('0px');
    setRankingDetailBoxWidth('0px');

    if (prevCalenderScroll === undefined) setPrevCalenderScroll(CalenderScrollRef.current.scrollTop);
  }

  // 스케일 섹션 삭제 클릭시 //
  function onClickDeleteScaleSection(index) {
    let newDates = dates.slice();

    newDates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].sectionList[nowSection].scaleSections.splice(index, 1);

    setDates(newDates);

    setClickScaleData(undefined);
    setNowScaleSection(undefined);

    setWodCalenderWidth(60);
    setWodDetailBoxWidth('520px');
    setScaleWodDetailBoxWidth('0px');
    setRankingBoxWidth('0px');
    setRankingDetailBoxWidth('0px');

    setTimeout(() => {
      CalenderScrollRef.current.scrollTo({ top: prevCalenderScroll });
      setPrevCalenderScroll(undefined);
    }, 250)
  }

  // 랭킹 불러오기 클릭시 //
  function onClickRanking() {
    setClickRankingData(dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex]);

    setWodCalenderWidth(31);
    setWodDetailBoxWidth('520px');
    setRankingBoxWidth('520px');
    setRankingDetailBoxWidth('0px');

    if (prevCalenderScroll === undefined) setPrevCalenderScroll(CalenderScrollRef.current.scrollTop);
  }

  // 랭킹 자세히 보기 클릭시 //
  function onClickRankingBox(data) {
    // setClickRankingData(dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex]);
    // console.log(data);

    // setClickUserId(data.linkCoachId);
    // console.log(data);
    setClickDetailRankingData(data);

    setWodCalenderWidth(0);
    setWodDetailBoxWidth('520px');
    setRankingBoxWidth('520px');
    setRankingDetailBoxWidth('520px');
  }

  // 옛날버전 랭킹 자세히 보기 클릭시 //
  function onClickLegacyRankingBox(data) {
    // setClickRankingData(dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex]);
    // console.log(data);

    setClickUserId(data.linkCoachId);
    // setClickRankingData(data);

    setWodCalenderWidth(0);
    setWodDetailBoxWidth('520px');
    setRankingBoxWidth('520px');
    setRankingDetailBoxWidth('520px');
  }

  // 랭킹 자세히 보기 닫기 클릭시 //
  function onClickExitRankingDetail() {
    setClickDetailRankingData(undefined);

    setWodCalenderWidth(31);
    setWodDetailBoxWidth('520px');
    setRankingBoxWidth('520px');
    setRankingDetailBoxWidth('0px');

    // setTimeout(() => {
    //   CalenderScrollRef.current.scrollTo({ top: prevCalenderScroll });
    //   setPrevCalenderScroll(undefined);
    // }, 250)
  }

  // 데이터 최신화 //
  function refreshTotalData() {
    let newDates = dates.slice();
    // console.log(newDates);
    setDates(newDates);
  }

  // 작성 도중 Delete키 인식하기 //
  function onKeyDownGroupPage(e) {
    // const code = e.keyCode;
    // let charCode = String.fromCharCode(code).toUpperCase();
    // console.log(code);
    // console.log(e.keyCode);
    // console.log(clickWeekIndex);
    // console.log(clickDayIndex);
    // console.log(clickWodIndex);
    // Delete 버튼 입력시 //
    if (e.keyCode === 46) {
      if (clickWeekIndex !== undefined
        && clickDayIndex !== undefined
        && clickWodIndex !== undefined) {
        // console.log(dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex])
        if (dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].workoutId === undefined) {
          // completeDeleteWod();
          // 아무것도 하지 않기 //
        }
        else {

          let requestData = {
            workoutId: dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].workoutId
          }

          const fetchModule = new FetchModule();
          (
            async () => {
              const responseData = await fetchModule.postDataOfExerciseVersion2('workout/delete', 'POST', requestData);

              if (responseData.status === 200) {
                completeDeleteWod(responseData.data);
              }
              else if (responseData.status === 201) {
                alert('이미 삭제되었거나 존재하지 않는 데이터입니다.');
                completeDeleteWod();
              }
              else if (responseData.status === 202) {
                alert('팔로우중인 운동 데이터는 삭제할 수 없습니다');
              }
              else if (requestData.status === 401) {
                alert('올바르지 않은 권한의 접근입니다.');
              }
            }
          )();
        }
      }
    }
  }

  // 수정 저장하기 클릭시 //
  function onClickSaveWod() {
    if (isSaving) {
      alert('저장이 진행중입니다');
      return;
    }
    else if (memberIndex === -1) {
      alert('운동을 저장할 회원을 선택해주세요');
      return;
    }

    setIsSaving(true);

    let requestData = dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex];

    for (let i = 0; i < requestData.sectionList.length; i++) {
      if (requestData.sectionList[i].title === '') {
        if (requestData.sectionList[i].tag === 'ForTime') requestData.sectionList[i].title = requestData.sectionList[i].round + ' Round For Time';
        else if (requestData.sectionList[i].tag === 'AMRAP') {
          let min = parseInt(requestData.sectionList[i].exerciseTime / 60);
          let sec = parseInt(requestData.sectionList[i].exerciseTime % 60);

          if (min > 0 && sec > 0) requestData.sectionList[i].title = min + 'min ' + sec + 'sec AMRAP';
          else if (min > 0) requestData.sectionList[i].title = min + 'min AMRAP';
          else if (sec > 0) requestData.sectionList[i].title = sec + 'sec AMRAP';
          else requestData.sectionList[i].title = 'AMRAP';
        }
        else if (requestData.sectionList[i].tag === 'EMOM') {
          let min = parseInt(requestData.sectionList[i].round * requestData.sectionList[i].exerciseTime / 60);
          let sec = parseInt(requestData.sectionList[i].round * requestData.sectionList[i].exerciseTime % 60);

          if (min > 0 && sec > 0) requestData.sectionList[i].title = min + 'min ' + sec + 'sec EMOM';
          else if (min > 0) requestData.sectionList[i].title = min + ' min EMOM';
          else if (sec > 0) requestData.sectionList[i].title = sec + ' sec EMOM';
          else requestData.sectionList[i].title = 'EMOM';
        }
        else if (requestData.sectionList[i].tag === 'TABATA') {
          let onMin = parseInt(requestData.sectionList[i].exerciseTime / 60);
          let onSec = parseInt(requestData.sectionList[i].exerciseTime % 60);
          let offMin = parseInt(requestData.sectionList[i].restTime / 60);
          let offSec = parseInt(requestData.sectionList[i].restTime % 60);

          // return requestData.sectionList[i].title = onMin + ':' + onSec + '/' + offMin + ':' + offSec + ' ' + requestData.sectionList[i].round + 'R TABATA';
          requestData.sectionList[i].title = 'On: ' + onMin + ':' + numberPad(onSec, 2) + ' / Off:' + offMin + ':' + numberPad(offSec, 2) + ' ' + requestData.sectionList[i].round + 'R TABATA';
        }
        else if (requestData.sectionList[i].tag === 'SINGLE') {
          if (requestData.sectionList[i].mainExerciseList.length <= 0) requestData.sectionList[i].title = 'SINGLE';
          else if (requestData.sectionList[i].mainExerciseList.length === 1) requestData.sectionList[i].title = requestData.sectionList[i].mainExerciseList[0].movementName;
          else requestData.sectionList[i].title = 'Multiple Single';
        }
        else if (requestData.sectionList[i].tag === 'Custom') {
          if (requestData.sectionList[i].scoreKeyword === '점수 없음'
            || requestData.sectionList[i].scoreKeyword === '피크와트'
            || requestData.sectionList[i].scoreKeyword === '최고 속도') requestData.sectionList[i].title = 'Custom : ' + requestData.sectionList[i].scoreKeyword;
          else requestData.sectionList[i].title = 'Custom : ' + requestData.sectionList[i].scoreKeyword + ' ' + requestData.sectionList[i].scorePriority;
        }
      }



      let mainProgramURLs = '';
      let mainNoteURLs = '';

      // 본문 내 URL 첨부 //
      for (let j = 0; j < requestData.sectionList[i].mainProgramURL.length; j++) {
        if (j === 0) mainProgramURLs += requestData.sectionList[i].mainProgramURL[j];
        else mainProgramURLs += ' ' + requestData.sectionList[i].mainProgramURL[j];
      }

      // Note 내 URL 첨부 //
      for (let j = 0; j < requestData.sectionList[i].mainNoteURL.length; j++) {
        if (j === 0) mainNoteURLs += requestData.sectionList[i].mainNoteURL[j];
        else mainNoteURLs += ' ' + requestData.sectionList[i].mainNoteURL[j];
      }

      requestData.sectionList[i].mainProgram += mainProgramURLs;
      requestData.sectionList[i].mainNote += mainNoteURLs;

      // Scale 내에 첨부된 URL 정리 //
      for (let j = 0; j < requestData.sectionList[i].scaleSections.length; j++) {
        let scaleMainProgramURLs = '';
        let scaleMainNoteURLs = '';

        for (let h = 0; h < requestData.sectionList[i].scaleSections[j].mainProgramURL.length; h++) {
          if (h === 0) scaleMainProgramURLs += requestData.sectionList[i].scaleSections[j].mainProgramURL[h];
          else scaleMainProgramURLs += ' ' + requestData.sectionList[i].scaleSections[j].mainProgramURL[h];
        }

        for (let h = 0; h < requestData.sectionList[i].scaleSections[j].mainNoteURL.length; h++) {
          if (h === 0) scaleMainNoteURLs += requestData.sectionList[i].scaleSections[j].mainNoteURL[h];
          else scaleMainNoteURLs += ' ' + requestData.sectionList[i].scaleSections[j].mainNoteURL[h];
        }

        requestData.sectionList[i].scaleSections[j].mainProgram += scaleMainProgramURLs;
        requestData.sectionList[i].scaleSections[j].mainNote += scaleMainNoteURLs;
      }
    }

    requestData.userId = members[memberIndex].linkCoachId;
    requestData.lessonId = 0;
    requestData.sectionList = willSaveWorkout(requestData.sectionList.slice());
    requestData.classDate = dates[clickWeekIndex][clickDayIndex].date.format('YYYY-MM-DDT00:00:00');

    if (requestData.title === '') requestData.title = moment(requestData.classDate).format('YYYY.MM.DD');

    requestData.lessonName = requestData.title;
    // console.log(requestData);
    // setIsSaving(false);
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfExerciseVersion2('one_on_one/save', 'POST', requestData);

        if (responseData.status === 200) {
          let newWod = [];

          newWod.push(JSON.parse(JSON.stringify(responseData.data)));

          let finalWod = makeUpWorkout(newWod)[0];

          dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex] = finalWod;
          dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].isCompleteSaved = true;
          refreshTotalData();

          setClickScaleData(undefined);

          setNowScaleSection(undefined);

          setWodCalenderWidth(60);
          setWodDetailBoxWidth('520px');
          setScaleWodDetailBoxWidth('0px');

          setSaveCompletePopUp({
            width: 218,
            marginTop: -60,
            opacity: 1
          })

          setTimeout(() => (
            setSaveCompletePopUp({
              width: 0,
              marginTop: 35,
              opacity: 0
            })
          ), 1500)

          setTimeout(() => {
            CalenderScrollRef.current.scrollTo({ top: prevCalenderScroll });
            setPrevCalenderScroll(undefined);
          }, 250)

          setIsSaving(false);
        }
        else {
          alert('저장에 실패하였습니다\n잠시 후 다시시도해주세요');
          setIsSaving(false);
        }
      }
    )();
  }

  // 회원 변경시 //
  function onChangeMember(index) {
    setClickDate(undefined);
    setClickData(undefined);
    setClickWeekIndex(undefined);
    setClickDayIndex(undefined);
    setClickWodIndex(undefined);
    setClickRankingData(undefined);
    setClickDetailRankingData(undefined);

    setNowScaleSection(undefined);
    setNowSection(0);
    setMemberIndex(index);

    // 그 이후 달력 및 와드 뷰 크기 정리하는 로직 //
    setWodCalenderWidth(100);
    setWodDetailBoxWidth('0px');
    setScaleWodDetailBoxWidth('0px');
    setRankingBoxWidth('0px');
    setRankingDetailBoxWidth('0px');
  }

  return (
    <TopLevelWrapper /*onKeyDown={onKeyDownGroupPage}*/>
      {
        isViewSelectedMemberModal &&
        <OneOnOneMemberSelectModal
          members={members}
          setMemberIndex={onChangeMember}
          setIsViewSelectedMemberModal={setIsViewSelectedMemberModal} />
      }
      <TopLineWrapper>
        <DateButtonWrapper>
          <ArrowWrapper onClick={() => onClickDateArrow('Prev')}>
            <ArrowImage src={LeftArrow} />
          </ArrowWrapper>
          <DateText>{standardDate.clone().format('YYYY년 MM월')}</DateText>
          <ArrowWrapper onClick={() => onClickDateArrow('Next')} >
            <ArrowImage src={RightArrow} />
          </ArrowWrapper>
        </DateButtonWrapper>
        <MemberInfoBox onClick={() => setIsViewSelectedMemberModal(true)}>
          <MemberProfileWrppper>
            <UserProfile profile={memberIndex !== -1 ? members[memberIndex].profile : DefaultLogoPath} defaultProfile={DefaultLogoPath} />
          </MemberProfileWrppper>
          <MemberNameWrapper>
            <Text fontFamily='NotoSansKR-Medium' fontSize={18} color='#000000' hover='cursor'>{memberIndex !== -1 ? members[memberIndex].name === undefined ? '정보 없음' : members[memberIndex].name : '정보 없음'}</Text>
          </MemberNameWrapper>
        </MemberInfoBox>
      </TopLineWrapper>
      <WodViewWrapper>
        <WodCalender
          today={today}
          dates={dates}
          clickWeekIndex={clickWeekIndex}
          clickDayIndex={clickDayIndex}
          clickWodIndex={clickWodIndex}
          width={wodCalenderWidth}
          maxHeight={maxHeight}

          refreshTotalData={refreshTotalData}

          copyWodInfo={copyWodInfo}
          setCopyWodInfo={setCopyWodInfo}
          lastCopyWodInfo={lastCopyWodInfo}
          setLastCopyWodInfo={setLastCopyWodInfo}
          onClickCalenderWod={onClickCalenderWod}

          ScrollRef={CalenderScrollRef}
          // completeDeleteWod={completeDeleteWod}

          viewStoreIds={undefined}
          isAllOffWod={false}

          setOpenPopUp={setOpenPopUp}
          setLockPopUp={setLockPopUp} />

        <WodDetailBox
          isGroup={false}
          width={wodDetailBoxWidth}
          lessonOptions={lessonOptions}
          selectedLesson={selectedLesson}
          setSelectedLesson={setSelectedLesson}
          dates={dates}
          clickDate={clickDate}
          clickData={clickData}
          clickWeekIndex={clickWeekIndex}
          clickDayIndex={clickDayIndex}
          clickWodIndex={clickWodIndex}
          totalWorkoutData={dates}

          nowSection={nowSection}
          setNowSection={setNowSection}
          nowScaleSection={nowScaleSection}
          setNowScaleSection={setNowScaleSection}

          movements={movements}
          setMovements={setMovements}
          recentlySearchHistory={recentlySearchHistory}
          setRecentlySearchHistory={setRecentlySearchHistory}

          refreshTotalData={refreshTotalData}

          onClickAddSection={onClickAddSection}
          onClickDeleteSection={onClickDeleteSection}
          onClickAddScaleSection={onClickAddScaleSection}

          onClickScaleWod={onClickScaleWod}
          onClickExitWodDetailBox={onClickExitWodDetailBox}
          onClickExitScaleWodDetailBox={onClickExitScaleWodDetailBox}
          onClickEditAndSave={onClickEditAndSave}

          onClickSaveWod={onClickSaveWod}

          onClickRanking={onClickRanking}

          maxHeight={maxHeight}
          modifyStartPopUp={modifyStartPopUp}
          setModifyStartPopUp={setModifyStartPopUp}
          saveCompletePopUp={saveCompletePopUp}
          setSaveCompletePopUp={setSaveCompletePopUp}
          openPopUp={openPopUp}
          setOpenPopUp={setOpenPopUp}
          lockPopUp={lockPopUp}
          setLockPopUp={setLockPopUp} />

        <ScaleWodDetaiBox
          width={scaleWodDetailBoxWidth}
          clickData={clickData}

          maxHeight={maxHeight}

          dates={dates}
          clickDate={clickDate}
          clickWeekIndex={clickWeekIndex}
          clickDayIndex={clickDayIndex}
          clickWodIndex={clickWodIndex}

          nowSection={nowSection}
          setNowSection={setNowSection}
          nowScaleSection={nowScaleSection}
          setNowScaleSection={setNowScaleSection}

          movements={movements}
          setMovements={setMovements}
          recentlySearchHistory={recentlySearchHistory}
          setRecentlySearchHistory={setRecentlySearchHistory}

          refreshTotalData={refreshTotalData}

          onClickDeleteScaleSection={onClickDeleteScaleSection}
          onClickExitScaleWodDetailBox={onClickExitScaleWodDetailBox} />

        <RankingBox
          width={rankingBoxWidth}
          maxHeight={maxHeight}
          clickRankingData={clickRankingData}
          nowSection={nowSection}
          setNowSection={setNowSection}
          onClickRankingBox={onClickRankingBox}
          onClickLegacyRankingBox={onClickLegacyRankingBox} />

        <RankingDetailBox
          width={rankingDetailBoxWidth}
          maxHeight={maxHeight}
          nowSection={nowSection}
          setNowSection={setNowSection}
          clickDate={clickDate}
          clickWodIndex={clickWodIndex}
          clickUserId={clickUserId}
          clickRankingData={clickRankingData}
          setClickRankingData={setClickRankingData}
          clickDetailRankingData={clickDetailRankingData}
          onClickExitRankingDetail={onClickExitRankingDetail} />
      </WodViewWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  
  // height: 100vh;

  // background-color: #FFFFFF;

  // &::-webkit-scrollbar {
  //   width: 2px;
  //   height: 4px;
  // }

  // &::-webkit-scrollbar-thumb {
	// 	border: 2px solid transparent;
	// 	background-color: #D9D9D9;
	// 	border-radius: 2px;
	// }

  // &::-webkit-scrollbar-track {
	// 	// background-color: #F9F9F9;
	// 	border-radius: 200px;
  //   opacity: 0;
	// }
`;

const WodViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-evenly;
  align-items: flex-start;

  margin-top: 16px;

  width: 100%;
  height: 100%;
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 35px;
  margin-top: 12px;

  width: calc(100% - 70px);
`;

const DateButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // margin-top: 12px;
  // margin: 12px 0px 0px 35px;

  width: 243px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 5px;
  background-color: #FFFFFF;

  gap: 54px;
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 22px;
  height: 22px;

  :hover { 
    cursor: pointer;
  }
`;

const ArrowImage = styled.img`
  display: flex;
  flex-direction: row;

  width: 9px;
  height: 15px;
`;

const MemberInfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  margin-top: -104px;
  margin-right: 60px;

  min-width: 135px;
  height: 41px;

  border-radius: 41px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const MemberProfileWrppper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 41px;
  height: 41px;

  border-radius: 41px;

  overflow: hidden;
`;

const MemberNameWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 0px 22px;
`;

const DateText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;