import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import DefaultLogoPath from '../../image/DefaultLogo.png';
import UserProfile from '../../Component/Normal/UserProfile';
import CenterFollowModal from '../../Model/Individual/MySettingModel/CenterFollowModal';

export default function ProgramFollowBox(props) {
  const {
    follows,
    followers,
    onClickFollow
  } = props;

  const [followStoreIds, setFollowStoreIds] = useState([]);
  const [isFollow, setIsFollow] = useState(true);
  const [clickData, setClickData] = useState(undefined);
  const [isClickedFollowSearch, setIsClickedFollowSearch] = useState(false);

  // 팔로우 중인 업체 키 추출 //
  useEffect(() => {
    let storeIds = [];

    follows.map((data) => storeIds.push(data.storeId));

    setFollowStoreIds(storeIds);
  }, [follows])

  // 찾아보기 버튼 클릭시 //
  function onClickSearchButton() {
    setIsFollow(true);
    setIsClickedFollowSearch(true);
  }

  // 승인 신청 클릭시 //
  function onClickFollowButton(data) {
    onClickFollow(data, isFollow);
    setIsClickedFollowSearch(false);
  }

  // 신청된 박스 클릭시 //
  function onClickStoreBox(data, isFollow) {
    setClickData(data);
    setIsFollow(isFollow);
    setIsClickedFollowSearch(true);
  }

  return (
    <TopLevelWrapper>
      {
        isClickedFollowSearch && 
        <CenterFollowModal 
          isExercise={true} 
          isFollow={isFollow} 
          followStoreIds={followStoreIds}
          follows={follows}
          clickData={clickData} 
          setClickData={setClickData} 
          onClickFollow={onClickFollowButton} 
          close={() => setIsClickedFollowSearch(false)} />
      }
      <TitleWrapper>
        <TitleBox backgroundColor='#6DD49E'>
          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='default'>프로그램</Text>
        </TitleBox>
      </TitleWrapper>
      <InfoView>
        <InnerInfoBox>
          <ProgramExplanationBox>
            <ExplanationWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>프로그램 팔로우</Text>
              <FindButton onClick={onClickSearchButton}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#969696' hover='pointer'>찾아보기</Text>
              </FindButton>
            </ExplanationWrapper>
            <Text style={{ marginTop: '6px' }} fontFamily='NotoSansKR-Medium' fontSize={12} color='#777777' hover='default'>링크존에서 다른 체육관의 프로그램을 받아볼수 있어요</Text>
          </ProgramExplanationBox>
          <FollowBox>
            {
              follows.length === 0 ?
                <NoneFollowBox>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD' hover='default'>팔로우중인 파트너가 없습니다</Text>
                </NoneFollowBox>
                :
                <FollowWrapper>
                  {
                    follows.map((data, index) => (
                      <StoreBox key={index} onClick={() => onClickStoreBox(data, true)}>
                        <StoreLogoBox approval={data.status}>
                          <UserProfile profile={data.storeLogo} defaultProfile={DefaultLogoPath} />
                        </StoreLogoBox>
                        {
                          !data.status &&
                          <ApprovalWaitWrapper>
                            <Text fontFamily='NotoSansKR-Black' fontSize={14} color='#232323' hover='cursor'>승인 대기중</Text>
                          </ApprovalWaitWrapper>
                        }
                      </StoreBox>
                    ))
                  }
                </FollowWrapper>
            }
          </FollowBox>
        </InnerInfoBox>
        <DivisionLine />
        <InnerInfoBox>
          <ProgramExplanationBox>
            <ExplanationWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>프로그램 팔로워</Text>
            </ExplanationWrapper>
            <Text style={{ marginTop: '6px' }} fontFamily='NotoSansKR-Medium' fontSize={12} color='#777777' hover='default'>다른 체육관에서 우리 프로그램을 받아볼 수 있어요</Text>
          </ProgramExplanationBox>
          <FollowBox>
            {
              followers.length === 0 ?
                <NoneFollowBox>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#BDBDBD' hover='default'>팔로워중인 파트너가 없습니다</Text>
                </NoneFollowBox>
                :
                <FollowWrapper>
                  {
                    followers.map((data, index) => (
                      <StoreBox key={index} onClick={() => onClickStoreBox(data, false)}>
                        <StoreLogoBox approval={data.status}>
                          <UserProfile profile={data.storeLogo} defaultProfile={DefaultLogoPath} />
                        </StoreLogoBox>
                        {
                          !data.status &&
                          <ApprovalWaitWrapper>
                            <Text fontFamily='NotoSansKR-Black' fontSize={14} color='#232323' hover='cursor'>승인 대기중</Text>
                          </ApprovalWaitWrapper>
                        }
                      </StoreBox>
                    ))
                  }
                </FollowWrapper>
            }
          </FollowBox>
        </InnerInfoBox>
      </InfoView>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 30px;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TitleBox = styled.p`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 2px 20px;
  margin: 0 0 0 0;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  background-color: ${props => props.backgroundColor};
`;

const InfoView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 10px 20px;

  width: calc(100% - 40px);

  gap: 10px;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  background-color: #FFFFFF;
`;

const InnerInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 546px;
  height: 312px;
`;

const DivisionLine = styled.div`
  width: 1px;
  height: 312px;

  background-color: #969696;
`;

const ProgramExplanationBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding-bottom: 8px;
  margin-top: 10px;

  width: 100%;

  border-bottom: 1px solid #DBDBDB;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 29px;
`;

const FindButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 65px;
  height: 27px;

  border: 1px solid #969696;
  border-radius: 67px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const FollowBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 20px;

  width: 100%;
  height: 210px;

  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    background-color: #DADADA;
    border-radius: 200px;
  }

  &::-webkit-scrollbar-track {
    background-color: #F0F0F0;
    border-radius: 200px;
  }
`;

const FollowWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  flex-wrap: wrap;

  gap: 10px;

  width: 100%;
`;

const NoneFollowBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const StoreBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 99px;
  height: 99px;

  border-radius: 15px;

  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const StoreLogoBox = styled.div`
  width: 100%;
  height: 100%;

  opacity: ${props => props.approval ? '1' : '0.6'};
`;

const ApprovalWaitWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 99px;
  height: 99px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  margin: 0 0 0 0;

  color: ${props => props.color};

  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;