import { React, useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import FetchModule from '../../../Share/Network/FetchModule';

import MemberShipHistoryInfoButtonImagePath from '../../../../image/MemberShipHistoryInfoButton.png';
import LessonReserveHistoryDeleteModal from './Modal/LessonReserveHistoryDeleteModal';

const CourseHistoryInfoView = (props) => {
  const { data, /*reserveRerendering*/ rendering } = props;

	const history = useHistory();

  const [isClicked, setIsClicked] = useState(false);
  const [state, setState] = useState('예약중');
  const [isAvailableExit, setIsAvailableExit] = useState(false);
  const [isAbsent, setIsAbsent] = useState(false);
  const [isLate, setIsLate] = useState(false);
  const [isAttendance, setIsAttendance] = useState(false);
  const [isClickedDeleteHistory, setIsClickedDeleteHistory] = useState(false);

  const OptionBoxRef = useRef();
  const OptionButtonRef = useRef();

  useEffect(() => {
    if (isClicked) {
      // OptionBoxRef.current.style.height = `180px`;
      OptionBoxRef.current.style.height = `144px`;
      OptionBoxRef.current.style.border = `1px solid #FF8B48`;
    }
    else {
      OptionBoxRef.current.style.height = `0px`;
      OptionBoxRef.current.style.border = `0px solid #FF8B48`;
    }
  }, [isClicked])

  useEffect(() => {
    // console.log("------");
    // console.log(data);
    if (data.status === 0) {
      // console.log(data.classDate.split('T')[0] + data.classTime.split('-')[0] + ':00')
      // console.log(moment().format('YYYY-MM-DDTHH:mm:ss'))
      // console.log(moment(data.classDate.split('T')[0] + "T" + data.classTime.split('-')[0] + ':00').isAfter(moment()));
      if (moment(data.classDate.split('T')[0] + "T" + data.classTime.split('-')[0] + ':00').isAfter(moment())) {
        setState('예약중');
        setIsAvailableExit(true);
        setIsAbsent(true);
        setIsLate(true);
        setIsAttendance(true);
      }
      else {
        setState('결석');
        setIsAvailableExit(true);
        setIsAbsent(false);
        setIsLate(true);
        setIsAttendance(true);
      }
    }
    else if (data.status === 1) {
      setState('출석');
      setIsAvailableExit(true);
      setIsAbsent(true);
      setIsLate(true);
      setIsAttendance(false);
      // setIsAvailableExit(false);
      // setIsAbsent(true);
      // setIsLate(true);
      // setIsAttendance(false);
    }
    else if (data.status === 2) {
      setState('지각');
      setIsAvailableExit(true);
      setIsAbsent(true);
      setIsLate(false);
      setIsAttendance(true);
      // setIsAvailableExit(false);
      // setIsAbsent(true);
      // setIsLate(false);
      // setIsAttendance(true);
    }
    else if (data.status === 3) {
      setState('결석');
      setIsAvailableExit(true);
      setIsAbsent(false);
      setIsLate(true);
      setIsAttendance(true);
      // setIsAvailableExit(false);
      // setIsAbsent(false);
      // setIsLate(true);
      // setIsAttendance(true);
    }
  }, [data])

  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [])

  function onClickOutSide(e) {
    if (OptionBoxRef.current !== e.target && OptionButtonRef.current !== e.target) {
      setIsClicked(false);
    }
  }

  function mouseOver() {
    OptionButtonRef.current.style.opacity = 1;
  }

  function mouseLeave() {
    OptionButtonRef.current.style.opacity = 0;
  }

  function onClickChangeState(state) {
    if (!isAttendance && state === 1) return;
    else if (!isLate && state === 2) return;
    else if (!isAbsent && state === 3) return;

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('reserve/modify/' + data.id + '/' + state, 'POST');
     
        if (responseData.status === 200) /*reserveRerendering();*/ rendering();
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  function onClickDeleteLessonReservation() {
    if (!isAvailableExit) return;

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('reserve/delete/manage/' + data.id, 'POST');
        
        if (responseData.status === 200) rendering();
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  // function onClickDelete

  return (
    <TopLevelWrapper onMouseOver={mouseOver} onMouseLeave={mouseLeave}>
      { isClickedDeleteHistory && <LessonReserveHistoryDeleteModal exitClickFn={() => setIsClickedDeleteHistory(false)} deleteClickFn={onClickDeleteLessonReservation}/> }
      <Table>
        <Body>
          {/* <Tr style={{ width: '53px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '23px' }}>
                <Text>{data.id}</Text>
              </TextWrapper>
            </Td>
          </Tr> */}
          <Tr style={{ width: '89px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '61px' }}>
                <Text>{data.classDate.replaceAll('-', '.').slice(2, 10)}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '244px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '244px' }}>
                <Text>{data.lessonName}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '87px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '58px' }}>
                <Text>{data.classTime.slice(0, 5)}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '80px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '80px' }}>
                <Text>{data.teacherName}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '185px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '129px' }}>
                <Text>{data.memberShipName}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr style={{ width: '82px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '14px' }}>
                <Text>{data.deduction}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          {/* <Tr style={{ width: '125px' }}> */}
          <Tr style={{ width: '180px' }}>
            <Td>
              <TextWrapper style={{ maxWidth: '26px' }}>
                <Text>{state}</Text>
              </TextWrapper>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <OptionButton ref={OptionButtonRef} src={MemberShipHistoryInfoButtonImagePath} onClick={() => setIsClicked(!isClicked)}/>
              <OptionWrapper ref={OptionBoxRef}>
                {/* <Option>예약중</Option> */}
                <Option isAvailable={isAvailableExit} onMouseDown={() => setIsClickedDeleteHistory(true)}>내역 삭제</Option>
                <Option isAvailable={isAttendance} onMouseDown={() => onClickChangeState(1)}>출석 처리</Option>
                <Option isAvailable={isLate} onMouseDown={() => onClickChangeState(2)}>지각 처리</Option>
                <Option isAvailable={isAbsent} onMouseDown={() => onClickChangeState(3)}>결석 처리</Option>
              </OptionWrapper>
            </Td>
          </Tr>
        </Body>
      </Table>
    </TopLevelWrapper>
  )
};

export default CourseHistoryInfoView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: space-around;
  // justify-content: center;
  align-items: center;

  width: 1018px;
  height: 50px;

  background: #FFFFFF;

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border: 1px solid #FFFFFF;
  border-radius: 30px;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #FF8B48;
  }
`;

const Table = styled.table`
  display: flex;
  flex-direction: row;
`;

const Body = styled.tbody`
  display: flex;
  flex-direction: row;
  
  margin-left: 28px;
`;

const Tr = styled.tr`
  display: flex;
  flex-direction: row;
`;

const Td = styled.td`
  display: flex;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  overflow: hidden;

  max-height: 20px;
  // border: 1px solid #232323;
`;

const Text = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

const OptionButton = styled.img`
  opacity: 0;
  
  transition: all 0.3s ease-in-out;
`;

const OptionWrapper = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  overflow: hidden;

  // margin-top: 200px;
  // margin-left: 960px;
  margin-top: 40px;
  margin-left: -20px;
  
  z-index: 5;

  // border: 1px solid #FF8B48;
  border-radius: 8px;
  // background-color: #FFFFFF;

  transition: all 0.2s ease-in-out;
`;

const Option = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 78px;
  height: 36px;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: ${props => props.isAvailable ? `#FF8B48` : `#CBCBCB`};
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: ${props => props.isAvailable ? `#FFFFFF` : `#CBCBCB`};
    background-color: #FF8B48;
  }
`;