import { forwardRef, React, useRef, useImperativeHandle, useState, useEffect } from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react-web';

import SearchInput from '../../../Component/MemberComponent/SearchInput.js';
import InfoPagination from '../../../Component/MemberComponent/InfoPagination.js';
import ValidColumnView from './ValidColumnView.js';
import ValidMemberInfo from './ValidMemberInfo.js';

import SortingModule from '../../Share/Normal/SortingModule.js';

import EmptyValueLogo from '../../../image/EmptyValueLogo.png';
import LoadingAnimation from '../../../Animation/MemberLoadingAnimation.json';
import moment from 'moment';

export default function ValidMemberView(props) {
  const { data, isLoading, memberShipDetailClick } = props;

  // const [totalData, setTotalData] = useState(data);
  const [sortingData, setSortingData] = useState([]);
  const [dividedData, setDividedData] = useState([]);
  const [nowPage, setNowPage] = useState(0);
  const [value, setValue] = useState('');

  const [selectedMembership, setSelectedMembership] = useState([]);
  const [genderSort, setGenderSort] = useState('normal');
  const [birthSort, setBirthSort] = useState('normal');
  const [startSort, setStartSort] = useState('normal');
  const [endSort, setEndSort] = useState('asc');
  const [classSort, setClassSort] = useState('normal');

  const [division, setDivision] = useState('None');
  const [usingCount, setUsingCount] = useState(0);
  const [expectedCount, setExpectedCount] = useState(0);
  const [holdingCount, setHoldingCount] = useState(0);

  const TopLevelRef = useRef();
  
  // useImperativeHandle(ref, () => ({
	// 	setOpacity: (val) => {
	// 		TopLevelRef.current.style.opacity = val;
	// 	},
  //   setZindex: (val) => {
	// 		TopLevelRef.current.style.zIndex = val;
  //   },
  //   setData: (val) => {
  //     setTotalData(val);
  //   }
	// }))

  // 회원 자세히 보기에서 뒤로 나온경우 //
  useEffect(() => {
    setNowPage(window.localStorage.getItem('ValidMemberPage') === null ? 0 : parseInt(window.localStorage.getItem('ValidMemberPage')));
  }, [])

  // 사용, 예정, 홀딩 회원 구분 //
  useEffect(() => {
    let now = moment();
    let using = [];
    let expected = [];
    let holding = [];

    for (let i = 0; i < data.length; i++) {
      let isUsing = false;
      let isHolding = false;
      let isExpected = false;

      for (let j = 0; j < data[i].memberships.length; j++) {
        // 시작시간이 미래인 경우 //
        if (moment(data[i].memberships[j].startDate).isAfter(now)) isExpected = true;
        // 홀딩중인 데이터가 있는경우 //
        else if (data[i].memberships[j].isHolding) isHolding = true;
        // 사용중인 데이터가 있는경우 //
        else if (moment(data[i].memberships[j].startDate).isBefore(now)
                  && moment(data[i].memberships[j].endDate).isAfter(now)) isUsing = true;
      }

      if (isExpected) {
        let isExistExpected = false;

        for (let j = 0; j < expected.length; j++) {
          if (data[i].memberId === expected[j].memberId) {
            isExistExpected = true;
            break;
          }
        }

        if (!isExistExpected) expected.push(data[i]);
      }

      if (isHolding) {
        let isExistHolding = false;

        for (let j = 0; j < holding.length; j++) {
          if (data[i].memberId === holding[j].memberId) {
            isExistHolding = true;
            break;
          }
        }

        if (!isExistHolding) holding.push(data[i]);
      }

      if (isUsing) {
        let isExistUsing = false;

        for (let j = 0; j < using.length; j++) {
          if (data[i].memberId === using[j].memberId) {
            isExistUsing = true;
            break;
          }
        }

        if (!isExistUsing) using.push(data[i]);
      }
    }

    setUsingCount(using.length);
    setHoldingCount(holding.length);
    setExpectedCount(expected.length);
  }, [data])

  // 이전 회원 정렬 방법 불러오기 //
  useEffect(() => {
    let prevGenderSort = window.localStorage.getItem('ValidMemberGenderSort');
    let prevBirthSort = window.localStorage.getItem('ValidMemberBirthSort');
    let prevStartSort = window.localStorage.getItem('ValidMemberStartSort');
    let prevEndSort = window.localStorage.getItem('ValidMemberEndSort');
    let prevClassSort = window.localStorage.getItem('ValidMemberClassSort');
    let prevMembershipNames = window.localStorage.getItem('ValidMemberMembershipNames');
    let prevDivision = window.localStorage.getItem('ValidMemberDivision');

    if (prevGenderSort !== null) setGenderSort(prevGenderSort);
    if (prevBirthSort !== null) setBirthSort(prevBirthSort);
    if (prevStartSort !== null) setStartSort(prevStartSort);
    if (prevEndSort !== null) setEndSort(prevEndSort);
    if (prevClassSort !== null) setClassSort(prevClassSort);
    if (prevDivision !== null) setDivision(prevDivision);
    if (prevMembershipNames !== null && prevMembershipNames !== '') {
      let selectedMemberships = [];

      prevMembershipNames = prevMembershipNames.split('<-@->');
      prevMembershipNames.map((data) => selectedMemberships.push({ name: data }));
      
      setSelectedMembership(selectedMemberships);
    }
  }, [])

  // 회원 정보 소팅
  useEffect(() => {
    let newData = data.slice();
    let now = moment();

    // 구버전 (회원권 1개만 확인가능) //
    // if (selectedMembership.length !== 0) {
    //   let tmpNewData = [];
      
    //   for (let i = 0; i < newData.length; i++) {
    //     for (let j = 0; j < selectedMembership.length; j++) {
    //       if (selectedMembership[j].name === newData[i].memberShipName) {
    //         tmpNewData.push(newData[i]);
    //       }
    //     }
    //   }

    //   newData = tmpNewData;
    // }
    // 구버전 (회원권 1개만 확인가능) //

    // 신버전 (여러개 가능) //
    // 회원권이 선택되어 있는 경우 //
    if (selectedMembership.length !== 0) {
      let tmpNewData = [];
      
      for (let i = 0; i < newData.length; i++) {
        let isExist = false;

        for (let j = 0; j < newData[i].memberships.length; j++) {
          for (let h = 0; h < selectedMembership.length; h++) {
            if (selectedMembership[h].name === newData[i].memberships[j].membershipName) {
              newData[i].viewMembershipName = newData[i].memberships[j].membershipName;
              newData[i].viewStartDate = newData[i].memberships[j].startDate;
              newData[i].viewEndDate = newData[i].memberships[j].endDate;
              newData[i].viewCount = newData[i].memberships[j].count;

              tmpNewData.push(newData[i]);
              
              isExist = true;
              break;
            }
          }

          if (isExist) break;
        }
      }

      newData = tmpNewData;
    }
    // 회원권이 선택되어있지 않는경우 초기 데이터로 전환 //
    else {
      let tmpNewData = [];
      
      for (let i = 0; i < newData.length; i++) {

        if (newData[i].memberships.length === 0) {
          newData[i].viewMembershipName = '없음';
          newData[i].viewStartDate = moment().format('YYYY-MM-DDT00:00:00');
          newData[i].viewEndDate = moment().format('YYYY-MM-DDT23:59:59');
          newData[i].viewCount = 0;
        }
        else if (newData[i].memberships.length === 1) {
          newData[i].viewMembershipName = newData[i].memberships[0].membershipName;
          newData[i].viewStartDate = newData[i].memberships[0].startDate;
          newData[i].viewEndDate = newData[i].memberships[0].endDate;
          newData[i].viewCount = newData[i].memberships[0].count;
        }
        else {
          newData[i].viewMembershipName = newData[i].memberships[0].membershipName + ' 외 ' + (newData[i].memberships.length - 1) + '개';
          newData[i].viewStartDate = newData[i].memberships[0].startDate;
          newData[i].viewEndDate = newData[i].memberships[0].endDate;
          newData[i].viewCount = newData[i].memberships[0].count;
        }

        tmpNewData.push(newData[i]);
      }

      newData = tmpNewData;
    }
    // 신버전 (여러개 가능) //

    // 사용중 클릭시 //
    if (division === 'Using' || division === 'Expected' || division === 'Holding') {
      let tmpNewData = [];
      
      for (let i = 0; i < newData.length; i++) {
        let isExist = false;

        for (let j = 0; j < newData[i].memberships.length; j++) {
          // 예정인 회원권 //
          if ((division === 'Expected' && moment(newData[i].memberships[j].startDate).isAfter(now))
            // 홀딩중인 회원권 //
            || (division === 'Holding' && newData[i].memberships[j].isHolding)
            // 사용중인 회원권 //
            || (division === 'Using' 
              && !newData[i].memberships[j].isHolding
              && moment(newData[i].memberships[j].startDate).isBefore(now) 
              && moment(newData[i].memberships[j].endDate).isAfter(now))) {
              newData[i].viewMembershipName = newData[i].memberships[j].membershipName;
              newData[i].viewStartDate = newData[i].memberships[j].startDate;
              newData[i].viewEndDate = newData[i].memberships[j].endDate;
              newData[i].viewCount = newData[i].memberships[j].count;

              isExist = true;
              break;
            }
        }
        
        if (isExist) tmpNewData.push(newData[i]);
      }

      newData = tmpNewData;
    }
    // 카테고리가 선택되지 않았을경우 //
    else {
      
    }

    if (genderSort === 'asc') newData = SortingModule.dataSort(newData, 'gender', 'asc', 'gender');
    else if (genderSort === 'desc') newData = SortingModule.dataSort(newData, 'gender', 'desc', 'gender');
    else if (birthSort === 'asc') newData = SortingModule.dataSort(newData, 'birthDay', 'asc', 'birthDay');
    else if (birthSort === 'desc') newData = SortingModule.dataSort(newData, 'birthDay', 'desc', 'birthDay');
    else if (startSort === 'asc') newData = SortingModule.dataSort(newData, 'viewStartDate', 'asc', 'startDate');
    else if (startSort === 'desc') newData = SortingModule.dataSort(newData, 'viewStartDate', 'desc', 'startDate');
    else if (endSort === 'asc') newData = SortingModule.dataSort(newData, 'viewEndDate', 'asc', 'endDate');
    else if (endSort === 'desc') newData = SortingModule.dataSort(newData, 'viewEndDate', 'desc', 'endDate');
    else if (classSort === 'asc') newData = SortingModule.dataSort(newData, 'memberShipName', 'asc', 'memberShipName');
    else if (classSort === 'desc') newData = SortingModule.dataSort(newData, 'memberShipName', 'desc', 'memberShipName');
    
    setSortingData(newData);

    let selectMembershipNames = '';

    for (let i = 0; i < selectedMembership.length; i++) {
      if (i === 0) selectMembershipNames += selectedMembership[i].name;
      else selectMembershipNames += '<-@->' + selectedMembership[i].name; 
    }

    window.localStorage.setItem('ValidMemberGenderSort', genderSort);
    window.localStorage.setItem('ValidMemberBirthSort', birthSort);
    window.localStorage.setItem('ValidMemberStartSort', startSort);
    window.localStorage.setItem('ValidMemberEndSort', endSort);
    window.localStorage.setItem('ValidMemberClassSort', classSort);
    window.localStorage.setItem('ValidMemberDivision', division);
    window.localStorage.setItem('ValidMemberMembershipNames', selectMembershipNames);
    
  }, [data, selectedMembership, genderSort, birthSort, startSort, endSort, classSort, division])

  // 검색 기능 포함 ( 한글 검색 알고리즘 최신화 ) //
  useEffect(() => {
    let nameList = [];
    let phoneList = [];

    for (let i = 0; i < sortingData.length; i++) {
      if (sortingData[i].name.toLowerCase().includes(value.toLowerCase())) nameList.push(sortingData[i]);
      else if (sortingData[i].phone.includes(value)) phoneList.push(sortingData[i]);
      // if (match(value.toLowerCase(), sortingData[i].name.toLowerCase())) nameList.push(sortingData[i]);
    }

    if (nameList.length === 0 && phoneList.length === 0) {
      for (let i = 0; i < sortingData.length; i++) {
        // if (sortingData[i].name.toLowerCase().includes(value.toLowerCase())) nameList.push(sortingData[i]);
        // else if (sortingData[i].phone.includes(value)) phoneList.push(sortingData[i]);
        // if (match(value.toLowerCase(), sortingData[i].name.toLowerCase())) nameList.push(sortingData[i]);
        if (match(value.toLowerCase(), sortingData[i].name.toLowerCase())) nameList.push(sortingData[i]);
      }
    }


    let totalList = nameList.concat(phoneList);
    let newDataList = [];
    
    // 페이지 분리 //
    if (totalList.length !== 0) {
      if (totalList.length > 20) newDataList = totalList.slice((nowPage * 20), (nowPage * 20) + 20);
      else newDataList = totalList.slice();
    }

    setDividedData(newDataList);
    // console.log(nameList)
  }, [nowPage, sortingData, value, match()])

  // 입력된 글자가 한글인지 확인 //
  function is_kr(value) {
    if (value === undefined) return false;
    
    return ((('ㄱ'.charCodeAt() <= value.charCodeAt()) && (value.charCodeAt() <= 'ㅎ'.charCodeAt()))
            || (('가'.charCodeAt() <= value.charCodeAt()) && value.charCodeAt() <= '힣'.charCodeAt()));
  }

  // 한글 글자 유니코드 추출하여 검색 조건 확인 //
  function kr_num(value) {
    return value.charCodeAt() - '가'.charCodeAt();
  }

  // 초성 중성 종성 구분하여 연산 //
  function kr_list(value) {
    var consonant_list = ['ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ'
                        , 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'];

    var res = [value];

    var kr_num_s = kr_num(value);

    if (kr_num_s > 0) {
      // 초성 + 중성일 경우 //
      if (kr_num_s % 28 !== 0) {
        res.push(String.fromCharCode(((parseInt(kr_num_s / 28) * 28) + '가'.charCodeAt())));
      }
      
      res.push(consonant_list[parseInt(kr_num_s / 588)])
    }

    return res;
  }

  // 한글, 영어 구분하여 검색 처리 //
  function eq_kr(value, data) {
    if (is_kr(value) && is_kr(data)) {
      return kr_list(data).includes(value);
    }
    else return value === data;
  }

  // 받침 확인하기 //
  function eq_kr_pos(value, data, nextData) {
    var consonant_list = ['ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ'
                        , 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'];

    var kr_pos = [
      [0, 0], [1, 0], [2, 1], [2, 9], [4, 2], [1, 12], [2, 18],
      [7, 3], [8, 5], [1, 0], [2, 6], [3, 7], [4, 9], [5, 16],
      [6, 17], [7, 18], [16, 6], [17, 7], [1, 9], [19, 9], [20, 10],
      [21, 11], [22, 12], [23, 14], [24, 15], [25, 16], [26, 17], [27, 18]
    ]

    if (!(is_kr(value) && is_kr(data) && is_kr(nextData))) return false;
    if (kr_num(value) < 0) return false;
    
    var s_pos = String.fromCharCode(value.charCodeAt() - kr_pos[kr_num(value) % 28][0])
    var s_pos_consonant = consonant_list[kr_pos[kr_num(value) % 28][1]]

    return (s_pos === data) && (kr_list(nextData).includes(s_pos_consonant));
  }

  // 일치하는 글자가 존재하는지 확인 //
  function match(value, data) {
    if (value === undefined || data === undefined) return false;

    var valueSize = value.length;
    var dataSize = data.length;

    if (dataSize < valueSize) return false;

    for (var i = 0; i < (dataSize - valueSize + 1); i++) {
      var res = true;

      for (var j = 0; j < valueSize; j++) {
        if (j === (valueSize - 1)) {
          if (!eq_kr(value[j], data[i + j])) {
            if (i + j + 1 < dataSize) {
              if (eq_kr_pos(value[j], data[i + j], data[i + j + 1])) {
                break;
              }
            }
            res = false;
            break;
          }
        }
        else {
          if (value[j] !== data[i + j]) {
            res = false;
            break;
          }
        }
      }

      if (res) return true;
    }
    
    return false;
  }


  // 유효회원 정보 클릭시 //
  function onClickValidMember(data) {
    window.localStorage.setItem('ValidMemberPage', nowPage);
    memberShipDetailClick(data);
  }

  // 다음 페이지 클릭시 //
  function onClickNextPage() {
    if (nowPage + 1 < Math.ceil(sortingData.length/20)) setNowPage(nowPage + 1);
  }

  // 이전 페이지 클릭시 //
  function onClickPrevPage() {
    if ((nowPage - 1) >= 0) setNowPage(nowPage - 1);  
  }

  // 카테고리 분류 버튼 클릭시 //
  function onClickDivisionButton(value) {
    if (division === value) setDivision('None');
    else setDivision(value);

    setNowPage(0);
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <TopBarWrapper>
         <TextWrapper>
          <TitleComponentWrapper>
            <TitleWrapper>
              <Title>유효 회원</Title>
              <CountText>{'(총 ' + data.length + '명)'}</CountText>
            </TitleWrapper>
            <SortedButton isClicked={division === 'Using'} onClick={() => onClickDivisionButton('Using')}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color={division === 'Using' ? '#FFFFFF' : '#777777'} cursor='pointer'>사용중인 회원 : {usingCount}명</Text>
            </SortedButton>
            <SortedButton isClicked={division === 'Expected'} onClick={() => onClickDivisionButton('Expected')}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color={division === 'Expected' ? '#FFFFFF' : '#777777'} cursor='pointer'>예정 회원 : {expectedCount}명</Text>
            </SortedButton>
            <SortedButton isClicked={division === 'Holding'} onClick={() => onClickDivisionButton('Holding')}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color={division === 'Holding' ? '#FFFFFF' : '#777777'} cursor='pointer'>홀딩중 : {holdingCount}명</Text>
            </SortedButton>
          </TitleComponentWrapper>
           <Explanation>등록된 모든 회원을 볼 수 있어요</Explanation>
         </TextWrapper>
         <SearchInput value={value} setValue={setValue} placeholder='회원의 이름이나 전화번호를 검색해주세요'/>
      </TopBarWrapper>
      <ValidColumnView
        selectedMembership={selectedMembership}
        setSelectedMembership={setSelectedMembership} 
        genderSort={genderSort}
        setGenderSort={setGenderSort}
        birthSort={birthSort}
        setBirthSort={setBirthSort}
        startSort={startSort}
        setStartSort={setStartSort}
        endSort={endSort}
        setEndSort={setEndSort}
        classSort={classSort}
        setClassSort={setClassSort}/>
      {
        isLoading ?
          <ViewBox>
            <LottieWrapper>
              <Lottie options={{ animationData: LoadingAnimation }} />
            </LottieWrapper>
          </ViewBox>
          :
          <ViewBox>
            {
              dividedData.length === 0 ?
                <InfoBox>
                  <EmptyLogoWrapper>
                    <EmptyLogoView src={EmptyValueLogo} />
                    <EmptyText>회원정보가 존재하지 않습니다.</EmptyText>
                  </EmptyLogoWrapper>
                </InfoBox>
                :
                <InfoBox>
                  {
                    dividedData.map((data, index) => (
                      <ValidMemberInfo key={index} data={data} onClick={() => onClickValidMember(data)} />
                    ))
                  }
                </InfoBox>
            }
          </ViewBox>
      }
      <PaginationWrapper>
        <InfoPagination 
          nowPage={nowPage} 
          // totalPage={Math.ceil(totalData.length/20)}
          totalPage={Math.ceil(sortingData.length/20)}
          nextClickFn={onClickNextPage}
          prevClickFn={onClickPrevPage}/>
      </PaginationWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;
 
  // width: 100%;
  // width: 1062px;

  // margin-left: 34px;
  margin-left: 42px;

  transition: all 0.3s ease-in-out;
`;

const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-left: 28px;

  // gap: 540px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: 4px;
`;

const TitleComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  gap: 10px;
`;

const SortedButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 10px;

  height: 24px;

  border-radius: 5px;
  background-color: ${props => props.isClicked ? `#FF8B48` : `#F6F4F2`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;
`;

const CountText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;

const ViewBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 20px;

  width: 100%;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 100px 0px 100px 0px;

  width: 400px;
  height: 400px;

  overflow: hidden;
  
  border-radius: 400px;
  background-color: #FFFFFF;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  // margin-top: 20px;
`;

const EmptyLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 135px;
  margin-bottom: 135px;
`;

const EmptyLogoView = styled.img`
  width: 180px;
  height: 180px;

  margin-bottom: 20px;
`;

const EmptyText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 16px;

  color: #DFDFDF;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  margin-top: 44px;
`;

const Text = styled.span`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};
  
  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }

  transition: all 0.3s ease-in-out;
`;
