import { forwardRef, React, useEffect, useImperativeHandle, useRef, useState } from 'react';
import styled, { keyframes }  from 'styled-components';

import AccountInput from '../../../Component/AccountComponent/AccountInput.js';
import LogoFileInput from '../../../Component/AccountComponent/LogoFileInput.js';
import KakaoAddressInput from '../../../Component/AccountComponent/KakaoAddressInput.js';

const SignUpInfo4 = forwardRef((props, ref) => {
	const { kakaoAddrClick } = props;

	const [addressClickCount, setAddressClickCount] = useState(0);
	const [logoClickCount, setLogoClickCount] = useState(0);

	const TopLevelRef = useRef();
	const AddressRef = useRef();
	const LogoRef = useRef();
	const AddressDetailRef = useRef();
	const AddressDetailPlanationRef = useRef();
	const AddressPlanationRef = useRef();
	const LogoPlanationRef = useRef();

	useImperativeHandle(ref, () => ({
		getAddress: () => {
			return AddressRef.current.getValue() + " " + AddressDetailRef.current.getValue();
		},
		getLogo: () => {
			return LogoRef.current.getImgBase64();
		},
		getLogoThumbnail: () => {
			return LogoRef.current.getImgBase64Thumbnail();
		},
		setAddressBold: () => {
			AddressRef.current.setDefaultPlaceholder('');
			AddressPlanationRef.current.style.color = '#FF3131';
			AddressPlanationRef.current.style.opacity = 1;
		},
		setLogoBold: () => {
			LogoRef.current.setBold();
			LogoPlanationRef.current.style.color = '#FF3131';
			LogoPlanationRef.current.style.opacity = 1;
		},
		setAddress: (val) => {
			AddressRef.current.setValue(val);
			setAddressClickCount(addressClickCount + 1);
		},
		setOpacity: (val) => {
			TopLevelRef.current.style.opacity = val;
		},
		setLocation: (val) => {
			TopLevelRef.current.style.transform = `translateX(${val}%)`;
		},
		reset: () => {
			// AddressRef.current.reset('');
			// AddressPlanationRef.current.style.opacity = 0;
			// LogoRef.current.reset();
			// LogoPlanationRef.current.style.opacity = 0;
			// setAddressClickCount(0);
			// setLogoClickCount(0);
		}
	}));

	useEffect(() => {
		if (addressClickCount === 0 && logoClickCount === 0) return;

		if (AddressRef.current.getValue() === undefined && addressClickCount > 0) {
			AddressPlanationRef.current.style.color = '#FF3131';
			AddressPlanationRef.current.style.opacity = 1;
		}
		else {
			AddressPlanationRef.current.style.opacity = 0;
		}

		if (LogoRef.current.getValue() === '' && logoClickCount > 0) {
			LogoPlanationRef.current.style.color = '#FF3131';
			LogoPlanationRef.current.style.opacity = 1;
		}
		else {
			LogoPlanationRef.current.style.opacity = 0;
		}
	}, [addressClickCount, logoClickCount])

	function clickCountFn(className, clickCount) {
		if (className === 'AddressInput') {
			setAddressClickCount(clickCount);
		}
		else if (className === 'LogoInput') {
			setLogoClickCount(clickCount);
		}
	}

	return (
		<TopLevelWrapper ref={TopLevelRef}>
			<InputInfo>센터 주소</InputInfo>
			<KakaoAddressInput ref={AddressRef} className='AddressInput' placeholder='' type='text' clickCountFn={clickCountFn} clicked={kakaoAddrClick} />
			<AccountExplanation ref={AddressPlanationRef}>필수항목을 입력해주세요</AccountExplanation>
			<InputInfo>상세 주소</InputInfo>
			<AccountInput ref={AddressDetailRef} className='AddressDetailInput' placeholder='' type='text' clickCountFn={clickCountFn}/>
			<AccountExplanation ref={AddressDetailPlanationRef}>필수항목을 입력해주세요</AccountExplanation>
			<InputInfo>센터 로고</InputInfo>
			<LogoFileInput ref={LogoRef} className='LogoInput' clickCountFn={clickCountFn}/>
			<AccountExplanation ref={LogoPlanationRef}>필수항목을 입력해주세요</AccountExplanation>
		</TopLevelWrapper>
	)
})

export default SignUpInfo4;

const startAnimation = keyframes`
	0% { opacity: 0; transform: translateX(150%); }
	10% { opacity: 0.1; transform: translateX(130%); }
	20% { opacity: 0.2; transform: translateX(100%); }
	30% { opacity: 0.3; transform: translateX(70%); }
	40% { opacity: 0.4; transform: translateX(40%); }
	50% { opacity: 0.5; transform: translateX(20%); }
	60% { opacity: 0.6; transform: translateX(0%); }
	70% { opacity: 0.7;}
	80% { opacity: 0.8; }
	90% { opacity: 0.9;}
	100% { opacity: 1;}
`;

const TopLevelWrapper = styled.div`
	position: absolute;
	display: flex;

	flex-direction: column; 
	justify-content: flex-start;

	width: 468px;
	// width: max(271px, 24.375vw);
	// height: 281px;

	margin-top: 159px;
	// margin-top: max(100px, 8.28125vw);

	background-color: rgba(255, 255, 255, 1);
	
	// opacity: 1;
	// z-index: 1;
	// transform: translateX(150%);

	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;


  transition: all 0.3s ease-in-out;
`;

const InputInfo = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 14px;
	// font-size: max(12px, 0.7291666666666667vw);
	// line-height: 20px;
 
	margin: 19px 0 0 0;
	// margin-top: max(11.73px, 0.9895833333333333vw);
	// margin-right: 0px;
	// margin-bottom: 0px;
	// margin-left: 0px;

	cursor: default;
`;

const AccountExplanation = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 12px;
	// font-size: max(12px, 0.625vw);
	// line-height: 17.38px;

	color: ${props => props.bold ? `#FF3131` : `#33C8FF`};

	margin: 4px 0 0 0;
	opacity: 0;
  transition: all 0.5s ease-in-out;
`;

