import { React, useImperativeHandle, forwardRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FetchModule from '../../../Share/Network/FetchModule';

import TextArea from '../../../../Component/MemberComponent/MemberDetail/TextArea';
import UserNameModifyModal from './Modal/UserNameModifyModal';
import DeleteWarningModal from './Modal/DeleteWarningModal';

import NoUserPhotoImagePath from '../../../../image/NoUserPhoto.png';
import WhiteTrashIcon from '../../../../image/WhiteTrashIcon.png';
import DefaultProfile from '../../../../image/DefaultProfile.png';
import PencelImg from '../../../../image/Pencel.png';
import GrayDocument from '../../../../image/GrayDocument.png';
import DarkGrayDocument from '../../../../image/DarkGrayDocument.png';
import WhiteDocument from '../../../../image/WhiteDocument.png';
import TermsEditModal from './Modal/TermsEditModal';
import TermsViewModal from './Modal/TermsViewModal';

const IndividualInfo = forwardRef((props, ref) => {
  const { 
    terms, 
    setTerms,
    provisions,
    setProvisions, 
    defaultProvisions, 
    memberData, 
    rendering 
  } = props;

  const history = useHistory();

  const [defaultVal, setDefaultVal] = useState(memberData);
  const [isFocused, setIsFocused] = useState(false);
  const [memberDetail, setMemberDetail] = useState(memberData.memberDetail);
  const [originalDetail, setOriginalDetail] = useState(undefined);
  const [isClickedModifyUserName, setIsClickedModifyUserName] = useState(false);
  const [isClickedDeleteWarning, setIsClickedDeleteWarning] = useState(false);
  const [isProfileError, setIsProfileError] = useState(false);
  const [isTermsButtonHover, setIsTermsButtonHover] = useState(false);
  const [isViewEditTerms, setIsViewEditTerms] = useState(false);
  const [isViewTerms, setIsViewTerms] = useState(false);
  const [isSavingProvision, setIsSavingProvision] = useState(false);

  useImperativeHandle(ref, () => ({
    setMemberData: (val) => {
      setDefaultVal(val);
    }
  }));

  useEffect(() => {
    setMemberDetail(memberData.memberDetail);
  }, [memberData])

  useEffect(() => {
    if (isFocused) setOriginalDetail(memberDetail);
  }, [isFocused, memberDetail])


  // 유저 프로필 결정 //
  function decideUserProfile() {
    if (isProfileError || defaultVal.profile === '') return DefaultProfile;
    else return defaultVal.profile;
  }

  // 약관 버튼 테두리 선택 //
  function decideTermsButtonBorder() {
    if (isTermsButtonHover) {
      if (defaultVal.termsId === undefined) return '1px solid #777777';
      else if (defaultVal.termsAgreementId === undefined) return '1px solid #777777';
      else return '1px solid #5BD98A';
    }
    else {
      if (defaultVal.termsId === undefined) return '1px solid #CACACA';
      else if (defaultVal.termsAgreementId === undefined) return '1px solid #CACACA';
      else return '1px solid #ADECC4';
    }
  }

  // 약관 버튼 배경 선택 //
  function decideTermsButtonBackgroundColor() {
    if (defaultVal.termsId === undefined) return '#FFFFFF';
    else {
      if (isTermsButtonHover) {
        if (defaultVal.termsAgreementId === undefined) return '#777777';
        else return '#5BD98A';
      }
      else {
        if (defaultVal.termsAgreementId === undefined) return '#CACACA';
        else return '#ADECC4';
      }
    }
  }

  // 약관 버튼 텍스트 선택 //
  function decideTermsButtonText() {
    if (defaultVal.termsId === undefined) return '전자 약관 준비전';
    else if (defaultVal.termsAgreementId === undefined) return '서명 미완료';
    else return '서명 완료';
  }

  // 약관 버튼 텍스트 색상 선택 //
  function decideTermsButtonTextColor() {
    if (defaultVal.termsId === undefined) {
      if (isTermsButtonHover) return '#777777';
      else return '#CACACA';
    }
    else return '#FFFFFF';
  }

  // 약관 아이콘 투명도 결정 //
  function decideTermsButtonIconOpacity(division) {
    if (division === 'White') {
      if (defaultVal.termsId === undefined) return 0;
      else return 1;
    }
    else if (division === 'Gray') {
      if (defaultVal.termsId === undefined) {
        if (isTermsButtonHover) return 0;
        else return 1;
      }
      else return 0;
    }
    else if (division === 'DarkGray') {
      if (defaultVal.termsId === undefined) {
        if (isTermsButtonHover) return 1;
        else return 0;
      }
      else return 0;
    }
  }

  // 회원권 삭제 클릭시
  function onClickMemberInfoDelete() {
    let requestData = {
      memberId: memberData.memberId
    }

    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postDataVersion2('member/delete', 'POST', requestData);
        // console.log(responseData);
				if (responseData.status === 200) {
          alert("회원 정보가 삭제되었습니다.");
          history.push('/member/valid');
        }
        else if (responseData.status === 201) {
          alert("유효한 회원정보가 아닙니다.");
          history.push('/member/valid');
        }
        else if (responseData.status === 202) {
          alert("현재 유효한 회원권이 존재합니다.\n삭제후 다시시도해 주세요.");
        }
        else if (responseData.status === 203) {
          alert('현재 사용중인 락커가 존재합니다.\n사용 종료후 다시시도 해주세요.');
        }
        else if (responseData.status === 204) {
          alert("회원정보가 존재하지 않습니다.");
          history.push('/member/valid');
        }
				else if (responseData.status === 401) {
          alert("로그인 후 사용하세요");
          history.push('/');
        }
			}
		)();
  }

  // 취소버튼 누를시
  function onClickExit() {
    setMemberDetail(originalDetail);
    setIsFocused(false);
  }

  // 수정버튼 클릭시
  function onClickModity() {
    let data = {
      storeId: window.localStorage.getItem('StoreId'),
      memberId: memberData.memberId,
      memberDetail: memberDetail
    }
    
    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postData('member/modify/member_detail', 'POST', data);
        // console.log(responseData);
				if (responseData.status === 200) {
          alert('정보수정을 완료하였습니다.');
          setIsFocused(false);
          rendering();
				}
        else if (responseData.status === 400) {
          alert("회원 정보 수정의 권한이 없습니다.");
          history.push('/member/valid');
        }
				else if (responseData.status === 401) {
          alert("로그인 후 사용하세요");
          history.push('/');
        }
			}
		)();
  }

  // 변경된 이름 저장하기 클릭시 //
  function onClickSave(changeName, changeDate, changeGender, changePhone) {
    let data = {
      memberId: memberData.memberId,
      userName: changeName,
      birthDay: changeDate,
      gender: changeGender,
      phone: changePhone
    }
    
    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postData('member/modify/username', 'POST', data);
        // console.log(responseData);
				if (responseData.status === 200) {
          alert('정보수정을 완료하였습니다.');
          setIsClickedModifyUserName(false);
          rendering();
				}
        else if (responseData.status === 201) {
          alert("회원 정보 수정의 권한이 없습니다.");
          history.push('/member/valid');
        }
				else if (responseData.status === 401) {
          alert("로그인 후 사용하세요");
          history.push('/');
        }
			}
		)();
  }

  // 약관 버튼 클릭시 //
  function onClickTermsButton() {
    if (defaultVal.termsId === undefined) setIsViewEditTerms(true);
    else setIsViewTerms(true);
    // else if (defaultVal.termsAgreementId === undefined) setIsViewTerms(true);
    // else return 
  }

  // 약관 저장하기 클릭시 //
  function onClickSaveTerms(data) {
    if (data.title === '') {
      alert('계약서 템플릿 이름을 입력해주세요');
      return;
    }
    else if (data.provisionKeys.length === 0) {
      alert('약관의 규정을 설정해주세요');
      return;
    }
    else if (isSavingProvision) {
      alert('약관의 저장을 진행중입니다');
      return;
    } 

    setIsSavingProvision(true);

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('terms/save', 'POST', data);
        // console.log(responseData);
        if (responseData.status === 200) {
          setTerms(responseData.data.terms);
          setProvisions(responseData.data.provisions);
          setIsViewEditTerms(false);
          rendering();
          setIsSavingProvision(false);
        }
        else {
          alert('약관 저장에 실패하였습니다\n잠시 후 다시시도해주세요');
          setIsSavingProvision(false);
        }
      }
    )();
  }

  // 약관 닫기 클릭시 //
  function onClickExitEditTerms() {
    setIsViewEditTerms(false);
  }

  // 약관 닫기 클릭시 //
  function onClickExitViewTerms() {
    setIsViewTerms(false);
  }

  return (
    <TopLevelWrapper>
      { 
        isClickedModifyUserName && 
        <UserNameModifyModal 
          userName={defaultVal.name} 
          birthDay={defaultVal.birthDay} 
          gender={defaultVal.gender} 
          phone={defaultVal.phone}
          onClickSave={onClickSave} 
          onClickClose={() => setIsClickedModifyUserName(false)}/> 
      }
      { isClickedDeleteWarning && <DeleteWarningModal onClickExit={() => setIsClickedDeleteWarning(false)} onClickDelete={onClickMemberInfoDelete}/> }
      {
        isViewTerms &&
          <TermsViewModal
            termsAgreementId={defaultVal.termsAgreementId}
            storeName={defaultVal.storeName}
            clickedTerms={defaultVal.terms}
            defaultProvisions={defaultProvisions} 
            provisions={provisions}
            onClickExit={onClickExitViewTerms} />
      }
      { 
        isViewEditTerms && 
          <TermsEditModal 
            storeName={defaultVal.storeName}
            clickedTerms={defaultVal.terms}
            defaultProvisions={defaultProvisions} 
            provisions={provisions}
            onClickExit={onClickExitEditTerms}
            onClickSave={onClickSaveTerms} /> 
      }
      <UserPhotoWrapper>
        <UserPhoto src={decideUserProfile()} onError={() => setIsProfileError(true)}/>
      </UserPhotoWrapper>
      <UserInfoView>
        <InfoBoxWrapper>
          <UserNameWrapper>
            <UserName>{defaultVal.name}</UserName>
            <Explanation>회원</Explanation>
            <NameModifyButton onClick={() => setIsClickedModifyUserName(true)}>
              <Pencel src={PencelImg}/>
            </NameModifyButton>
          </UserNameWrapper>
          <RowLayerWrapper>
            <Explanation style={{ marginRight: '48px' }}>성별</Explanation>
            <UserInfoText>{defaultVal.gender === 'male' ? '남성' : '여성'}</UserInfoText>
          </RowLayerWrapper>
          <RowLayerWrapper>
            <Explanation style={{ marginRight: '22px' }}>생년월일</Explanation>
            <UserInfoText>{defaultVal.birthDay}</UserInfoText>
          </RowLayerWrapper>
          <RowLayerWrapper>
            <Explanation style={{ marginRight: '22px' }}>전화번호</Explanation>
            <UserInfoText>{defaultVal.phone === '' ? '없음' : defaultVal.phone}</UserInfoText>
          </RowLayerWrapper>
          <RowLayerWrapper>
            <Explanation style={{ marginRight: '35px'}}>아이디</Explanation>
            <UserInfoText>{defaultVal.nickName}</UserInfoText>
          </RowLayerWrapper>
          <RowLayerWrapper>
            <Explanation style={{ marginRight: '48px' }}>락커</Explanation>
            <UserInfoText>-</UserInfoText>
          </RowLayerWrapper>
        </InfoBoxWrapper>
        <ColumnLayerWrapper>
          <ExplanationWrapper>
            <Explanation>회원별 기록사항</Explanation>
            <ButtonWrapper>
              { isFocused && <ExitButton onClick={() => onClickExit()}>취소</ExitButton> }
              { isFocused && <ModifyButton onClick={() => onClickModity()}>수정</ModifyButton> }
              <DeleteButtonWrapper>
                <TermsButton 
                  border={decideTermsButtonBorder()}
                  backgroundColor={decideTermsButtonBackgroundColor()}
                  
                  onMouseOver={() => setIsTermsButtonHover(true)}
                  onMouseLeave={() => setIsTermsButtonHover(false)}
                  onClick={onClickTermsButton}>
                  <TermsIconWrapper>
                    <TermsIcon src={WhiteDocument} opacity={decideTermsButtonIconOpacity('White')}/>
                    <TermsIcon src={GrayDocument} opacity={decideTermsButtonIconOpacity('Gray')}/>
                    <TermsIcon src={DarkGrayDocument} opacity={decideTermsButtonIconOpacity('DarkGray')}/>
                  </TermsIconWrapper>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={12} color={decideTermsButtonTextColor()} cursor='pointer'>{decideTermsButtonText()}</Text>
                </TermsButton>
                <DeleteButton onClick={() => setIsClickedDeleteWarning(true)}>
                  <DeleteIcon src={WhiteTrashIcon}/>
                </DeleteButton>
              </DeleteButtonWrapper>
            </ButtonWrapper>
            </ExplanationWrapper>
            <MemberNoticeWindow>
              <TextArea value={memberDetail} setValue={setMemberDetail} setIsFocused={setIsFocused} placeholder='해당 회원에 대해 기록해야 할 사항들을 입력해주세요'/>
            </MemberNoticeWindow>
        </ColumnLayerWrapper>
      </UserInfoView>
    </TopLevelWrapper>
  )
})

export default IndividualInfo;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 38px;

  transition: all 0.3s ease-in-out;
`;

const UserPhotoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 229px;
  height: 229px;

  overflow: hidden;

  border: 1px solid #DFDFDF;
  border-radius: 24px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);

  background-color: #FFFFFF;
`;

const UserPhoto = styled.img`
  display: flex;
  flex-direction: column;

  // width: ${props => props.isNonePicture ? `64px` : `229px`};
  // height: ${props => props.isNonePicture ? `64px` : `229px`};

  width: 100%;
  height: 100%;

  // border: 1px solid #DFDFDF;
  // border-radius: 24px;
  // box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);

  // background-color: #FFFFFF;
`;

const UserInfoView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  // width: 753px;
  width: 697px;
  height: 229px;

  padding: 0 28px 0 28px;

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border-radius: 20px;
  background-color: #FFFFFF
`;

const InfoBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 192px;
  gap: 8px;

  overflow: hidden;
`;

const ColumnLayerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 8px;
`;

const RowLayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const UserNameWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const UserName = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const UserInfoText = styled.p`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 114px;

  overflow: hidden;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #595959;

  margin: 0 0 0 0;
`;

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: 200px;

  gap: 12px;
`;

const DeleteButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: -52px;
  margin-right: -27px;
`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 52px;
  height: 36px;

  // border-radius: 0px 30px 0px 18px;
  border-radius: 0px 20px 0px 0px;
  background-color: #FF0000;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: #FF7322;
  }
`;

const DeleteIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const TermsButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 10px;

  height: 34px;

  gap: 4px;

  border: ${props => props.border};
  border-radius: 0px 0px 0px 5px;

  background-color: ${props => props.backgroundColor};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const ModifyButton = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #87C8A6;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const TermsIconWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
`;

const TermsIcon = styled.img`
  position: absolute;

  width: 100%;
  height: 100%;

  opacity: ${props => props.opacity};

  transition: all 0.3s ease-in-out;
`;

const ExitButton = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FF3131;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const MemberNoticeWindow = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 16px 16px 16px 16px;
  margin: 0 0 0 0;

  width: 431px;
  height: 98px;

  overflow-x: hidden;
  overflow-y: scroll;

  border: 1px solid #DFDFDF;
  border-radius: 24px;
  background-color: #FFFFFF;

  &::-webkit-scrollbar {
    height: 4px;
    width: 0px;
  }

  &::-webkit-scrollbar-thumb {
		border: 2px solid transparent;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const MemberNotice = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  margin: 0 0 0 0;

  color: #CBCBCB;
`;

const NameModifyButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const Pencel = styled.img`
  width: 16px;
  height: 16px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: ${props => props.cursor};
  }
`;