import { React, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import Lottie from 'lottie-react-web';

import FetchModule from '../../Share/Network/FetchModule';

import PriceInput from '../../../Component/MySettingPageComponent/PriceInput';
import CalenderInput from '../../../Component/LockerComponent/LockerCalenderInput';
import RefundReasonInput from '../../../Component/MemberComponent/MemberDetail/RefundReasonInput';

// import SendMessageIcon from '../../../image/Send.png';
import RefundIcon from '../../../image/RefundIcon.png';
import LoadingAnimation from '../../../Animation/LoadingAnimation.json';

export default function RefundModal(props) {
  const { className, rendering, exitClick, refundData, index } = props;

  const history = useHistory();

  const [refundPrice, setRefundPrice] = useState(0);
  const [originalRefundCardPrice, setOriginalRefundCardPrice] = useState(0);
  const [originalRefundCashPrice, setOriginalRefundCashPrice] = useState(0);
  const [refundCardPrice, setRefundCardPrice] = useState(0);
  const [refundCashPrice, setRefundCashPrice] = useState(0);
  const [approvalDate, setApprovalDate] = useState(moment());
  const [reason, setReason] = useState('');

  const [isViewSelectModal, setIsViewSelectModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('회원권 환불 진행중');

  // 센터 환불인지 링크 환불인지 여부
  const [isCenterRefund, setIsCenterRefund] = useState(true);

  // 스크롤 정지 //
  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  // 이전 환불 신청이 있는지 확인 및 세팅 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('sales/refund/state/' + refundData.merchantUid, 'GET');

        if (responseData.status === 200) {
          setRefundCardPrice(responseData.data.cardPrice);
          setRefundCashPrice(responseData.data.cashPrice);
          setOriginalRefundCardPrice(responseData.data.cardPrice);
          setOriginalRefundCashPrice(responseData.data.cashPrice);
          // setRefundPrice(responseData.data.cardPrice + responseData.data.cashPrice);
          setApprovalDate(moment(responseData.data.approvalDate));
          setReason(responseData.data.reason);
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history, refundData])

  useEffect(() => {
    // if (refundData.merchantUid === undefined) return;

    // if (refundData.merchantUid.split('-')[2] !== '3') {
    //   const fetchModule = new FetchModule();
    //   (
    //     async () => {
    //       const responseData = await fetchModule.getIndividualData('sales/refund_info/' + refundData.merchantUid, 'GET');

    //       if (responseData.status === 200) setRefundPrice(responseData.data[0]);
    //       else if (responseData.status === 401) history.push('/');
    //     }
    //   )();
    // }
    // else {
    //   setIsCenterRefund(true);
    // }

    if (refundData.merchantUid === undefined) return;

    if (refundData.merchantUid.split('-')[2] !== '3') setIsCenterRefund(false);
    else setIsCenterRefund(true);

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getIndividualData('sales/refund_info/' + refundData.merchantUid, 'GET');

        if (responseData.status === 200) setRefundPrice(responseData.data[0]);
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [history, refundData])

  // 로딩 텍스트 변경 //
  useEffect(() => {
    if (!isLoading) return;

    setTimeout(() => {
      if (loadingText === '회원권 환불 진행중') setLoadingText('회원권 환불 진행중.');
      else if (loadingText === '회원권 환불 진행중.') setLoadingText('회원권 환불 진행중..');
      else if (loadingText === '회원권 환불 진행중..') setLoadingText('회원권 환불 진행중...');
      else if (loadingText === '회원권 환불 진행중...') setLoadingText('회원권 환불 진행중');
    }, 800);
  }, [isLoading, loadingText])

  // 환불버튼 클릭시 //
  function onClickRefund() {

    if (!isCenterRefund) {
      if (parseInt(refundCardPrice) + parseInt(refundCashPrice) === 0) {
        alert("환불금액을 입력해주세요");
        return;
      }

      // let requestData = {
      //   merchantUid: refundData.merchantUid,
      //   cardPrice: refundCardPrice,
      //   cashPrice: refundCashPrice,
      //   reason: reason,
      // }

      // const fetchModule = new FetchModule();
      // (
      //   async () => {
      //     const responseData = await fetchModule.postDataVersion2('sales/refund/linkpay', 'POST', requestData);

      //     if (responseData.status === 200) {
      //       rendering();
      //       exitClick();
      //     }
      //     else if (responseData.status === 201) {
      //       alert('올바르지 않은 요청입니다.');
      //       return;
      //     }
      //     else if (responseData.status === 202) {
      //       alert('결제가 완료된 내역이 아닙니다.');
      //       return;
      //     }
      //     else if (responseData.status === 203) {
      //       alert('링크페이 결제 내역이 아닙니다.');
      //       return;
      //     }
      //     else if (responseData.status === 204) {
      //       alert('결제 데이터가 존재하지 않습니다.');
      //       return;
      //     }
      //     else if (responseData.status === 205) {
      //       alert('이미 환불이 완료되었습니다.');
      //       return;
      //     }
      //     else if (responseData.status === 206) {
      //       alert('환불 가능 금액을 초과하였습니다.');
      //       return;
      //     }
      //     else if (responseData.status === 401) history.push('/');
      //     else {
      //       alert(responseData.error);
      //       return;
      //     }
      //   }
      // )();


      // 정산일 이후 결제된 내역인경우 카드 취소 //
      if (moment().startOf('month').isBefore(moment(refundData.approvalDate))) {
        let requestData = {
          merchantUid: refundData.merchantUid,
          cardPrice: refundCardPrice,
          cashPrice: refundCashPrice,
          reason: reason,
        }
        
        setIsLoading(true);
        
        const fetchModule = new FetchModule();
        (
          async () => {
            const responseData = await fetchModule.postDataVersion2('sales/refund/linkpay', 'POST', requestData);

            if (responseData.status === 200) {
              setIsLoading(false);
              rendering();
              exitClick();
            }
            else if (responseData.status === 201) {
              alert('올바르지 않은 요청입니다.');
              setIsLoading(false);
              return;
            }
            else if (responseData.status === 202) {
              alert('결제가 완료된 내역이 아닙니다.');
              setIsLoading(false);
              return;
            }
            else if (responseData.status === 203) {
              alert('링크페이 결제 내역이 아닙니다.');
              setIsLoading(false);
              return;
            }
            else if (responseData.status === 204) {
              alert('결제 데이터가 존재하지 않습니다.');
              setIsLoading(false);
              return;
            }
            else if (responseData.status === 205) {
              alert('이미 환불이 완료되었습니다.');
              setIsLoading(false);
              return;
            }
            else if (responseData.status === 206) {
              alert('환불 가능 금액을 초과하였습니다.');
              setIsLoading(false);
              return;
            }
            else if (responseData.status === 401) history.push('/');
            else {
              alert(responseData.error);
              setIsLoading(false);
              return;
            }
          }
        )();
      }
      // 정산일 이후의 경우 환불내역 생성전 안내문구 띄우기 //
      else {
        setIsViewSelectModal(true);
      }
    }
    else {
      if (parseInt(refundCardPrice) + parseInt(refundCashPrice) === 0) {
        alert("환불금액을 입력해주세요");
        return;
      }

      let requestData = {
        merchantUid: refundData.merchantUid,
        cardPrice: refundCardPrice,
        cashPrice: refundCashPrice,
        reason: reason,
        approvalDate: approvalDate.format('YYYY-MM-DDTHH:mm:ss')
      }

      setIsLoading(true);

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('sales/refund/center', 'POST', requestData);

          if (responseData.status === 200) {
            rendering();
            exitClick();
            setIsLoading(false);
          }
          else if (responseData.status === 204) {
            alert("환불 가능 금액을 초과하였습니다.");
            setIsLoading(false);
          }
          else if (responseData.status === 401) history.push('/');
        }
      )();
    }
  }

  // 정산일 이전 데이터 확인모달에서 환불버튼 클릭시 //
  function onClickRefundOnCheckModal() {
    let requestData = {
      merchantUid: refundData.merchantUid,
      cardPrice: refundCardPrice,
      cashPrice: refundCashPrice,
      reason: reason,
    }
    // console.log(requestData);
    setIsLoading(true);

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('sales/refund/linkpay', 'POST', requestData);

        if (responseData.status === 200) {
          setIsLoading(false);
          rendering();
          exitClick();
        }
        else if (responseData.status === 201) {
          alert('올바르지 않은 요청입니다.');
          setIsLoading(false);
          return;
        }
        else if (responseData.status === 202) {
          alert('결제가 완료된 내역이 아닙니다.');
          setIsLoading(false);
          return;
        }
        else if (responseData.status === 203) {
          alert('링크페이 결제 내역이 아닙니다.');
          setIsLoading(false);
          return;
        }
        else if (responseData.status === 204) {
          alert('결제 데이터가 존재하지 않습니다.');
          setIsLoading(false);
          return;
        }
        else if (responseData.status === 205) {
          alert('이미 환불이 완료되었습니다.');
          setIsLoading(false);
          return;
        }
        else if (responseData.status === 206) {
          alert('환불 가능 금액을 초과하였습니다.');
          setIsLoading(false);
          return;
        }
        else if (responseData.status === 401) history.push('/');
        else {
          alert(responseData.error);
          setIsLoading(false);
          return;
        }
      }
    )();
  }

  function onClickApprovalDate() {
  }


  function onChangeApprovalDate(data) {
    if (!isCenterRefund) {
      alert('링크페이 결제내역은 환불일을 변경할 수 없습니다.');
      return;
    }

    setApprovalDate(data);
  }


  return (
    <TopLevelWrapper>
      {
        isLoading ?
          <ViewWrapper>
            <LottieWrapper>
              <Lottie options={{ animationData: LoadingAnimation }} />
            </LottieWrapper>
            <LoadingTextWrapper>
              <Text fontFmaily='NotoSansKR-Medium' fontSize={16} color='#595959' cursor='default'>{loadingText}</Text>
            </LoadingTextWrapper>
          </ViewWrapper>
          :
          <ModalTotalWrapper>
            {
              isViewSelectModal ?
                <SelectModalView>
                  <RedTrashBoxWrapper>
                    <EmptyButtonWrapper />
                    <RedTrashBox>
                      <RefundImage src={RefundIcon} />
                    </RedTrashBox>
                    <EmptyButtonWrapper />
                  </RedTrashBoxWrapper>
                  <TotalWrapper>
                    <Title>링크페이 환불</Title>
                    <SelectTextWrapper>
                      <Explanation>이미 정산된 회원권입니다</Explanation>
                      <Explanation>카드 취소는 진행되지 않습니다</Explanation>
                    </SelectTextWrapper>
                  </TotalWrapper>
                  <SelectButtonWrapper>
                    <NoButton onClick={() => setIsViewSelectModal(false)}>닫기</NoButton>
                    <YesButton onClick={onClickRefundOnCheckModal}>환불하기</YesButton>
                  </SelectButtonWrapper>
                </SelectModalView>
                :
                <ModalView>
                  <TextWrapper topMagin={28}>
                    {
                      isCenterRefund ?
                        <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323'>회원권 환불하기</Text>
                        :
                        <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323'>링크페이 환불하기</Text>
                    }
                    {/* <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323'>회원권 환불하기</Text> */}
                  </TextWrapper>
                  <TextWrapper topMagin={16}>
                    {
                      isCenterRefund ?
                        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>선택한 결제 내역에 대한 환불을 진행하며</Text>
                        :
                        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>정산일(매월 1일) 이후 결제된 내역만 카드 취소되며,</Text>
                    }
                    {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>선택한 결제 내역에 대한 환불을 진행하며</Text> */}
                  </TextWrapper>
                  <TextWrapper topMagin={14}>
                    {
                      isCenterRefund ?
                        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>되돌릴 수 없습니다. 환불을 계속 하시겠습니까?</Text>
                        :
                        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>정산일 이전 내역은 센터에서 환급합니다.</Text>
                    }
                    {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>되돌릴 수 없습니다. 환불을 계속 하시겠습니까?</Text> */}
                  </TextWrapper>
                  <ComponentWrapper>
                    <CalenderWrapper>
                      <Text style={{ marginRight: '45px' }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>환불일</Text>
                      <CalenderInput width={209} value={approvalDate} setValue={onChangeApprovalDate} className='' clickFn={onClickApprovalDate} />
                    </CalenderWrapper>
                    <TextWrapper topMagin={13}>
                      <PriceInput division='카드' value={refundCardPrice} setValue={setRefundCardPrice} />
                    </TextWrapper>
                    <TextWrapper topMagin={13}>
                      <PriceInput division='현금' value={refundCashPrice} setValue={setRefundCashPrice} />
                    </TextWrapper>
                    <TextWrapper topMagin={13}>
                      <RefundReasonInput value={reason} setValue={setReason} />
                    </TextWrapper>
                    <CenterTextWrapper>
                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>총</Text>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FF3131' style={{ marginLeft: `4px` }}>{(parseInt(refundCashPrice) + parseInt(refundCardPrice)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원 </Text>
                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>을 환급합니다.</Text>
                    </CenterTextWrapper>
                  </ComponentWrapper>
                  <ButtonWrapper isCenterRefund={isCenterRefund}>
                    <ExitButton onClick={exitClick}>취소</ExitButton>
                    <SaveButton onClick={onClickRefund}>확인</SaveButton>
                  </ButtonWrapper>
                </ModalView>
            }
          </ModalTotalWrapper>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;
  
  width: 100vw;
  height: 100vh;

  overflow-y: scroll;

  background-color: rgba(0, 0, 0, 0.2);
`;

const ModalTotalWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 371px;
  // height: 343px;
  height: 518px;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  // margin-top: ${props => props.isCenterRefund ? `28px` : `37px`};
  margin-top: 28px;
  gap: 16px;

  width: 314px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const CenterTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: 100%;

  margin-top: 13px;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  margin-top: ${props => props.topMagin}px;

  width: 314px;
`;

const CalenderWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  margin-top: 21px;

  width: 315px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const RefundRuleButton = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: red;

  margin: 0 0 0 160px;

  :hover {
    cursor: pointer;
  }
`;

const SelectModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 354px;
  height: 200px;

  border-radius: 15px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: 12px;

  width: 90.33%;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const SelectTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;

  margin-top: 8px;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #595959;
  
  margin: 0 0 0 0;
`;

const RedTrashBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 12px 0px 12px;
  margin-top: -43px;

  width: calc(100% - 24px);
`;

const RedTrashBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  min-width: 86px;
  max-width: 86px;
  width: 86px;

  min-height: 86px;
  max-height: 86px;
  height: 86px;

  border-radius: 86px;
  background-color: #FF3131;
`;

const RefundImage = styled.img`
  // margin-left: -10px;

  // width: 53px;
  // height: 53px;
  width: 60px;
  height: 60px;
`;

const EmptyButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 12px;
  height: 12px;
`;

const ExitButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 36px;

  width: 12px;
  height: 12px;

  :hover {
    cursor: pointer;
  }
`;

const ExitImage = styled.img`
  width: 12px;
  height: 12px;
`;

const SelectButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 28px;

  width: 100%;
  height: 52px;

  overflow: hidden;

  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
`;

const NoButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 50%;
  height: 100%;

  background-color: #CFCFCF;
  
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #6DD49E;
  }
`;

const YesButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 50%;
  height: 100%;

  background-color: #CFCFCF;
  
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #FF3131;
  }
`;

const CompleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: #CFCFCF;
  
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #6DD49E;
  }
`;

const ViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 40px;

  width: 180px;
  height: 240px;

  overflow: hidden;

  border-radius: 8px;
  background-color: #FFFFFF;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 160px;
  height: 160px;
`;

const LoadingTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 140px;
`;