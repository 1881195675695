import React from 'react';
import styled from 'styled-components';

import WodTitleInputBox from '../Component/WodTitleInputBox';

// Image Import //
import PlusImage from '../../image/PlusButton.png';
import TrashImage from '../../image/TrashIcon.png';
import DivisionBox from './DivisionBox';
import TodayWodBox from './TodayWodBox';
// Image Import //

export default function ExerciseBox(props) {
  const {
    clickDate,

    clickData,
    onClickAddSection,
    onClickDeleteSection,
    onClickAddScaleSection,

    movements,
    recentlySearchHistory,
    setRecentlySearchHistory,

    refreshTotalData,

    onClickScaleWod,

    wodTitle,
    setWodTitle,

    nowSection,
    setNowSection,
    nowScaleSection,
    setNowScaleSection,

    onClickURL
  } = props;

  // 와드 제목 변경시 //
  function onChangeWodTitle(value) {
    clickData.title = value;

    refreshTotalData();
  }

  // 섹션 추가 버튼 클릭시 //
  function onClickAddSectionButton() {
    setNowSection(clickData.sectionList.length);
    onClickAddSection();
  }

  // 섹션 삭제 버튼 클릭시 //
  function onClickDeleteSectionButton() {
    // if (clickData.sectionList.length > 1) {
    //   setNowSection(clickData.sectionList.length - 2);
    //   onClickDeleteSection(nowSection);
    // }
    // console.log(nowSection)
    onClickDeleteSection(nowSection);
  }

  // 섹션 스케일 추가 버튼 클릭시 //
  function onClickAddScaleSectionButton() {
    onClickAddScaleSection(nowSection);
  }

  // 섹션 스케일 버튼 클릭시 //
  function onClickScaleSectionButton(index) {
    setNowScaleSection(index);
    onClickScaleWod();
  }

  return (
    <ExerciseBoxWrapper>
      <WodEditWrapper>
        <WodTitleInputBoxWrapper>
          <WodTitleInputBox value={clickData.title} setValue={onChangeWodTitle} placeholder={clickDate === undefined ? `` : clickDate.format('YYYY.MM.DD')} />
        </WodTitleInputBoxWrapper>
        <TotalWodSectionButtonWrapper>
          <WodSectionButtonWrapper>
            {
              clickData.sectionList.map((data, index) => (
                <WodSectionButton key={'MakeExerciseSectionNum-' + index} isClicked={nowSection === index} onClick={() => setNowSection(index)}>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='pointer'>{String.fromCharCode(index + 65)}</Text>
                </WodSectionButton>
              ))
            }
            <WodSectionFunctionButton backgroundColor='#FF8B48' onClick={onClickAddSectionButton}>
              <WodSectionButtonImage width={15} height={15} src={PlusImage} />
            </WodSectionFunctionButton>
            <WodSectionFunctionButton backgroundColor='#FF3131' onClick={onClickDeleteSectionButton}>
              <WodSectionButtonImage width={17} height={17} src={TrashImage} />
            </WodSectionFunctionButton>
          </WodSectionButtonWrapper>
        </TotalWodSectionButtonWrapper>
        <DivisionLine/>
        <ScaleSectionBoxWrapper>
          <WodSectionButtonWrapper>
            <SectionViewBox>
              <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='pointer'>{String.fromCharCode(nowSection + 65) + ' Session'}</Text>
            </SectionViewBox>
            {
              clickData.sectionList[nowSection].scaleSections.map((data, index) => (
                <ScaleWodSectionButton key={'MakeExerciseScaleSectionNum-' + index} isClicked={nowScaleSection === index} onClick={() => onClickScaleSectionButton(index)}>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='pointer'>{String.fromCharCode(nowSection + 65) + '-' + (index + 2)}</Text>
                </ScaleWodSectionButton>
              ))
            }
            <ScaleWodSectionAddButton onClick={onClickAddScaleSectionButton}>
              <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='pointer'>Scale +</Text>
            </ScaleWodSectionAddButton>
            {/* <WodSectionFunctionButton backgroundColor='#FF3131' onClick={onClickDeleteSectionButton}>
              <WodSectionButtonImage width={17} height={17} src={TrashImage} />
            </WodSectionFunctionButton> */}
          </WodSectionButtonWrapper>
        </ScaleSectionBoxWrapper>
        <DivisionBoxWrapper>
          <DivisionBox 
            sectionData={clickData.sectionList[nowSection]}
            refreshTotalData={refreshTotalData}/>
        </DivisionBoxWrapper>
        <DivisionLine/>
          <TodayWodBoxWrapper>
            <TodayWodBox 
              sectionData={clickData.sectionList[nowSection]}
              movements={movements}
              nowSection={nowSection}
              // nowScaleSection={nowScaleSection}
              recentlySearchHistory={recentlySearchHistory}
              setRecentlySearchHistory={setRecentlySearchHistory}
              refreshTotalData={refreshTotalData}
              onClickURL={onClickURL}/>
          </TodayWodBoxWrapper>
      </WodEditWrapper>
    </ExerciseBoxWrapper>
  )
}

const ExerciseBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: calc(100% - 30px);
`;

const WodEditWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
`;

const WodTitleInputBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 24px;

  width: 100%;
`;

const TotalWodSectionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-item: center;

  padding: 20px 0px 18px 0px;

  width: 100%;
`;

const WodSectionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-item: center;

  gap: 8px;

  flex-wrap: wrap;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: calc(100% + 30px);
  height: 1px;

  background-color: #E5E5E5;
`;

const ScaleSectionBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-item: center;

  margin-top: 10px;
  
  width: 100%;
`;

const SectionViewBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 85px;
  height: 35px;

  border-radius: 10px;
  background-color: #6DD49E;
`;

const WodSectionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 34px;
  height: 34px;

  border-radius: 10px;
  background-color: ${props => props.isClicked ? '#6DD49E' : '#CBCBCB'};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const WodSectionFunctionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 34px;
  height: 34px;

  border-radius: 10px;
  background-color: ${props => props.backgroundColor};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const ScaleWodSectionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 43px;
  height: 34px;

  border-radius: 10px;
  background-color: ${props => props.isClicked ? '#4D92EF' : '#CBCBCB'};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const ScaleWodSectionAddButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 69px;
  height: 34px;

  border-radius: 10px;
  background-color: #CBCBCB;

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const WodSectionButtonImage = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const DivisionBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 20px 0px;

  width: 100%;
`;

const TodayWodBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;