import { React, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import FetchModule from '../../Model/Share/Network/FetchModule.js';

import ValidMemberView from '../../Model/Individual/MemberModel/ValidMemberView.js';
import MemberDetailView from '../../Model/Individual/MemberModel/MemberDetail/MemberDetailView.js';

function ValidMemberInfoPage() {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   setIsLoading(true);
  //   const fetchModule = new FetchModule();
	// 	(
	// 		async () => {
	// 			const responseData = await fetchModule.postData('member/alive/', 'POST');
  //       // console.log(responseData.data);
  //       if (responseData.status === 200) setData(responseData.data);
  //       else if (responseData.status === 401) history.push('/');
  //       // else alert("회원정보를 불러오는데 실패하였습니다.\n잠시후 다시시도 해주세요");

  //       setIsLoading(false);
	// 		}
	// 	)();
  // }, [history])


  useEffect(() => {
    setIsLoading(true);

    const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.getDataVersion2('member/alive', 'GET');
        // console.log(responseData.data);
        if (responseData.status === 200) setData(responseData.data);
        else if (responseData.status === 401) history.push('/');

        setIsLoading(false);
			}
		)();
  }, [history])

  // useEffect(() => {
  //   ValidRef.current.setData(data);
  // }, [data])

  function MemberShipDetailClick(value) {
    history.push('/member/valid/detail/' + value.memberId);
  }

  return (
    <TopLevelWrapper>
      <ValidMemberView /*ref={ValidRef}*/ data={data} isLoading={isLoading} memberShipDetailClick={MemberShipDetailClick}/>
    </TopLevelWrapper>
  )
}

export default ValidMemberInfoPage;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;