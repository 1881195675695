import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

import WhiteSelectOffPath from '../../image/WhiteSelectOff.png';

export default function MainPageWorkoutSelectBox(props) {
  const { options, value, setValue } = props;

  const [isClicked, setIsClicked] = useState(false);
  const [enabled, setEnabled] = useState(false);

  const TotalRef = useRef();
  const SelectListRef = useRef();

  // 이미 저장된 수업은 먼저 세팅해주기 //
  useEffect(() => {
    if (options.length === 0) {
      setEnabled(false);
      return;
    }
    
    if (options.length === 1) setEnabled(false);
    else setEnabled(true);

    setValue(options[0]);
  }, [options])

  // useEffect(() => {

  // }, [value])

  // 컴포넌트 밖을 클릭하면 셀렉트 창 닫기
  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [onClickOutSide])

  // 컴포넌트 밖을 클릭하면 셀렉트 창 닫기
  function onClickOutSide(e) {
    if (TotalRef.current !== e.target && !TotalRef.current.contains(e.target) && enabled) {
      SelectListRef.current.style.height = `0px`;
      SelectListRef.current.style.border = '0px solid #FFFFFF';
      SelectListRef.current.style.overflow = 'hidden';
      setIsClicked(false);
    }
  }

  // 셀렉트 박스 색상 결정 //
  function decideSelectBoxBackgroundColor() {
    if (value === undefined) return '#FFFFFF';
    else return value.color;
  }

  // 셀렉트 박스 텍스트 결정 //
  function decideValueText() {
    if (value === undefined) return '';
    else return (value.title + ' (' + value.lessonName + ')');
  }

  // 셀렉트 박스 클릭시 //
  function onClickSelectBox() {
    if (!enabled) return;

    if (isClicked) {
      SelectListRef.current.style.height = `0px`;
      SelectListRef.current.style.border = '0px solid #FFFFFF';
      SelectListRef.current.style.overflow = 'hidden';
      setIsClicked(false);
    }
    else {
      SelectListRef.current.style.backgroundColor = '#FFFFFF';
      SelectListRef.current.style.border = '1px solid #FF8B48';
      SelectListRef.current.style.height = 32 * (options.length) > 500 ? `500px` : (32 * (options.length) + 'px');
      SelectListRef.current.style.maxHeight = `500px`;
      SelectListRef.current.style.overflowY = 'scroll';
      SelectListRef.current.style.overflowX = 'none';
      setIsClicked(true);
    }
  }

  // 수업 선택시 //
  function onClickSelectList(data) {
    setValue(data);

    SelectListRef.current.style.height = `0px`;
    SelectListRef.current.style.border = '0px solid #FFFFFF';
    SelectListRef.current.style.overflow = 'hidden';
    setIsClicked(false);
  }

  return (
    <SelectBoxWrapper ref={TotalRef}>
      {
        options.length === 0 ?
          <NoneSelectBox>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#4F4F4F' hover='pointer'>프로그램이 존재하지 않습니다</Text>
          </NoneSelectBox>
          :
          <SelectBoxInnerWrapper>
            <SeletedBox
              backgroundColor={decideSelectBoxBackgroundColor()}
              onClick={onClickSelectBox}>
              <SelectBoxTextWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' hover='pointer'>{decideValueText()}</Text>
              </SelectBoxTextWrapper>
              {enabled && <ArrowImage src={WhiteSelectOffPath} isClicked={isClicked} />}
            </SeletedBox>
            <WillSelectList ref={SelectListRef}>
              {
                options.map((data, index) => (
                  <SelectListTextWrapper
                    key={'SelectLesson-' + index}
                    onClick={() => onClickSelectList(data)}
                    hoverBackgroundColor={data.color}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' hover='pointer'>{(data.title + ' (' + data.lessonName + ')')}</Text>
                  </SelectListTextWrapper>
                ))
              }
            </WillSelectList>
          </SelectBoxInnerWrapper>
      }
    </SelectBoxWrapper>
  )
}

const SelectBoxWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  transition: all 0.3s ease-in-out;
`;

const SelectBoxInnerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  transition: all 0.3s ease-in-out;
`;

const NoneSelectBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 10px;

  width: calc(100% - 24px);
  height: calc(100% - 4px);

  border: 2px solid #DBDBDB;
  border-radius: 30px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const SeletedBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 10px;

  width: calc(100% - 20px);
  height: 100%;

  border-radius: 30px;
  background-color: ${props => props.backgroundColor};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const ArrowImage = styled.img`
  width: 20px;
  height: 20px;

  // opacity: ${props => props.enabled ? 1 : 0};
  transform: ${props => props.isClicked ? `rotate(-180deg)` : `rotate(0deg)`};

  transition: all 0.3s ease-in-out;
`;

const WillSelectList = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 37px;

  width: 100%;
  height: 0px;

  border-radius: 6px;

	overflow: hidden;

	:hover {
		cursor: pointer;
	}

	&::-webkit-scrollbar {
		// width: 9px;
		width: 0px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #FF8B48;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}

  transition: all 0.3s ease-in-out;
`;

const SelectBoxTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 80%;
  height: 21px;

  overflow: hidden;
`;

const SelectListTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding: 8px 10px;

  width: calc(100% - 20px);
  height: 16px;

  overflow: hidden;
  
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
    background-color: ${props => props.hoverBackgroundColor};
  }

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;