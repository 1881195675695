import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import styled from "styled-components";
import moment from "moment";

import { makeUpWorkout } from "../../Exercise/Transaction/Transaction.js";
import FetchModule from "../../Model/Share/Network/FetchModule";

import MainPageWorkoutSelectBox from "../Component/MainPageWorkoutSelectBox";

import WhiteCrownImage from '../../image/WhiteCrown.png';
import EmptySign from '../../image/EmptySign.png';
import MainPageWodViewBox from "./WodViewBox.js";
import MainPageRankingView from "./MainPageRankingView";

export default function MainPageBoardView(props) {
  const { } = props;

  const history = useHistory();

  const [wods, setWods] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [selectedWod, setSelectedWod] = useState(undefined)

  const [nowSection, setNowSection] = useState(0);
  const [nowScaleSection, setNowScaleSection] = useState(undefined);

  const [isClickedRanking, setIsClickedRanking] = useState(false);
  

  // 금일 와드 정보 불러오기 //
  useEffect(() => {
    let requestData = {
      date: moment().format('YYYY-MM-DDT00:00:00')
      // date: moment('2024-12-12').format('YYYY-MM-DDT00:00:00')
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfExerciseVersion2('workout/daily_wod', 'POST', requestData);
        // console.log(responseData.data);
        if (responseData.status === 200) {
          let newWorkouts = makeUpWorkout(responseData.data.workouts.slice());
          let newSelectOptions = [];

          for (let i = 0; i < newWorkouts.length; i++) {
            let selectOption = {
              workoutId: newWorkouts[i].workoutId,
              title: newWorkouts[i].title,
              lessonName: newWorkouts[i].lessonName,
              color: newWorkouts[i].lessonColor
            }

            newSelectOptions.push(selectOption);

            setWods(newWorkouts);
            setSelectOptions(newSelectOptions);
          }
        }
      }
    )();
  }, [])

  // 선택된 정보에 맞게 와드 데이터 세팅 //
  useEffect(() => {
    if (wods.length === 0 || selectedOption === undefined) {
      setSelectedWod(undefined);
      return;
    }

    let wodIndex = wods.findIndex(obj => obj.workoutId === selectedOption.workoutId);

    setSelectedWod(wods[wodIndex]);
  }, [wods, selectedOption])

  // 한자리 숫자 앞에 0 붙이기 //
  function numberPad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  }

  // Section Title 설정
  function decideSectionTitle(data) {
    if (data === undefined) return '';
    else if (data.tag === 'ForTime') return data.round + ' Round For Time';
    else if (data.tag === 'AMRAP') {
      let min = parseInt(data.exerciseTime / 60);
      let sec = parseInt(data.exerciseTime % 60);

      if (min > 0 && sec > 0) return min + 'min ' + sec + 'sec AMRAP';
      else if (min > 0) return min + 'min AMRAP';
      else if (sec > 0) return sec + 'sec AMRAP';
      else return 'AMRAP';
    }
    else if (data.tag === 'EMOM') {
      let min = parseInt(data.round * data.exerciseTime / 60);
      let sec = parseInt(data.round * data.exerciseTime % 60);

      if (min > 0 && sec > 0) return min + 'min ' + sec + 'sec EMOM';
      else if (min > 0) return min + ' min EMOM';
      else if (sec > 0) return sec + ' sec EMOM';
      else return 'EMOM';
    }
    else if (data.tag === 'TABATA') {
      let onMin = parseInt(data.exerciseTime / 60);
      let onSec = parseInt(data.exerciseTime % 60);
      let offMin = parseInt(data.restTime / 60);
      let offSec = parseInt(data.restTime % 60);

      return 'On: ' + onMin + ':' + numberPad(onSec, 2) + ' / Off:' + offMin + ':' + numberPad(offSec, 2) + ' ' + data.round + 'R TABATA';
    }
    else if (data.tag === 'SINGLE') {
      if (data.mainExerciseList.length <= 0) return 'SINGLE';
      else if (data.mainExerciseList.length === 1) return data.mainExerciseList[0].movementName;
      else return 'Multiple Single';
    }
    else if (data.tag === 'Custom') {
      if (data.customTitle !== '') return data.customTitle;
      else if (data.scoreKeyword === '점수 없음'
        || data.scoreKeyword === '피크와트'
        || data.scoreKeyword === '최고 속도') return 'Custom : ' + data.scoreKeyword;
      else return 'Custom : ' + data.scoreKeyword + ' ' + data.scorePriority;
    }
  }

  // 선택한 와드 정보 정리 표출 //
  function decideSelectedWodInfoText() {
    if (selectedOption === undefined) return '';
    else return selectedOption.lessonName + ' ' + moment().format('YYYY.MM.DD') + ' - ' + selectedOption.title;
  }

  // 보여줄 섹션 결정 //
  function decideViewSection() {
    if (nowScaleSection === undefined) return selectedWod.sectionList[nowSection];
    else return selectedWod.sectionList[nowSection].scaleSections[nowScaleSection];
  }

  // 메인 세션 클릭시 //
  function onClickNowSection(index) {
    setNowSection(index);
    setNowScaleSection(undefined);
  }

  // 스케일 세션 클릭시 //
  function onClickScaleSectionButton(index) {
    setNowScaleSection(index);
  }

  // 순위표 보기 클릭시 //
  function onClickRanking() {
    setIsClickedRanking(!isClickedRanking);
  }

  // 링크 클릭시 //
  function onClickURL(url) {
    window.open(url, "_blank", "noreferrer");
  }

  // 자세히 보기 클릭시 //
  function onClickDetail() {
    history.push('/board');
  }

  // 수업 선택시 //
  function onCompleteSelectLesson(value) {
    setNowSection(0);
    setNowScaleSection(undefined);
    setSelectedOption(value);
  }

  return (
    <BoardBox>
      <InfoWrapper>
        <TitleWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' cursor='default'>보드</Text>
          <ExplanationWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777' cursor='default'>기록사항을 편하게 적어두세요</Text>
            <Text onClick={onClickDetail} fontFamily='NotoSansKR-Medium' fontSize={12} color='#FF8B48' cursor='pointer'>자세히 보기</Text>
          </ExplanationWrapper>
        </TitleWrapper>
        <DivisionLine style={{ marginTop: '24px', marginBottom: '28px' }} />
        <SelectBoxLineWrapper>
          <SelectBoxWrapper>
            <MainPageWorkoutSelectBox options={selectOptions} value={selectedOption} setValue={onCompleteSelectLesson} />
          </SelectBoxWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' cursor='default'>{moment().format('YYYY.MM.DD')}</Text>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' cursor='default'>{decideSelectedWodInfoText()}</Text>
        </SelectBoxLineWrapper>
        <DivisionLine style={{ marginTop: '18px', marginBottom: '14px' }} />
        {
          selectedWod === undefined ?
            <TotalWodBox>
              <NoneWodBox>
                <Image width={148} height={62} src={EmptySign} />
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#CBCBCB' cursor='default'>프로그램이 존재하지 않습니다</Text>
              </NoneWodBox>
            </TotalWodBox>
            :
            <TotalWodBox>
              <WodBoxWrapper isClickedRanking={isClickedRanking}>
                <TotalWodSectionButtonWrapper>
                  <WodSectionButtonWrapper>
                    {
                      selectedWod.sectionList.map((data, index) => (
                        <WodSectionButton key={'MainBoardSection-' + index} isClicked={nowSection === index} onClick={() => onClickNowSection(index)}>
                          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='pointer'>{String.fromCharCode(index + 65)}</Text>
                        </WodSectionButton>
                      ))
                    }
                  </WodSectionButtonWrapper>
                  <RankingButton isClickedRanking={isClickedRanking} onClick={onClickRanking}>
                    <Image width={18} height={20} src={WhiteCrownImage} />
                  </RankingButton>
                </TotalWodSectionButtonWrapper>
                <ScaleSectionBoxWrapper>
                  <WodSectionButtonWrapper>
                    {
                      selectedWod.sectionList[nowSection].scaleSections.map((data, index) => (
                        <ScaleWodSectionButton isClicked={nowScaleSection === index} onClick={() => onClickScaleSectionButton(index)}>
                          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='pointer'>{String.fromCharCode(nowSection + 65) + '-' + (index + 2)}</Text>
                        </ScaleWodSectionButton>
                      ))
                    }
                  </WodSectionButtonWrapper>
                </ScaleSectionBoxWrapper>
                <SectionTitleWrapper>
                  <SectionBox isClickedScale={nowScaleSection !== undefined}>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='pointer'>{nowScaleSection === undefined ? String.fromCharCode(nowSection + 65) : String.fromCharCode(nowSection + 65) + '-' + (nowScaleSection + 2)}</Text>
                  </SectionBox>
                  <SectionTitleBox isClickedScale={nowScaleSection !== undefined}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333' hover='pointer'>{decideSectionTitle(decideViewSection())}</Text>
                  </SectionTitleBox>
                </SectionTitleWrapper>
                <MainPageWodViewBox
                  sectionData={decideViewSection()}
                  onClickURL={onClickURL} 
                  isClickedRanking={isClickedRanking}
                  isMainPage={true}/>
              </WodBoxWrapper>
              <RankingBoxWrapper isClickedRanking={isClickedRanking}>
                <MainPageRankingView
                  nowSection={nowSection}
                  setNowSection={setNowSection}

                  selectedWod={selectedWod}
                  isClickedRanking={isClickedRanking}
                  onClickGoBack={onClickRanking}/>
              </RankingBoxWrapper>
            </TotalWodBox>
        }
      </InfoWrapper>
    </BoardBox>
  )
}

const BoardBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 20px 0px;

  width: 1054px;

  border-radius: 20px;
  background-color: #FFFFFF;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 94.59%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  gap: 4px;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const DivisionLine = styled.div`
  width: 100%;
  height: 2px;

  background-color: #ECECEC;
`;

const SelectBoxLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  gap: 10px;
`;

const SelectBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 220px;
  height: 35px;
`;

const TotalWodBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
`;

const WodBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.isClickedRanking ? '55%' : '100%'};
  
  transition: all 0.3s ease-in-out;
`;

const RankingBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.isClickedRanking ? '43%' : '0%'};

  transition: all 0.3s ease-in-out;
`;

const TotalWodSectionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-item: center;

  padding: 20px 0px 6px 0px;

  width: 100%;
`;

const WodSectionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-item: center;

  gap: 8px;
`;

const ScaleSectionBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-item: center;
  
  width: 100%;
`;

const SectionTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-item: center;

  margin-top: 20px;

  width: 100%;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isClickedScale ? `45px` : `34px`};
  height: 34px;

  border-radius: 6px;
  background-color: ${props => props.isClickedScale ? `#4D92EF` : `#6DD49E`};

  :hover {
    cursor: default;
  }

  transition: all 0.3s ease-in-out;
`;

const SectionTitleBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 15px;

  width: ${props => props.isClickedScale ? `calc(100% - 81px)` : `calc(100% - 70px)`};
  height: 34px;

  border-radius: 6px;
  background-color: #F9F9F9;

  transition: all 0.3s ease-in-out;
`;

const WodSectionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 34px;
  height: 34px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? '#6DD49E' : '#CBCBCB'};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const ScaleWodSectionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 43px;
  height: 34px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? '#4D92EF' : '#CBCBCB'};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const RankingButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 63px;
  height: 32px;

  border-radius: 5px;
  background-color: ${props => props.isClickedRanking ? `#FF8B48` : `#FFB800`};

  :hover {
    cursor: pointer;
  }
`;

const NoneWodBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 60px 0px;

  width: 100%;

  gap: 8px;
`;

const Image = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.cursor};
  }

  transition: all 0.3s ease-in-out;
`;