import { React, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Lottie from 'lottie-react-web';
import moment from 'moment';

import MemberList from './MemberList';

import Duration from '../../../image/DurationImage.png';
import NewImage from '../../../image/NewImage.png';
import AgeRangeIcon from '../../../image/AgeRangeIcon.png';
import CurrentTimerIcon from '../../../image/CurrentTimerIcon.png';

import LoadingAnimation from '../../../Animation/LoadingAnimation.json';

export default function MemberEngagementView(props) {
  const {
    startDate,
    endDate,
    calcDateText,
    engagementReserves,
    selectedPartnerGym,
    isOpenPartnerGymSales,
    isEngagementLoading,
    setIsEngagementLoading
  } = props;

  const history = useHistory();

  const [engagementStates, setEngagementStates] = useState([]);
  const [engagementTotalCount, setEngagementTotalCount] = useState(1);
  const [engagementMaxCount, setEngagementMaxCount] = useState(0);
  const [engagementMaxText, setEngagementMaxText] = useState('');

  // 연령별 이용자 분석 및 출석 현황 정리 //
  useEffect(() => {
    let year = parseInt(moment().format('YYYY'));
    let startChildren = year - 8;

    let startTeenager = year - 18;
    let endTeenager = year - 9;

    let startTewnties = year - 28;
    let endTewnties = year - 19;

    let startThirties = year - 38;
    let endThirties = year - 29;

    let startForties = year - 48;
    let endForties = year - 39;

    let startFifties = year - 58;
    let endFifties = year - 49;

    let startSixties = year - 68;
    let endSixties = year - 59;

    let startSeventies = year - 78;
    let endSeventies = year - 69;

    let startEighties = year - 88;
    let endEighties = year - 79;

    let startNineties = year - 98;
    let endNineties = year - 89;

    let engagementStateList = [
      {
        text: '10대 이하',
        startYear: startChildren,
        endYear: year,
        totalCount: 0,
        usages: []
      },
      {
        text: '10대',
        startYear: startTeenager,
        endYear: endTeenager,
        totalCount: 0,
        usages: []
      },
      {
        text: '20대',
        startYear: startTewnties,
        endYear: endTewnties,
        totalCount: 0,
        usages: []
      },
      {
        text: '30대',
        startYear: startThirties,
        endYear: endThirties,
        totalCount: 0,
        usages: []
      },
      {
        text: '40대',
        startYear: startForties,
        endYear: endForties,
        totalCount: 0,
        usages: []
      },
      {
        text: '50대',
        startYear: startFifties,
        endYear: endFifties,
        totalCount: 0,
        usages: []
      },
      {
        text: '60대',
        startYear: startSixties,
        endYear: endSixties,
        totalCount: 0,
        usages: []
      },
      {
        text: '70대',
        startYear: startSeventies,
        endYear: endSeventies,
        totalCount: 0,
        usages: []
      },
      {
        text: '80대',
        startYear: startEighties,
        endYear: endEighties,
        totalCount: 0,
        usages: []
      },
      {
        text: '90대',
        startYear: startNineties,
        endYear: endNineties,
        totalCount: 0,
        usages: []
      },
    ];

    for (let i = 0; i < engagementReserves.length; i++) {
      let birthYear = parseInt(engagementReserves[i].birthDay.slice(0, 4));

      for (let j = 0; j < engagementStateList.length; j++) {
        if (engagementStateList[j].startYear <= birthYear && birthYear <= engagementStateList[j].endYear) {
          let isExist = false;

          for (let h = 0; h < engagementStateList[j].usages.length; h++) {
            if (engagementStateList[j].usages[h].membershipId === engagementReserves[i].membershipId) {
              engagementStateList[j].totalCount += 1;
              engagementStateList[j].usages[h].count += 1;
              isExist = true;
              break;
            }
          }

          if (!isExist) {
            engagementStateList[j].totalCount = 1;

            engagementStateList[j].usages.push({
              membershipId: engagementReserves[i].membershipId,
              membershipName: engagementReserves[i].membershipName,
              count: 1
            });
          }
        }
      }
    }

    let finalEngagementStates = [];
    let totalCount = 1;
    let maxText = '10대 이하';
    let maxCount = 0;

    for (let i = 0; i < engagementStateList.length; i++) {
      if (engagementStateList[i].usages.length > 0) {
        engagementStateList[i].usages.sort((a, b) => b.count - a.count || a.membershipId - b.membershipId);
        finalEngagementStates.push(engagementStateList[i]);
        totalCount += engagementStateList[i].totalCount;

        if (engagementStateList[i].totalCount > maxCount) {
          maxText = engagementStateList[i].text;
          maxCount = engagementStateList[i].totalCount;
        }
      }
    }

    setEngagementTotalCount(totalCount);
    setEngagementMaxCount(maxCount);
    setEngagementMaxText(maxText);
    setEngagementStates(finalEngagementStates);
    setIsEngagementLoading(false);
  }, [engagementReserves])


  // 수업 이름 표기 //
  function decideLessonName(data) {
    let classStartTime = data.classTime.split('-')[0];

    return classStartTime + ' ' + data.lessonName;
  }

  return (
    <TopLevelWrapper>
      <InfoBox>
        <Title>{calcDateText() + ' 연령별 이용자 분석'}</Title>
        {
          isEngagementLoading ?
            <AnimationBox>
              <LottieWrapper>
                <Lottie options={{ animationData: LoadingAnimation }} />
              </LottieWrapper>
            </AnimationBox>
            :
            <InnerInfoBox>
              {
                isOpenPartnerGymSales ?
                  <InfoView>
                    <ImageBoxWrapper gap={8}>
                      <AgeRangeImage src={AgeRangeIcon} />
                      <ColorTextBox backgroundColor='#6DD49E'>
                        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' hover='default'>가장 많은 연령대</Text>
                        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' hover='default'>:</Text>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='default'>{engagementMaxText + ' ' + (engagementMaxCount / engagementTotalCount * 100).toFixed(2) + '%'}</Text>
                      </ColorTextBox>
                    </ImageBoxWrapper>
                    <MemberBoxWrapper>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#595959' hover='default'>연령별 이용자 실적</Text>
                      <MemberBox>
                        <MemberColumn>
                          <Text style={{ marginRight: `88px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#777777' hover='default'>연령</Text>
                          <Text style={{ marginRight: `200px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#777777' hover='default'>이용 회원권</Text>
                          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#777777' hover='default'>사용 횟수</Text>
                        </MemberColumn>
                        <MemberListView>
                          {
                            engagementStates.map((data, index) => (
                              <EngagementUsageBox
                                key={'EngagementState-' + index}
                                isFirst={index === 0}
                                isLast={index === (engagementStates.length - 1)}>
                                <RangeBox height={30 * (data.usages.length)}>
                                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#777777' hover='default'>{data.text}</Text>
                                  {/* {data.usages.length > 1 && <Text fontFamily='NotoSansKR-Regular' fontSize={10} color='#777777' hover='default'>{data.startYear + '-' + data.endYear}</Text>} */}
                                  {<Text fontFamily='NotoSansKR-Regular' fontSize={10} color='#777777' hover='default'>{data.startYear + '-' + data.endYear}</Text>}
                                </RangeBox>
                                <EngagementMembershipBoxWrapper>
                                  {
                                    data.usages.map((usage, usageIndex) => (
                                      <EngagementInfoBox
                                        key={'EngagementUsage-' + usageIndex}
                                        isFirst={(index === 0 || usageIndex === 0)}
                                        isLast={(index === (engagementStates.length - 1)) || (usageIndex === (data.usages.length - 1))}>
                                        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#777777' hover='default'>{usage.membershipName}</Text>
                                      </EngagementInfoBox>
                                    ))
                                  }
                                </EngagementMembershipBoxWrapper>
                                <EngagementMembershipCountBoxWrapper>
                                  {
                                    data.usages.map((usage, usageIndex) => (
                                      <EngagementInfoBox
                                        key={'EngagementUsage-' + usageIndex}
                                        isFirst={(index === 0 || usageIndex === 0)}
                                        isLast={(index === (engagementStates.length - 1)) || (usageIndex === (data.usages.length - 1))}>
                                        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#777777' hover='default'>{usage.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '회'}</Text>
                                      </EngagementInfoBox>
                                    ))
                                  }
                                </EngagementMembershipCountBoxWrapper>
                              </EngagementUsageBox>
                            ))
                          }
                        </MemberListView>
                      </MemberBox>
                    </MemberBoxWrapper>
                  </InfoView>
                  :
                  <NotOpenTextWrapper>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#444444' cursor='default'>이 업체는 파트너 체육관에 매출을 비공개 합니다</Text>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#444444' cursor='default'>링크존 설정에서 “파트너 체육관”에서 권한 설정해 주세요</Text>
                  </NotOpenTextWrapper>
              }
            </InnerInfoBox>
        }
      </InfoBox>

      <BoxDivisionLine />

      <InfoBox>
        <Title>{calcDateText() + ' 실시간 출석 현황'}</Title>
        {
          isEngagementLoading ?
            <AnimationBox>
              <LottieWrapper>
                <Lottie options={{ animationData: LoadingAnimation }} />
              </LottieWrapper>
            </AnimationBox>
            :
            <InnerInfoBox>
              {
                isOpenPartnerGymSales ?
                  <InfoView>
                    <ImageBoxWrapper gap={8}>
                      <CurrentTimerImage src={CurrentTimerIcon} />
                      <ColorTextBox backgroundColor='#FF7575'>
                        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' hover='default'>총 방문 회원 수</Text>
                        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' hover='default'>:</Text>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='default'>{engagementReserves.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '회'}</Text>
                      </ColorTextBox>
                    </ImageBoxWrapper>
                    <MemberBoxWrapper>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#595959' hover='default'>실시간 방문 시간</Text>
                      <MemberBox>
                        <MemberColumn>
                          <Text style={{ marginRight: `40px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#777777' hover='default'>이름</Text>
                          <Text style={{ marginRight: `18px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#777777' hover='default'>성별</Text>
                          <Text style={{ marginRight: `40px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#777777' hover='default'>생년월일</Text>
                          <Text style={{ marginRight: `132px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#777777' hover='default'>수업</Text>
                          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#777777' hover='default'>출석 날짜</Text>
                        </MemberColumn>
                        <MemberListView>
                          {
                            engagementReserves.map((data, index) => (
                              <EngagementMemberWrapper key={'EngagementCurrent-' + index}>
                                <EngagementTextBox width={59}>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#777777' hover='pointer'>{data.userName}</Text>
                                </EngagementTextBox>
                                <EngagementTextBox width={34}>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#777777' hover='pointer'>{data.gender === 'male' ? '남성' : '여성'}</Text>
                                </EngagementTextBox>
                                <EngagementTextBox width={84}>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#777777' hover='pointer'>{data.birthDay.slice(0, 4) + '.' + data.birthDay.slice(4, 6) + '.' + data.birthDay.slice(6, 8)}</Text>
                                </EngagementTextBox>
                                <EngagementTextBox width={150}>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#777777' hover='pointer'>{decideLessonName(data)}</Text>
                                </EngagementTextBox>
                                <EngagementTextBox width={72}>
                                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#777777' hover='pointer'>{moment(data.classDate).format('YYYY.MM.DD')}</Text>
                                </EngagementTextBox>
                              </EngagementMemberWrapper>
                            ))
                          }
                        </MemberListView>
                      </MemberBox>
                    </MemberBoxWrapper>
                  </InfoView>
                  :
                  <NotOpenTextWrapper>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#444444' cursor='default'>이 업체는 파트너 체육관에 매출을 비공개 합니다</Text>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#444444' cursor='default'>링크존 설정에서 “파트너 체육관”에서 권한 설정해 주세요</Text>
                  </NotOpenTextWrapper>
              }
            </InnerInfoBox>
        }
      </InfoBox>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 1020px;
  height: 448px;

  gap: 28px;
  margin-top: 38px;

  border-radius: 30px;
  box-shadow: 0 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const BoxDivisionLine = styled.div`
  diplay: flex;
  flex-direction: row;

  width: 1px;
  height: 392px;

  background-color: #ECECEC;
`;

const InnerInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 364px;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-conetent: center;
  align-items: flex-start;

  width: 454px;
`;

const InfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-conetent: center;
  align-items: flex-start;
`;

const Title = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const AnimationBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  z-index: 5;
  
  width: 100%;
  height: 364px;

  background-color: #FFFFFF;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 80px;
  height: 80px;
`;

const ImageBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;eeeee

  margin-top: 24px;

  gap: ${props => props.gap}px;
`;

const AgeRangeImage = styled.img`
  width: 24px;
  height: 24px;
`;

const CurrentTimerImage = styled.img`
  width: 30px;
  height: 30px;
`;

const ColorTextBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 10px 0px 10px;

  height: 28px;

  gap: 4px;

  border-radius: 8px;
  background-color: ${props => props.backgroundColor};
`;

const MemberBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 24px;
`;

const MemberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 10px;

  width: 454px;
  height: 240px;
`;

const MemberColumn = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 8px 0px 8px;

  width: calc(100% - 16px);
  height: 32px;

  border-radius: 8px 8px 0px 0px;
  background-color: #ECECEC;
`;

const MemberListView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 216px;

  overflow-y: scroll;
  overflow-x: none;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
		background-color: #FFFFFF;
		border-radius: 200px;
	}
`;

const EngagementMemberWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 8px 0px 8px;

  gap: 8px;

  width: calc(100% - 16px);
  min-height: 36px;
  max-height: 36px;

  background-color: #FFFFFF;

  :hover {
    cursor: pointer;

    border-radius: 5px;
    background-color: #ECECEC;
  }

  transition: all 0.3s ease-in-out;
`;

const EngagementTextBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.width}px;
  height: 20px;

  overflow: hidden;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }
`;

const NotOpenTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const EngagementUsageBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  border-top: ${props => props.isFirst ? `0px solid #FFFFFF` : `0.5px solid #FBFBFB`};
  border-bottom: ${props => props.isLast ? `0px solid #FFFFFF` : `0.5px solid #FBFBFB`};
`;

const RangeBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: calc(25% - 1px);
  height: ${props => props.height}px;

  border-right: 1px solid #FBFBFB;
`;

const EngagementMembershipBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 59%;
`;

const EngagementInfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 10px;

  width: calc(100% - 20px);
  height: ${props => (props.isFirst || props.isLast) ? 29 : 30}px;

  border-top: ${props => props.isFirst ? `0px solid #FFFFFF` : `0.5px solid #FBFBFB`};
  border-bottom: ${props => props.isLast ? `0px solid #FFFFFF` : `0.5px solid #FBFBFB`};

  overflow: hidden;
`;

const EngagementMembershipCountBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: calc(16% - 1px);

  border-left: 1px solid #FBFBFB;
`;