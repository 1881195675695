import React from "react";
import styled from "styled-components";

import URLIcon from '../../image/WhiteUrlIcon.png';
import MainPageMovementViewBox from "./MainPageMovementViewBox";
import FullScreenMovementViewBox from "./FullScreenMovementViewBox";

export default function FullScreenWodViewBox(props) {
  const { nowSection, index, sectionData, onClickURL, isClickedRanking, isMainPage, isScaleSection, fontScale } = props;

  // 한자리 숫자 앞에 0 붙이기 //
  function numberPad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  }

  // Section Title 설정
  function decideSectionTitle(data) {
    if (data === undefined) return '';
    else if (data.tag === 'ForTime') return data.round + ' Round For Time';
    else if (data.tag === 'AMRAP') {
      let min = parseInt(data.exerciseTime / 60);
      let sec = parseInt(data.exerciseTime % 60);

      if (min > 0 && sec > 0) return min + 'min ' + sec + 'sec AMRAP';
      else if (min > 0) return min + 'min AMRAP';
      else if (sec > 0) return sec + 'sec AMRAP';
      else return 'AMRAP';
    }
    else if (data.tag === 'EMOM') {
      let min = parseInt(data.round * data.exerciseTime / 60);
      let sec = parseInt(data.round * data.exerciseTime % 60);

      if (min > 0 && sec > 0) return min + 'min ' + sec + 'sec EMOM';
      else if (min > 0) return min + ' min EMOM';
      else if (sec > 0) return sec + ' sec EMOM';
      else return 'EMOM';
    }
    else if (data.tag === 'TABATA') {
      let onMin = parseInt(data.exerciseTime / 60);
      let onSec = parseInt(data.exerciseTime % 60);
      let offMin = parseInt(data.restTime / 60);
      let offSec = parseInt(data.restTime % 60);

      return 'On: ' + onMin + ':' + numberPad(onSec, 2) + ' / Off:' + offMin + ':' + numberPad(offSec, 2) + ' ' + data.round + 'R TABATA';
    }
    else if (data.tag === 'SINGLE') {
      if (data.mainExerciseList.length <= 0) return 'SINGLE';
      else if (data.mainExerciseList.length === 1) return data.mainExerciseList[0].movementName;
      else return 'Multiple Single';
    }
    else if (data.tag === 'Custom') {
      if (data.customTitle !== '') return data.customTitle;
      else if (data.scoreKeyword === '점수 없음'
        || data.scoreKeyword === '피크와트'
        || data.scoreKeyword === '최고 속도') return 'Custom : ' + data.scoreKeyword;
      else return 'Custom : ' + data.scoreKeyword + ' ' + data.scorePriority;
    }
  }

  // For Time Timecap 계산 //
  function decideTimeCap() {
    let min = parseInt(sectionData.timeLimit / 60);
    let sec = parseInt(sectionData.timeLimit % 60);

    if (min > 0 && sec > 0) return 'Timecap ' + min + 'min ' + sec + 'sec';
    else if (min > 0) return 'Timecap ' + min + 'min';
    else if (sec > 0) return 'Timecap ' + sec + 'sec';
    else return 'Timecap 0sec';
  }

  // TABATA ON OFF 계산 //
  function decideTABATAOnOff(division) {
    if (division === 'ON') {
      let min = parseInt(sectionData.exerciseTime / 60);
      let sec = parseInt(sectionData.exerciseTime % 60);

      if (min > 0 && sec > 0) return min + ':' + numberPad(sec, 2);
      else if (min > 0) return min + ':00'
      else if (sec > 0) return '0:' + numberPad(sec, 2);
      else return '0:00';
    }
    else {
      let min = parseInt(sectionData.restTime / 60);
      let sec = parseInt(sectionData.restTime % 60);

      if (min > 0 && sec > 0) return min + ':' + numberPad(sec, 2);
      else if (min > 0) return min + ':00'
      else if (sec > 0) return '0:' + numberPad(sec, 2);
      else return '0:00';
    }
  }

  return (
    <WodBoxWrapper>
      <SectionTitleWrapper>
        {
          isScaleSection ? 
            <ScaleSectionBox width={(43 + (fontScale * 2)) < 200 ? (43 + (fontScale * 2)) : 200} height={(34 + (fontScale * 1.5)) < 158 ? (34 + (fontScale * 1.5)) : 158}>
              <Text fontFamily='NotoSansKR-Bold' fontSize={(14 + fontScale) < 100 ? (14 + fontScale) : 100} color='#FFFFFF' hover='pointer'>{String.fromCharCode(nowSection + 65) + '-' + (index + 2)}</Text>
            </ScaleSectionBox>
            :
            <SectionBox width={(34 + (fontScale * 1.5)) < 158 ? (34 + (fontScale * 1.5)) : 158} height={(34 + (fontScale * 1.5)) < 158 ? (34 + (fontScale * 1.5)) : 158}>
              <Text fontFamily='NotoSansKR-Bold' fontSize={(14 + fontScale) < 100 ? (14 + fontScale) : 100} color='#FFFFFF' hover='pointer'>{String.fromCharCode(nowSection + 65)}</Text>
            </SectionBox>
        }
        {
          isScaleSection ?
            <ScaleSectionTitleBox sectionBoxWidth={(43 + (fontScale * 2)) < 200 ? (43 + (fontScale * 2)) : 200} height={(34 + (fontScale * 1.5)) < 158 ? (34 + (fontScale * 1.5)) : 158}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={(14 + fontScale) < 100 ? (14 + fontScale) : 100} color='#FFFFFF' hover='pointer'>{decideSectionTitle(sectionData)}</Text>
            </ScaleSectionTitleBox>
            :
            <SectionTitleBox sectionBoxWidth={(34 + (fontScale * 1.5)) < 158 ? (34 + (fontScale * 1.5)) : 158} height={(34 + (fontScale * 1.5)) < 158 ? (34 + (fontScale * 1.5)) : 158}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={(14 + fontScale) < 100 ? (14 + fontScale) : 100} color='#FFFFFF' hover='pointer'>{decideSectionTitle(sectionData)}</Text>
            </SectionTitleBox>
        }
      </SectionTitleWrapper>
      {
        sectionData.tag === 'EMOM' &&
        <TABATASectionWrapper>
          <TABATARoundBox height={31 + (31 * fontScale / 100)}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * fontScale / 100)} color='#333333'>{sectionData.round + 'R'}</Text>
          </TABATARoundBox>
          <EMOMOnBox height={31 + (31 * fontScale / 100)} backgroundColor='#6DD49E'>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * fontScale / 100)} color='#FFFFFF'>{'ON ' + decideTABATAOnOff('ON')}</Text>
          </EMOMOnBox>
        </TABATASectionWrapper>
      }
      {
        sectionData.tag === 'TABATA' &&
        <TABATASectionWrapper>
          <TABATARoundBox height={31 + (31 * fontScale / 100)}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * fontScale / 100)} color='#333333'>{sectionData.round + 'R'}</Text>
          </TABATARoundBox>
          <TABATAOnOffBox height={31 + (31 * fontScale / 100)} backgroundColor='#FF8B48'>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * fontScale / 100)} color='#FFFFFF'>{'ON ' + decideTABATAOnOff('ON')}</Text>
          </TABATAOnOffBox>
          <TABATAOnOffBox height={31 + (31 * fontScale / 100)} backgroundColor='#6DD49E'>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14 + (14 * fontScale / 100)} color='#FFFFFF'>{'REST ' + decideTABATAOnOff('OFF')}</Text>
          </TABATAOnOffBox>
        </TABATASectionWrapper>
      }
      <WodBox isClickedRanking={isClickedRanking || !isMainPage}>
        {
          sectionData.tag !== 'SINGLE' &&
          <ProgramBox isClickedRanking={isClickedRanking || !isMainPage}>
            <NoteViewBox>
              <ProgramSectionBox height={(23 + fontScale) < 75 ? (23 + fontScale) : 75}>
                <Text fontFamily='Poppins-Medium' fontSize={(14 + fontScale) < 50 ? (14 + fontScale) : 50} color='#FFFFFF' hover='default'>Program</Text>
              </ProgramSectionBox>
              <NoteTextAreaWrapper isExistURL={sectionData.mainProgramURL.length !== 0}>
                <PreText fontFamily='NotoSansKR-Medium' fontSize={(14 + fontScale) < 100 ? (14 + fontScale) : 100} color='#FFFFFF'>{sectionData.mainProgram}</PreText>
              </NoteTextAreaWrapper>
              {
                sectionData.mainProgramURL.map((data, index) => (
                  <URLBox
                    key={'MainProgramURL-' + index}
                    isLast={(sectionData.mainProgramURL.length === (index + 1)) && !sectionData.isUseTimeLimit}>
                    <URLWrapper onClick={() => onClickURL(data)}>
                      <URLIconBox isLast={(sectionData.mainProgramURL.length === (index + 1)) && !sectionData.isUseTimeLimit} backgroundColor='#969696'>
                        <Icon src={URLIcon} />
                      </URLIconBox>
                      <URLTextWrapper>
                        <Text fontFamily='Poppins-Medium' fontSize={14} color='#FFFFFF' hover='pointer'>{data}</Text>
                      </URLTextWrapper>
                    </URLWrapper>
                  </URLBox>
                ))
              }
              {
                sectionData.isUseTimeLimit &&
                <TimeLimitBar height={(24 + fontScale) < 100 ? (24 + fontScale) : 100}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={(14 + fontScale) < 50 ? (14 + fontScale) : 50} color='#FFFFFF'>{decideTimeCap()}</Text>
                </TimeLimitBar>
              }
            </NoteViewBox>
          </ProgramBox>
        }
        {
          sectionData.tag === 'SINGLE' &&
          <ProgramBox isClickedRanking={isClickedRanking || !isMainPage}>
            <SingleWodBox>
              <FullScreenMovementViewBox sectionData={sectionData} fontScale={fontScale} />
            </SingleWodBox>
          </ProgramBox>
        }
        {sectionData.mainNote !== '' && <DivisionLine style={{ margin: '20px 0px 20px 0px' }} />}
        <NoteBox isClickedRanking={isClickedRanking || !isMainPage}>
          {
            sectionData.mainNote !== '' &&
            <NoteViewBox /*style={sectionData.tag === 'SINGLE' ? { margin: '20px 0px' } : { marginBottom: '20px' }}*/
              style={{ marginBottom: '20px' }}>
              <NoteSectionBox height={(23 + fontScale) < 75 ? (23 + fontScale) : 75}>
                <Text fontFamily='Poppins-Medium' fontSize={(14 + fontScale) < 50 ? (14 + fontScale) : 50} color='#FFFFFF' hover='default'>Notes</Text>
              </NoteSectionBox>
              <NoteTextAreaWrapper isExistURL={sectionData.mainNoteURL.length !== 0}>
                <PreText fontFamily='NotoSansKR-Medium' fontSize={(14 + fontScale) < 100 ? (14 + fontScale) : 100} color='#FFFFFF'>{sectionData.mainNote}</PreText>
              </NoteTextAreaWrapper>
              {
                sectionData.mainNoteURL.map((data, index) => (
                  <URLBox
                    key={'MainNoteURL-' + index}
                    isLast={sectionData.mainNoteURL.length === (index + 1)} >
                    <URLWrapper onClick={() => onClickURL(data)}>
                      <URLIconBox isLast={sectionData.mainNoteURL.length === (index + 1)} backgroundColor='#FF8B48'>
                        <Icon src={URLIcon} />
                      </URLIconBox>
                      <URLTextWrapper>
                        <Text fontFamily='Poppins-Medium' fontSize={14} color='#FFFFFF' hover='pointer'>{data}</Text>
                      </URLTextWrapper>
                    </URLWrapper>
                  </URLBox>
                ))
              }
            </NoteViewBox>
          }
        </NoteBox>
      </WodBox>
    </WodBoxWrapper>
  )
}


const WodBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justifyo-content: flex-start;
  align-items: flex-start;

  // margin-top: 10px;

  width: 100%;
`;

const SectionTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 20px;

  width: 100%;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 34px;
  // height: 34px;
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 6px;
  background-color: #6DD49E;

  transition: all 0.3s ease-in-out;
`;

const ScaleSectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 43px;
  // height: 34px;
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 6px;
  background-color: #4D92EF;

  transition: all 0.3s ease-in-out;
`;

const SectionTitleBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  flex-wrap: nowrap;

  padding: 8px 15px;

  // width: calc(100% - 70px);
  // height: 34px;

  width: calc(100% - ${props => props.sectionBoxWidth}px - 38px);
  height: calc(${props => props.height}px - 16px);

  border-radius: 6px;
  background-color: #17171B;

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const ScaleSectionTitleBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  flex-wrap: nowrap;

  padding: 8px 15px;

  // width: calc(100% - 80px);
  // height: 34px;
  width: calc(100% - ${props => props.sectionBoxWidth}px - 38px);
  height: calc(${props => props.height}px - 16px);

  border-radius: 6px;
  background-color: #17171B;

  overflow: hidden;
  
  transition: all 0.3s ease-in-out;
`;

const TABATASectionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 10px;
  margin-bottom: 10px; 

  width: 100%;
`;

const TABATARoundBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 18%;
  // height: 31px;
  height: ${props => props.height}px;

  border-radius: 6px;
  background-color: #F9F9F9;
`;

const TABATAOnOffBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 40%;
  // height: 31px;
  height: ${props => props.height}px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
`;

const EMOMOnBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 80%;
  // height: 31px;
  height: ${props => props.height}px;

  border-radius: 6px;
  background-color: ${props => props.backgroundColor};
`;

const VideoBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 10px;

  width: 100%;
  height: 274px;

  gap: 18px;

  overflow-x: scroll;
  overflow-y: hidden;


  &::-webkit-scrollbar {
    width: 1px;
    height: 6px;
  }
  
  &::-webkit-scrollbar-thumb {
    // border: 2px solid transparent;
    height: 6px;
    background-color: #BFBFBF;
    border-radius: 2px;
  }
  
  &::-webkit-scrollbar-track {
    // width: 1px;
    height: 2px;
    background-color: #F9F9F9;
    border-radius: 200px;
    // opacity: 0;D4D4D4
  }
`;

const VideoViewWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  min-width: 489px;
  height: 254px;
  
  border-radius: 10px;

  opacity: 1;
  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const WodBox = styled.div`
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 20px;

  width: 100%;

  gap: ${props => props.isClickedRanking ? '12px' : '0px'};
  
  transition: all 0.3s ease-in-out;
`;


const ProgramBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.isClickedRanking ? `100%` : `60%`};
  
  transition: all 0.3s ease-in-out;
`;

const SingleWodBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: calc(100% + 30px);
  width: 100%;
`;

const NoteBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.isClickedRanking ? `100%` : `39%`};
  
  transition: all 0.3s ease-in-out;
`;

const NoteViewBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  // border: 1px solid #E0E0E0;
  // border-radius: 4px;
  // background-color: #FFFFFF;
`;

const ProgramSectionBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 5px;

  // width: 92px;
  // height: 23px;
  height: ${props => props.height}px;

  border-radius: 5px 5px 0px 0px;
  background-color: #969696;
`;

const NoteSectionBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 5px;

  // width: 71px;
  // height: 23px;
  height: ${props => props.height}px;

  border-radius: 5px 5px 0px 0px;
  background-color: #FF8B48;
`;

const NoteTextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 8px 15px 14px 15px;
  // margin: 8px 15px 14px 15px;

  width: calc(100% - 30px);
  
  border-radius: ${props => props.isExistURL ? `0px 4px 0px 0px` : `0px 4px 4px 4px`};
  // background-color: #F9F9F9;
  background-color: #17171B;
`;

const PreText = styled.pre`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  width: 100%;

  white-space: pre-wrap;
  word-wrap: break-word;
`;

const DivisionLine = styled.div`
  width: 100%;
  height: 2px;

  background-color: #17171B;
`;


const URLBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 28px;

  border-bottom-right-radius: ${props => props.isLast ? '4px' : '0px'};

  // background-color: #F9F9F9;
  background-color: #17171B;
`;

const URLWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: calc(100% - 30px);
  height: 100%;

  gap: 10px;
`;

const URLIconBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 46px;
  height: 100%;

  border-bottom-left-radius: ${props => props.isLast ? '4px' : '0px'};
  background-color: ${props => props.backgroundColor};
`;

const Icon = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const URLTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: calc(100% - 56px);
  height: 20px;

  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const TimeLimitBar = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 24px;

  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #FF3131;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;