import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

// Function //
import { makeUpWorkout } from "../../Exercise/Transaction/Transaction.js";
import useWindowSize from '../../Model/Share/Normal/useWindowSize.js';
import FetchModule from "../../Model/Share/Network/FetchModule";
// Function //

// Component //
import CalendarBoxButton from "../Component/BlackCalendarBoxButton";
import WorkoutSelectBox from "../Component/BlackWorkoutSelectBox";
import FontScaleSelectInput from '../Component/FontScaleSelectInput';
import FullScreenRankingView from '../Model/FullScreenRankingView.js';
import FullScreenWodViewBox from '../Model/FullScreenWodView.js';
// Component //

// Image //
import LeftArrow from '../../image/Left.png'//왼쪽 화살표
import RightArrow from '../../image/Right.png'//우측 화살표
import WhiteLeftArrow from '../../image/WhiteLeftArrow.png'//왼쪽 화살표
import BoardIcon from '../../image/WhiteBoard.png';
import EmptySign from '../../image/BlackEmptySign.png';
import PlayIcon from '../../image/BlackPlayIcon.png';
import PlayCircleIcon from '../../image/WhitePlayCircleIcon.png';
// Image //

export default function FullScreenBoardPage({ match }) {

  const size = useWindowSize();

  const [date, setDate] = useState(moment());
  const [wods, setWods] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [selectedWod, setSelectedWod] = useState(undefined);

  const [nowSection, setNowSection] = useState(0);
  const [clickedScaleSections, setClickedScaleSections] = useState([]);

  const [fontScale, setFontScale] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isClickedPlay, setIsClickedPlay] = useState(false);
  const [movementVideos, setMovementVideos] = useState([]);
  const [videoHeight, setVideoHeight] = useState(0);

  // 토큰 저장 //
  useEffect(() => {
    window.sessionStorage.setItem('Token', 'Bearer ' + match.params.token);
  }, [match])

  // 금일 와드 정보 불러오기 //
  useEffect(() => {
    let requestData = {
      date: date.format('YYYY-MM-DDT00:00:00')
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfExerciseVersion2('workout/daily_wod', 'POST', requestData);
        // console.log(responseData.data);
        if (responseData.status === 200) {
          if (responseData.data.workouts.length > 0) {
            let newWorkouts = makeUpWorkout(responseData.data.workouts.slice());
            let newSelectOptions = [];
  
            for (let i = 0; i < newWorkouts.length; i++) {
              let selectOption = {
                workoutId: newWorkouts[i].workoutId,
                title: newWorkouts[i].title,
                lessonName: newWorkouts[i].lessonName,
                color: newWorkouts[i].lessonColor
              }
  
              newSelectOptions.push(selectOption);
  
              setWods(newWorkouts);
              setSelectOptions(newSelectOptions);
            }
          }
          else {
            setWods([]);
            setSelectOptions([]);
            setMovementVideos([]);
            setIsClickedPlay(false);
          }
        }
      }
    )();
  }, [date])

  // 선택된 정보에 맞게 와드 데이터 세팅 //
  useEffect(() => {
    if (wods.length === 0 || selectedOption === undefined) {
      setSelectedWod(undefined);
      return;
    }

    let wodIndex = wods.findIndex(obj => obj.workoutId === selectedOption.workoutId);

    setSelectedWod(wods[wodIndex]);
  }, [wods, selectedOption])

  // 화면 크기 받아와 영상 높이 계산 //
  useEffect(() => {
    let width = size.width;
    let height = width * 0.5739;

    setVideoHeight(height);
  }, [size])

  // 현재 페이지 내의 채널톡 지우기 //
  useEffect(() => {
    setTimeout(() => {
      try {
        document.getElementById('ch-plugin').style.opacity = 0;
        document.getElementById('ch-plugin').style.zIndex = -10;
      }
      catch(e) {
        console.log(e);

        setTimeout(() => {
          try {
            document.getElementById('ch-plugin').style.opacity = 0;
            document.getElementById('ch-plugin').style.zIndex = -10;
          }
          catch(e) {
            console.log(e);
          }
        }, 500)
      }
    }, 100)
  }, [])

  // 총합 와드박스 크기 계산 //
  function decideTotalWodBoxWidth() {
    if (isClickedPlay) return '100%';
    else {
      let rankWidth = (366 + (fontScale * 4));

      if (rankWidth < 734) return 'calc(99% - ' + rankWidth + 'px)';
      else return 'calc(99% - 734px)';
    }
  }

  // 순위표 크기 계산 //
  function decideRankViewWidth() {
    if (isClickedPlay) return '0%';
    else {
      let rankWidth = (366 + (fontScale * 4));
      
      if (rankWidth < 734) return rankWidth + 'px';
      else return '734px';
    }
  }

  // 와드박스 크기 계산 //
  function decideWodBoxWidth(isScale, scaleIndex) {
    let wodBoxCount = clickedScaleSections.length + 1;

    // 스케일 와드 박스 크기 계산 //
    if (isScale) {
      // 스케일 와드가 클릭되지 않은 경우 //
      if (wodBoxCount === 1) return 0;
      // 스케일 와드가 선택되어 있는 경우 //
      else {
        let calcWidth = parseInt((100 - (2 * wodBoxCount)) / wodBoxCount);

        if (clickedScaleSections.includes(scaleIndex)) return calcWidth;
        else return 0;
      }
    }
    // 기본 와드 박스 크기 계산 //
    else {
      // 스케일 와드가 클릭되지 않은 경우 //
      if (wodBoxCount === 1 || isClickedPlay) return 100;
      // 스케일 와드가 선택되어 있는 경우 //
      else return parseInt((100 - (2 * wodBoxCount)) / wodBoxCount);
    }
  }

  // youtube link 정리 //
  function decideYoutubeLink(data) {
    let youtubeLink = 'https://www.youtube.com/embed/';
    let id = data.replace('https://www.youtube.com/watch?v=', '').replace('https://youtube.com/shorts/', '').replace('https://youtu.be/', '');

    if (id.includes('?si=')) id = id.split('?si=')[0];

    let autoplay = '?autoplay=1&mute=1&loop=1&playlist=' + id;
    
    return youtubeLink + id + autoplay;
  }

  // 날짜 이전 버튼 클릭시 //
  function onClickPrevDate() {
    setDate(date.clone().subtract(1, 'days'));
  }

  // 날짜 다음 버튼 클릭시 //
  function onClickNextDate() {
    setDate(date.clone().add(1, 'days'));
  }

  // 전체화면, 일반화면 전환 버튼 클릭시 //
  function changFullScreenMode() {
    var isInFullScreen = (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null);

    if (isInFullScreen) {
      const docu = document;
      if (docu.exitFullscreen) docu.exitFullscreen();
      else if (docu.mozCancelFullScreen) docu.mozCancelFullScreen();
      else if (docu.webkitExitFullscreen) docu.webkitExitFullscreen();
      else if (docu.msExitFullscreen) docu.msExitFullscreen();
    }
    else {
      const docuElem = document.documentElement;

      if (docuElem.requestFullscreen) docuElem.requestFullscreen();
      else if (docuElem.webkitRequestFullscreen) docuElem.webkitRequestFullscreen();
      else if (docuElem.mozRequestFullScreen) docuElem.mozRequestFullScreen();
      else if (docuElem.msRequestFullScreen) docuElem.msRequestFullScreen();
    }
    setIsFullScreen(!isInFullScreen);
  }

  // 링크 클릭시 //
  function onClickURL(url) {
    window.open(url, "_blank", "noreferrer");
  }

  // 일반 세션 클릭시 //
  function onClickSection(index) {
    // 영상이 재생중인 경우 //
    if (isClickedPlay) {
      let videos = [];

      if (selectedWod.sectionList[index].youtubeLink !== undefined
        && selectedWod.sectionList[index].youtubeLink !== '') {
        videos.push(selectedWod.sectionList[index].youtubeLink);
      }
  
      if (selectedWod.sectionList[index].youtubeLinks !== undefined
        && selectedWod.sectionList[index].youtubeLinks.length > 0) {
        selectedWod.sectionList[index].youtubeLinks.map((data) => {
          videos.push(data);
        })
      }
      
      setMovementVideos(videos);
      setClickedScaleSections([]);
      setNowSection(index);
      // setIsClickedPlay(false);
    }
    else {
      setClickedScaleSections([]);
      setNowSection(index);
    }
  }

  // 스케일 세션 클릭시 //
  function onClickScaleSection(sectionIndex, scaleSectionIndex) {
    // 클릭한 스케일 세션이 현재 클릭된 세션과 같은경우 //
    if (nowSection === sectionIndex) {
      let indexs = clickedScaleSections.slice();

      let findIndex = indexs.indexOf(scaleSectionIndex);

      // 선택된적이 없는경우 //
      if (findIndex === -1) indexs.push(scaleSectionIndex);
      // 이미 선택된 데이터의 경우 //
      else indexs.splice(findIndex, 1);

      setClickedScaleSections(indexs);
    }
    // 클릭한 스케일 세션이 현재 클릭된 세션과 다른경우 //
    else {
      let indexs = [];

      indexs.push(scaleSectionIndex);

      setNowSection(sectionIndex);
      setClickedScaleSections(indexs);
    }
  }

  // 영상 재생 버튼 클릭시 //
  function onClickPlayButton() {
    if (selectedWod === undefined) {
      alert('와드를 선택해 주세요');
      return;
    }
    else if (isClickedPlay) {
      setMovementVideos([]);
      setIsClickedPlay(false);
      return;
    }

    let videos = [];

    if (selectedWod.sectionList[nowSection].youtubeLink !== undefined
      && selectedWod.sectionList[nowSection].youtubeLink !== '') {
      videos.push(selectedWod.sectionList[nowSection].youtubeLink);
    }

    if (selectedWod.sectionList[nowSection].youtubeLinks !== undefined
      && selectedWod.sectionList[nowSection].youtubeLinks.length > 0) {
      selectedWod.sectionList[nowSection].youtubeLinks.map((data) => {
        videos.push(data);
      })
    }
    // selectedWod.sectionList[nowSection]
    if (videos.length === 0) {
      setMovementVideos([]);
      alert('재생 가능한 영상이 없습니다');
      return;
    }
    else {
      setMovementVideos(videos);
      setIsClickedPlay(!isClickedPlay);
    }
  }

  // 뒤로가기 버튼 클릭시 창 닫기 //
  function onClickGoBack() {
    window.close();
  }

  return (
    <FullScreeBoard>
      <TotalWrapper>
        <NavigationBarWrapper>
          <NavigationBarLeftWrapper>
            <GoBackButton onClick={onClickGoBack}>
              <GoBackButtonCircle>
                <Image width={8} height={12} src={WhiteLeftArrow} />
              </GoBackButtonCircle>
            </GoBackButton>
            <NavigationView>
              <Image width={32} height={34} src={BoardIcon} />
              <BoardComponent>
                <Text fontFamily='NotoSansKR-Medium' fontSize={18} color='#000000' cursor='default'>보드</Text>
              </BoardComponent>
            </NavigationView>
          </NavigationBarLeftWrapper>
          <FullScreenButton onClick={changFullScreenMode}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={18} color='#FFFFFF' cursor='pointer'>{isFullScreen ? `일반화면 전환` : `전체화면 전환`}</Text>
          </FullScreenButton>
        </NavigationBarWrapper>
        <TopLineWrapper>
          <TopLineButtonLeftWrapper>
            <TopLineButtonWrapper>
              <DateButtonWrapper>
                <ArrowWrapper onClick={onClickPrevDate} >
                  <ArrowImage src={LeftArrow} />
                </ArrowWrapper>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>{date.clone().format('YYYY년 MM월 DD일')}</Text>
                <ArrowWrapper onClick={onClickNextDate}>
                  <ArrowImage src={RightArrow} />
                </ArrowWrapper>
              </DateButtonWrapper>
              <CalendarBoxButton
                width={38}
                height={38}
                value={date}
                setValue={setDate} />
            </TopLineButtonWrapper>
            <DivisionLine width='2px' height='22px' radius={2} backgroundColor='#D9D9D9' />
            <SelectBoxWrapper>
              <WorkoutSelectBox options={selectOptions} value={selectedOption} setValue={setSelectedOption} />
            </SelectBoxWrapper>
          </TopLineButtonLeftWrapper>
          <FontScaleSelectInput defaultScale={1} setScale={setFontScale} />
        </TopLineWrapper>
      </TotalWrapper>

      <TotalWrapper>
        <InfoWrapper style={{ marginTop: '24px' }}>
          <InfoInnerWrapper>
            {
              selectedWod === undefined ?
                <TotalWodBox style={{ marginTop: '260px' }}>
                  <NoneWodBox>
                    <Image width={370} height={154} src={EmptySign} />
                    <Text fontFamily='NotoSansKR-Medium' fontSize={40} color='#BDBDBD' cursor='default'>프로그램이</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={40} color='#BDBDBD' cursor='default'>존재하지 않습니다</Text>
                  </NoneWodBox>
                </TotalWodBox>
                :
                <TotalWodBox>
                  <WodSectionLineWrapper>
                    <TotalWodSectionButtonWrapper height={(38 + fontScale) < 110 ? (38 + fontScale) : 110}>
                      {
                        selectedWod.sectionList.map((data, index) => (
                          <WodSectionButtonWrapper key={'BlackBoardSection-' + index} >
                            <WodSectionButton
                              width={(34 + fontScale) < 100 ? (34 + fontScale) : 100}
                              height={(34 + fontScale) < 100 ? (34 + fontScale) : 100}
                              isClicked={nowSection === index}
                              onClick={() => onClickSection(index)}>
                              <Text fontFamily='NotoSansKR-Bold' fontSize={(14 + fontScale) < 60 ? (14 + fontScale) : 60} color='#FFFFFF' hover='pointer'>{String.fromCharCode(index + 65)}</Text>
                            </WodSectionButton>
                            {
                              data.scaleSections.map((scaleData, scaleIndex) => (
                                <ScaleWodSectionButton
                                  key={'BlackBoardScaleSection-' + scaleIndex}
                                  width={(43 + (fontScale * 2)) < 129 ? (43 + (fontScale * 2)) : 129}
                                  height={(34 + fontScale) < 100 ? (34 + fontScale) : 100}
                                  isClicked={nowSection === index && clickedScaleSections.includes(scaleIndex)}
                                  onClick={() => onClickScaleSection(index, scaleIndex)}>
                                  <Text fontFamily='NotoSansKR-Bold' fontSize={(14 + fontScale) < 60 ? (14 + fontScale) : 60} color='#FFFFFF' hover='pointer'>{String.fromCharCode(index + 65) + '-' + (scaleIndex + 2)}</Text>
                                </ScaleWodSectionButton>
                              ))
                            }
                            {selectedWod.sectionList.length !== (index + 1) && <DivisionLine style={{ margin: '0px 15px 0px 7px' }} width='4px' height={(28 + fontScale) < 100 ? ((28 + fontScale) + 'px') : '100px'} radius={2} backgroundColor='#D9D9D9' />}
                          </WodSectionButtonWrapper>
                        ))
                      }
                    </TotalWodSectionButtonWrapper>
                    <PlayButton
                      width={(79 + (fontScale * 2)) < 282 ? (79 + (fontScale * 2)) : 282}
                      height={(32 + (fontScale)) < 102 ? (32 + (fontScale)) : 102}
                      onClick={onClickPlayButton}>
                      {/* <PlayCircle
                        width={(22 + fontScale) < 68 ? (22 + fontScale) : 68}
                        height={(22 + fontScale) < 68 ? (22 + fontScale) : 68}
                        radius={(22 + fontScale) < 68 ? (22 + fontScale) : 68}>
                        <Image
                          width={(10 + fontScale) < 30 ? (10 + fontScale) : 30}
                          height={(10 + fontScale) < 30 ? (10 + fontScale) : 30}
                          src={PlayIcon} />
                      </PlayCircle> */}
                      <Image
                        width={(22 + (fontScale / 2)) < 68 ? (22 + (fontScale / 2)) : 68}
                        height={(22 + (fontScale / 2)) < 68 ? (22 + (fontScale / 2)) : 68}
                        src={PlayCircleIcon}/>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={(14 + (fontScale / 2)) < 60 ? (14 + (fontScale / 2)) : 60} color='#FFFFFF' hover='pointer'>Play</Text>
                    </PlayButton>
                  </WodSectionLineWrapper>
                </TotalWodBox>
            }
          </InfoInnerWrapper>
        </InfoWrapper>
      </TotalWrapper>
      {/* 영상 뷰 */}
      {
        isClickedPlay &&
        <TotalWrapper style={{ padding: '0px', width: '100%' }}>
          <TotalVideoWrapper>
            {
              movementVideos.map((data, idx) => (
                <VideoViewWrapper 
                  key={'Video-' + idx}
                  height={videoHeight}>
                  <iframe
                    width="100%"
                    height="100%"
                    src={decideYoutubeLink(data)}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube" />
                </VideoViewWrapper>
              ))
            }
          </TotalVideoWrapper>
        </TotalWrapper>
      }
      {/* 영상 뷰 */}
      {/* 와드 뷰 */}
      {
        selectedWod !== undefined &&
        <TotalWrapper>
          <InfoWrapper>
            <InfoInnerWrapper>
              <TotalWodBox>
                <WodInfoBox>
                  <WodBoxWrapper 
                    isClickedPlay={isClickedPlay}
                    width={decideTotalWodBoxWidth()}>
                    <FullScreenWodViewBoxWrapper
                      width={decideWodBoxWidth(false, -1)}
                      isView={true}>
                      <FullScreenWodViewBox
                        nowSection={nowSection}
                        index={nowSection}
                        sectionData={selectedWod.sectionList[nowSection]}
                        onClickURL={onClickURL}
                        isClickedRanking={false}
                        isMainPage={false}
                        isScaleSection={false}
                        fontScale={fontScale} />
                    </FullScreenWodViewBoxWrapper>
                    {
                      selectedWod.sectionList[nowSection].scaleSections.map((data, index) => (
                        <FullScreenWodViewBoxWrapper
                          key={'FullScreenWodSection-' + index}
                          width={decideWodBoxWidth(true, index)}
                          isView={clickedScaleSections.includes(index)}>
                          <FullScreenWodViewBox
                            nowSection={nowSection}
                            index={index}
                            sectionData={data}
                            onClickURL={onClickURL}
                            isClickedRanking={false}
                            isMainPage={false}
                            isScaleSection={true}
                            fontScale={fontScale} />
                        </FullScreenWodViewBoxWrapper>
                      ))
                    }
                  </WodBoxWrapper>
                  <RankingBoxWrapper 
                    isClickedPlay={isClickedPlay}
                    width={decideRankViewWidth()}>
                    <FullScreenRankingView
                      nowSection={nowSection}
                      selectedWod={selectedWod}
                      fontScale={fontScale} />
                  </RankingBoxWrapper>
                </WodInfoBox>
              </TotalWodBox>
            </InfoInnerWrapper>
          </InfoWrapper>
        </TotalWrapper>
      }
      {/* 와드 뷰 */}
    </FullScreeBoard>
  )
}

const FullScreeBoard = styled.div`
  // position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // min-width: 100wv;
  // min-height 100vh;
  // min-width: 100%;
  min-width: 900px;
  width: 100wv;
  min-height: 100%;
  height 100vh;

  background-color: #000000;
  
  overflow-x: scroll;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  
  &::-webkit-scrollbar-thumb {
    border: 0px solid transparent;
    background-color: #000000;
    border-radius: 2px;
  }
  
  &::-webkit-scrollbar-track {
    background-color: #000000;
    border-radius: 200px;
    opacity: 0;
  }
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 20px;

  min-width: 900px;
  width: calc(100vw - 40px);
  // width: calc(100% - 40px);
`;

const NavigationBarWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 12px;

  width: 100%;

  gap: 12px;
`;

const NavigationBarLeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 12px;
`;

const GoBackButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 40px;
  background-color: #17171B;

  :hover {
    cursor: pointer;
  }
`;

const GoBackButtonCircle = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 26px;
  height: 26px;

  border: 3px solid #FFFFFF;
  border-radius: 32px;
  background-color: #17171B;
`;

const NavigationView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding-left: 15px;
  
  gap: 21px;

  border-radius: 28px;
  background-color: #17171B;
`;

const BoardComponent = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 93px;
  height: 40px;

  border-radius: 30px;
  background-color: #FF9E67;
`;

const FullScreenButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 138px;
  height: 38px;

  border: 1px solid #FFFFFF;
  border-radius: 10px;
  background-color: #17171B;

  :hover {
    cursor: pointer;
  }
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 24px;

  width: 100%;
`;

const TopLineButtonLeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 20px;
`;

const TopLineButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 10px;
`;

const PlayButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 79px;
  // height: 32px;
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  gap: 4px;

  border-radius: 5px;
  background-color: #17171B;

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const PlayCircle = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 22px;
  // height: 22px;
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  // border-radius: 22px;
  border-radius: ${props => props.radius}px;

  background-color: #FFFFFF;
`;

const BlackBoardButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 10px;

  height: 36px;

  border: 1px solid #E9E9E9;
  border-radius: 10px;
  background-color: #FFFFFF;

  gap: 10px;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
  }

  transition: all 0.3s ease-in-out;
`;

const DateButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 187px;
  height: 36px;

  padding: 0 8px 0 8px;

  border: 1px solid #E9E9E9;
  border-radius: 10px;
  background-color: #202023;
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 22px;
  height: 22px;

  :hover { 
    cursor: pointer;
  }
`;

const ArrowImage = styled.img`
  display: flex;
  flex-direction: row;

  width: 9px;
  height: 15px;

  // background: url(${props => props.img}) no-repeat center center;

  :hover {
    cursor: pointer;
  }
`;

const DivisionLine = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};

  border-radius: ${props => props.radius}px;
  background-color: ${props => props.backgroundColor};
`;

const SelectBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 220px;
  height: 38px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  // border-radius: 10px;
  // background-color: #FFFFFF;
`;

const InfoInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
`;

const TotalWodBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const WodInfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 10px;

  width: 100%;
`;

const WodBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  // width: ${props => props.isClickedPlay ? `100%` : `75%`};
  width: ${props => props.width};
  
  transition: all 0.3s ease-in-out;
`;

const RankingBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // min-width: 366px;
  // width: ${props => props.isClickedPlay ? '0%' : '24%'};
  min-width: ${props => props.width};
  height: ${props => props.isClickedPlay ? '0px' : 'auto'};

  opacity: ${props => props.isClickedPlay ? 0 : 1};
  overflow: ${props => props.isClickedPlay ? 'hidden' : 'none'};
  
  transition: all 0.3s ease-in-out;
`;

const WodSectionLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TotalWodSectionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 90%;
  // width: 80%;
  width: 75%;
  height: ${props => props.height}px;

  overflow-x: scroll;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    width: 0px;
    height: 3px;
  }

  &::-webkit-scrollbar-thumb {
		border: 1px solid transparent;
		background-color: #17171B;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
		background-color: #000000;
		border-radius: 200px;
	}
`;

const WodSectionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 8px;
`;

const WodSectionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 34px;
  // height: 34px;
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? '#6DD49E' : '#17171B'};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const ScaleWodSectionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 43px;
  // height: 34px;
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? '#4D92EF' : '#17171B'};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const NoneWodBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 60px 0px;

  width: 100%;

  gap: 8px;
`;

const Image = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  transition: all 0.3s ease-in-out;
`;

const FullScreenWodViewBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.width}%;
  height: ${props => props.isView ? 'auto' : '0px'};

  overflow: hidden;
  opacity: ${props => props.isView ? 1 : 0};

  transition: all 0.3s ease-in-out;
`;

const TotalVideoWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  gap: 40px;

  transition: all 0.3s ease-in-out;
`;

const VideoViewWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // min-width: 489px;
  width: 100%;
  // height: 254px;
  height: ${props => props.height}px;
  
  // border-radius: 10px;

  opacity: 1;
  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
   
  :hover {
    cursor: ${props => props.cursor};
  }

  transition: all 0.3s ease-in-out;
`;