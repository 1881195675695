import { React, useRef, forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import HistoryView from './HistoryView.js';
import IndividualInfo from './IndividualInfo.js';
import FetchModule from '../../../Share/Network/FetchModule.js';
import moment from 'moment';

const MemberDetailView = forwardRef((props, ref) => {
  const { data, memberId, division, index } = props;

  const history = useHistory();

  const [memberData, setMemberData] = useState([{
    name: '',
    birthDay: '',
    gender: '',
    linkCoachId: '',
    phone: '',
    profile: 'None'
  }]);
  const [memberShipData, setMemberShipData] = useState([]);
  const [totalMemberShips, setTotalMemberShips] = useState([]);
  const [physicalData, setPhysicalData] = useState([]);
  const [lessonHistoryData, setLessonHistoryData] = useState([]);
  const [totalHistoryData, setTotalHistoryData] = useState([]);
  const [lockerData, setLockerData] = useState([]);
  const [productUsages, setProductUsages] = useState([]);
  const [rendering, setRendering] = useState(false);
  const [lockerRendering, setLockerRendering] = useState(false);
  const [terms, setTerms] = useState([]);
  const [provisions, setProvisions] = useState([]);

  const defaultProvisions = [
    {
      id: 0,
      isDefault: true,
      title: '개인정보 이용 동의서\n[필수 동의]',
      text: '• [센터 이름]에 가입하고 시설을 이용하기 위해 아래와 같이 개인정보를 수집, 이용 및 제3자에게 위탁하고자 합니다. 내용을 자세히 읽으신 후 동의 여부를 결정해 주십시오.\n• 개인정보 제공자가 동의한 내용 외의 다른 목적으로 활용하지 않으며, 제공된 개인정보의 이용을 거부하고자 할 때에는 개인정보 관리책임자를 통해 열람, 정정 혹은 삭제를 요구할 수 있습니다.\n    • 개인정보 수집, 이용 내역\n        • 항목 : 성명, 성별, 연락처, 생년월일, 주소, 유입경로 (+추가항목은 자동 추가)\n        • 수집, 이용 목적 : 회원가입, 상담, 상품 구매, 이용정보 및 공지사항 전달 관련 정보 제공을 위해 사용합니다.\n        • 개인정보의 보유 및 이용기간 : 수집,이용 동의일로부터 개인정보의 수집, 이용 목적을 달성할 때까지\n    • 개인정보 취급 업무 위탁 내역(고지사항)\n        • 위탁받는 자 : 링코 (주)하이비스\n        • 업무내용 : 회원 정보 관리\n• 귀하께서는 본 안내에 따른 개인정보 수집, 이용에 대하여 동의를 거부하실 권리가 있습니다. 다만, 귀하가 개인정보의 수집/이용에 동의를 거부하시는 경우에 회원가입이 불가함을 알려드립니다.'
    },
    {
      id: 0,
      isDefault: true,
      title: '광고성 정보 이용 동의서\n[선택 동의]',
      text: '• 제공하신 정보(이름, 이메일 주소, 휴대폰 번호)는 [시설이름]의 마케팅 활용을 위해 사용합니다.\n    • 개인정보 제공자가 동의한 내용을 다른 목적으로 활용하지 않습니다.\n    • 귀하는 이에 대한 동의를 거부할 수 있습니다.\n    • 본 계약 만료 후 1년까지 보관합니다.'
    }
  ];


  const TopLevelRef = useRef();
  const IndividualRef = useRef();
  const HistoryRef = useRef();

  useImperativeHandle(ref, () => ({
		setOpacity: (val) => {
			TopLevelRef.current.style.opacity = val;
		},
    setZindex: (val) => {
			TopLevelRef.current.style.zIndex = val;
    }
	}))

  useEffect(() => {
    IndividualRef.current.setMemberData(memberData);
  }, [memberData])

  // 센터 약관 불러오기 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('terms/', 'GET');
        // console.log(responseData);
        if (responseData.status === 200) {
          setTerms(responseData.data.terms);
          setProvisions(responseData.data.provisions);
        }
      }
    )();
  }, [])

  // 회원 데이터 불러오기 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('member/detail/' + memberId, 'GET');
        // console.log(responseData.data);
        if (responseData.status === 200) {
          setMemberData(responseData.data);
        }
        else if (responseData.status === 201) {
          alert('존재하지 않는 회원입니다.');
          history.push('/member/valid');
        }
        else if (responseData.status === 202) {
          alert('올바르지 않은 회원 내역 접근입니다.');
          history.push('/member/valid');
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }, [memberId, rendering])

  // 회원권 데이터 불러오기 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('member/detail/membership/' + memberId, 'GET');
        // console.log(responseData);
        if (responseData.status === 200) {
          let memberShips = responseData.data.memberShipDetails;
          let memberShipHistories = responseData.data.memberShipHistoryDates.slice();

          memberShips.map((data) => ( data.historyData = [] ));

          // desc 진행 //
          memberShips.sort(function (a, b) {
            return a.endDate > b.endDate ? -1 : a.endDate < b.endDate ? 1 : 0;
          })

          // desc 진행 //
          memberShipHistories.sort(function (a, b) {
            return a.endDate > b.endDate ? -1 : a.endDate < b.endDate ? 1 : 0;
          })

          for (let i = 0; i < memberShips.length; i++) {
            for (let j = 0; j < memberShipHistories.length; j++) {
              if (memberShips[i].usageId === memberShipHistories[j].usageId) {
                memberShips[i].historyData.push(memberShipHistories[j]);
              }
            }
          }
          // console.log(memberShips);
          setMemberShipData(memberShips);
          setTotalHistoryData(memberShipHistories);
        }
      }
    )();
  }, [memberId, rendering])

  // 회원권 수강내역 불러오기 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('member/detail/reserve/' + memberId, 'GET');
        
        if (responseData.status === 200) {
          // let memberShips = memberShipData.slice();
          let finalLessonHistoryData = responseData.data.slice();

          for (let i = 0; i < finalLessonHistoryData.length; i++) {
            finalLessonHistoryData[i].createdDate = moment(finalLessonHistoryData[i].classDate.split('T')[0] + 'T' + finalLessonHistoryData[i].classTime.split('-')[0] + ':00').format('YYYY-MM-DDThh:mm:ss')
          }

          // desc 진행 //
          finalLessonHistoryData.sort(function (a, b) {
            return a.createdDate > b.createdDate ? -1 : a.createdDate < b.createdDate ? 1 : 0;
          })

          setLessonHistoryData(finalLessonHistoryData);
        }
      }
    )();
  }, [memberId, rendering])

  // 물품 구매내역 불러오기 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('member/detail/product/' + memberId, 'GET');
        
        if (responseData.status === 200) {
          setProductUsages(responseData.data);
        }
      }
    )();

  }, [memberId, rendering])

  // 회원권 수강 내역 정리 //
  useEffect(() => {
    if (memberShipData.length === 0 || lessonHistoryData.length === 0) {
      setTotalMemberShips(memberShipData);
      return;
    }

    let memberShips = memberShipData.slice();
    let finalLessonHistoryData = lessonHistoryData.slice();

    for (let i = 0; i < memberShips.length; i++) {
      let usageHistoryCount = 0;

      for (let j = 0; j < finalLessonHistoryData.length; j++) {
        // if (memberShips[i].usageId === finalLessonHistoryData[j].usageId) usageHistoryCount += 1;
        if (memberShips[i].usageId === finalLessonHistoryData[j].usageId) usageHistoryCount += finalLessonHistoryData[j].deduction;
      }

      memberShips[i].courseTakeCount = usageHistoryCount;
    }

    setTotalMemberShips(memberShips);
  }, [memberShipData, lessonHistoryData])

  // 락커 데이터 정리 //
	useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('locker/' + memberId, 'GET');
        
        if (responseData.status === 200) {
          let usingList = [];
          let existList = [];

          for (var i = 0; i < responseData.data.length; i++) {
            if (responseData.data[i].status === '사용중') usingList.push(responseData.data[i]);
            else existList.push(responseData.data[i]);
          }

          usingList = usingList.sort(function (a, b) {
            let x = new Date(a.scheduledEndDate);
            let y = new Date(b.scheduledEndDate);

            if (x > y) return -1;
            if (x < y) return 1;
            
            return 0;
          })
          
          existList = existList.sort(function (a, b) {
            let x = new Date(a.scheduledEndDate);
            let y = new Date(b.scheduledEndDate);

            if (x > y) return -1;
            if (x < y) return 1;
            
            return 0;
          })

          let totalList = usingList.concat(existList);

          setLockerData(totalList);
        }
        // else if (responseData.status === 401) history.push('/');
      }
    )();
	}, [history, lockerRendering])

  
  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <IndividualInfo 
        ref={IndividualRef} 
        terms={terms}
        setTerms={setTerms}
        provisions={provisions}
        setProvisions={setProvisions}
        defaultProvisions={defaultProvisions}
        memberData={memberData} 
        rendering={() => setRendering(!rendering)}/>
      <HistoryView 
        ref={HistoryRef} 
        memberId={memberId}
        memberData={memberData}
        // data={memberShipData}
        // memberShipData={memberShipData}
        memberShipData={totalMemberShips}
        // holdingData={holdingData}
        holdingData={totalHistoryData}
        division={division} 
        lessonHistoryData={lessonHistoryData} 
        lockerData={lockerData}
        physicalData={physicalData} 
        productUsages={productUsages}
        setProductUsages={setProductUsages}
        rendering={() => setRendering(!rendering)} 
        lockerRendering={() => setLockerRendering(!lockerRendering)}
        /*reserveRerendering={() => setReserveRerendering(!reserveRerendering)}*//>
    </TopLevelWrapper>
  )
})

export default MemberDetailView;

const TopLevelWrapper = styled.div`
  // position: absolute;
  display: flex;
  flex-direction: column;

  margin-left: 34px;
  // opacity: 0;
  // z-index: -3;
  
  transition: all 0.3s ease-in-out;
`;