import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import WodTitleInputBox from '../Component/WodTitleInputBox';

// Image Import //
import WhiteCrownImage from '../../image/WhiteCrown.png';
import TrashImage from '../../image/TrashIcon.png';
import DivisionBox from './DivisionBox';
import TodayWodBox from './TodayWodBox';
import moment from 'moment';
import TodayWodViewBox from './TodayWodViewBox';
// Image Import //

export default function ExerciseViewBox(props) {
  const {
    clickDate,

    clickData,
    onClickAddSection,
    onClickDeleteSection,
    onClickAddScaleSection,

    movements,
    recentlySearchHistory,
    setRecentlySearchHistory,

    refreshTotalData,

    onClickScaleWod,
    onClickRanking,

    wodTitle,
    setWodTitle,

    nowSection,
    setNowSection,
    nowScaleSection,
    setNowScaleSection,
    
    onClickURL
  } = props;

  const [isCompleteFirstLoading, setIsCompleteFirstLoading] = useState(false);
  const [clickSection, setClickSection] = useState(undefined);

  useEffect(() => {
    if (clickData === undefined || nowSection === undefined || isCompleteFirstLoading) return;
    
    setClickSection(clickData.sectionList[nowSection]);
    setIsCompleteFirstLoading(true);
  }, [clickData, nowSection, isCompleteFirstLoading])

  // 한자리 숫자 앞에 0 붙이기 //
  function numberPad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  }

  // Section Title 설정
  function decideSectionTitle(data) {
    if (data === undefined) return '';
    else if (data.tag === 'ForTime') return data.round + ' Round For Time';
    else if (data.tag === 'AMRAP') {
      let min = parseInt(data.exerciseTime / 60);
      let sec = parseInt(data.exerciseTime % 60);

      if (min > 0 && sec > 0) return min + 'min ' + sec + 'sec AMRAP';
      else if (min > 0) return min + 'min AMRAP';
      else if (sec > 0) return sec + 'sec AMRAP';
      else return 'AMRAP';
    }
    else if (data.tag === 'EMOM') {
      let min = parseInt(data.round * data.exerciseTime / 60);
      let sec = parseInt(data.round * data.exerciseTime % 60);

      if (min > 0 && sec > 0) return min + 'min ' + sec + 'sec EMOM';
      else if (min > 0) return min + ' min EMOM';
      else if (sec > 0) return sec + ' sec EMOM';
      else return 'EMOM';
    }
    else if (data.tag === 'TABATA') {
      let onMin = parseInt(data.exerciseTime / 60);
      let onSec = parseInt(data.exerciseTime % 60);
      let offMin = parseInt(data.restTime / 60);
      let offSec = parseInt(data.restTime % 60);

      return 'On: ' + onMin + ':' + numberPad(onSec, 2) + ' / Off:' + offMin + ':' + numberPad(offSec, 2) + ' ' + data.round + 'R TABATA';
    }
    else if (data.tag === 'SINGLE') {
      if (data.mainExerciseList.length <= 0) return 'SINGLE';
      else if (data.mainExerciseList.length === 1) return data.mainExerciseList[0].movementName;
      else return 'Multiple Single';
    }
    else if (data.tag === 'Custom') {
      if (data.customTitle !== '') return data.customTitle;
      else if (data.scoreKeyword === '점수 없음'
        || data.scoreKeyword === '피크와트'
        || data.scoreKeyword === '최고 속도') return 'Custom : ' + data.scoreKeyword;
      else return 'Custom : ' + data.scoreKeyword + ' ' + data.scorePriority;
    }
  }

  // 보여줄 섹션 결정 //
  function decideViewSection() {
    if (nowScaleSection === undefined) return clickData.sectionList[nowSection];
    else return clickData.sectionList[nowSection].scaleSections[nowScaleSection];
  }

  // 섹션 버튼 클릭시 //
  function onClickSectionButton(index) {
    setNowSection(index);
    setNowScaleSection(undefined);

    // setClickSection(clickData.sectionList[index]);
  }

  // 섹션 스케일 버튼 클릭시 //
  function onClickScaleSectionButton(index) {
    setNowScaleSection(index);
    // onClickScaleWod();

    // setClickSection(clickData.sectionList[nowSection].scaleSections[index]);
  }

  return (
    <ExerciseBoxWrapper>
      <WodEditWrapper>
        <WodTitleInputBoxWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333' hover='default'>{clickData.lessonName + ' ' + moment(clickData.classDate).format('YYYY.MM.DD') + ' - ' + wodTitle}</Text>
        </WodTitleInputBoxWrapper>
        <DivisionLine style={{ margin: '25px 0px 0px 0px'}}/>
        <TotalWodSectionButtonWrapper>
          <WodSectionButtonWrapper>
            {
              clickData.sectionList.map((data, index) => (
                <WodSectionButton key={'MakeExercise-' + index} isClicked={nowSection === index && nowScaleSection === undefined} onClick={() => onClickSectionButton(index)}>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='pointer'>{String.fromCharCode(index + 65)}</Text>
                </WodSectionButton>
              ))
            }
          </WodSectionButtonWrapper>
          <RankingButton onClick={onClickRanking}>
            <Image width={18} height={20} src={WhiteCrownImage}/>
          </RankingButton>
        </TotalWodSectionButtonWrapper>
        <ScaleSectionBoxWrapper>
          <WodSectionButtonWrapper>
            {
              clickData.sectionList[nowSection].scaleSections.map((data, index) => (
                <ScaleWodSectionButton isClicked={nowScaleSection === index} onClick={() => onClickScaleSectionButton(index)}>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='pointer'>{String.fromCharCode(nowSection + 65) + '-' + (index + 2)}</Text>
                </ScaleWodSectionButton>
              ))
            }
          </WodSectionButtonWrapper>
        </ScaleSectionBoxWrapper>
        <SectionTitleWrapper>
          <SectionBox isClickedScale={nowScaleSection !== undefined}>
            <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='pointer'>{nowScaleSection === undefined ? String.fromCharCode(nowSection + 65) : String.fromCharCode(nowSection + 65) + '-' + (nowScaleSection + 2)}</Text>
          </SectionBox>
          <SectionTitleBox isClickedScale={nowScaleSection !== undefined}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333' hover='pointer'>{decideSectionTitle(decideViewSection())}</Text>
          </SectionTitleBox>
        </SectionTitleWrapper>
        {
          clickSection !== undefined &&
          <TodayWodBoxWrapper>
            <TodayWodViewBox 
              // sectionData={clickData.sectionList[nowSection]}
              // sectionData={clickSection}
              onClickURL={onClickURL}
              sectionData={decideViewSection()}
              movements={movements}
              recentlySearchHistory={recentlySearchHistory}
              setRecentlySearchHistory={setRecentlySearchHistory}
              refreshTotalData={refreshTotalData}/>
          </TodayWodBoxWrapper>
        }
      </WodEditWrapper>
    </ExerciseBoxWrapper>
  )
}

const ExerciseBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: calc(100% - 30px);
`;

const WodEditWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
`;

const WodTitleInputBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 24px;

  width: 100%;
`;

const TotalWodSectionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-item: center;

  padding: 20px 0px 6px 0px;

  width: 100%;
`;

const WodSectionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-item: center;

  gap: 8px;
`;

const RankingButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 63px;
  height: 32px;

  border-radius: 5px;
  background-color: #FFB800;

  :hover {
    cursor: pointer;
  }
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: calc(100% + 30px);
  height: 1px;

  background-color: #E5E5E5;
`;

const ScaleSectionBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-item: center;
  
  width: 100%;
`;

const SectionTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-item: center;

  margin-top: 20px;

  width: 100%;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.isClickedScale ? `45px` : `34px`};
  height: 34px;

  border-radius: 6px;
  background-color: ${props => props.isClickedScale ? `#4D92EF` : `#6DD49E`};

  :hover {
    cursor: default;
  }

  transition: all 0.3s ease-in-out;
`;

const SectionTitleBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 15px;

  width: ${props => props.isClickedScale ? `calc(100% - 81px)` : `calc(100% - 70px)`};
  height: 34px;

  border-radius: 6px;
  background-color: #F9F9F9;

  transition: all 0.3s ease-in-out;
`;

const WodSectionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 34px;
  height: 34px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? '#6DD49E' : '#CBCBCB'};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const ScaleWodSectionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 43px;
  height: 34px;

  border-radius: 6px;
  background-color: ${props => props.isClicked ? '#4D92EF' : '#CBCBCB'};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const TodayWodBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
`;

const Image = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;