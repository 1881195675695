import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import './App.css';
import styled from "styled-components";
import { isMobile, isTablet } from 'react-device-detect';

import HomeMain from './View/Home.js'
// account Page //
import AccountPage from './View/AccountPage/AccountPage.js';
import CertificationPage from './View/AccountPage/CertificationPage.js';
import EmailCertificationPage from './View/AccountPage/EmailCertificationPage.js';
import SearchIdentifierPage from './View/AccountPage/SearchIdentifierPage.js';
import PermutePage from './View/AccountPage/PermutePage.js';
import PasswordPermutePage from './View/AccountPage/PasswordPermutePage.js';
// Member Page //
import MemberRegistrationPage from './View/MemberPage/MemberRegistrationPage.js'
import ValidMemberInfoPage from "./View/MemberPage/ValidMemberInfoPage.js";
import ExpiredMemberInfo from './View/MemberPage/ExpiredMemberInfoPage.js';
import WaittingMemberPage from "./View/MemberPage/WaittingMemberPage";
// Schedule Page //
import ClassManagePage from "./View/ClassPage/ClassManagePage";
import SchedulePage from './View/SchedulePage/SchedulePage.js';
// Note Page //
// import OneOnOnePage from "./View/NotePage/OneOnOnePage";
// import OneOnOneTestPage from "./View/NotePage/OneOnOneTestPage";
import OneOnOnePage from './Exercise/Page/OneOnOnePage';
import ProgramPage from "./View/NotePage/ProgramPage";
import GroupPage from "./Exercise/Page/GroupPage.js";
// import ProgramTestPage from "./View/NotePage/ProgramTestPage";
import RankingPage from "./View/NotePage/RankingPage";
import SharePage from "./View/NotePage/SharePage";
import TimeLinePage from "./View/NotePage/TimeLinePage";
// Board Page //
// import BoardPage from "./View/BoardPage/Board";
// import BoardTestPage from "./View/BoardPage/BoardTestPage";
// import FullScreenBoardPage from './View/BoardPage/FullScreenBoard';
// import FullScreenBoardTestPage from './View/BoardPage/FullScreenBoardTestPage';
import BoardPage from "./Board/Page/BoardPage.js";
import FullScreenBoardPage from "./Board/Page/FullScreenBoardPage.js";
// Setting Page //
import SettingPage from './View/SettingPage.js';
import MyStoreSettingPage from "./MyStoreSetting/View/MyStoreSettingPage.js";
// Statistics Page //
import StatisticsPage from './View/StatisticsPage.js';
// Locker Page //
import LockerPage from './View/LockerPage.js';
// NoticePage //
import NoticeListPage from './View/NoticePage/NoticeListPage.js';
import NoticeCreatePage from "./View/NoticePage/NoticeCreatePage";
import NoticePage from './View/NoticePage/NoticePage';
import NoticeModifyPage from "./View/NoticePage/NoticeModifyPage";
// Payment Page //
import PaymentCompleteToss from "./View/PaymentPage/PaymentCompleteToss";
import PaymentSubscribeCompleteToss from "./View/PaymentPage/PaymentSubscribeCompleteToss";
import ZonePaymentSubscribeCompleteToss from "./View/PaymentPage/ZonePaymentSubscribeCompleteToss";
// Ruler Page //
import RulerPage from "./View/RulerPage";

import DefaultView from './Model/Share/Normal/DefaultView.js';
import NavigationBar from "./Model/Share/Normal/NavigationBar.js";
import BottomInfo from './Model/Share/Normal/BottomInfo.js';
import ServicePage from "./View/RulesPage/ServicePage";
import LocationPage from "./View/RulesPage/LocationPage";
import PrivacyPage from "./View/RulesPage/PrivacyPage";
import PromotionPage from "./View/RulesPage/PromotionPage";
import MemberDetailPage from "./View/MemberPage/MemberDetailPage";
import RefundPage from "./View/RulesPage/RefundPage";
import MySettingPage from "./View/MySettingPage";
import Test from "./View/Test";

// Channel Talk Component //
import ChannelTalk from "./Model/Share/Network/ChannelTalk";
import FindIdPage from "./View/AccountPage/FindIdPage";
import PartnerGymMemberPage from "./View/MemberPage/PartnerGymMemberPage";
import PartnerGymMemberDetailPage from "./View/MemberPage/PartnerGymMemberDetailPage";
import MessageSendPage from "./View/Message/Page/MessageSendPage.js";
import NoteNavigationBar from "./Model/Share/Normal/NoteNavigationBar.js";

export default function App() {
  
  const [nowPage, setNowPage] = useState('/');

  useEffect(() => {
    // console.log(window.location.href);
    if (isTablet || isMobile) {
      let url = window.location.href;
      // let params = window.location.search
      // alert(window.location.href)
      // alert(window.location.key)
      // // alert(params.certificationString)
      // if (url.includes('/certification')) window.location.href = 'https://m.link-zone.io/certification/' + url.split('/certification/')[1];
      // else window.location.href = "https://m.link-zone.io";

      if (!url.includes('/certification')) window.location.href = "https://m.link-zone.io";
    }
  }, [])

  // Channel Talk Commponent Building //
  useEffect(() => {
    ChannelTalk.boot({"pluginKey": "d2f4dfce-95e8-498e-aed3-84804b9d3772"});
  }, [])

  // 페이지 최소크기 결정 //
  function decideContainerMinWidth() {
    if (nowPage.includes('/note/')) return 'auto';
    else if (nowPage.includes('/full/board/')) return '900px';
    else return '1194px';
  }

  // 페이지 최대 크기 결정 //
  function decideContentWrapperMaxWidth() {
    if (nowPage.includes('/note/') || nowPage.includes('/full/board/')) return 'auto';
    else return '1194px';

  }

  return (
    <Container 
      minWidth={decideContainerMinWidth()}
      isNotePage={nowPage.includes('/note/')}>
      <Router>
        <DefaultView />
        { nowPage.includes('/note/') && <NoteNavigationBar nowPage={nowPage} setNowPage={setNowPage}/> }
        <ContentWrapper 
          maxWidth={decideContentWrapperMaxWidth()}
          isNotePage={nowPage.includes('/note/')}>
          { !nowPage.includes('/note/') && <NavigationBar nowPage={nowPage} setNowPage={setNowPage}/> }
          <Switch>
            <Route path='/test' exact component={Test}/>
            <Route path="/" exact component={AccountPage} />
            <Route path="/main" exact component={HomeMain} />
            <Route path="/certification/:certificationString" exact component={EmailCertificationPage} />
            <Route path="/certification" exact component={CertificationPage} />
            <Route path="/identifier" exact component={SearchIdentifierPage} />
            <Route path="/permute/:token/:name" exact component={PasswordPermutePage} />
            <Route path="/permute/" exact component={PermutePage} />
            {/* <Route path="/mypage" exact component={MySettingPage} /> */}
            <Route path='/mypage' exact component={MyStoreSettingPage} />
            <Route path="/find-id" exact component={FindIdPage}/>
            {/* MemberPage */}
            <Route path="/member/valid" exact component={ValidMemberInfoPage} />
            <Route path="/member/valid/detail/:memberId" exact component={MemberDetailPage}/>
            <Route path="/member/valid/detail/:memberId/:division" exact component={MemberDetailPage}/>
            <Route path="/member/expired/detail/:memberId" exact component={MemberDetailPage}/>
            <Route path="/member/expired" exact component={ExpiredMemberInfo} />
            <Route path="/member/registration" exact component={MemberRegistrationPage} />
            <Route path="/member/waitting" exact component={WaittingMemberPage} />
            <Route path="/member/partner_gym" exact component={PartnerGymMemberPage} />
            <Route path="/member/partner_gym/detail/:memberId" exact component={PartnerGymMemberDetailPage} />
            {/* SchedulePage */}
            <Route path="/class" exact component={ClassManagePage} />
            <Route path="/schedule" exact component={SchedulePage} />
            {/* Note Page */}
            {/* <Route path="/note/one-on-one" exact component={OneOnOneTestPage} />
            <Route path="/note/program" exact component={ProgramTestPage} /> */}
            <Route path="/note/ranking" exact component={RankingPage} />
            <Route path="/note/share" exact component={SharePage} />
            <Route path="/note/timeline" exact component={TimeLinePage} />
            {/* <Route path="/note/program/test" exact component={ProgramPage} />
            <Route path="/note/one-on-one/test" exact component={OneOnOnePage} /> */}
            {/* <Route path="/note/program" exact component={ProgramPage} /> */}
            <Route path="/note/program" exact component={GroupPage} />
            <Route path="/note/one-on-one" exact component={OneOnOnePage} />
            {/* BoardPage */}
            {/* <Route path="/board" exact component={BoardPage} /> */}
            <Route path="/board" exact component={BoardPage} />
            <Route path="/full/board/:token" exact component={FullScreenBoardPage} />
            {/* <Route path="/full/board/:token" exact component={FullScreenBoardPage} /> */}
            {/* <Route path="/full/board/:token" exact component={FullScreenBoardTestPage} /> */}
            {/* SettingPage */}
            <Route path="/setting" exact component={SettingPage} />
            {/* StatisticsPage */}
            <Route path="/statistics" exact component={StatisticsPage} />
            {/* Locker Page */}
            <Route path="/locker" exact component={LockerPage} />
            {/* Notice Page */}
            <Route path="/notice" exact component={NoticeListPage} />
            <Route path="/notice/details/:noticeId" exact component={NoticePage} />
            <Route path="/notice/create" exact component={NoticeCreatePage} />
            <Route path="/notice/modify/:noticeId" exact component={NoticeModifyPage} />
            {/* Message Page */}
            <Route path="/message" exact component={MessageSendPage} />
            {/* Rules Page */}
            <Route path="/rule/service" exact component={ServicePage} />
            <Route path="/rule/location" exact component={LocationPage} />
            <Route path="/rule/privacy" exact component={PrivacyPage} />
            <Route path="/rule/promotion" exact component={PromotionPage} />
            <Route path="/rule/refund" exact component={RefundPage}/>
            {/* Payment Page */}
            <Route path="/payment/complete/:status" exact component={PaymentCompleteToss}/>
            <Route path="/payment/subscribe/:status" exact component={PaymentSubscribeCompleteToss}/>
            <Route path="/payment/zone/subscribe/:status" exact component={ZonePaymentSubscribeCompleteToss}/>
            {/* <Route path="/payment/" */}
            {/* Coach Ruler */}
            <Route path="/ruler" exact component={RulerPage}/>
            <Redirect to="/main"></Redirect>
          </Switch>
        </ContentWrapper>
        <BottomInfo />
      </Router>
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  top: 0;
  left: 0;

  width: 100vw;
  // min-width: ${props => props.isNotePage ? `auto` : `1194px`};
  min-width: ${props => props.minWidth};
  
  background-color: #FDFCFB;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  width: 100%;
  // max-width: ${props => props.isNotePage ? `auto` : `1194px`};
  max-width: ${props => props.maxWidth};

  height: 100%;
`;              