import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import moment from "moment";

import Calender from '../../image/WhiteCalendar.png' //캘린더 기본
import CalenderRightArrowImagePath from '../../image/WhiteCalenderRightArrow.png';
import CalenderLeftArrowImagePath from '../../image/WhiteCalenderLeftArrow.png';
import CalenderLeftArrow2ImagePath from '../../image/CalenderLeftArrow2.png';
import CalenderRightArrow2ImagePath from '../../image/CalenderRightArrow2.png';

export default function BlackCalendarBoxButton(props) {
  const {
    width,
    height,

    value,
    setValue
  } = props;

  const [today, setToday] = useState(moment());
  const [isClicked, setIsClicked] = useState(false);
  const [dateList, setDateList] = useState([]);

  const TotalRef = useRef();
  const CalenderRef = useRef();

  const firstWeek = today.clone().startOf('month').week();
  const lastWeek = today.clone().endOf('month').week() === 1 ? 53 : today.clone().endOf('month').week();

  useEffect(() => {
    setToday(value);
  }, [value])

  // 데이터 세팅 //
  useEffect(() => {
    let newDateList = [];

    for (let week = firstWeek; week <= lastWeek; week++) {
      for (let i = 0; i < 7; i++) {
        let days = today.clone().startOf('year').week(week).startOf('week').add(i, 'day');

        newDateList.push(days);
      }
    }
    setDateList(newDateList);

    if (isClicked) {
      CalenderRef.current.style.height = `${118 + 44 * (newDateList.length / 7)}px`;
      CalenderRef.current.style.border = `1px solid #FF8B48`;
      CalenderRef.current.style.zIndex = `30`;
      CalenderRef.current.style.opacity = `1`;
    }
    else {
      CalenderRef.current.style.height = `0px`;
      CalenderRef.current.style.border = `0px solid #FF8B48`;
      CalenderRef.current.style.zIndex = `-3`;
      CalenderRef.current.style.opacity = `0`;
    }
  }, [isClicked, today, firstWeek, lastWeek])

  // 컴포넌트 밖을 클릭하면 셀렉트 창 닫기
  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [])

  // 컴포넌트 밖을 클릭하면 셀렉트 창 닫기
  function onClickOutSide(e) {
    if (TotalRef.current !== e.target && !TotalRef.current.contains(e.target)) {
      CalenderRef.current.style.height = `0px`;
      CalenderRef.current.style.border = `0px solid #FF8B48`;
      CalenderRef.current.style.zIndex = `-3`;
      CalenderRef.current.style.opacity = `0`;
      setIsClicked(false);
    }
  }

  function dateClick(index) {
    setValue(dateList[index])
    setIsClicked(false);
  }

  return (
    <CalendarBox
      ref={TotalRef}
      width={width}
      height={height}>
      <CalendarButton
        width={width}
        height={height}
        img={Calender}
        isClicked={isClicked}
        onClick={() => setIsClicked(!isClicked)}>
          <Image width={20} height={20} src={Calender}/>
      </CalendarButton>
      <CalenderView 
        ref={CalenderRef}
        height={height}>
        <TitleWrapper>
          <LayerWrapper>
            <Arrow img={CalenderLeftArrow2ImagePath} onClick={() => setToday(today.clone().subtract(1, 'year'))} style={{ margin: `0 -7px 0 0` }} />
            <Arrow img={CalenderLeftArrowImagePath} onClick={() => setToday(today.clone().subtract(1, 'month'))} />
            <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' cursor='default'>{today.format('YYYY년 MM월')}</Text>
            <Arrow img={CalenderRightArrowImagePath} onClick={() => setToday(today.clone().add(1, 'month'))} />
            <Arrow img={CalenderRightArrow2ImagePath} onClick={() => setToday(today.clone().add(1, 'year'))} style={{ margin: `0 0 0 -7px` }} />
          </LayerWrapper>
          {/* <ExitButton src={DeleteButton} onClick={() => setIsClicked(false)}/> */}
        </TitleWrapper>
        <DateColumnWrapper small={true}>
          <Text fontFamily='Poppins-bold' fontSize={12} color='#FF8B48' cursor='default' style={{ width: `25px`, marginRight: `16px` }}>SUN</Text>
          <Text fontFamily='Poppins-bold' fontSize={12} color='#FF8B48' cursor='default' style={{ width: `30px`, marginRight: `15px` }}>MON</Text>
          <Text fontFamily='Poppins-bold' fontSize={12} color='#FF8B48' cursor='default' style={{ width: `23px`, marginRight: `16px` }}>TUE</Text>
          <Text fontFamily='Poppins-bold' fontSize={12} color='#FF8B48' cursor='default' style={{ width: `28px`, marginRight: `16px` }}>WED</Text>
          <Text fontFamily='Poppins-bold' fontSize={12} color='#FF8B48' cursor='default' style={{ width: `25px`, marginRight: `21px` }}>THU</Text>
          <Text fontFamily='Poppins-bold' fontSize={12} color='#FF8B48' cursor='default' style={{ width: `18px`, marginRight: `21px` }}>FRI</Text>
          <Text fontFamily='Poppins-bold' fontSize={12} color='#FF8B48' cursor='default' style={{ width: `24px`/*, marginRight: `16px`*/ }}>SAT</Text>
        </DateColumnWrapper>
        <DateWrapper small={true}>
          {
            dateList.map((data, index) => (
              data.format('MM') === today.format('MM') ?
              <Date key={index} isClicked={value === '' ? today.format('YYYY.MM.DD') === data.format('YYYY.MM.DD') : value.format('YYYY.MM.DD') === data.format('YYYY.MM.DD')} onClick={() => dateClick(index)}>
                {data.format('D')}
              </Date>
              :
              <WithoutHoverDate key={index}>
                {data.format('D')}
              </WithoutHoverDate>
            ))
          }
        </DateWrapper>
      </CalenderView>
    </CalendarBox>
  )
}

const CalendarBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const CalendarButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: calc(${props => props.width}px - 2px);
  height: calc(${props => props.height}px - 2px);

  border: 1px solid #E9E9E9;
  border-radius: 10px;
  // background: url(${props => props.img}) no-repeat center center;
  background-color: #17171B;

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const CalenderView = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;

  border: 0px solid #FF8B48;
  border-radius: 8px;
  // background-color: #FFFFFF;
  background-color: #17171B;

  z-index: -30;
  opacity: 0;

  margin-top: calc(${props => props.height}px + 4px);

  overflow: hidden;
  
  transition: all 0.3s ease-in-out;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  margin: 28px 30px 0 22px;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
`;

const Arrow = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
  
  background: url(${props => props.img}) no-repeat center center;

  :hover {
    cursor: pointer;
  }
`;

const DateColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // margin-top: 29px;
  // margin-left: ${props => props.small ? `8px` : `28px`};
  // margin-bottom: 19px;

  margin: 29px 0px 19px 28px;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  // width: ${props => props.small ? `300px` : `321px`};
  // margin-left: ${props => props.small ? `0px` : `20px`};

  width: 321px;
  margin-left: 20px;
`;

const Date = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 40px;

  margin: 0 1px 2px 1px;

  // background-color: ${props => props.isClicked ? `#FF8B48` : `#FFFFFF`};
  background-color: ${props => props.isClicked ? `#FF8B48` : `#17171B`};
  
  font-family: Poppins-Medium;
  font-size: 14px;

  // color: ${props => props.isClicked ? `#FFFFFF` : `#595959`};
  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #FF7322;
    color: #FFFFFF;

    font-fmaily: Poppins-Bold;
    // font-size: 14px;
  }
`;

const WithoutHoverDate = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border-radius: 40px;

  margin: 0 1px 2px 1px;

  // background-color: #FFFFFF;
  background-color: #17171B;

  font-family: Poppins-Regular;
  font-size: 12px;

  color: #777777;

  :hover {
    cursor: default;
  }
`;

const Image = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  object-fit: contain;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;
