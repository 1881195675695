import React from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react-web';

import Money from '../../../image/MoneyImage.png';

import LoadingAnimation from '../../../Animation/LoadingAnimation.json';

export default function DurationBox(props) {
  const {
    isOpenPartnerGymSales,
    calcDateText,
    priceAvg,
    durationAvg,
    durationMembers,
    frequencyData,
    isFrequencyLoading
  } = props;


  // ACV 산출 //
  function calcACV() {
    // (월 평균 매출 / 장기 회원 수) * 평균 회원 지속일 //
    if (durationAvg === 0 || priceAvg === 0 || durationMembers.length === 0) return '0원';
    else return Math.round(durationAvg * (priceAvg / durationMembers.length)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원';
  }

  // 시간 정리 //
  function calcLessonClassTime(data) {
    let tmp = data.split('-');
    let splitTmp = tmp[0].split(':');
    let splitTmp2 = tmp[1].split(':');
    let startTime = '';
    let endTime = '';

    if (parseInt(splitTmp[0]) > 12) startTime = (parseInt(splitTmp[0]) - 12) + ':' + splitTmp[1] + ' PM';
    else startTime = splitTmp[0] + ':' + splitTmp[1] + ' AM';

    if (parseInt(splitTmp2[0]) > 12) endTime = (parseInt(splitTmp2[0]) - 12) + ':' + splitTmp2[1] + ' PM';
    else endTime = splitTmp2[0] + ':' + splitTmp2[1] + ' AM';

    return startTime + ' - ' + endTime;
  }

  return (
    <TopLevelWrapper>
      <InfoBox>
        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>{calcDateText() + ' Average Client Value [ACV]'}</Text>
        {
          isOpenPartnerGymSales ?
            <InnerInfoBox>
              <TotalACVBox>
                <ImageBoxWrapper gap={9}>
                  <MoneyImage src={Money} />
                  <ColorTextBox backgroundColor='#FF8B48'>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' hover='default'>월 평균 매출</Text>
                    <Text style={{ marginLeft: `4px` }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='default'>: {priceAvg.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</Text>
                  </ColorTextBox>
                </ImageBoxWrapper>
                <ACVBoxWrapper>
                  <ACVBox>
                    <ACVInnerWrapper>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={50} color='#FDFCFB' hover='default'>ACV</Text>
                      <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Bold' fontSize={14} color='#FDFCFB' hover='default'>고객 한명이 평균 지출하는 금액</Text>
                      <ACVValueBox>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#777777' hover='default'>{calcACV()}</Text>
                      </ACVValueBox>
                      {/* <ACVExplanationWrapper>
                        <SmallText fontFamily='NotoSansKR-Medium' fontSize={8} color='#FDFCFB' hover='default'>높은 ACV는 고객이 체육관에서 더 많은 시간을 보내고,</SmallText>
                        <SmallText fontFamily='NotoSansKR-Medium' fontSize={8} color='#FDFCFB' hover='default'>더 많은 서비스를 이용하며, 더 많은 지출을 하는 것을 나타내므로, 체육관의 수익성을 높일 수 있습니다.</SmallText>
                      </ACVExplanationWrapper> */}
                    </ACVInnerWrapper>
                  </ACVBox>
                  <ACVExplanationWrapper>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#777777' hover='default'>높은 ACV는 고객이 체육관에서 더 많은 시간을 보내고,</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#777777' hover='default'>더 많은 서비스를 이용하며, 더 많은 지출을 하는 것을 나타내므로,</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#777777' hover='default'>체육관의 수익성을 높일 수 있습니다.</Text>
                  </ACVExplanationWrapper>
                </ACVBoxWrapper>
              </TotalACVBox>
            </InnerInfoBox>
            :
            <InnerInfoBox>
              <NotOpenTextWrapper>
                <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#444444' cursor='default'>이 업체는 파트너 체육관에 매출을 비공개 합니다</Text>
                <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#444444' cursor='default'>링크존 설정에서 “파트너 체육관”에서 권한 설정해 주세요</Text>
              </NotOpenTextWrapper>
            </InnerInfoBox>
        }
      </InfoBox>
      <BoxDivisionLine />
      {
        isFrequencyLoading ?
          <InfoBox>
            <AnimationBox>
              <LottieWrapper>
                <Lottie options={{ animationData: LoadingAnimation }} />
              </LottieWrapper>
            </AnimationBox>
          </InfoBox>
          :
          <InfoBox>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>{calcDateText() + ' 수업별 수강인원'}</Text>
            {
              isOpenPartnerGymSales ?
                <InnerInfoBox>
                  <ScrollWrapper>
                    {
                      frequencyData.map((data, index) => (
                        <ComponentWrapper key={index}>
                          <Text fontFamily='Poppins-Medium' fontSize={12} color='#777777' hover='default'>{calcLessonClassTime(data.classTime)}</Text>
                          <InnerComponentWrapper>
                            <LessonNameBox backgroundColor={data.color}>
                              <LessonNameWrapper>
                                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='default'>{data.lessonName}</Text>
                              </LessonNameWrapper>
                            </LessonNameBox>
                            <DivisionLine />
                            <ReserveResultBox>
                              <ReserveResultTextWrapper>
                                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#777777' hover='default'>{'총 ' + data.reserveCount + '명 수강'}</Text>
                              </ReserveResultTextWrapper>
                            </ReserveResultBox>
                          </InnerComponentWrapper>
                        </ComponentWrapper>
                      ))
                    }
                  </ScrollWrapper>
                </InnerInfoBox>
                :
                <InnerInfoBox>
                  <NotOpenTextWrapper>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#444444' cursor='default'>이 업체는 파트너 체육관에 매출을 비공개 합니다</Text>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#444444' cursor='default'>링크존 설정에서 “파트너 체육관”에서 권한 설정해 주세요</Text>
                  </NotOpenTextWrapper>
                </InnerInfoBox>
            }
          </InfoBox>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 1020px;
  height: 472px;

  gap: 28px;
  margin-top: 38px;

  border-radius: 30px;
  box-shadow: 0 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-conetent: flex-start;
  align-items: flex-start;

  width: 44.7%;
  height: 426px;
`;

const InnerInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 364px;
`;

const TotalACVBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;
  height: 364px;
`;

const AnimationBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  z-index: 5;
  
  width: 100%;
  height: 364px;

  background-color: #FFFFFF;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 80px;
  height: 80px;
`;

const ACVBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: cneter;
  align-items: center;

  margin-top: 30px;

  width: 100%;
`;

const ACVBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 87.22%;
  // height: 220px;
  height: 195px;

  border-radius: 8px;
  background-color: #FF8B48;
`;

const ACVInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: 16px;
  margin-top: -20px;

  width: 90.9%;
`;

const ACVValueBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;

  width: 158px;
  height: 40px;

  border-radius: 10px;
  background-color: #FFFFFF;
`;

const ACVExplanationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 30px;

  width: 87.22%;

  gap: 2px;
`;

const BoxDivisionLine = styled.div`
  diplay: flex;
  flex-direction: row;

  width: 1px;
  // height: 392px;
  height: 426px;

  background-color: #ECECEC;
`;

const ImageBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 24px;

  gap: ${props => props.gap}px;
`;

const DurationImage = styled.img`
  width: 52px;
  height: 52px;
`;

const MoneyImage = styled.img`
  width: 40px;
  height: 38px;
`;

const ColorTextBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 9px;
  padding: 0px 19px 0px 19px;

  height: 28px;

  border-radius: 8px;
  background-color: ${props => props.backgroundColor};
`;

const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 28px;

  width: 100%;
  // height: 358px;
  height: 390px;

  gap: 18px;

  overflow-y: scroll;
  overflow-x: none;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    background-color: #DADADA;
    border-radius: 200px;
  }

  &::-webkit-scrollbar-track {
    background-color: #FBFBFB;
    border-radius: 200px;
  }
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 4px;
`;

const InnerComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const LessonNameBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 146px;
  height: 28px;

  border-radius: 8px;
  background-color: ${props => props.backgroundColor}
`;

const LessonNameWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  width: 116px;
  height: 20px;

  text-align: center;
  
  overflow: hidden;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: row;

  width: 130px;
  height: 1px;

  margin: 0 16px 0 16px;
  border: 1px solid #ECECEC;
  background-color: #ECECEC;
`;

const ReserveResultBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 127px;
  height: 28px;

  border-radius: 8px;
  background-color: #ECECEC;
`;

const ReserveResultTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 127px;
  height: 28px;

  border-radius: 8px;
  background-color: #ECECEC;
`;

const DurationMemberBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 24px;
`;

const DurationMemberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 16px;

  width: 454px;
  height: 240px;
`;

const DurationMemberColumn = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 8px 0px 8px;

  width: calc(100% - 16px);
  height: 32px;

  border-radius: 8px;
  background-color: #ECECEC;
`;

const DurationMemberList = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 216px;

  overflow-y: scroll;
  overflow-x: none;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		// height: 92px;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const DurationMemberWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 8px 0px 8px;

  gap: 8px;

  width: calc(100% - 16px);
  min-height: 36px;
  max-height: 36px;

  background-color: #FFFFFF;

  :hover {
    background-color: #F4F2F0;
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const DurationNameBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 59px;
  height: 20px;

  overflow: hidden;
`;

const DurationGenderBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 46px;
  height: 20px;

  overflow: hidden;
`;

const DurationBirthBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90px;
  height: 20px;

  overflow: hidden;
`;

const DurationPhoneBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 106px;
  height: 20px;

  overflow: hidden;
`;

const DurationContentBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 101px;
  height: 20px;

  overflow: hidden;
`;

const NotOpenTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }
`;

const SmallText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;
  color: ${props => props.color};

  margin: 0 0 0 0;
  zoom: 0.8;

  :hover {
    cursor: ${props => props.hover};
  }
`;