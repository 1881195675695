import React, { useState } from "react";
import styled from "styled-components";

import VideoViewModal from "../../Exercise/Model/VideoViewModal";
import BlackRecordMovementViewBox from "../Component/BlackRecordMovementViewBox";

export default function FullScreenMovementViewBox(props) {
  const { sectionData, fontScale } = props;

  const [videoLink, setVideoLink] = useState('');
  const [isClickedVideoView, setIsClickedVideoView] = useState(false);


  // 동작 영상 모달 열기 클릭시 //
  function onClickMovementVideoViewModal(index) {
    if (sectionData.mainExerciseList[index].customVideoLinkId === undefined) setVideoLink(sectionData.mainExerciseList[index].videoLinkId);
    else setVideoLink(sectionData.mainExerciseList[index].customVideoLinkId);

    setIsClickedVideoView(true);
  }

  return (
    <AddMovementBoxWrapper>
      {isClickedVideoView && <VideoViewModal data={videoLink} close={() => setIsClickedVideoView(!isClickedVideoView)} />}
      <InnerWrapper>
        <MovementBoxWrapper>
          {
            sectionData.mainExerciseList.map((data, index) => (
              <BlackRecordMovementViewBox
                idx={index}
                data={data}
                tag={sectionData.tag}
                fontScale={fontScale}
      
                onClickMovementVideo={onClickMovementVideoViewModal}/>
            ))
          }
        </MovementBoxWrapper>
      </InnerWrapper>
    </AddMovementBoxWrapper>
  )
}

const AddMovementBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // padding: 20px 0px;

  // width: calc(100% + 30px);
  width: 100%;

  // background-color:rgb(0, 0, 0);
  background-color: #17171B;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  // width: calc(100% - 30px);
  width: 100%;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 1px;

  background-color: #E5E5E5;
`;

const MovementBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  // width: calc(100% - 30px);
  gap: 10px;
`;

const AddMyMovementWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;
  
  width: 100%;

  border-radius: ${props => props.isClickedAddMyMovement ? '5px' : '0px'};
  background-color: ${props => props.isClickedAddMyMovement ? '#FF8B48' : '#FFFFFF'};

  transition: all 0.3s ease-in-out;
`;

const InnerAddMyMovementWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;
  
  margin: ${props => props.isClickedAddMyMovement ? '0px 0px 3px 0px' : '0px'};

  width: ${props => props.isClickedAddMyMovement ? `calc(100% - 6px)` : `100%`};

  border-bottom-left-radius: ${props => props.isClickedAddMyMovement ? `4px` : `0px`};
  border-bottom-right-radius: ${props => props.isClickedAddMyMovement ? `4px` : `0px`};
  background-color: ${props => props.isClickedAddMyMovement ? `#F9F9F9` : `#FFFFFF`};
  
  transition: all 0.3s ease-in-out;
`;

const SearchMovementBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: ${props => props.isClickedAddMyMovement ? `100%` : `calc(100% - 30px)`};
  height: ${props => props.isClickedAddMovement ? `463px` : `0px`};

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;


const MyMovementBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  // height: ${props => props.isClickedAddMyMovement ? `610px` : `0px`};
  height: 570px;

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const AddMyMovementColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: calc(100% - 24px);

  gap: 8px;
`;

const SearchInputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: 14px;

  width: 100%;
`;

const MyMovementOptionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
  
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 20px;
  margin-bottom:  ${props => props.isClickedAddMyMovement ? '20px' : '0px'};

  width: ${props => props.isClickedAddMyMovement ? `calc(100% - 24px)` : `calc(100% - 30px)`};

  transition: all 0.3s ease-in-out;
`;

const ExitAddMovementButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isClickedAddMovement ? `12.44%` : `0%`};
  height: 36px;

  border-radius: 8px;
  background-color: #CBCBCB;

  overflow: hidden;
  :hover {
    cursor: pointer
  }

  transition: all 0.3s ease-in-out;
`;

const AddMovementButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.isClickedAddMovement ? `86.53%` : `100%`};
  height: 36px;

  border-radius: ${props => props.isClickedAddMovement ? `8px` : `12px`};
  background-color: ${props => props.isClickedAddMovement ? `#FF8B48` : `#6DD49E`};

  :hover {
    cursor: pointer
  }

  transition: all 0.3s ease-in-out;
`;

const RecentlySearchWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: ${props => props.isClickedMyMovement ? `0px` : `572px`};

  overflow-x: hidden;
  overflow-y: scroll;

  margin-top: 16px;

  border-bottom: 1px solid #D9D9D9;

  transition: all 0.3s ease-in-out;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const MovementPlus = styled.img`
  width: 20px;
  height: 20px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;


const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;
`;

const EmptyDataWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const RecentlyBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 98%;
  min-height: 33px;

  border-bottom: 1px solid #F5F5F5;
`;

const RecentlyTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  :hover {
    cursor: pointer;
    color: #FF8B48;
  }
`;

const RecentlyText = styled.pre`
  font-family: Poppins-Regular;
  font-size: 12px;

  color: ${props => props.isClicked ? `#FF8B48` : `#4F4F4F`};

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: #FF8B48;
  }
`;

const SearchText = styled.pre`
  font-family: Poppins-Regular;
  font-size: 12px;

  color: #FF8B48;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: #FF8B48;
  }
`;

const RecentlySearchTextButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const DataSearchButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const DeleteButton = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const MyMovementTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin: 12px 0px;

  width: calc(100% - 30px);
`;

const MovementOptionCheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 8px;
  
  width: 13.5%;
`;

const MovementOptionCheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 34px;

  border-radius: 4px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#E0E0E0`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const MovementOptionCheck = styled.img`
  width: 15.75px;
  height: 13.5px;
`;

const VideoLinkInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 36px;
`;

const VideoComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  gap: 8px;
  margin-top: 16px;
`;

const VideoPrevBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  overflow: hidden;

  width: 490px;
  height: 250px;

  border-radius: 5px;

  background-color: #E0E0E0;
`;
