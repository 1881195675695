import { React, useRef, useImperativeHandle, forwardRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import MemberShipNameInput from '../../../../Component/SettingComponent/MemberShipNameInput';
import SettingCoachGenderInput from '../../../../Component/SettingComponent/SettingCoachGenderInput';
import CoachImageInput from '../../../../Component/SettingComponent/CoachImageInput';

import FetchModule from '../../../Share/Network/FetchModule';
import FindCoachIdByPhoneInput from '../../../../Component/SettingComponent/FindCoachIdByPhoneInput';
import CoachAuthBox from '../../../../Component/SettingComponent/CoachAuthBox';

import GrayExitPath from '../../../../image/GrayExit.png';
import DefaultLogoPath from '../../../../image/DefaultLogo.png'

export default function NewCoachAddModal(props) {
  const { data, index, exitClickFn, rendering } = props;

  const [userId, setUserId] = useState('없음');
  const [linkCoachId, setLinkCoachId] = useState('None');
  const [isExitView, setIsExitView] = useState(true);
  const [modalHeight, setModalHeight] = useState(136);
  const [notYetSignUpBoxHeight, setNotYetSignUpBoxHeight] = useState(0);
  const [totalComponentHeight, setTotalComponentHeight] = useState(0);

  const [isMemberAuth, setIsMemberAuth] = useState(true);
  const [isExerciseAuth, setIsExerciseAuth] = useState(true);
  const [isSalesAuth, setIsSalesAuth] = useState(true);
  const [profile, setProfile] = useState('');

  const [originalName, setOriginalName] = useState('');
  const [originalPhone, setOriginalPhone] = useState('');
  const [originalGender, setOriginalGender] = useState('');
  const [originalUserId, setOriginalUserId] = useState('');
  const [originalLinkCoachId, setOriginalLinkCoachId] = useState('');
  const [originalProfile, setOriginalProfile] = useState('');

  const history = useHistory();

  const TopLevelRef = useRef();
  const NameRef = useRef();
  const PhoneRef = useRef();
  const LinkCoachRef = useRef();
  const GenderRef = useRef();
  const ProfileRef = useRef();

  useEffect(() => {
    if (index === -1) return;

    NameRef.current.setValue(data[index].name);
    PhoneRef.current.setValue(data[index].phone);
    GenderRef.current.setValue(data[index].gender);
    
    setProfile(data[index].profile);
    setLinkCoachId(data[index].linkCoachId);

    let userIdList = data[index].linkCoachId.split('@');

    if (userIdList[0].includes('kakao.')) setUserId(userIdList[0].replaceAll('kakao.', '') + '@' + userIdList[1]);
    else if (userIdList[0].includes('naver.')) setUserId(data[index].linkCoachId = userIdList[0].replaceAll('naver.', '') + '@' + userIdList[1]);
    else if (userIdList[0].includes('google.')) setUserId(data[index].linkCoachId = userIdList[0].replaceAll('google.', '') + '@' + userIdList[1]);
    else if (userIdList[0].includes('apple.')) setUserId(data[index].linkCoachId = userIdList[0].replaceAll('apple.', '') + '@' + userIdList[1]);
    else setUserId(data[index].linkCoachId === 'None' ? '없음' : data[index].linkCoachId);

    setIsMemberAuth(data[index].isMemberAuth);
    setIsExerciseAuth(data[index].isExerciseAuth);
    setIsSalesAuth(data[index].isSalesAuth);

    setIsExitView(false);
    setNotYetSignUpBoxHeight(0);
    setTotalComponentHeight(486);
    setModalHeight(640);

    setOriginalName(data[index].name);
    setOriginalPhone(data[index].phone);
    setOriginalGender(data[index].gender);
    setOriginalUserId(data[index].userId);
    setOriginalLinkCoachId(data[index].linkCoachId);
    setOriginalProfile(data[index].profile);
  }, [data, index])

  // useEffect(() => {
  //   console.log('---------');
  //   console.log(linkCoachId);
  // }, [linkCoachId])
  function exitClick() {
    exitClickFn();
  }

  function SaveClick() {

    if (originalPhone === '010XXXXXXXX') {
      // rendering
      exitClickFn('Class');

      return;
    }

    // var storeId = window.sessionStorage.getItem('StoreId');
    let storeId = window.localStorage.getItem('StoreId');
    let name = NameRef.current.getValue();
    let phone = PhoneRef.current.getValue();
    let gender = GenderRef.current.getValue();

    let regexEn = /[a-zA-Z]/;
    let regexKr = /[ㄱ-ㅎ가-힣]/;
    let regexNum = /[0-9]/;

    // 이름이 공백이거나 숫자가 들어간 경우
    if (name === '' || regexNum.test(name)) {
      alert('이름을 정확히 입력하세요');
      return;
    }
    // 전화번호에 영어나 한글이 섞여있거나 길이가 부족할 경우
    else if (phone.length !== 11 || regexEn.test(phone) || regexKr.test(phone)) {
      alert('전화번호를 정확히 입력하세요');
      return;
    }
    // // 이메일이 공백이거나 형식에 맞지 않을경우
    // else if (email === '' || !regexExp.test(email)) {
    //   alert('이메일을 정확히 입력하세요');
    //   return;
    // }
    // 링크 코치 아이디가 공백이거나 한글이 섞여있는 경우
    // else if (linkCoachId === '' || regexKr.test(linkCoachId)) {
    //   alert('링크 코치 아이디를 정확히 입력하세요');
    //   return;
    // }
    // 새로 생성일 경우에만 이미지가 선택되었는지 확인
    // else if (profile === undefined && index === -1) {
    //   alert('프로필 사진을 업로드하세요');
    //   return;
    // }

    let newData = {
      id: index === -1 ? '' : data[index].id,
      storeId: storeId,
      name: name,
      phone: phone,
      gender: gender,
      // email: email,
      linkCoachId: linkCoachId,
      profile: profile === undefined ? '' : profile,
      isMemberAuth: isMemberAuth,
      isExerciseAuth: isExerciseAuth,
      isSalesAuth: isSalesAuth
    }
    // console.log(newData);
    const fetchModule = new FetchModule();
    (
      async () => {
        let responseData;
        if (index === -1) responseData = await fetchModule.postData('coach/create', 'POST', newData);
        else responseData = await fetchModule.patchData('coach/modify/', 'PATCH', newData);

        // console.log(responseData);
        if (responseData.status === 200) {
          rendering();
          exitClickFn('Class');
        }
        else if (responseData.status === 401) history.push('/');
        else { }
      }
    )();
  }

  // 링크코치 가입정보 찾기 버튼 클릭시 //
  function onClickFindLinkCoachId(phone) {
    if (originalPhone === '010XXXXXXXX') {
      alert('기본 등록된 코치는 변경할 수 없습니다');
      
      NameRef.current.setValue(originalName);
      PhoneRef.current.setValue(originalPhone);
      GenderRef.current.setValue(originalGender);
    
      return;
    }

    // console.log(originalPhone);
    let requestData = {
      phone: phone
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postData('coach/find', 'POST', requestData);
        // console.log(responseData.data);
        if (responseData.status === 200) {
          NameRef.current.setValue(responseData.data[0].name);
          GenderRef.current.setValue(responseData.data[0].gender);
          // ProfileRef.current.setImgBase64(responseData.data[0].profile);
          setProfile(responseData.data[0].profile);

          let linkCoachId = JSON.parse(JSON.stringify(responseData.data[0].linkCoachId));
          setLinkCoachId(linkCoachId);

          let userLinkCoachId = JSON.parse(JSON.stringify(responseData.data[0].linkCoachId));
          let userIdList = userLinkCoachId.split('@');

          if (userIdList[0].includes('kakao.')) setUserId(userIdList[0].replaceAll('kakao.', '') + '@' + userIdList[1]);
          else if (userIdList[0].includes('naver.')) setUserId(responseData.data[0].linkCoachId = userIdList[0].replaceAll('naver.', '') + '@' + userIdList[1]);
          else if (userIdList[0].includes('google.')) setUserId(responseData.data[0].linkCoachId = userIdList[0].replaceAll('google.', '') + '@' + userIdList[1]);
          else if (userIdList[0].includes('apple.')) setUserId(responseData.data[0].linkCoachId = userIdList[0].replaceAll('apple.', '') + '@' + userIdList[1]);
          else setUserId(responseData.data[0].linkCoachId);

          setIsExitView(false);
          setNotYetSignUpBoxHeight(0);
          setTotalComponentHeight(486);
          setModalHeight(640);
        }
        else if (responseData.status === 201) {
          // alert('해당 번호의 가입정보가 없습니다.');
          setIsExitView(true);
          NameRef.current.setValue('');
          GenderRef.current.setValue('Male');
          // ProfileRef.current.setImgBase64(data[index].profile);
          setProfile('');
          setLinkCoachId('');
          setUserId('None');
      
          setIsMemberAuth(true);
          setIsExerciseAuth(false);
          setIsSalesAuth(false);

          setNotYetSignUpBoxHeight(44);
          setTotalComponentHeight(0);
          setModalHeight(197);
        }
        else if (responseData.status === 401) history.push('/');
      }
    )();
  }

  // 아직 링크코치를 가입하지 않으셨습니다 클릭시 //
  function onClickNotYetBox() {
    setIsExitView(false);
    setNotYetSignUpBoxHeight(0);
    setTotalComponentHeight(486);
    setModalHeight(640);
  }

  return (
    <TopLevelWrapper>
      <TotalModalWrapper>
        <Banner>
          <BannerImage src='https://picture.link-zone.org/MildCoachLogo.png' />
          <Text style={{ marginRight: '12px' }} fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF' hover='default'>링크코치 어플 코치 권한 추가</Text>
        </Banner>
        <Modal height={modalHeight}>
          <TitleWrapper>
            <Title>코치 추가</Title>
            {
              isExitView &&
              <TitleExitButtonWrapper onClick={exitClick}>
                <TitleExitButton src={GrayExitPath} />
              </TitleExitButtonWrapper>
            }
          </TitleWrapper>
          <TotalWrapper>
            <ModelWrapper>
              <ComponentWrapper>
                <ComponentTitle>전화 번호</ComponentTitle>
                <FindCoachIdByPhoneInput ref={PhoneRef} placeholder='-를 제외하고 입력해주세요' onClickFind={onClickFindLinkCoachId} />
              </ComponentWrapper>
              <NotYetSignUpBox isView={notYetSignUpBoxHeight !== 0} height={notYetSignUpBoxHeight} onClick={onClickNotYetBox}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF' hover='cursor'>아직 링크코치를 가입하지 않으셨습니다</Text>
              </NotYetSignUpBox>
              <TotalComponentWrapper height={totalComponentHeight}>
                <ComponentWrapper>
                  <ComponentTitle>이름</ComponentTitle>
                  <MemberShipNameInput ref={NameRef} placeholder='코치 이름을 입력해주세요' />
                </ComponentWrapper>
                <ComponentWrapper>
                  <ComponentTitle>링크코치 아이디</ComponentTitle>
                  <InputModel>
                    <UserIdText>{userId}</UserIdText>
                  </InputModel>
                </ComponentWrapper>
                <ComponentWrapper>
                  <ComponentTitle>성별</ComponentTitle>
                  <SettingCoachGenderInput ref={GenderRef} />
                </ComponentWrapper>
                <ComponentWrapper style={{ width: '100%' }}>
                  <ComponentTitle>코치 권한</ComponentTitle>
                  <CoachAuthBox
                    isMemberAuth={isMemberAuth}
                    setIsMemberAuth={setIsMemberAuth}
                    isExerciseAuth={isExerciseAuth}
                    setIsExerciseAuth={setIsExerciseAuth}
                    isSalesAuth={isSalesAuth}
                    setIsSalesAuth={setIsSalesAuth}/>
                </ComponentWrapper>  
                <ComponentWrapper style={{ width: '100%' }}>
                  <ComponentTitle>프로필 사진</ComponentTitle>
                  <BottomWrapper>
                    <LogoWrapper>
                      <Logo src={profile === '' ? DefaultLogoPath : profile} />
                    </LogoWrapper>
                    <ButtonWrapper>
                      <ExitButton onClick={exitClick}>취소</ExitButton>
                      <DeleteButton onClick={SaveClick}>저장하기</DeleteButton>
                    </ButtonWrapper>
                  </BottomWrapper>
                </ComponentWrapper>
              </TotalComponentWrapper>
            </ModelWrapper>
          </TotalWrapper>
        </Modal>
      </TotalModalWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1000;

  background-color: rgba(0, 0, 0, 0.2);
  
  transition: all 0.3s ease-in-out;
`;

const TotalModalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 402px;

  gap: 8px;
`;

const Banner = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 60px;

  border-radius: 8px;
  background-color: #6DD49E;

  :hover {
    cursor: default;
  }
`;

const BannerImage = styled.img`
  margin-left: 8px;

  width: 50px;
  height: 50px;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 0 28px 0 28px;

  // width: 744px;
  width: calc(100% - 56px);
  // height: 507px;
  height: ${props => props.height}px;

  overflow: hidden;

  border-radius: 15px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 24px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 16px;

  width: 100%;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const NotYetSignUpBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: ${props => props.isView ? `16px` : `0px`};

  width: 100%;
  // height: 44px;
  height: ${props => props.height}px;

  border-radius: 8px;
  background-color: #6DD49E;

  overflow: hidden;

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const TitleExitButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer
  }
`;

const TitleExitButton = styled.img`
  width: 100%;
  height: 100%;
`;

const ModelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
`;

const TotalComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: ${props => props.height}px;

  overflow: hidden;

  // background-color: red;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 16px;
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-end;

  width: 100%;
`;

const ComponentTitle = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;
`;

const InputModel = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

	padding: 0 0 0 8px;
	margin-top: 8px;

  width: 336px;
  height: 30px;

  overflow: hidden;

	border: 1px solid #DFDFDF;
  border-radius: 8px;
`;

const UserIdText = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 14px;

  color: #000000;

  margin: 0 0 0 0;
  padding: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;
  
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
    color: #777777;
  }
`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
	font-family: ${props => props.fontFamily};
	font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  padding: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 8px;

  width: 137px;
  height: 137px;

  border-radius: 8px;

  overflow: hidden;
`;

const Logo = styled.img`
  width: 137px;
  height: 137px;

  object-fit: contain;
`;