import { React, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

// Note Text Area //
export default function NoteTextArea(props) {
  const { value, setValue, division, placeholder, checkURL } = props;

  const [isFirst, setIsFirst] = useState(true);

  const TextRef = useRef(null);


  // useEffect(() => {
  //   if (isFirst) {
  //     TextRef.current.style.height = 'auto';
  //     TextRef.current.style.height = '200px';
  //     console.log("???");
  //     console.log(TextRef.current.scrollHeight);
  //     setTimeout(() => (
  //       setIsFirst(false)
  //     ), 1000);
  //     // setIsFirst(false);
  //   }
  //   else {
  //     console.log("???");
  //     console.log(TextRef.current.scrollHeight);
  //     TextRef.current.style.height = 'auto';
  //     TextRef.current.style.height = TextRef.current.scrollHeight + 'px';
  //   }
  // }, [isFirst])

  // useEffect(() => {
  //   setTimeout(() => (
  //     TextRef.current.style.height = 'auto',
  //     TextRef.current.style.height = TextRef.current.scrollHeight + 'px'
  //   ), 200);
  // }, [TextRef])

  // const handleResizeHeight = useCallback(() => {
  //   TextRef.current.style.height = 'auto';
  //   TextRef.current.style.height = TextRef.current.scrollHeight + 'px';
  // }, []);

  useEffect(() => {
    if (isFirst) {
      TextRef.current.style.height = '100px';
      
      setTimeout(() => {
        setIsFirst(false);
      }, 100)
    }
    else {
      TextRef.current.style.height = 'auto';
      TextRef.current.style.height = TextRef.current.scrollHeight + 'px';
    }
  }, [value, isFirst])

  // useEffect(() => {
  //   // if (value === '') {
  //   //   TextRef.current.style.height = '100px';
  //   // }
  //   // else {
  //   //   console.log('value : ' + value);
  //   //   console.log('height1 : ' + TextRef.current.offsetHeight);
  //   //   TextRef.current.style.height = 'auto';
  //   //   console.log('height2 : ' + TextRef.current.offsetHeight);
  //   //   console.log('clientHeight : ' + TextRef.current.clientHeight)
  //   //   console.log('scrollHeight : ' + TextRef.current.scrollHeight)
  //   //   TextRef.current.style.height = TextRef.current.scrollHeight + 'px';
  //   //   // if (TextRef.current.scrollHeight === undefined) TextRef.current.style.height = '100px';
  //   //   // else TextRef.current.style.height = TextRef.current.scrollHeight + 'px';
  //   // }
  //   console.log('\n--------------');
  //   console.log('value : ' + value);
  //   console.log('height1 : ' + TextRef.current.offsetHeight);
  //   TextRef.current.style.height = 'auto';
  //   console.log('height2 : ' + TextRef.current.offsetHeight);
  //   console.log('clientHeight : ' + TextRef.current.clientHeight)
  //   console.log('scrollHeight : ' + TextRef.current.scrollHeight)
  //   TextRef.current.style.height = TextRef.current.scrollHeight + 'px';
  // }, [value, TextRef])

  function handleChange(e) {
    setValue(e.target.value, division);
  }

	function handlePaste(e) {
		checkURL();
	}

	function handleKeyPress(e) {
		if (e.key === 'Enter' || e.key === ' ') {
			checkURL();
		}
	}

  return (
    <Input
      ref={TextRef}
      type='text'
      value={value}
      placeholder={placeholder}
      onChange={handleChange} 
			onPaste={handlePaste}
			onKeyPress={handleKeyPress}
      /*onInput={handleResizeHeight}*//>
  )
};

const Input = styled.textarea`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  // width: 100%;
  width: 92.02%;
  // height: auto;
  // height: 200px;
  min-height: 100px;
  max-height: auto;
  resize: none;
  
	// overflow: hidden;
  // resize: vertical;

  // padding-left: 16px;

  font-family: NotoSansKR-Regular;
  font-size: 14px;

  border: 0px solid #DFDFDF;

  color: #595959;

	outline: none;

	::placeholder {
		color: #CBCBCB;
	}
`;