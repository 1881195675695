import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

import { organizationRanking, calcLegacyRanking } from "../../Exercise/Transaction/Transaction";
import LegacyRankingBox from '../../Exercise/Model/Legacy/LegacyRankingBox';
import FetchModule from "../../Model/Share/Network/FetchModule";
import UserProfile from '../../Component/Normal/UserProfile';

import FirstPlaceImg from '../../image/FirstPlace.png';
import SecondPlaceImg from '../../image/SecondPlace.png';
import ThirdPlaceImg from '../../image/ThirdPlace.png';
// import RightImg from '../../image/NotAvailableRight.png';
import DefaultProfile from '../../image/DefaultProfile.png';
import RefreshIcon from '../../image/RefreshIcon.png';


export default function FullScreenRankingView(props) {
  const {
    nowSection,
    selectedWod,
    fontScale
  } = props;

  const [legacyRankingData, setLegacyRankingData] = useState([]);
  const [legacyUseData, setLegacyUseData] = useState([]);

  const [rankingData, setRankingData] = useState([]);
  const [finalRank, setFinalRank] = useState([]);
  const [division, setDivision] = useState('Total');
  const [refresh, setRefresh] = useState(false);
  const [isRefreshHover, setIsRefreshHover] = useState(false);
  const [availableLoading, setAvailableLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingRatate, setLoadingRatate] = useState(0);

  const TimeRef = useRef(null);

  // Ranking Data API Call //
  useEffect(() => {
    if (selectedWod === undefined) {
      setRankingData([]);
      return;
    }

    setIsLoading(true);
    setAvailableLoading(false);

    // 신버전 랭킹 데이터 불러오기 //
    if (selectedWod.wodVersion === 2) {
      const fetchModule = new FetchModule();
      (
        async () => {
          let responseData = await fetchModule.getDataOfExerciseVersion2('record/' + selectedWod.workoutId, 'GET');
          // console.log(responseData.data);
          if (responseData.status === 200) {

            setRankingData(responseData.data.sectionRank);
          }
          // else if (responseData.status === 204) setLegacyUseData([]);
        }
      )();
    }
    else {
      // setIsLoading(true);
      // console.log(clic)
      let requestData = {
        workoutId: selectedWod.workoutId
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          let responseData = await fetchModule.postDataOfExerciseVersion2('workout/tmp/ranking', 'POST', requestData);
          // console.log(responseData);
          if (responseData.status === 200) {
            let workoutData = responseData.data.slice();

            setLegacyUseData(organizationRanking(workoutData));
          }
          else if (responseData.status === 204) setLegacyUseData([]);
        }
      )();
    }
  }, [selectedWod, refresh])

  // 옛날버전 랭킹 산정 //
  useEffect(() => {
    if (legacyUseData.length === 0) {
      setLegacyRankingData([]);
      return;
    }

    let legacyRanking = calcLegacyRanking(legacyUseData, nowSection);

    setLegacyRankingData(legacyRanking);
  }, [legacyUseData, nowSection])

  // 새로운 버전 랭킹 구분 정리 //
  useEffect(() => {
    if (rankingData.length === 0 || nowSection === undefined) {
      setFinalRank([]);
      setIsLoading(false);
      setAvailableLoading(false);
      return;
    }

    let newList = [];

    if (division === 'Total') {
      newList = rankingData[nowSection].sectionRecords.slice();
    }
    for (let i = 0; i < rankingData[nowSection].sectionRecords.length; i++) {
      if (rankingData[nowSection].sectionRecords[i].gender === division) newList.push(rankingData[nowSection].sectionRecords[i]);
    }
    // console.log(newList);
    setFinalRank(newList);

    setTimeout(() => setIsLoading(false), 1000);
    // setIsLoading(false);
  }, [rankingData, nowSection, division])

  // 로딩 애니메이션 조작 //
  useEffect(() => {
    if (loadingRatate === 0 && !isLoading) return;
    else if (!isLoading) {
      setTimeout(() => setLoadingRatate(0), 1000);
      return;
    }
    else {
      setTimeout(() => setLoadingRatate(loadingRatate - 180), 500);
    }
  }, [isLoading, loadingRatate])

  // 로딩 애니메이션을 위한 요청 딜레이 변수 //
  useEffect(() => {
    if (!isLoading) setTimeout(() => setAvailableLoading(true), 1000);
  }, [isLoading])

  // 한자리 숫자 앞에 0 붙이기 //
  function numberPad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  }

  // 옛날버전 랭킹 자세히보기 클릭시 //
  function onClickViewRankingDetail() {
    // 아무것도 안함 //
  }

  // 순위 최신화 버튼 클릭시 //
  function onClickRefresh() {
    if (isLoading) {
      alert('순위 산정이 진행중입니다');
      return;
    }
    else if (!availableLoading) {
      alert('잠시 후 다시시도해주세요');
      return;
    }

    setIsLoading(true);
    setRefresh(!refresh);
  }

  // 점수 보여주기 //
  function onViewFinalScoreText(data) {
    if (data.tag === 'ForTime') {
      if (data.success === 'Success') {
        let min = parseInt(data.recordTime / 60);
        let sec = parseInt(data.recordTime % 60);

        return (
          <RowTextWrapper>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={(14 + fontScale) < 30 ? (14 + fontScale) : 30} color='#FFFFFF'>{numberPad(min, 2) + ':' + numberPad(sec, 2)}</Text>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={(14 + fontScale) < 30 ? (14 + fontScale) : 30} color='#BDBDBD'>Time</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF'>{data.scoreText}</Text>
          </RowTextWrapper>
        )
      }
      else {
        return (
          <RowTextWrapper>
            <Text style={{ marginRight: 2 }} fontFamily='NotoSansKR-Regular' fontSize={(14 + fontScale) < 30 ? (14 + fontScale) : 30} color='#FFFFFF'>{data.completeRound}</Text>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={(14 + fontScale) < 30 ? (14 + fontScale) : 30} color='#BDBDBD'>R</Text>
            <Text style={{ marginRight: 2 }} fontFamily='NotoSansKR-Regular' fontSize={(14 + fontScale) < 30 ? (14 + fontScale) : 30} color='#FFFFFF'>{data.lastRound}</Text>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={(14 + fontScale) < 30 ? (14 + fontScale) : 30} color='#BDBDBD'>Reps</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF'>{data.scoreText}</Text>
          </RowTextWrapper>
        )
      }
    }
    else if (data.tag === 'AMRAP') {
      return (
        <RowTextWrapper>
          <Text style={{ marginRight: 2 }} fontFamily='NotoSansKR-Regular' fontSize={(14 + fontScale) < 30 ? (14 + fontScale) : 30} color='#FFFFFF'>{data.completeRound}</Text>
          <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={(14 + fontScale) < 30 ? (14 + fontScale) : 30} color='#BDBDBD'>R</Text>
          <Text style={{ marginRight: 2 }} fontFamily='NotoSansKR-Regular' fontSize={(14 + fontScale) < 30 ? (14 + fontScale) : 30} color='#FFFFFF'>{data.lastRound}</Text>
          <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={(14 + fontScale) < 30 ? (14 + fontScale) : 30} color='#BDBDBD'>Reps</Text>
          <Text fontFamily='NotoSansKR-Regular' fontSize={(14 + fontScale) < 30 ? (14 + fontScale) : 30} color='#FFFFFF'>{data.scoreText}</Text>
        </RowTextWrapper>
      )
    }
    else if (data.tag === 'SINGLE') {
      return (
        <RowTextWrapper>
          <Text fontFamily='NotoSansKR-Regular' fontSize={(14 + fontScale) < 30 ? (14 + fontScale) : 30} color='#FFFFFF'>{data.scoreText}</Text>
        </RowTextWrapper>
      )
    }
    else if (data.tag === 'EMOM' || data.tag === 'TABATA') {
      return (
        <RowTextWrapper >
          <Text fontFamily='NotoSansKR-Regular' fontSize={(14 + fontScale) < 30 ? (14 + fontScale) : 30} color='#FFFFFF'>{data.scoreText}</Text>
        </RowTextWrapper>
      )
    }
    else if (data.tag === 'Custom') {
      if (data.scoreKeyword === 'Not Scored') {
        return (
          <RowTextWrapper>
            <NotScoredBox isSuccess={data.success === 'Success'}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={(14 + fontScale) < 30 ? (14 + fontScale) : 30} color='#FFFFFF'>{data.success === 'Success' ? '운동 완료' : '운동 미수행'}</Text>
            </NotScoredBox>
          </RowTextWrapper>
        )
      }
      else if (data.scoreKeyword === 'Time') {
        let min = parseInt(data.recordTime / 60);
        let sec = parseInt(data.recordTime % 60);

        return (
          <RowTextWrapper>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={(14 + fontScale) < 30 ? (14 + fontScale) : 30} color='#FFFFFF'>{numberPad(min, 2) + ':' + numberPad(sec, 2)}</Text>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={(14 + fontScale) < 30 ? (14 + fontScale) : 30} color='#BDBDBD'>Time</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14 + (7 * fontScale / 100)} color='#FFFFFF'>{data.scoreText}</Text>
          </RowTextWrapper>
        )
      }
      else if (data.scoreKeyword === 'Rounds And Reps') {
        return (
          <RowTextWrapper>
            <Text style={{ marginRight: 2 }} fontFamily='NotoSansKR-Regular' fontSize={(14 + fontScale) < 30 ? (14 + fontScale) : 30} color='#FFFFFF'>{data.customLastRound}</Text>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={(14 + fontScale) < 30 ? (14 + fontScale) : 30} color='#BDBDBD'>R</Text>
            <Text style={{ marginRight: 2 }} fontFamily='NotoSansKR-Regular' fontSize={(14 + fontScale) < 30 ? (14 + fontScale) : 30} color='#FFFFFF'>{data.customLastReps}</Text>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={(14 + fontScale) < 30 ? (14 + fontScale) : 30} color='#BDBDBD'>Reps</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF'>{data.scoreText}</Text>
          </RowTextWrapper>
        )
      }
      else if (data.scoreKeyword === 'Reps'
        || data.scoreKeyword === 'Distance'
        || data.scoreKeyword === 'Weight'
        || data.scoreKeyword === 'Points'
        || data.scoreKeyword === 'Peak Watts'
        || data.scoreKeyword === 'Max Speed'
      ) {
        return (
          <RowTextWrapper>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={(14 + fontScale) < 30 ? (14 + fontScale) : 30} color='#FFFFFF'>{data.customKeywordValue}</Text>
            <Text style={{ marginRight: 6 }} fontFamily='NotoSansKR-Regular' fontSize={(14 + fontScale) < 30 ? (14 + fontScale) : 30} color='#BDBDBD'>{data.customKeywordUnit}</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF'>{data.scoreText}</Text>
          </RowTextWrapper>
        )
      }
    }
  }

  return (
    <TotalRankingBoxWrapper>
      {
        (selectedWod !== undefined) &&
        <RankingBoxWrapper>
          {
            selectedWod.wodVersion === 2 ?
              <RankingViewBox>
                <NewPrevRankingViewInnerWrapper>
                  <RankingInfoTextWrapper>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={(14 + (fontScale / 2)) < 50 ? (14 + (fontScale / 2)) : 50} color='#FFFFFF' hover='default'>전체 순위</Text>
                    <RankingRefreshButton
                      height={(32 + fontScale) < 102 ? (32 + fontScale) : 102}
                      onMouseOver={() => setIsRefreshHover(true)}
                      onMouseLeave={() => setIsRefreshHover(false)}
                      onClick={onClickRefresh}>
                      <RefreshImageWrapper isRefreshHover={isRefreshHover}>
                        <Image
                          width={(18 + fontScale) < 64 ? (18 + fontScale) : 64}
                          height={(18 + fontScale) < 64 ? (18 + fontScale) : 64}
                          src={RefreshIcon} />
                      </RefreshImageWrapper>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={(14 + (fontScale / 2)) < 50 ? (14 + (fontScale / 2)) : 50} color='#FFFFFF' hover='pointer'>순위최신화</Text>
                    </RankingRefreshButton>
                  </RankingInfoTextWrapper>
                  <TotalRankingViewWrapper>
                    <RankingLoadingView 
                      isLoading={isLoading}
                      gap={(8 + fontScale) < 16 ? (8 + fontScale) : 16}>
                      <LoadingImageWrapper loadingRatate={loadingRatate}>
                        <Image
                          width={(18 + fontScale) < 64 ? (18 + fontScale) : 64}
                          height={(18 + fontScale) < 64 ? (18 + fontScale) : 64}
                          src={RefreshIcon} />
                      </LoadingImageWrapper>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={(14 + fontScale) < 50 ? (14 + fontScale) : 50} color='#FFFFFF' hover='pointer'>순위 산정중</Text>
                    </RankingLoadingView>
                    <RankingViewListWrapper isLoading={isLoading}>
                      {
                        finalRank.map((data, index) => (
                          <RankingViewWrapper
                            key={index}
                            height={(64 + fontScale) < 150 ? (64 + fontScale) : 150}>
                            <RankingNumberBoxWrapper>
                              {
                                (index >= 0 && index < 3) ?
                                  <RankingLeaderBoxWrapper>
                                    <RankingLeaderBox
                                      width={(40 + fontScale) < 106 ? (40 + fontScale) : 106}
                                      height={(40 + fontScale) < 106 ? (40 + fontScale) : 106}
                                      src={index === 1 ? FirstPlaceImg : index === 2 ? SecondPlaceImg : ThirdPlaceImg} />
                                    <RankingImageTextWrapper>
                                      <Text fontFamily='NotoSansKR-Bold' fontSize={(16 + (fontScale / 2)) < 50 ? (16 + (fontScale / 2)) : 50} color='#FFFFFF'>{index + 1}</Text>
                                    </RankingImageTextWrapper>
                                  </RankingLeaderBoxWrapper>
                                  :
                                  <RankingNumberBox
                                    width={(40 + fontScale) < 106 ? (40 + fontScale) : 106}
                                    height={(40 + fontScale) < 106 ? (40 + fontScale) : 106}>
                                    <Text fontFamily='NotoSansKR-Bold' fontSize={(16 + (fontScale / 2)) < 50 ? (16 + (fontScale / 2)) : 50} color='#FFFFFF'>{index + 1}</Text>
                                  </RankingNumberBox>
                              }
                            </RankingNumberBoxWrapper>
                            <RankingClickBox 
                              rankBoxWidth={(40 + fontScale) < 106 ? (40 + fontScale) : 106}
                              height={(64 + fontScale) < 150 ? (64 + fontScale) : 150}>
                              <InfoBox>
                                <LeftWrapper>
                                  <ProfileWrapper
                                    width={(40 + fontScale) < 106 ? (40 + fontScale) : 106}
                                    height={(40 + fontScale) < 106 ? (40 + fontScale) : 106}>
                                    <UserProfile profile={data.userProfile} defaultProfile={DefaultProfile} />
                                  </ProfileWrapper>
                                  <InfoTextWrapper profileBoxWidth={(40 + fontScale) < 106 ? (40 + fontScale) : 106}>
                                    <InfoRowTextWrapper>
                                      <Text fontFamily='NotoSansKR-Medium' fontSize={(14 + (fontScale / 2)) < 50 ? (14 + (fontScale / 2)) : 50} color='#FFFFFF'>{data.userName}</Text>
                                      {
                                        (data.derivationSectionId !== undefined) &&
                                        <ScaleBox height={(16 + (fontScale / 2)) < 50 ? (16 + (fontScale / 2)) : 50}>
                                          <Text fontFamily='Poppins-Medium' fontSize={(12 + (fontScale / 2)) < 50 ? (12 + (fontScale / 2)) : 50} color='#FFFFFF'>{String.fromCharCode(nowSection + 65) + '-' + (data.scaleSectionIndex + 1)}</Text>
                                        </ScaleBox>
                                      }
                                    </InfoRowTextWrapper>
                                    <ScoreTextWrapper height={(20 + fontScale) < 64 ? (20 + fontScale) : 64}>
                                      {/* <Text style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} fontFamily='NotoSansKR-Regular' fontSize={12} color='#DBDBDB'>{onViewFinalScoreText(data)}</Text> */}
                                      {onViewFinalScoreText(data)}
                                    </ScoreTextWrapper>
                                  </InfoTextWrapper>
                                </LeftWrapper>
                                {/* <DetailButton src={RightImg} /> */}
                              </InfoBox>
                            </RankingClickBox>
                          </RankingViewWrapper>
                        ))
                      }
                    </RankingViewListWrapper>
                  </TotalRankingViewWrapper>
                </NewPrevRankingViewInnerWrapper>
              </RankingViewBox>
              :
              <RankingViewBox style={{ marginTop: '26px' }}>
                <PrevRankingViewInnerWrapper>
                  <LegacyRankingBox
                    sectionData={legacyUseData.length > 0 ? legacyUseData[0].sectionList : null}
                    rankingList={legacyRankingData}
                    onClickRankingBox={onClickViewRankingDetail} />
                </PrevRankingViewInnerWrapper>
              </RankingViewBox>
          }
        </RankingBoxWrapper>
      }
    </TotalRankingBoxWrapper>
  )
}


const TotalRankingBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;
  
  width: 100%;

  transition: all 0.3s ease-in-out;
`;

const RankingBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  overflow-x: hidden;
  
  // border-top-left-radius: 20px;
  // border-top-right-radius: 20px;
  // background-color: #FFFFFF;
  // box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);

  transition: all 0.3s ease-in-out;
`;

const RankingViewBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  // height: calc(100% - 57px);
  // height: ${props => props.height}px;
  // min-height: ${props => props.height}px;

  // overflow-x: hidden;
  // overflow-y: scroll;
  overflow: hidden;

  outline: none;

  // background-color: #F9F9F9;
`;

const PrevRankingViewInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;  

  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
		border: 0px solid transparent;
		background-color: #D9D9D9;
		border-radius: 2px;
	}

  &::-webkit-scrollbar-track {
		// background-color: #F9F9F9;
		border-radius: 200px;
    opacity: 0;
	}
`;

const NewPrevRankingViewInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding-bottom: 15px;

  width: 96%;
`;

const RankingInfoTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;  
`;

const RankingRefreshButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 10px;

  // height: 32px;
  height ${props => props.height}px;

  gap: 8px;

  border-radius: 5px;
  background-color: #17171B;

  :hover {
    cursor: pointer;
  }
`;

const ScoreTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  // height: 20px;
  height: ${props => props.height}px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;

const TotalRankingViewWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: 16px;

  width: 100%;
  min-height: 120px;
`;

const RankingLoadingView = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 16px;

  width: 100%;
  height: 100%;
  // height: 300px;

  gap: ${props => props.gap}px;

  opacity: ${props => props.isLoading ? `1` : `0`};

  transition: all 0.3s ease-in-out;
`;

const RankingViewListWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  gap: 20px;

  opacity: ${props => props.isLoading ? `0` : `1`};

  transition: all 0.3s ease-in-out;
`;

const RankingViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 97.71%;
  height: ${props => props.height}px;
`;

const RankingNumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const RankingLeaderBox = styled.img`
  // width: 40px;
  // height: 40px;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const RankingImageTextWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: -10px;
  // margin-top: -36px;
`;

const RankingNumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 40px;
  // height: 40px;
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
  background-color: #E0E0E0;
`;

const RankingClickBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 88%;
  width calc(100% - ${props => props.rankBoxWidth}px - 5px);
  // height: 64px;
  height: ${props => props.height}px;

  border-radius: 12px;
  background-color: #17171B;

  transition: all 0.3s ease-in-out;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 95.7%;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: calc(100% - 18px);
  // width: calc(100% - 26px);
  
  gap: 8px;
`;

const ProfileWrapper = styled.div`
  // width: 40px;
  // height: 40px;
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;

  overflow: hidden;
`;

const InfoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: calc(100% - 48px);
  width: calc(100% - ${props => props.profileBoxWidth}px - 8px);

  gap: 3px;
`;

const InfoRowTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const DetailButton = styled.img`
  width: 9.6px;
  height: 17.7px;
`;

const RowTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 100%;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const NotScoredBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 10px;

  height: 21px;

  border-radius: 6px;
  background-color: ${props => props.isSuccess ? '#6DD49E' : '#D92828'};
`;

const ScaleBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-left: 4px;
  padding: 0px 10px;

  // height: 16px;
  height: ${props => props.height}px;

  border-radius: 5px;
  background-color: #4D92EF;
`;

const RefreshImageWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  transform: ${props => props.isRefreshHover ? `rotate(-180deg)` : 'rotate(0deg)'};

  transition: all 0.3s ease-in-out;
`;

const LoadingImageWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  transform: rotate(${props => props.loadingRatate}deg);

  transition: all 0.3s ease-in-out;
`;

const Image = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  transition: all 0.3s ease-in-out;
`;
