import { React, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

const WaittingTopView = forwardRef((props, ref) => {
  const { isWaitting, settingWaittingView, waittingCnt, ignoreCnt } = props;

  const TopLevelRef = useRef();
  const RejectDisplayRef = useRef();
  const WaitterDisplayRef = useRef();
  
  useImperativeHandle(ref, () => ({
    setZindex: (val) => {
      TopLevelRef.current.style.zIndex = val;
    }
  }));

  // 마우스 호버링 이벤트
  function onMouseOver(className) {
    if (className === 'Reject') {
      RejectDisplayRef.current.style.color = `#FFFFFF`;
      RejectDisplayRef.current.style.backgroundColor = `#777777`;
    }
    else if (className === 'Wait') {
      WaitterDisplayRef.current.style.color = `#FFFFFF`;
      WaitterDisplayRef.current.style.backgroundColor = `#FF8B48`;
    }
  } 
  
  // 마우스 호버링 이벤트
  function onMouseLeave(className) {
    if (className === 'Reject') {
      RejectDisplayRef.current.style.color = `#FFFFFF`;
      RejectDisplayRef.current.style.backgroundColor = `#DFDFDF`;
    }
    else if (className === 'Wait') {
      WaitterDisplayRef.current.style.color = `#FFFFFF`;
      WaitterDisplayRef.current.style.backgroundColor = `#5BD98A`;
    }
  }

  function onClick(className) {
    settingWaittingView(className);
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      {
        isWaitting ?
          <TitleWrapper>
            <Title>회원권 신청</Title>
            <Explanation>현재 회원 등록 예정중인 상태의 회원정보를 볼 수 있어요</Explanation>
          </TitleWrapper>
          :
          <TitleWrapper>
            <Title>등록 거절</Title>
            <Explanation>등록 거절중인 상태의 회원정보를 볼 수 있어요</Explanation>
          </TitleWrapper>
      }
      <TotalButtonWrapper>
        <ButtonWrapper className='Reject' onClick={() => onClick('Reject')} onMouseOver={() => onMouseOver('Reject')} onMouseLeave={() => onMouseLeave('Reject')}>
          <CountDisplay ref={RejectDisplayRef} backgroundColor='#DFDFDF' color='#A8A8A8'>{ignoreCnt}</CountDisplay>
          <RejectButton>
            <ButtonText>등록 거절 회원</ButtonText>
          </RejectButton>
        </ButtonWrapper>
        <ButtonWrapper className='Wait' onClick={() => onClick('Wait')} onMouseOver={() => onMouseOver('Wait')} onMouseLeave={() => onMouseLeave('Wait')}>
          <CountDisplay ref={WaitterDisplayRef} backgroundColor='#5BD98A' color='#FFFFFF'>{waittingCnt}</CountDisplay>
          <WaitterButton>
            <ButtonText>대기중인 회원</ButtonText>
          </WaitterButton>
        </ButtonWrapper>
      </TotalButtonWrapper>
    </TopLevelWrapper>
  )
})

export default WaittingTopView;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  z-index: 1;
  
  margin-left: 28px;
  // margin-bottom: 26px;

  width: 995px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 16px;

  color: #232323;

  margin: 0 0 0 0;
`;

const Explanation = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 12px;

  color: #777777;

  margin: 0 0 0 0;
`;

const TotalButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 22px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const CountDisplay = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 28px;
  height: 28px;

  z-index: 3;

  margin-top: -13px;
  margin-left: -13px;

  font-family: NotoSansKR-Bold;
  font-size: 12px;

  color: ${props => props.color};

  border-radius: 28px;
  background-color: ${props => props.backgroundColor};
  
  transition: all 0.3s ease-in-out;
  
  :hover {
    cursor: default;
  }
`;

const RejectButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 99px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #777777;
  }
`;

const WaitterButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 99px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    border: 1px solid #FF8B48;
    // background-color: #FF8B48;
  }
`;

const ButtonText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #777777;

  margin: 0 0 0 0;
`;