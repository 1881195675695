import { React } from 'react';
import styled from 'styled-components';

import ProgramVideoIcon from '../../image/ProgramVideoIcon.png';

export default function RecordMovementViewBox(props) {
  const {
    data,

    idx,
    // tag,
    // reps,
    division,

    // onChangeData,
    // onChangeUnit,
    // onClickRest,

    // onClickCopyMovement,
    onClickMovementVideo,
    // onClickMovementOption,
    // onClickDeleteMovement,
  } = props;

  // const weightOptions = ['Kg', 'Pounds', '%RM', '%Body'];
  // const distanceOptions = ['Km', 'm', 'Cm', 'inch', 'Feet'];
  // const caloriesOptions = ['Kcal', 'Cal'];

  // 한자리 숫자 앞에 0 붙이기 //
  function numberPad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  }

  return (
    <TopLevelWrapper>
      <TotalWrapper>
        <MovementWrapper isUseRest={data.isUseRest}>
          <MovementTitleWrapper>
            <TextScaleButtonWrapper>
              <NumberWrapper>
                <NumberBox isGreen={division === 'buyin' || division === 'buyout'}>
                  <HoverPointerText fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF'>{numberPad(idx + 1, 2)}</HoverPointerText>
                </NumberBox>
                <HoverPointerText fontFamily='Poppins-Medium' fontSize={12} color='#4F4F4F'>{data.movementName}</HoverPointerText>
              </NumberWrapper>
            </TextScaleButtonWrapper>
            <IconWrapper>
              <Icon src={ProgramVideoIcon} onClick={() => onClickMovementVideo(idx)} />
            </IconWrapper>
          </MovementTitleWrapper>
          {
            data.isUseCount &&
            <LayerWrapper topMargin={16}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>횟수</Text>
              <ComponentWrapper>
                <DivisionLine />
              </ComponentWrapper>
              <SingleCountWrapper>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{data.count}</Text>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#CBCBCB'>Reps</Text>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#CBCBCB'>x</Text>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{data.sets}</Text>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#CBCBCB'>Sets</Text>
              </SingleCountWrapper>
            </LayerWrapper>
          }
          {
            data.isUseWeight &&
            <LayerWrapper topMargin={21}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>무게</Text>
              <ComponentWrapper>
                <DivisionLine />
              </ComponentWrapper>
              <SingleCountWrapper>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{data.weight}</Text>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#CBCBCB'>{data.weightUnit}</Text>
              </SingleCountWrapper>
            </LayerWrapper>
          }
          {
            data.isUseDistance &&
            <LayerWrapper topMargin={21}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>거리</Text>
              <ComponentWrapper>
                <DivisionLine />
              </ComponentWrapper>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{data.distance}</Text>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#CBCBCB'>{data.distanceUnit}</Text>
            </LayerWrapper>
          }
          {
            data.isUseHeight &&
            <LayerWrapper topMargin={21}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>높이</Text>
              <ComponentWrapper>
                <DivisionLine />
              </ComponentWrapper>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{data.height}</Text>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#CBCBCB'>{data.heightUnit}</Text>
            </LayerWrapper>
          }
          {
            data.isUseCalories &&
            <LayerWrapper topMargin={21}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>열량</Text>
              <ComponentWrapper>
                <DivisionLine />
              </ComponentWrapper>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{data.calories}</Text>
              <Text fontFamily='Poppins-Medium' fontSize={14} color='#CBCBCB'>{data.caloriesUnit}</Text>
            </LayerWrapper>
          }
          {
            data.isUseTime &&
            <LayerWrapper topMargin={21}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>시간</Text>
              <ComponentWrapper>
                <DivisionLine />
              </ComponentWrapper>
              <SingleCountWrapper>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{data.minute}</Text>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#CBCBCB'>Min</Text>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{data.second}</Text>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#CBCBCB'>Sec</Text>
              </SingleCountWrapper>
            </LayerWrapper>
          }
          {
            data.isUseTempo &&
            <LayerWrapper topMargin={21}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#828282'>템포</Text>
              <ComponentWrapper>
                <DivisionLine />
              </ComponentWrapper>
              <SingleCountWrapper>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F'>{data.tempo}</Text>
                <Text fontFamily='Poppins-Medium' fontSize={14} color='#CBCBCB'>Tempo</Text>
              </SingleCountWrapper>
            </LayerWrapper>
          }
          {
            data.isUseRest &&
            <RestComponentWrapper topMargin={25} isClicked={data.isUseRest}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>동작간 쉬는 시간</Text>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>{data.rest + ' 초'}</Text>
            </RestComponentWrapper>
          }
        </MovementWrapper>
      </TotalWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  z-index: ${props => props.index};

  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: calc(100% - 30px);
`;

const MovementWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding-bottom: ${props => props.isUseRest ? `0px` : `25px`};
  width: 100%;

  margin-top: 23px;
  background-color: #FFFFFF;
`;

const NumberWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;

  :hover {
    cursor: pointer;
  }
`;

const MovementTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 12px;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const NumberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 27px;
  height: 18px;

  border-radius: 6px;
  background-color: ${props => props.isGreen ? `#6DD49E` : `#FF8B48`};

  // font-family: NotoSansKR-Bold;
  // font-size: 12px;

  // color: #FFFFFF;

  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-top: ${props => props.topMargin}px;
`;

const ComponentWrapper = styled.div`
  flex: 0.94;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // width: 63.87%;

  gap: 8px;
`;

const SingleCountWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const TextScaleButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 12px;
`;

const HoverPointerText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  padding: 0 0 0 0;

  :hover {
    cursor: default;
  }

  transition: all 0.3s ease-in-out;
`;

const TimeInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 24px;
`;

const RestComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 15px;
  margin-left: -15px;
  
  width: 100%;
  height: 22px;

  gap: 7px;

  margin-top: ${props => props.topMargin}px;

  background-color: #5BD98A;

  transition: all 0.3s ease-in-out;
`;

const RestComponentBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 6px;
`;

const DotWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-evenly;
  align-items: center;

  height: 60%;
`;

const Dot = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 5px;
  height: 5px;

  border-radius: 5px;
  background-color: #FFFFFF;
`;

const CheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 18px;
  height: 18px;

  border: ${props => props.isClicked ? `1px solid #FFFFFF` : `1px solid #CBCBCB`};
  border-radius: 6px;
  background-color: ${props => props.isClicked ? `#FFFFFF` : `#F6F6F6`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const Check = styled.img`
  width: 10px;
  height: 9px;

  opacity: ${props => props.isClicked ? '1' : '0'};

  transition: all 0.3s ease-in-out;
`;


const DifferentRepsBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 16px 0px 16px;

  width: 286px;
  height: 34px;

  border: 1px solid #E0E0E0;
  border-radius: 15px;
  background-color: #FFFFFF;
`;

const ValueBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 87%;
  height: 100%;

  overflow: hidden;
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 96px;
  height: 36px;
`;

const UnitBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  width: 13%;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 1px;

  background-color: #E5E5E5;
`;