import React, { useEffect, useState } from "react";
import styled from "styled-components";

import TrashImage from '../../../image/TrashIcon.png';

export default function ProvisionButton(props) {
  const { data, keys, onClickProvisionsButton } = props;

  const [isClicked, setIsClicked] = useState(false);
  const [fontColor, setFontColor] = useState('#FFFFFF');
  const [border, setBorder] = useState('1px solid #6DD49E');
  const [backgroundColor, setBackgroundColor] = useState('#FFFFFF');

  // 데이터 세팅 //
  useEffect(() => {
    setIsClicked(keys.includes(data.id));

    if (keys.includes(data.id)) {
      setFontColor('#FFFFFF');
      setBorder('1px solid #D92828');
      setBackgroundColor('#EB9393');
    }
    else {
      setFontColor('#6DD49E');
      setBorder('1px solid #6DD49E');
      setBackgroundColor('#FFFFFF');
    }
  }, [data, keys])

  // 마우스 호버링시 //
  function onMouseOver() {
    // 이미 선택되어 있는경우 //
    if (keys.includes(data.id)) {
      setBackgroundColor('#FF3131');
    }
    else {
      setFontColor('#FFFFFF');
      setBackgroundColor('#6DD49E');
    }
  }

  // 마우스 호버링 끝 //
  function onMouseLeave() {
    // 이미 선택되어 있는경우 //
    if (keys.includes(data.id)) {
      setBackgroundColor('#EB9393');
    }
    else {
      setFontColor('#6DD49E');
      setBackgroundColor('#FFFFFF');
    }
  }

  return (
    <ProvisionsButtonView 
      border={border}
      backgroundColor={backgroundColor}
      onMouseOver={onMouseOver} 
      onMouseLeave={onMouseLeave}
      onClick={() => onClickProvisionsButton(data)}>
      {
        isClicked ?
          <Image width={16} height={16} src={TrashImage} />
          :
          <PlusBox width={16} height={16}>
            <Stick width={14} height={2} radius={2} backgroundColor={fontColor}/>
            <Stick width={2} height={14} radius={2} backgroundColor={fontColor}/>
          </PlusBox>
      }
      <ProvisionTitleWrapper>
        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color={fontColor} hover='pointer'>{data.title}</Text>
      </ProvisionTitleWrapper>
    </ProvisionsButtonView>
  )
}

const ProvisionsButtonView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 10px;

  width: 104px;
  height: 32px;

  border: ${props => props.border};
  border-radius: 5px;
  background-color: ${props => props.backgroundColor};

  gap: 4px;

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const Image = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  object-fit: contain;
`;

const PlusBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const Stick = styled.div`
  position: absolute;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: ${props => props.radius}px;
  background-color: ${props => props.backgroundColor};

  transition: all 0.3s ease-in-out;
`;

const ProvisionTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 81px;
  height: 20px;

  overflow: hidden;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  margin: 0 0 0 0;

  color: ${props => props.color};

  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;