import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

import html2pdf from "html2pdf.js";
import moment from "moment";

import TermsDownloadIcon from '../../../../../image/TermsDownloadIcon.png';
import FetchModule from "../../../../Share/Network/FetchModule";

export default function TermsViewModal(props) {
  const {
    termsAgreementId,
    storeName,
    clickedTerms,
    provisions,
    defaultProvisions,
    onClickExit,
  } = props;

  const [isCompleteAgreement, setIsCompleteAgreement] = useState(false);
  const [title, setTitle] = useState('');
  const [includeProvisions, setIncludeProvisions] = useState([]);
  const [signDate, setSignDate] = useState(moment());
  const [userName, setUserName] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [userGender, setUserGender] = useState('');
  const [signature, setSignature] = useState('');

  const TermsRef = useRef(null);
  const TitleBoxRef = useRef(null);
  const TitleRef = useRef(null);
  const MemberInfoRef = useRef(null);
  const SubTitleTextRef = useRef(null);
  const IncludeProvisionsRef = useRef([]);
  const IncludeProvisionsTitleRef = useRef([]);
  const IncludeProvisionsTextRef = useRef([]);
  const DefaultProvisionsRef = useRef([]);
  const DefaultProvisionsTitleRef = useRef([]);
  const DefaultProvisionsTextRef = useRef([]);
  const DivisionLineRef = useRef(null);
  const SignWrapperRef = useRef(null);
  const StoreNameRef = useRef(null);

  // 데이터 정리 //
  useEffect(() => {
    if (clickedTerms === undefined) {
      setTitle('');
      setIncludeProvisions([]);
    }
    else {
      setTitle(clickedTerms.title);

      let newIncludeProvisions = [];

      provisions.map((data) => {
        if (clickedTerms.provisionKeys.includes(data.id)) {
          newIncludeProvisions.push(data);
        }
      })

      setIncludeProvisions(newIncludeProvisions);
    }
  }, [clickedTerms])

  useEffect(() => {
    if (termsAgreementId === undefined) {
      setIsCompleteAgreement(false);
      return;
    }
    else {
      let requestData = {
        termsAgreementId: termsAgreementId
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('terms/agreement', 'POST', requestData);
          
          if (responseData.status === 200) {
            setSignDate(moment(responseData.data.createdDate));
            setUserName(responseData.data.name);
            setUserPhone(responseData.data.phone);
            setUserGender(responseData.data.gender === 'male' ? '남성' : '여성');
            // setSignature(responseData.data.signature);
            setIsCompleteAgreement(true);

            let signature = responseData.data.signature;

            convertImageToBase64(signature)
              .then((base64Image) => {
                setSignature(base64Image);
              })
              .catch((error) => {
                console.error('Error converting image to Base64', error);
              });

          }
          else setIsCompleteAgreement(false);
        }
      )();
    }
  }, [termsAgreementId])


  // 이미지 Base64로 변환하는 함수
  const convertImageToBase64 = (imageUrl) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';  // CORS 설정
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = reject;
      img.src = imageUrl;
    });
  };

  // 전화번호 뷰 정리 //
  function selectPhoneView(phone) {
    if (phone === '') return '없음';
    else {
      return phone[0]
        + phone[1]
        + phone[2]
        + '-'
        + phone[3]
        + phone[4]
        + phone[5]
        + phone[6]
        + '-' +
        + phone[7]
        + phone[8]
        + phone[9]
        + phone[10];
    }
  }

  // 약관 다운로드 클릭시 //
  function onClickDownloadTerms() {

    // let titleBoxHeight = TitleBoxRef.current?.offsetHeight + 10;
    // let titleHeight = TitleRef.current?.offsetHeight + 10;
    // let memberInfoHeight = MemberInfoRef.current?.offsetHeight;
    // let subTitleTextHeight = SubTitleTextRef.current?.offsetHeight;
    
    // let divisionLineHeight = (DivisionLineRef.current?.offsetHeight) + 30;
    // let signWrapperHeight = SignWrapperRef.current?.offsetHeight;
    // let storeNameHeight = StoreNameRef.current?.offsetHeight;

    // let topHeight = titleBoxHeight + titleHeight + memberInfoHeight + subTitleTextHeight;

    // let standardHeight = 1093;
    // let increase = 1093;
    // let includeProvisionsHeight = 0;
    // let defaultProvisionsHeight = 0;

    // console.log('--------');
    // // console.log('titleBoxHeight : ' + titleBoxHeight);
    // // console.log('titleHeight : ' + titleHeight);
    // // console.log('memberInfoHeight : ' + memberInfoHeight);
    // // console.log('subTitleTextHeight : ' + subTitleTextHeight);

    // // console.log('divisionLineHeight : ' + divisionLineHeight);
    // // console.log('signWrapperHeight : ' + signWrapperHeight);
    // // console.log('storeNameHeight : ' + storeNameHeight);

    // // console.log('topHeight : ' + topHeight);

    // for (let i = 0; i < IncludeProvisionsRef.current?.length; i++) {
    //   console.log(IncludeProvisionsTitleRef.current[i].innerText);
    //   console.log(topHeight + includeProvisionsHeight + IncludeProvisionsRef.current[i].offsetHeight + 24);
    //   console.log(topHeight + includeProvisionsHeight + IncludeProvisionsTitleRef.current[i].offsetHeight + 24);
    //   console.log(standardHeight);

    //   if (topHeight + includeProvisionsHeight + IncludeProvisionsRef.current[i]?.offsetHeight + 24 < standardHeight) {
    //     includeProvisionsHeight += (IncludeProvisionsRef.current[i]?.offsetHeight + 24);
    //   }
    //   else {
    //     if (topHeight + includeProvisionsHeight + IncludeProvisionsTitleRef.current[i].offsetHeight + 24 > standardHeight) {
    //       let totalHeight = topHeight + includeProvisionsHeight + IncludeProvisionsTitleRef.current[i].offsetHeight + 24;

    //       if (totalHeight - standardHeight > (IncludeProvisionsTitleRef.current[i].offsetHeight + 24)) {
    //         standardHeight += increase;
    //         includeProvisionsHeight += (IncludeProvisionsRef.current[i]?.offsetHeight + 24);
    //       }
    //       else {
    //         IncludeProvisionsTitleRef.current[i].style.paddingTop = ((totalHeight - standardHeight) + 'px');
    //         standardHeight += increase;
    //         includeProvisionsHeight += (IncludeProvisionsRef.current[i]?.offsetHeight + 24);
    //       }
    //     }
    //     else {
    //       let text = IncludeProvisionsTextRef.current[i].innerText;
    //       let textList = text.split('\n');
    //       let prevCheckText = '';
    //       let checkText = '';
    //       let index = 0;

    //       for (let j = 0; j < textList.length; j++) {
    //         if (j === 0) checkText += textList[j];
    //         else checkText += '\n' + textList[j];

    //         IncludeProvisionsTextRef.current[i].innerText = checkText;

    //         if (topHeight + includeProvisionsHeight + IncludeProvisionsRef.current[i]?.offsetHeight + 24 > standardHeight) {
    //           console.log(topHeight + includeProvisionsHeight + IncludeProvisionsRef.current[i]?.offsetHeight + 24);
    //           prevCheckText += '\n\n\n' + textList[j];
    //           index = j;
    //           break;
    //         }

    //         if (j === 0) prevCheckText += textList[j];
    //         else prevCheckText += '\n' + textList[j];
    //       }

    //       for (let j = index + 1; j < textList.length; j++) {
    //         prevCheckText += '\n' + textList[j];
    //       }

    //       IncludeProvisionsTextRef.current[i].innerText = prevCheckText;
    //       includeProvisionsHeight += (IncludeProvisionsRef.current[i]?.offsetHeight + 24);
    //       standardHeight += increase;
    //     } 
    //   }
    // }

    // // if (DefaultProvisionsRef.current.length > 0) {
    // //   defaultProvisionsHeight += 24;
    // // }

    // for (let i = 0; i < DefaultProvisionsRef.current?.length; i++) {
    //   if (topHeight + includeProvisionsHeight + defaultProvisionsHeight + DefaultProvisionsRef.current[i]?.offsetHeight + 24 < standardHeight) {
    //     defaultProvisionsHeight += (DefaultProvisionsRef.current[i]?.offsetHeight + 24);
    //   }
    //   else {
    //     if (topHeight + includeProvisionsHeight + defaultProvisionsHeight + DefaultProvisionsTitleRef.current[i].offsetHeight + 24 > standardHeight) {
    //       let totalHeight = topHeight + includeProvisionsHeight + defaultProvisionsHeight + DefaultProvisionsTitleRef.current[i].offsetHeight + 24;

    //       if (totalHeight - standardHeight > (DefaultProvisionsTitleRef.current[i].offsetHeight + 24)) {
    //         standardHeight += increase;
    //         defaultProvisionsHeight += (DefaultProvisionsRef.current[i]?.offsetHeight + 24);
    //       }
    //       else {
    //         DefaultProvisionsTitleRef.current[i].style.paddingTop = ((totalHeight - standardHeight) + 'px');
    //         standardHeight += increase;
    //         defaultProvisionsHeight += (DefaultProvisionsRef.current[i]?.offsetHeight + 24);
    //       }
    //     }
    //     else {
    //       let text = DefaultProvisionsTextRef.current[i].innerText;
    //       let textList = text.split('\n');
    //       let prevCheckText = '';
    //       let checkText = '';
    //       let index = 0;

    //       for (let j = 0; j < textList.length; j++) {
    //         if (j === 0) checkText += textList[j];
    //         else checkText += '\n' + textList[j];

    //         DefaultProvisionsTextRef.current[i].innerText = checkText;
    //         // console.log(DefaultProvisionsTextRef.current[i].innerText);
    //         // console.log(DefaultProvisionsRef.current[i]?.offsetHeight);
    //         // console.log(topHeight + includeProvisionsHeight + defaultProvisionsHeight + DefaultProvisionsRef.current[i]?.offsetHeight + 24);
    //         // console.log(standardHeight);

    //         if (topHeight + includeProvisionsHeight + defaultProvisionsHeight + DefaultProvisionsRef.current[i]?.offsetHeight + 24 >= standardHeight) {
    //           prevCheckText += '\n\n\n\n' + textList[j];
    //           index = j;
    //           break;
    //         }

    //         if (j === 0) prevCheckText += textList[j];
    //         else prevCheckText += '\n' + textList[j];
    //       }

    //       for (let j = index + 1; j < textList.length; j++) {
    //         prevCheckText += '\n' + textList[j];
    //       }

    //       DefaultProvisionsTextRef.current[i].innerText = prevCheckText;
    //       defaultProvisionsHeight += (DefaultProvisionsRef.current[i]?.offsetHeight + 24);
    //       standardHeight += increase;
    //     } 
    //   }
    // }
    console.log(TermsRef.current.offsetHeight);
    let totalHeight = TermsRef.current?.offsetHeight;
    let fileName = signDate.format('YY.MM.DD') + ' ' + storeName + '-' + title + '(' + userName + ').pdf';

    let pageBreakMode = [];

    if (totalHeight >= 1093) pageBreakMode = ['avoid-all', 'css', 'legacy'];

    // html2pdf 옵션 설정
    const options = {
      margin: [4, 4, 4, 4], // 상, 좌, 하, 우 여백 설정
      filename: fileName, // 파일 이름
      image: { type: 'jpeg', quality: 1 }, // 이미지 품질 설정
      html2canvas: { scale: 2, dpi: 300, letterRendering: true }, // HTML 캔버스를 300dpi로 렌더링
      jsPDF: {
        unit: 'mm',  // 단위 설정 (mm)
        format: 'a4', // 페이지 크기 설정 (A4)
        // format: 'letter',
        orientation: 'portrait', // 세로 모드 (landscape로 설정하면 가로)
        // autoPaging: true, // 자동 페이지 넘기기
      },
      pagebreak: { mode: pageBreakMode }
    };
    
    html2pdf()
      .from(TermsRef.current)
      .set(options)
      .save();
  }

  return (
    <Background>
      <Modal>
        <InfoWrapper>
          <TopLineWrapper>
            <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' hover='default'>약관 확인하기</Text>
            <TopLineButtonWrapper>
              <DownloadBox onClick={onClickDownloadTerms}>
                <IconImage width={21} height={21} src={TermsDownloadIcon}/>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' hover='pointer'>약관 다운로드</Text>
              </DownloadBox>
              <DeleteButton onClick={onClickExit}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' hover='pointer'>닫기</Text>
              </DeleteButton>
            </TopLineButtonWrapper>
          </TopLineWrapper>
          <ComponentView>
            <TermsView>

              <TermsScroll>
                <TermsWrapper ref={TermsRef}>
                  <TermsTitleBoxWrapper ref={TitleBoxRef}>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' hover='default'>{title}</Text>
                  </TermsTitleBoxWrapper>
                  <TermsTitle ref={TitleRef}>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' hover='default'>[센터 이름] 회원가입 계약서</Text>
                  </TermsTitle>

                  <TermsInfoWrapper>
                    <TotalMemberInfoWrapper ref={MemberInfoRef}>
                      <DivisionLine style={{ margin: '10px 0px 14px 0px', padding: '0px' }} width='100%' backgroundColor='#F9F9F9' />
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>회원 정보</Text>
                      <MemberInfoWrapper>
                        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>이름</Text>
                        {
                          isCompleteAgreement ?
                            <Text style={{marginRight: '16px'}} fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>{' : ' + userName}</Text>
                            :
                            <MemberInfoBox />
                        }
                        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>성별</Text>
                        {
                          isCompleteAgreement ?
                            <Text style={{marginRight: '16px'}} fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>{' : ' + userGender}</Text>
                            :
                            <MemberInfoBox />
                        }
                        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>연락처</Text>
                        {
                          isCompleteAgreement ?
                            <Text style={{marginRight: '16px'}} fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>{' : ' + selectPhoneView(userPhone)}</Text>
                            :
                            <MemberInfoBox />
                        }
                      </MemberInfoWrapper>

                      <DivisionLine style={{ margin: '10px 0px 14px 0px', padding: '0px' }} width='100%' backgroundColor='#F9F9F9' />
                    </TotalMemberInfoWrapper>
                  
                    <ProvisionInfoWrapper>
                      <Text ref={SubTitleTextRef} fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>이용약관</Text>
                      {
                        includeProvisions.map((data, index) => (
                          <ProvisionInfoWrapper 
                            key={'IncludeProvision-' + index}
                            ref={(element) => (IncludeProvisionsRef.current[index] = element)}>
                            <Text ref={(element) => (IncludeProvisionsTitleRef.current[index] = element)} fontFamily='NotoSansKR-Medium' fontSize={14} color='#595959' hover='default'>{data.title}</Text>
                            <PreText ref={(element) => (IncludeProvisionsTextRef.current[index] = element)} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>{data.text}</PreText>
                          </ProvisionInfoWrapper>
                        ))
                      }
                      {
                        defaultProvisions.map((data, index) => (
                          <ProvisionInfoWrapper 
                            key={'DefaultProvision-' + index}
                            ref={(element) => (DefaultProvisionsRef.current[index] = element)}>
                            <Text ref={(element) => (DefaultProvisionsTitleRef.current[index] = element)} fontFamily='NotoSansKR-Medium' fontSize={14} color='#595959' hover='default'>{data.title}</Text>
                            <PreText ref={(element) => (DefaultProvisionsTextRef.current[index] = element)} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>{data.text}</PreText>
                          </ProvisionInfoWrapper>
                        ))
                      }
                    </ProvisionInfoWrapper>

                    <DivisionLine ref={DivisionLineRef} style={{ margin: '20px 0px 30px 0px', padding: '0px' }} width='100%' backgroundColor='#F9F9F9' />
                    
                    <SignWrapper ref={SignWrapperRef}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#595959' hover='default'>{signDate.format('YYYY년 MM월 DD일') + ' [서명 날짜]'}</Text>
                      <SignBoxWrapper>
                        {
                          isCompleteAgreement ?
                            <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#595959' hover='default'>{userName}</Text>
                            :
                            <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#595959' hover='default'>회원 이름</Text>
                        }
                        <SignBox>
                          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#595959' hover='default'>서명</Text>
                          {
                            isCompleteAgreement &&
                              <SignatureBoxWrapper>
                                <SignatureBox src={signature}/>
                              </SignatureBoxWrapper>
                          }
                        </SignBox>
                      </SignBoxWrapper>
                    </SignWrapper>
                    <StoreNameWrapper ref={StoreNameRef}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#595959' hover='default'>{'[' + storeName + ']'}</Text>
                    </StoreNameWrapper>
                  </TermsInfoWrapper>
                </TermsWrapper>
              </TermsScroll>
            </TermsView>
          </ComponentView>
        </InfoWrapper>
      </Modal>
    </Background>
  )
}

const Background = styled.div`
  position: fixed;
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
`;

const Modal = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 1132px;
  min-height: 320px;
  height: 86%;

  border-radius: 10px;
  background-color: #FFFFFF;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: calc(100% - 40px);
  height: calc(100% - 20px);
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TopLineButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 15px;
`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 62px;
  height: 40px;

  border-radius: 10px;
  background-color: #CACACA;

  :hover {
    cursor: pointer;
  }
`;

const DownloadBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 16px;

  height: 40px;

  gap: 10px;

  border-radius: 10px;
  background-color: #CACACA;

  :hover {
    cursor: pointer;
  }
`;

const ComponentView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 20px;

  width: 100%;
  height: calc(100% - 60px);
`;

const TermsView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 20px 10px 20px 10px;

  // width: 756px;
  width: 100%;
  height: calc(100% - 40px);

  border-radius: 10px;
  background-color: #F9F9F9;
`;

const TermsScroll = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 752px;
  width: 100%;
  height: 100%;

  overflow-x: hidden;
  overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #DFDFDF;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const TermsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 10px 10px;

  width: 97%;

  background-color: #FFFFFF;
`;

const TermsTitleBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: calc(100% - 4px);
  height: 36px;

  border: 2px dotted #000000;
  border-radius: 5px;
  background-color: #F9F9F9;
`;

const TermsTitle = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 8px 10px;
  margin-top: 10px;

  width: calc(100% - 20px);

  background-color: #F9F9F9;
`;

const TermsInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 0px 10px;

  width: calc(100% - 20px);
`;

const TotalMemberInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const MemberInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 18px;

  width: 100%;

  gap: 4px;
`;

const MemberInfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 86px;
  height: 16px;

  border: 2px dotted #000000;
  background-color: #FFFFFF;
`;

const DivisionLine = styled.div`
  width: ${props => props.width};
  height: 2px;

  background-color: ${props => props.backgroundColor};
`;

const ProvisionInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  flex-wrap: wrap;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 24px;
`;

const SignWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const SignBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 20px;
`;

const SignBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 146px;
  height: 36px;

  border: 1px solid #595959;
  border-radius: 5px;
  background-color: #FFFFFF;
`;

const SignatureBoxWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 146px;
  height: 60px;
`;

const SignatureBox = styled.img`
  width: 100%;
  height: 100%;
`;

const StoreNameWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 20px 0px 60px 0px;
  // margin: 20px 0px 60px 0px;

  width: 100%;
`;

const IconImage = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const PreText = styled.pre`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  margin: 0 0 0 0;

  white-space: pre-wrap;

  color: ${props => props.color};

  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  margin: 0 0 0 0;

  color: ${props => props.color};

  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;