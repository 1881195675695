import { React, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

import TimeDivisionLine from '../../../Component/MainComponent/TimeDivisionLine.js';

//modal
// import ClassDetailModal from '../ClassModel/ClassDetailModalModel/ClassDetailModal';
// import ReserveModal from '../ClassModel/ReserveModalModel/ReserveModal';

import ClassDetailModal from './ClassManageModal/ClassDetailModal.js';
import ReservationHistoryModal from './ClassManageModal/ReservationHistoryModal.js';
import MemberReservationModal from './ClassManageModal/MemberReservationModal.js';

// import ClassInfoBox from '../ClassModel/ClassInfoBox';
import ClassInfoBox from './ClassInfoBox';

import EmptySign from '../../../image/EmptySign.png';

import FetchModule from '../../Share/Network/FetchModule';
import NewMemberReservationModal from './ClassManageModal/NewMemberReservationModal.js';

const DailyClass = forwardRef((props, ref) => {
	const { 
    today, 
    useScheduleData, 
    reserveData, 
    reserveDataReLoad, 
    onClickScheduleDetail,
    setReserveDataReLoad,
    openReserveLockModal } = props;

	const [index, setIndex] = useState(-1);

	const [detailModal, setDetailModal] = useState(false);
  const [memberReservationModal, setMemberReservationModal] = useState(false);
	const [reservationHistoryModal, setReservationHistoryModal] = useState(false);


	// const [useScheduleData, setUseScheduleData] = useState([]);

	const TopLevelRef = useRef();

	useImperativeHandle(ref, () => ({
		visible: () => {
			TopLevelRef.current.style.opacity = 1;
			TopLevelRef.current.style.zIndex = 1;
		},
		unVisible: () => {
			TopLevelRef.current.style.opacity = 0;
			TopLevelRef.current.style.zIndex = -3;
		}
	}));


	return (
    <TopLevelWrapper ref={TopLevelRef}>
      {/* {detailModal && <ClassDetailModal data={data[index]} cancel={() => setDetailModal(false)} classDate={today.clone().format('YYYY.MM.DD')} />}
      {reserveModal && <ReserveModal data={data[index]} cancel={() => setReserveModal(false)} />} */}
      { detailModal && <ClassDetailModal data={useScheduleData[index]} date={today} cancel={() => setDetailModal(false)}/>}
      { memberReservationModal && <MemberReservationModal data={reserveData[index]} scheduleData={useScheduleData[index]} reserveDataReLoad={reserveDataReLoad} setReserveDataReLoad={setReserveDataReLoad} cancel={() => setMemberReservationModal(false)} historyOpen={() => setReservationHistoryModal(true)}/>}
      { reservationHistoryModal && <ReservationHistoryModal data={reserveData[index]} scheduleData={useScheduleData[index]} cancel={() => setReservationHistoryModal(false)} startFn={() => setMemberReservationModal(false)} closeFn={() => setMemberReservationModal(true)}/> }
      { memberReservationModal && <NewMemberReservationModal data={useScheduleData[index]}  scheduleData={useScheduleData[index]} reserveDataReLoad={reserveDataReLoad} setReserveDataReLoad={setReserveDataReLoad} cancel={() => setMemberReservationModal(false)} historyOpen={() => setReservationHistoryModal(true)}/> }
      {
        useScheduleData.length !== 0 ?
        <ScrollAndBox>
        <TimeDivisionLineWrapper>
          {
            useScheduleData.map((datas, index) => (
              <TimeDivisionLine key={index} scheduleData={datas} isMain={false}/>
            ))
          }
        </TimeDivisionLineWrapper>
        <ClassInfoBoxWrapper>
          {
            useScheduleData.map((data, index) => (
              data.classDate === today.clone().format('YYYY.MM.DD') ?
                <ClassInfoBox
                  key={index}
                  data={data}
                  // reserveData={reserveData[index]}
                  reserveData={reserveData}
                  clickFn={() => setIndex(index)}
                  onClickScheduleDetail={() => onClickScheduleDetail(data.scheduleId, data)}
                  openDetailModal={() => setDetailModal(true)}
                  openReservationModal={() => setMemberReservationModal(true)}
                  openReserveLockModal={() => openReserveLockModal(index)}/>
                :
                null
            ))
          }
        </ClassInfoBoxWrapper>
      </ScrollAndBox>
      :
      <EmptyClassInfoBox>
        <EmptyImage src={EmptySign}/>
        <EmptyText>수업이 존재하지 않습니다</EmptyText>
      </EmptyClassInfoBox>
      }
    </TopLevelWrapper>
  )
})

export default DailyClass;

const TopLevelWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 1020px;
  // height: 449px;
  height: 686px;

  padding-top: 28px;
  padding-bottom: 28px;

  // margin-top: 16px;
  margin-bottom: 96px;

  // opacity: 0;
  // z-index: -3;

  border-radius: 30px;
  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  background-color: #FFFFFF;
  
  transition: all 0.3s ease-in-out;
`;

const ScrollAndBox = styled.div`
	display: flex;
	flex-direction: row;

	overflow-y: scroll;
	overflow-x:hidden;

	// width: 1020px;
	// height: 448px;
  height: 686px;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		// height: 92px;
		background-color: #DADADA;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
		// height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}
`;

const TimeDivisionLineWrapper = styled.div`
	display: flex;
	flex-direction: column;

	width:50px;
`;

const ClassInfoBoxWrapper = styled.div`
	display: flex;
	flex-direction: column;

	gap:8px;
	margin-right: 4px;

	width:913px;
	heigth:448px;
`;

const EmptyClassInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const EmptyImage = styled.img`
  width: 148px;
  height: 62px;
`;

const EmptyText = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #CBCBCB;

  margin: 8px 0 0 0;

  :hover {
    cursor: default;
  }
`;