import React, { useState, useEffect } from "react";
import styled from "styled-components";

// Image Import //
// import PlusImage from '../../image/PlusButton.png';
import TrashImage from '../../image/TrashIcon.png';
// import AdditionExerciseButtonImage from '../../image/AdditionExerciseButton.png';
import YoutubeButtonImage from '../../image/YoutubeButtonImage.png';
import YoutubeVideoURLInput from "../../Component/NoteComponent/YoutubeVideoURLInput";
import NoteTextArea from "../../Component/NoteComponent/NoteTextArea";
import SingleAddMovementBox from "./SingleAddMovementBox";
import WodTitleInputBox from "../Component/WodTitleInputBox";
// import LockingIcon from '../../image/Lock.png';
// import UnLockingIcon from '../../image/UnLock.png';
import URLIcon from '../../image/WhiteUrlIcon.png';
// Image Import //

export default function TodayWodBox(props) {
  const { 
    sectionData, 
    movements,
    nowSection,
    nowScaleSection,
    recentlySearchHistory,
    setRecentlySearchHistory,
    refreshTotalData,
    onClickURL
  } = props;

  const [isNeedMainProgramCheckURL, setIsNeedMainProgramCheckURL] = useState(false);
  const [isNeedNoteCheckURL, setIsNeedNoteCheckURL] = useState(false);
  const [isClickedAdditionLink, setIsClickedAdditionLink] = useState(false);
  const [linkValue, setLinkValue] = useState('');

  // 메인 와드 입력값에서 URL 골라내기 //
  useEffect(() => {
    if (!isNeedMainProgramCheckURL) return;

    // console.log(sectionData);
    let newContent = sectionData.mainProgram;
    let newUrls = sectionData.mainProgramURL.slice();

    while (true) {
      let url = collectURL(newContent);

      if (url === undefined || url === '') break;
      else {
        console.log(url === undefined);
        console.log(url);
        
        let isYoutube = true;

        if (!url.includes('https://youtu.be/') && !url.includes('https://www.youtube.com/watch?v=') && !url.includes('https://youtube.com/shorts/')) {
          isYoutube = false;
        }
        else if (url.includes('https://youtu.be/')
          && (url.split('https://youtu.be/')[0] !== '' || url.split('https://youtu.be/')[1] === '')) {
          isYoutube = false;
        }
        else if (url.includes('https://www.youtube.com/watch?v=')
          && (url.split('https://www.youtube.com/watch?v=')[0] !== '' || url.split('https://www.youtube.com/watch?v=')[1] === '')) {
          isYoutube = false;
        }

        newContent = newContent.replace(url, '');
        
        // 유튜브 URL인 경우 영상 추가로 전환 //
        if (isYoutube) {
          if (sectionData.youtubeLink === '') {
            sectionData.youtubeLink = url;
            sectionData.isViewVideo = true;
          }
          else {
            let youtubeURLs = sectionData.youtubeLinks.slice();

            youtubeURLs.push(url);

            sectionData.youtubeLinks = youtubeURLs;
          }
        }
        // 유튜브 URL이 아닌경우 링크 추가 //
        else newUrls.push(url.replaceAll(',', ''));
      }
    }

    sectionData.mainProgram = newContent;
    sectionData.mainProgramURL = newUrls;

    setIsNeedMainProgramCheckURL(false);
  }, [sectionData, isNeedMainProgramCheckURL])

  // 노트 입력값에서 URL 골라내기 //
  useEffect(() => {
    if (!isNeedNoteCheckURL) return;

    // console.log(sectionData);
    let newContent = sectionData.mainNote;
    let newUrls = sectionData.mainNoteURL.slice();

    while (true) {
      let url = collectURL(newContent);

      if (url === undefined || url === '') break;
      else {
        newContent = newContent.replace(url, '');
        newUrls.push(url.replaceAll(',', ''));
      }
    }

    sectionData.mainNote = newContent;
    sectionData.mainNoteURL = newUrls;

    setIsNeedNoteCheckURL(false);
  }, [sectionData, isNeedNoteCheckURL])

  // Total Video Wrapper Width 계산 //
  function onCalcTranslateWidth(data) {
    if (data.isViewVideo) return '100%';
    else if (data.isClickedYoutubeButton) return `calc(100% - 2px)`;
    else return '100px';
  }

  // Video버튼 Width 계산 //
  function onCalcButtonWidth(data) {
    if (data.isClickedYoutubeButton || data.isViewVideo) return '0px';
    else return '42px';
  }

  // VideoURL 입력창 Width 계산 //
  function onCalcInputWidth(data) {
    // console.log(data);
    if (data.isViewVideo) return '0px';
    // else if (data.isClickedYoutubeButton) return '451px';
    else if (data.isClickedYoutubeButton) return '86%';
    else return '0px';
  }

  // Video버튼과 입력창 높이 계산 //
  function onCalcButtonAndInputHeight(data) {
    if (data.isViewVideo) return '0px';
    else return '20px';
  }

  // Video View Width 계산 //
  function onCalcVideoViewWidth(isViewVideo) {
    if (isViewVideo) return '100%';
    else return '0px';
  }

  // Video View Height 계산 //
  function onCalcVideoViewHeight(isViewVideo) {
    if (isViewVideo) return '100%';
    else return '0px';
  }

  // Custom와드 제목 //
  function decideCustomWodTitleExplanation() {
    if (nowScaleSection === undefined) return `Custom ` + String.fromCharCode(nowSection + 65) + ` 세션 제목을 정해주세요`;
    else return `Custom ` + String.fromCharCode(nowSection + 65) + '-' + (nowScaleSection + 2) + ` 세션 제목을 정해주세요`;
  }

  // Section에 Youtube링크 버튼을 클릭 시 //
  function onClickYoutubeButton(data) {
    if (!data.isClickedYoutubeButton) {
      data.isClickedYoutubeButton = true;
      refreshTotalData();
    }
  }

  // Sectuin Youtube Link 입력시 //
  function onChangeYoutbeLink(value) {
    sectionData.youtubeLink = value;

    let url = value;
    let isFail = false;

    if (!url.includes('https://youtu.be/') && !url.includes('https://www.youtube.com/watch?v=') && !url.includes('https://youtube.com/shorts/')) {
      isFail = true;
    }
    else if (url.includes('https://youtu.be/')
      && (url.split('https://youtu.be/')[0] !== '' || url.split('https://youtu.be/')[1] === '')) {
      isFail = true;
    }
    else if (url.includes('https://www.youtube.com/watch?v=')
      && (url.split('https://www.youtube.com/watch?v=')[0] !== '' || url.split('https://www.youtube.com/watch?v=')[1] === '')) {
      isFail = true;
    }


    if (!isFail) sectionData.isViewVideo = true;

    refreshTotalData();
  }

  // Section Youtube Link에서 엔터 입력시 //
  function onPressEnterInYoutubeLinkInput() {
    let url = sectionData.youtubeLink;

    if (!url.includes('https://youtu.be/') && !url.includes('https://www.youtube.com/watch?v=') && !url.includes('https://youtube.com/shorts/')) {
      alert('올바른 영상링크를 기입해주세요');
      return;
    }
    else if (url.includes('https://youtu.be/')
      && (url.split('https://youtu.be/')[0] !== '' || url.split('https://youtu.be/')[1] === '')) {
      alert('올바른 영상링크를 기입해주세요');
      return;
    }
    else if (url.includes('https://www.youtube.com/watch?v='
      && (url.split('https://www.youtube.com/watch?v=')[0] !== '' || url.split('https://www.youtube.com/watch?v=')[1] === ''))) {
      alert('올바른 영상링크를 기입해주세요');
      return;
    }

    sectionData.isViewVideo = true;

    refreshTotalData();
  }

  // 영상 삭제 버튼 클릭시 //
  function onClickDeletedVideo(data) {
    data.youtubeLink = '';
    data.isViewVideo = false;

    refreshTotalData();
  }

  // Total Video Wrapper Width 계산 //
  function onCalcAdditionTranslateWidth() {
    if (isClickedAdditionLink) return `calc(100% - 2px)`;
    else return '69px';
  }

  // Video버튼 Width 계산 //
  function onCalcAdditionButtonWidth() {
    // if (data.isClickedYoutubeButton || data.isViewVideo) return '0px';
    // else return '42px';
    if (isClickedAdditionLink) return '0px';
    else return '15px';
  }

  // VideoURL 입력창 Width 계산 //
  function onCalcAdditionInputWidth() {
    // if (isClickedAdditionLink) return '451px';
    if (isClickedAdditionLink) return '86%';
    else return '0px';
  }

  // Video버튼과 입력창 높이 계산 //
  function onCalcAdditionButtonAndInputHeight() {
    return '20px';
  }

  // Sectuin Youtube Link 입력시 //
  function onChangeAdditionYoutbeLink(value) {
    setLinkValue(value);

    let url = value;
    let isFail = false;

    if (!url.includes('https://youtu.be/') && !url.includes('https://www.youtube.com/watch?v=') && !url.includes('https://youtube.com/shorts/')) {
      isFail = true;
    }
    else if (url.includes('https://youtu.be/')
      && (url.split('https://youtu.be/')[0] !== '' || url.split('https://youtu.be/')[1] === '')) {
      isFail = true;
    }
    else if (url.includes('https://www.youtube.com/watch?v=')
      && (url.split('https://www.youtube.com/watch?v=')[0] !== '' || url.split('https://www.youtube.com/watch?v=')[1] === '')) {
      isFail = true;
    }


    if (!isFail) {
      let isExist = false;

      for (let i = 0; i < sectionData.youtubeLinks.length; i++) {
        if (sectionData.youtubeLinks[i].includes(value) || value.includes(sectionData.youtubeLinks[i])) {
          isExist = true;
          break;
        }
      }

      if (!isExist) {
        sectionData.youtubeLinks.push(value);
        setLinkValue('');
        setIsClickedAdditionLink(false);
        refreshTotalData();
      }
    }
  }

  // Section Youtube Link에서 엔터 입력시 //
  function onPressEnterInAdditionYoutubeLinkInput() {
    let url = linkValue;

    if (!url.includes('https://youtu.be/') && !url.includes('https://www.youtube.com/watch?v=') && !url.includes('https://youtube.com/shorts/')) {
      alert('올바른 영상링크를 기입해주세요');
      return;
    }
    else if (url.includes('https://youtu.be/')
      && (url.split('https://youtu.be/')[0] !== '' || url.split('https://youtu.be/')[1] === '')) {
      alert('올바른 영상링크를 기입해주세요');
      return;
    }
    else if (url.includes('https://www.youtube.com/watch?v='
      && (url.split('https://www.youtube.com/watch?v=')[0] !== '' || url.split('https://www.youtube.com/watch?v=')[1] === ''))) {
      alert('올바른 영상링크를 기입해주세요');
      return;
    }

    sectionData.youtubeLinks.push(url);
    setLinkValue('');
    setIsClickedAdditionLink(false);
    refreshTotalData();
  }

  // 영상 삭제 버튼 클릭시 //
  function onClickDeletedAdditionVideo(data, index) {
    let newLinks = data.youtubeLinks.slice();

    newLinks.splice(index, 1);
    data.youtubeLinks = newLinks;
    refreshTotalData();
  }

  // Custom Section Title 변경시 //
  function onChangeCustomSectionTitle(value) {
    sectionData.customTitle = value;

    refreshTotalData();
  }

  // Program 변경시 //
  function onChangeProgram(value) {
    sectionData.mainProgram = value;

    refreshTotalData();
  }

  // Note 변경시 //
  function onChangeNotes(value) {
    sectionData.mainNote = value;

    refreshTotalData();
  }

  // Main Program 붙여넣기나 스페이스바, 엔터키 감지시 URL 체크 //
  function onCheckMainProgramURL() {
    setTimeout(() => {
      setIsNeedMainProgramCheckURL(true);
    }, 100);
  }

  // Note 붙여넣기나 스페이스바, 엔터키 감지시 URL 체크 //
  function onCheckNoteURL() {
    setTimeout(() => {
      setIsNeedNoteCheckURL(true);
    }, 100);
  }

  // 텍스트내에 링크 추출 //
  function collectURL(text) {
    let urlRegex = /(https?:\/\/[^ ]*)/;
    let url = text.match(urlRegex) === null ? '' : text.match(urlRegex)[1];

    return url;
  }

  // Main Program URL 삭제 클릭시 //
  function onClickMainProgramDeleteURL(index) {
    let newURLs = sectionData.mainProgramURL.slice();

    newURLs.splice(index, 1);
    sectionData.mainProgramURL = newURLs;

    refreshTotalData();
  }

  // Note URL 삭제 클릭시 //
  function onClickMainNoteDeleteURL(index) {
    let newURLs = sectionData.mainNoteURL.slice();

    newURLs.splice(index, 1);
    sectionData.mainNoteURL = newURLs;

    refreshTotalData();
  }

  return (
    <TodayWodBoxWrapper>
      <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#666666' hover='default'>오늘의 와드</Text>
      {
        sectionData.tag === 'Custom' &&
        <CustomWodTitleInputBoxWrapper>
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#666666' hover='default'>{decideCustomWodTitleExplanation()}</Text>
          <WodTitleInputBox value={sectionData.customTitle} setValue={onChangeCustomSectionTitle}  placeholder={decideCustomWodTitleExplanation()}/>
        </CustomWodTitleInputBoxWrapper>
      }
      <YoutubeWrapper isExistAdditionLinks={sectionData.youtubeLinks !== undefined && sectionData.youtubeLinks.length !== 0} isViewVideo={sectionData.isViewVideo}>
        <TranslateButton
          isClicked={sectionData.isClickedYoutubeButton}
          isViewVideo={sectionData.isViewVideo}
          width={onCalcTranslateWidth(sectionData)}
          onClick={() => onClickYoutubeButton(sectionData)}>
          {
            !sectionData.isViewVideo && <YoutubeImage src={YoutubeButtonImage} />
          }
          <VideoTextWrapper width={onCalcButtonWidth(sectionData)} height={onCalcButtonAndInputHeight(sectionData)}>
            <Text fontFamily='Poppins-Medium' fontSize={14} color='#FFFFFF' hover='pointer'>VIDEO</Text>
          </VideoTextWrapper>
          <VideoURLWrapper
            isViewVideo={sectionData.isViewVideo}
            width={onCalcInputWidth(sectionData)}
            height={onCalcButtonAndInputHeight(sectionData)}>
            <YoutubeVideoURLInput value={sectionData.youtubeLink} setValue={onChangeYoutbeLink} onPressEnter={onPressEnterInYoutubeLinkInput} />
          </VideoURLWrapper>
          <VideoViewWrapper
            isViewVideo={sectionData.isViewVideo}
            width={onCalcVideoViewWidth(sectionData.isViewVideo)}
            height={onCalcVideoViewHeight(sectionData.isViewVideo)}>
            <iframe
              width="100%"
              height="100%"
              src={(sectionData.youtubeLink !== undefined && sectionData.youtubeLink !== '') ? `https://www.youtube.com/embed/` + sectionData.youtubeLink.replace('https://www.youtube.com/watch?v=', '').replace('https://youtube.com/shorts/', '').replace('https://youtu.be/', '') : ''}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube" />
            <VideoDeleteWrapper onClick={() => onClickDeletedVideo(sectionData)}>
              <TrashIcon src={TrashImage} />
            </VideoDeleteWrapper>
          </VideoViewWrapper>
        </TranslateButton>
      </YoutubeWrapper>
      {
        sectionData.isViewVideo &&
        <AdditionYoutubeWrapper>
          {
            sectionData.youtubeLinks.map((linkData, idx) => (
              <VideoViewWrapper
                key={idx}
                isViewVideo={true}
                style={{ marginBottom: '8px' }}
                width='100%'
                height='278px'>
                <iframe
                  width="100%"
                  height="100%"
                  src={(linkData !== undefined && linkData !== '') ? `https://www.youtube.com/embed/` + linkData.replace('https://www.youtube.com/watch?v=', '').replace('https://youtube.com/shorts/', '').replace('https://youtu.be/', '') : ''}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube" />
                <VideoDeleteWrapper onClick={() => onClickDeletedAdditionVideo(sectionData, idx)}>
                  <TrashIcon src={TrashImage} />
                </VideoDeleteWrapper>
              </VideoViewWrapper>
            ))
          }
          <AdditionTranslateButton
            isClicked={isClickedAdditionLink}
            isViewVideo={true}
            width={onCalcAdditionTranslateWidth(sectionData)}
            onClick={() => setIsClickedAdditionLink(true)}>
            <YoutubeImage src={YoutubeButtonImage} />
            <VideoTextWrapper width={onCalcAdditionButtonWidth(sectionData)} height={onCalcAdditionButtonAndInputHeight(sectionData)}>
              <PlusWrapper>
                <Stick width={14} height={2} radius={2} backgroundColor='#FFFFFF'/>
                <Stick width={2} height={14} radius={2} backgroundColor='#FFFFFF'/>
              </PlusWrapper>
            </VideoTextWrapper>
            <AdditionVideoURLWrapper
              width={onCalcAdditionInputWidth(sectionData)}
              height={onCalcAdditionButtonAndInputHeight(sectionData)}>
              <YoutubeVideoURLInput value={linkValue} setValue={onChangeAdditionYoutbeLink} onPressEnter={onPressEnterInAdditionYoutubeLinkInput} />
            </AdditionVideoURLWrapper>
          </AdditionTranslateButton>
        </AdditionYoutubeWrapper>
      }
      {
        sectionData.tag !== 'SINGLE' &&
        <NoteViewBox style={{ marginTop: '20px' }}>
         <ProgramSectionBox>
           <Text fontFamily='Poppins-Medium' fontSize={14} color='#FFFFFF' hover='default'>Program</Text>
         </ProgramSectionBox>
         <NoteTextAreaWrapper>
           <NoteTextArea 
            value={sectionData.mainProgram} 
            setValue={onChangeProgram} 
            division='main' 
            placeholder='작성하신 운동 방법, 무게 세팅, 동작 수행 시 중요한 점을 작성해 주세요'
            checkURL={onCheckMainProgramURL}/>
         </NoteTextAreaWrapper>
         {
          sectionData.mainProgramURL.map((data, index) => (
            <URLBox key={'MainURL-' + index}>
              <URLWrapper onClick={() => onClickURL(data)}>
                <URLIconBox isLast={sectionData.mainProgramURL.length === (index + 1)} backgroundColor='#969696'>
                  <Icon src={URLIcon} />
                </URLIconBox>
                <URLTextWrapper>
                  <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='pointer'>{data}</Text>
                </URLTextWrapper>
              </URLWrapper>
              <ExitButton onClick={() => onClickMainProgramDeleteURL(index)}>
                <StickWrapper>
                  <Stick width={14} height={2} radius={2} backgroundColor='#BDBDBD'/>
                  <Stick width={2} height={14} radius={2} backgroundColor='#BDBDBD'/>
                </StickWrapper>
              </ExitButton>
            </URLBox>
          ))
         }
       </NoteViewBox>
      }
      {
        sectionData.tag === 'SINGLE' &&
        <SingleWodBox>
          <SingleAddMovementBox
            sectionData={sectionData}
            movements={movements}
            recentlySearchHistory={recentlySearchHistory}
            setRecentlySearchHistory={setRecentlySearchHistory}
            refreshTotalData={refreshTotalData}/>
        </SingleWodBox>
      }
      <NoteViewBox style={{ margin: '20px 0px' }}>
        <NoteSectionBox>
          <Text fontFamily='Poppins-Medium' fontSize={14} color='#FFFFFF' hover='default'>Notes</Text>
        </NoteSectionBox>
        <NoteTextAreaWrapper>
          <NoteTextArea 
            value={sectionData.mainNote} 
            setValue={onChangeNotes} 
            division='main' 
            placeholder='작성하신 운동 방법, 무게 세팅, 동작 수행 시 중요한 점을 작성해 주세요'
            checkURL={onCheckNoteURL}/>
        </NoteTextAreaWrapper>
        {
          sectionData.mainNoteURL.map((data, index) => (
            <URLBox key={'MainURL-' + index}>
              <URLWrapper onClick={() => onClickURL(data)}>
                <URLIconBox isLast={sectionData.mainNoteURL.length === (index + 1)} backgroundColor='#FF8B48'>
                  <Icon src={URLIcon} />
                </URLIconBox>
                <URLTextWrapper>
                  <Text fontFamily='Poppins-Medium' fontSize={14} color='#4F4F4F' hover='pointer'>{data}</Text>
                </URLTextWrapper>
              </URLWrapper>
              <ExitButton onClick={() => onClickMainNoteDeleteURL(index)}>
                <StickWrapper>
                  <Stick width={14} height={2} radius={2} backgroundColor='#BDBDBD'/>
                  <Stick width={2} height={14} radius={2} backgroundColor='#BDBDBD'/>
                </StickWrapper>
              </ExitButton>
            </URLBox>
          ))
         }
      </NoteViewBox>
    </TodayWodBoxWrapper>
  )
}

const TodayWodBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justifyo-content: flex-start;
  align-items: flex-start;

  margin-top: 8px;

  width: 100%;
`;

const CustomWodTitleInputBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  margin-top: 10px;
  margin-bottom: 10px;

  gap: 8px;

  width: 100%;
`;

const YoutubeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  margin-top: 8px;
  // margin-bottom: ${props => (props.isExistAdditionLinks || props.isViewVideo) ? '8px' : '20px'};

  width: 100%;
  height: ${props => props.isViewVideo ? '278px' : '36px'};

  transition: all 0.3s ease-in-out;
`;

const AdditionYoutubeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  margin-bottom: 20px;

  // width: 97.71%;
  width: 100%;
  // height: 36px;

  transition: all 0.3s ease-in-out;
`;

const TranslateButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.width};
  height: ${props => props.isViewVideo ? '100%' : 'calc(100% - 2px)'};

  border: ${props => props.isViewVideo ? `none` : `1px solid #FF3131`};
  border-radius: ${props => props.isViewVideo ? `0px` : `12px`};
  background-color: ${props => props.isClicked ? `#FFFFFF` : `#FF3131`};

  :hover {
    cursor: pointer;
  }

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const AdditionTranslateButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.width};
  height: 36px;

  border: 1px solid #FF3131;
  border-radius: 12px;
  background-color: ${props => props.isClicked ? `#FFFFFF` : `#FF3131`};

  :hover {
    cursor: pointer;
  }

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const YoutubeImage = styled.img`
  margin-right: 8px;

  width: 31px;
  height: 22px;
`;

const VideoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.width};
  height: ${props => props.height};

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const VideoURLWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.width};
  height: ${props => props.height};

  opacity: ${props => props.isViewVideo ? `0` : `1`};
  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const AdditionVideoURLWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.width};
  height: ${props => props.height};

  overflow: hidden;
  
  transition: all 0.3s ease-in-out;
`;

const VideoViewWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;

  width: ${props => props.width};
  height: ${props => props.height};
  
  opacity: ${props => props.isViewVideo ? `1` : `0`};
  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const VideoDeleteWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 16px 16px 0px 0px;

  width: 32px;
  height: 32px;

  border-radius: 32px;
  background-color: rgba(0, 0, 0, 0.50);

  :hover {
    cursor: pointer;
  }
`;

const TrashIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const PlusWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 2px;

  width: 15px;
  height: 15px;
`;

const Stick = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: ${props => props.radius}px;
  // background-color: #FFFFFF;
  background-color: ${props => props.backgroundColor};
`;

const ProgramSectionBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 92px;
  height: 23px;

  border-radius: 4px 0px 4px 0px;
  background-color: #969696;
`;

const NoteSectionBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 71px;
  height: 23px;

  border-radius: 4px 0px 4px 0px;
  background-color: #FF8B48;
`;

const SingleWodBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-left: -15px;

  width: calc(100% + 30px);

  border-bottom: 1px solid #E0E0E0;
  background-color: #FFFFFF;
`;

const NoteViewBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  border: 1px solid #E0E0E0;
  border-radius: 4px;
  background-color: #FFFFFF;
`;

const NoteTextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin: 8px 0px 14px 0px;

  width: 100%;
`;

const URLBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 28px;

  border-top: 1px solid #E0E0E0;

  // background-color: red;
`;

const URLWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: calc(100% - 30px);
  height: 100%;

  gap: 10px;
`;

const URLIconBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 46px;
  height: 100%;

  border-bottom-left-radius: ${props => props.isLast ? '4px' : '0px'};
  background-color: ${props => props.backgroundColor};
`;

const Icon = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const ExitButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 31px;
  height: 100%;

  :hover {
    cursor: pointer;
  }
`;

const StickWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  transform: rotate(45deg);

  :hover {
    cursor: pointer;
  }
`;

const URLTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: calc(100% - 56px);
  height: 20px;

  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;