import { React, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import moment from 'moment';

import SearchInput from '../../../../../View/Message/Model/SearchInput';
import SelectGenderBox from '../../../../../View/Message/Model/SelectGenderBox';
import SelectAbsenceBox from '../../../../../View/Message/Model/SelectAbsenceBox';
import SelectRemainDayBox from '../../../../../View/Message/Model/SelectRemainDayBox';
import SortingModule from '../../../../Share/Normal/SortingModule';
import UserProfile from '../../../../../Component/Normal/UserProfile';

import FetchModule from '../../../../Share/Network/FetchModule';
import SelectHoldingStateBox from '../../../../../Component/MemberComponent/MemberDetail/SelectHoldingStateBox';
import NewDayCountInput from '../../../../../Component/MemberComponent/MemberDetail/NewDayCountInput';
import NewCountInput from '../../../../../Component/MemberComponent/MemberDetail/NewCountInput';
import NewDayReasonInput from '../../../../../Component/MemberComponent/MemberDetail/NewDayReasonInput';
import UpwardCalenderInput from '../../../../../Component/MemberComponent/MemberDetail/NewUpwardCalendarInput';

import NormalSortButtonPath from '../../../../../image/GrayNormalSortButton.png';
import AscSortButtonPath from '../../../../../image/AscSortButton.png';
import DescSortButtonPath from '../../../../../image/DescSortButton.png';
import DefaultProfile from '../../../../../image/DefaultProfile.png';
import CheckImagePath from '../../../../../image/Check.png';
import SearchIconPath from '../../../../../image/WhiteSearchIcon.png';
import SelectStatusBox from '../../../../../Component/MemberComponent/MemberDetail/SelectStatusBox';
import SelectMembershipBox from '../../../../../Component/MemberComponent/MemberDetail/SelectMembershipBox';

export default function ExtendAddModal(props) {
  const { memberData, usageId, membershipName, onClickAdditionButton, onClickExit } = props;

  const [isFirst, setIsFirst] = useState(true);
  const [memberName, setMemberName] = useState('');
  const [memberProfile, setMemberProfile] = useState('');

  const [memberships, setMemberships] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [members, setMembers] = useState([]);
  const [originalSortedMembers, setOriginalSortedMembers] = useState([]);
  const [sortedMembers, setSortedMembers] = useState([]);
  const [notPaginationSortedMembers, setNotPaginationSortedMembers] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const [isClickedValidSorting, setIsClickedValidSorting] = useState(false);
  const [isClickedExpectedSorting, setIsClickedExpectedSorting] = useState(false);

  const [isClickedMaleSorting, setIsClickedMaleSorting] = useState(false);
  const [isClickedFemaleSorting, setIsClickedFemaleSorting] = useState(false);

  const [isClickedHoldingSorting, setIsClickedHoldingSorting] = useState(false);
  const [isClickedMaintainSorting, setIsClickedMaintainSorting] = useState(false);

  const [selectedMemberships, setSelectedMemberships] = useState([]);

  const [startSort, setStartSort] = useState('normal');
  const [endSort, setEndSort] = useState('desc');

  const [startButtonImg, setStartButtonImg] = useState(AscSortButtonPath);
  const [endButtonImg, setEndButtonImg] = useState(AscSortButtonPath);

  const [isClickedCalendar, setIsClickedCalendar] = useState(false);
  const [isClickedAllMemberSelect, setIsClickedAllMemberSelect] = useState(false);
  const [nowPage, setNowPage] = useState(0);
  const [totalPage, setTotalPage] = useState(1);

  const [reason, setReason] = useState('');
  const [extendsDays, setExtendsDays] = useState(0);
  const [extendsCount, setExtendsCount] = useState(0);
  const [startDate, setStartDate] = useState(moment().format('YYYY.MM.DD'));

  const [isClickedAdditionMember, setIsClickedAdditionMemeber] = useState(false);

  // 스크롤 제어 //
  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  // 회원 사진 및 이름 정리 //
  useEffect(() => {
    if (memberData === undefined) {
      setMemberName('');
      setMemberProfile('');
    }
    else {
      setMemberName(memberData.name);
      setMemberProfile(memberData.profile);
    }
  }, [memberData])

  // 회원 및 회원권 정보 API호출 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('member/valid/include_hold', 'GET');

        if (responseData.status === 200) {
          setMemberships(responseData.data.memberships);
          setMemberList(responseData.data.members);
        }
      }
    )();
  }, [])

  // 회원 선택 체크 변수 처리 //
  useEffect(() => {
    let newMembers = memberList.slice();
    let count = 0;
    // newMembers.map((data) => data.isClicked = false);
    newMembers.map((data) => {
      if (data.isClicked) count += 1;
    });

    if (count === newMembers.length) setIsClickedAllMemberSelect(true);
    else setIsClickedAllMemberSelect(false);

    // newMembers.sort((a, b) => b.endDate - .endDate);
    // newMembers.sort((a, b) => moment(b.endDate).isBefore(a.endDate));
    newMembers = SortingModule.dataSort(newMembers, 'endDate', 'desc', 'endDate');

    // console.log(newMembers);
    setMembers(newMembers);
    setOriginalSortedMembers(newMembers);
  }, [memberList])

  // 회원정보 정렬 //
  useEffect(() => {
    if (members.length === 0) setSortedMembers([]);
    else {
      let newMembers = [];

      let filterMembers = [];

      // members.map((data) => {
      //   if (moment(data.endDate).isAfter(moment(startDate))) {
      //     let isHolding = false;

      //     data.holdings.map((holdingData) => {
      //       if (holdingData.)
      //     })
      //   }
      // })

      let standardDate = moment(startDate);

      for (let i = 0; i < members.length; i++) {
        // 연장 적용일 기준 유효회원 뽑기 //
        if (moment(members[i].endDate).isAfter(standardDate)) {
          // 적용일 기준 홀딩 여부 확인 //
          let isHolding = false;

          for (let j = 0; j < members[i].holdings.length; j++) {
            if (moment(members[i].holdings[j].startDate).isBefore(standardDate)
              && moment(members[i].holdings[j].endDate).isAfter(standardDate)) {
                isHolding = true;
                break;
            } 
          }

          members[i].isHolding = isHolding;

          filterMembers.push(members[i]);
        }
      }

      // 유효, 예정 상태 추출 데이터 //
      let statusMembers = [];

      // 상태 둘다 체크되어있거나 둘다 체크하지 않은 경우//
      if ((isClickedValidSorting && isClickedExpectedSorting)
        || (!isClickedValidSorting && !isClickedExpectedSorting)) {
        statusMembers = filterMembers;
      }
      // 유효 회원권만 체크된 경우 //
      else if (isClickedValidSorting) {
        filterMembers.map((data) => {
          if (moment(data.startDate).isBefore(standardDate)
            && moment(data.endDate).isAfter(standardDate)) statusMembers.push(data);
        })
      }
      // 예정 회원권만 체크된 경우 //
      else if (isClickedExpectedSorting) {
        filterMembers.map((data) => {
          if (moment(data.startDate).isAfter(standardDate)
            && moment(data.endDate).isAfter(standardDate)) statusMembers.push(data);
        })
      }

      // 성별구분 추출 데이터 //
      let genderCheckMembers = [];

      // 성별 둘다 체크되어있거나 둘다 체크하지 않은 경우//
      if ((isClickedFemaleSorting && isClickedMaleSorting)
        || (!isClickedFemaleSorting && !isClickedMaleSorting)) {
        genderCheckMembers = statusMembers;
      }
      // 여성만 체크된 경우 //
      else if (isClickedFemaleSorting) {
        statusMembers.map((data) => {
          if (data.gender === 'female') genderCheckMembers.push(data);
        })
      }
      // 남성만 체크된 경우 //
      else if (isClickedMaleSorting) {
        statusMembers.map((data) => {
          if (data.gender === 'male') genderCheckMembers.push(data);
        })
      }

      // 홀딩구분 추출 데이터 //
      let holdingCheckMembers = [];

      // 홀딩, 사용 둘다 체크되어있거나 둘다 체크하지 않은 경우//
      if ((isClickedHoldingSorting && isClickedMaintainSorting)
        || (!isClickedHoldingSorting && !isClickedMaintainSorting)) {
          holdingCheckMembers = genderCheckMembers;
      }
      // 홀딩만 체크된 경우 //
      else if (isClickedHoldingSorting) {
        genderCheckMembers.map((data) => {
          if (data.isHolding) holdingCheckMembers.push(data);
        })
      }
      // 사용만 체크된 경우 //
      else if (isClickedMaintainSorting) {
        genderCheckMembers.map((data) => {
          if (!data.isHolding) holdingCheckMembers.push(data);
        })
      }

      let membershipCheckMembers = [];

      if (selectedMemberships.length === 0) membershipCheckMembers = holdingCheckMembers;
      else {
        holdingCheckMembers.map((data) => {
          for (let i = 0; i < selectedMemberships.length; i++) {
            if (data.membershipName === selectedMemberships[i].membershipName) {
              membershipCheckMembers.push(data);
              break;
            }
          }
        })
      }

      if (startSort === 'asc') membershipCheckMembers = SortingModule.dataSort(membershipCheckMembers, 'startDate', 'asc', 'startDate');
      else if (startSort === 'desc') membershipCheckMembers = SortingModule.dataSort(membershipCheckMembers, 'startDate', 'desc', 'startDate');
      else if (endSort === 'asc') membershipCheckMembers = SortingModule.dataSort(membershipCheckMembers, 'endDate', 'asc', 'endDate');
      else if (endSort === 'desc') membershipCheckMembers = SortingModule.dataSort(membershipCheckMembers, 'endDate', 'desc', 'endDate');

      let nameList = [];
      let phoneList = [];

      for (let i = 0; i < membershipCheckMembers.length; i++) {
        if (membershipCheckMembers[i].name.toLowerCase().includes(searchValue.toLowerCase())) nameList.push(membershipCheckMembers[i]);
        else if (membershipCheckMembers[i].phone.includes(searchValue)) phoneList.push(membershipCheckMembers[i]);
      }

      if (nameList.length === 0 && phoneList.length === 0) {
        for (let i = 0; i < membershipCheckMembers.length; i++) {
          if (match(searchValue.toLowerCase(), membershipCheckMembers[i].name.toLowerCase())) nameList.push(membershipCheckMembers[i]);
        }
      }

      let totalList = nameList.concat(phoneList);

      if (totalList.length > 10) {
        let finalPage = parseInt(totalList.length / 10);

        if (nowPage > finalPage) setNowPage(finalPage);

        setTotalPage(finalPage);
      }
      else {
        setNowPage(0);
        setTotalPage(1);
      }

      // totalList.map((data) => data.isClicked = false);

      // 페이지 분리 //
      if (totalList.length !== 0) {
        if (totalList.length > 10) newMembers = totalList.slice((nowPage * 10), (nowPage * 10) + 10);
        else newMembers = totalList.slice();
      }

      // console.log(newMembers);
      // setOriginalSortedMembers(totalList);
      setSortedMembers(newMembers);
      setNotPaginationSortedMembers(totalList);
      setIsClickedAllMemberSelect(false);
    }
  },
    [
      members,
      searchValue,
      isClickedValidSorting,
      isClickedExpectedSorting,
      isClickedHoldingSorting,
      isClickedMaintainSorting,
      isClickedMaleSorting,
      isClickedFemaleSorting,
      selectedMemberships,
      startSort,
      endSort,
      nowPage,
      startDate,
      match()
    ])

    useEffect(() => {
      if (!isFirst || notPaginationSortedMembers.length === 0) return;
      
      let newDates = notPaginationSortedMembers.slice();

      newDates.map((data) => {
        if (data.usageId === usageId) data.isClicked = true;
        else data.isClicked = false;
      })

      setIsFirst(false);
      setNotPaginationSortedMembers(newDates);
    }, [isFirst, usageId, notPaginationSortedMembers])

  // 시작일 버튼 클릭시 이미지 변경 //
  useEffect(() => {
    if (startSort === 'normal') setStartButtonImg(NormalSortButtonPath);
    else if (startSort === 'asc') setStartButtonImg(AscSortButtonPath);
    else if (startSort === 'desc') setStartButtonImg(DescSortButtonPath);
  }, [startSort])

  // 종료일 버튼 클릭시 이미지 변경 //
  useEffect(() => {
    if (endSort === 'normal') setEndButtonImg(NormalSortButtonPath);
    else if (endSort === 'asc') setEndButtonImg(AscSortButtonPath);
    else if (endSort === 'desc') setEndButtonImg(DescSortButtonPath);
  }, [endSort])

  // 몇명 선택되었는지 텍스트 표출 //
  function decideSelectedCountText() {
    // if (isClickedAllMemberSelect) return originalSortedMembers.length + ' 명';
    // else {
    //   let count = 0;

    //   originalSortedMembers.map((data) => {
    //     if (data.isClicked) count += 1;
    //   })

    //   return count + ' 명';
    // }

    let count = 0;

    originalSortedMembers.map((data) => {
      if (data.isClicked) count += 1;
    })

    return count + ' 명';
  }

  // 입력된 글자가 한글인지 확인 //
  function is_kr(value) {
    if (value === undefined) return false;

    return ((('ㄱ'.charCodeAt() <= value.charCodeAt()) && (value.charCodeAt() <= 'ㅎ'.charCodeAt()))
      || (('가'.charCodeAt() <= value.charCodeAt()) && value.charCodeAt() <= '힣'.charCodeAt()));
  }

  // 한글 글자 유니코드 추출하여 검색 조건 확인 //
  function kr_num(value) {
    return value.charCodeAt() - '가'.charCodeAt();
  }

  // 초성 중성 종성 구분하여 연산 //
  function kr_list(value) {
    var consonant_list = ['ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ'
      , 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'];

    var res = [value];

    var kr_num_s = kr_num(value);

    if (kr_num_s > 0) {
      // 초성 + 중성일 경우 //
      if (kr_num_s % 28 !== 0) {
        res.push(String.fromCharCode(((parseInt(kr_num_s / 28) * 28) + '가'.charCodeAt())));
      }

      res.push(consonant_list[parseInt(kr_num_s / 588)])
    }

    return res;
  }

  // 한글, 영어 구분하여 검색 처리 //
  function eq_kr(value, data) {
    if (is_kr(value) && is_kr(data)) {
      return kr_list(data).includes(value);
    }
    else return value === data;
  }

  // 받침 확인하기 //
  function eq_kr_pos(value, data, nextData) {
    var consonant_list = ['ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ'
      , 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'];

    var kr_pos = [
      [0, 0], [1, 0], [2, 1], [2, 9], [4, 2], [1, 12], [2, 18],
      [7, 3], [8, 5], [1, 0], [2, 6], [3, 7], [4, 9], [5, 16],
      [6, 17], [7, 18], [16, 6], [17, 7], [1, 9], [19, 9], [20, 10],
      [21, 11], [22, 12], [23, 14], [24, 15], [25, 16], [26, 17], [27, 18]
    ]

    if (!(is_kr(value) && is_kr(data) && is_kr(nextData))) return false;
    if (kr_num(value) < 0) return false;

    var s_pos = String.fromCharCode(value.charCodeAt() - kr_pos[kr_num(value) % 28][0])
    var s_pos_consonant = consonant_list[kr_pos[kr_num(value) % 28][1]]

    return (s_pos === data) && (kr_list(nextData).includes(s_pos_consonant));
  }

  // 일치하는 글자가 존재하는지 확인 //
  function match(value, data) {
    if (value === undefined || data === undefined) return false;

    var valueSize = value.length;
    var dataSize = data.length;

    if (dataSize < valueSize) return false;

    for (var i = 0; i < (dataSize - valueSize + 1); i++) {
      var res = true;

      for (var j = 0; j < valueSize; j++) {
        if (j === (valueSize - 1)) {
          if (!eq_kr(value[j], data[i + j])) {
            if (i + j + 1 < dataSize) {
              if (eq_kr_pos(value[j], data[i + j], data[i + j + 1])) {
                break;
              }
            }
            res = false;
            break;
          }
        }
        else {
          if (value[j] !== data[i + j]) {
            res = false;
            break;
          }
        }
      }

      if (res) return true;
    }

    return false;
  }

  // 필터 초기화 클릭시 //
  function onClickResetFilter() {

    let newOriginalSortedMembers = originalSortedMembers.slice();

    newOriginalSortedMembers.map((data) => data.isClicked = false);

    setOriginalSortedMembers(newOriginalSortedMembers);
    setNotPaginationSortedMembers(newOriginalSortedMembers);

    setSearchValue('');
    setIsClickedMaleSorting(false);
    setIsClickedFemaleSorting(false);
    setIsClickedAllMemberSelect(false);
    setStartSort('normal');
    setEndSort('desc');
  }

  // 시작일 정렬 버튼 클릭시 //
  function onClickStartSort() {
    setEndSort('normal');

    if (startSort === 'normal' || startSort === 'desc') setStartSort('asc');
    else if (startSort === 'asc') setStartSort('desc');
  }

  // 종료일 정렬 버튼 클릭시 //
  function onClickEndSort() {
    setStartSort('normal');

    if (endSort === 'normal' || endSort === 'desc') setEndSort('asc');
    else if (endSort === 'asc') setEndSort('desc');
  }

  // 전체선택 클릭시 //
  function onClickAllSelectButton() {
    // let newOriginalSortedMembers = originalSortedMembers.slice();

    // newOriginalSortedMembers.map((data) => data.isClicked = !isClickedAllMemberSelect);

    // setOriginalSortedMembers(newOriginalSortedMembers);
    // setIsClickedAllMemberSelect(!isClickedAllMemberSelect);

    let newNotPaginationSortedMembers = notPaginationSortedMembers.slice();

    newNotPaginationSortedMembers.map((data) => data.isClicked = !isClickedAllMemberSelect);

    setNotPaginationSortedMembers(newNotPaginationSortedMembers);
    setIsClickedAllMemberSelect(!isClickedAllMemberSelect);
  }

  // 페이지네이션 이전버튼 클릭시 //
  function onClickPrevButton() {
    if (nowPage !== 0) {
      setNowPage(nowPage - 1);
    }
  }

  // 페이지네이션 다음버튼 클릭시 //
  function onClickNextButton() {
    if ((nowPage + 1) !== totalPage) {
      setNowPage(nowPage + 1);
    }
  }

  // 회원 선택시 //
  function onClickMemberInfo(data) {
    let newOriginalSortedMembers = originalSortedMembers.slice();
    let index = newOriginalSortedMembers.findIndex(item => item.usageId === data.usageId);

    if (newOriginalSortedMembers[index].isClicked) setIsClickedAllMemberSelect(false);

    newOriginalSortedMembers[index].isClicked = !newOriginalSortedMembers[index].isClicked;

    setOriginalSortedMembers(newOriginalSortedMembers);
  }

  // 회원 선택목록에서 제외버튼 클릭시 //
  function onClickDeleteButton(data) {
    let newOriginalSortedMembers = originalSortedMembers.slice();
    let index = newOriginalSortedMembers.findIndex(item => item.memberId === data.memberId);

    if (newOriginalSortedMembers[index].isClicked) setIsClickedAllMemberSelect(false);

    newOriginalSortedMembers[index].isClicked = !newOriginalSortedMembers[index].isClicked;

    setOriginalSortedMembers(newOriginalSortedMembers);
  }

  // 연장하기 버튼 클릭시 //
  function onClickAddition() {
    let days = extendsDays;

    if (days === 0 && extendsCount === 0) {
      alert('연장 적용일수또는 추가 수강횟수를 설정해주세요!');
      return;
    }

    let usageIds = [];

    originalSortedMembers.map((data) => {
      if (data.isClicked) usageIds.push(data.usageId);
    })

    let requestData = {
      usageIds: usageIds,
      applyDate: moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
      plusTotalMax: extendsCount,
      days: days,
      reason: reason
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('membership/extends', 'POST', requestData);

        if (responseData.status === 200) {
          alert('연장 적용이 완료되었습니다!');
          onClickExit();
        }
      }
    )();
  }

  // 달력 클릭시 //
  function calenderClick() {
  }

  return (
    <TopLevelWrapper>
      <Modal isClickedAdditionMember={isClickedAdditionMember} isClickedCalendar={isClickedCalendar}>
        <ModalInnerWrapper>
          <TopLineWrapper>
            <TitleWrapper>
              <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' hover='default'>회원권 연장</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777' hover='default'>회원권을 연장할 회원을 추가할수 있습니다</Text>
            </TitleWrapper>
            <ButtonWrapper>
              <ExitButton onClick={onClickExit}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#3E4148' hover='pointer'>취소</Text>
              </ExitButton>
              <AdditionButton onClick={onClickAddition}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' hover='pointer'>연장하기</Text>
              </AdditionButton>
            </ButtonWrapper>
          </TopLineWrapper>
          {
            !isClickedAdditionMember &&
            <MemberInfoBox>
              <ProfileBox>
                <UserProfile profile={memberProfile} defaultProfile={DefaultProfile} />
              </ProfileBox>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#595959' hover='default'>{memberName}</Text>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#595959' hover='default'>{membershipName}</Text>
            </MemberInfoBox>
          }
          { !isClickedAdditionMember && <DivisionLine style={{ margin: '17px 0px' }} width='100%'/> }
          <ExtendsDataWrapper isClickedAdditionMember={isClickedAdditionMember}>
            <ExtendsLeftDataWrapper>
              <NewDayCountInput value={extendsDays} setValue={setExtendsDays} />
              <NewCountInput value={extendsCount} setValue={setExtendsCount} />
            </ExtendsLeftDataWrapper>

            <CalendarInputWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#595959' hover='default'>회원권 연장 적용일</Text>
              <UpwardCalenderInput 
                disabled={false} 
                value={startDate} 
                setValue={setStartDate} 
                className='StartCalender'
                clickFn={calenderClick} 
                placeholder='시작일' 
                width={171} 
                isClicked={isClickedCalendar}
                setIsClicked={setIsClickedCalendar}/>
            </CalendarInputWrapper>
          </ExtendsDataWrapper>
          <ExtendsReasonBoxWrapper>
            <NewDayReasonInput value={reason} setValue={setReason} />
          </ExtendsReasonBoxWrapper>

          <DivisionLine style={{ margin: '17px 0px' }} width='100%' />
          { 
            !isClickedAdditionMember &&
            <AdditionMemberButtonWrapper>
              <AdditionMemberButton onClick={() => setIsClickedAdditionMemeber(true)}>
                <SearchIcon width={24} height={24} src={SearchIconPath}/>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' hover='pointer'>연장 회원 추가</Text>
              </AdditionMemberButton>
            </AdditionMemberButtonWrapper>
          }
          {
            isClickedAdditionMember &&
            <AdditionMemberView>
              <SearchInputWrapper>
                <SearchInput value={searchValue} setValue={setSearchValue} placeholder='회원 검색' />
              </SearchInputWrapper>
              <ResetAndSortingButtonWrapper>
                <SortingButtonWrapper>
                  <SelectStatusBox
                    isClickedValidSorting={isClickedValidSorting}
                    isClickedExpectedSorting={isClickedExpectedSorting}
                    setIsClickedValidSorting={setIsClickedValidSorting}
                    setIsClickedExpectedSorting={setIsClickedExpectedSorting}/>

                  <SelectGenderBox
                    isClickedMaleSorting={isClickedMaleSorting}
                    isClickedFemaleSorting={isClickedFemaleSorting}
                    setIsClickedMaleSorting={setIsClickedMaleSorting}
                    setIsClickedFemaleSorting={setIsClickedFemaleSorting} />

                  <SelectHoldingStateBox
                    isClickedHoldingSorting={isClickedHoldingSorting}
                    isClickedMaintainSorting={isClickedMaintainSorting}
                    setIsClickedHoldingSorting={setIsClickedHoldingSorting}
                    setIsClickedMaintainSorting={setIsClickedMaintainSorting}/>

                  <SelectMembershipBox
                    value={selectedMemberships}
                    setValue={setSelectedMemberships}
                    memberships={memberships}/>

                </SortingButtonWrapper>
                <FilterResetButton onClick={onClickResetFilter}>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#3E4148' hover='pointer'>필터 초기화</Text>
                </FilterResetButton>
              </ResetAndSortingButtonWrapper>

              <TotalMemberBoxWrapper>
                <TotalMemberBox>
                  <TotalMemberColumnWrppaer>
                    <MemberCheckBox isClicked={isClickedAllMemberSelect} onClick={onClickAllSelectButton}>
                      {isClickedAllMemberSelect && <CheckImage src={CheckImagePath} />}
                    </MemberCheckBox>
                    <TextWrapper marginLeft={12} width={60}>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>이름</Text>
                    </TextWrapper>
                    <TextWrapper marginLeft={5} width={50}>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>성별</Text>
                    </TextWrapper>
                    <TextWrapper marginLeft={5} width={84}>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>생년월일</Text>
                    </TextWrapper>
                    <TextWrapper marginLeft={5} width={239}>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>회원권</Text>
                    </TextWrapper>
                    <TextWrapper marginLeft={5} width={75}>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>시작일</Text>
                      <SortingButton img={startButtonImg} onClick={() => onClickStartSort()} />
                    </TextWrapper>
                    <TextWrapper marginLeft={5} width={75}>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>종료일</Text>
                      <SortingButton img={endButtonImg} onClick={() => onClickEndSort()} />
                    </TextWrapper>

                    <PaginationWrapper style={{ marginLeft: '28px' }}>
                      <PaginationButton isAvailableClick={nowPage !== 0} onClick={onClickPrevButton}>
                        <LeftPaginationTriangle />
                      </PaginationButton>
                      <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#232323' hover='default'>{(nowPage + 1) + '/' + totalPage}</Text>
                      <PaginationButton isAvailableClick={(nowPage + 1) !== totalPage} onClick={onClickNextButton}>
                        <RightPaginationTriangle />
                      </PaginationButton>
                    </PaginationWrapper>
                  </TotalMemberColumnWrppaer>
                  <TotalMemberListWrapper>
                    {
                      sortedMembers.map((data, index) => (
                        <TotalMemberInfo key={'TotalSendMember-' + index} onClick={() => onClickMemberInfo(data)}>
                          <MemberCheckBox isClicked={data.isClicked}>
                            {data.isClicked && <CheckImage src={CheckImagePath} />}
                          </MemberCheckBox>
                          <TextWrapper marginLeft={12} width={60}>
                            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{data.name}</Text>
                          </TextWrapper>
                          <TextWrapper marginLeft={5} width={50}>
                            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{data.gender === 'male' ? '남성' : '여성'}</Text>
                          </TextWrapper>
                          <TextWrapper marginLeft={5} width={84}>
                            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{data.birth.slice(2, 4) + '.' + data.birth.slice(4, 6) + '.' + data.birth.slice(6, 8)}</Text>
                          </TextWrapper>
                          <TextWrapper marginLeft={5} width={239}>
                            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{data.membershipName}</Text>
                          </TextWrapper>
                          <TextWrapper marginLeft={5} width={75}>
                            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{data.startDate.split('T')[0].replaceAll('-', '.')}</Text>
                          </TextWrapper>
                          <TextWrapper marginLeft={5} width={75}>
                            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{data.endDate.split('T')[0].replaceAll('-', '.')}</Text>
                          </TextWrapper>
                          {/* {
                            (moment(data.endDate).diff(moment(), 'day') >= 0 && moment(data.endDate).diff(moment(), 'day') <= 5) &&
                            <TextWrapper marginLeft={54} width={45}>
                              <Dday>{moment(moment(data.endDate).format('YYYY-MM-DD')).diff(moment(moment().format('YYYY-MM-DD')), 'day') === 0 ? 'D-day' : 'D-' + moment(moment(data.endDate).format('YYYY-MM-DD')).diff(moment(moment().format('YYYY-MM-DD')), 'day')}</Dday>
                            </TextWrapper>
                          } */}
                          {
                            data.isHolding &&
                            <TextWrapper marginLeft={54} width={45}>
                              <Dday>홀딩중</Dday>
                            </TextWrapper>
                          }
                        </TotalMemberInfo>
                      ))
                    }
                  </TotalMemberListWrapper>
                </TotalMemberBox>
                <SelectedMemberBoxWrapper>
                  <SelectedTextWrapper>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#777777' hover='default'>현재</Text>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FF8B48' hover='default'>{decideSelectedCountText()}</Text>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#777777' hover='default'>선택했어요</Text>
                  </SelectedTextWrapper>
                  <DivisionLine width='194px' marginTop={10} />
                  <SelectedMemberBox>
                    <SelectedMemberInfoWrapper>
                      {
                        originalSortedMembers.map((data, index) => (
                          data.isClicked &&
                          <SelectedMemberInfo key={'SelectedSortMember-' + index}>
                            <ProfileWrapper isFirst={index === 0} isLast={originalSortedMembers.length - 1 === index}>
                              <UserProfile profile={data.profile} defaultProfile={DefaultProfile} />
                            </ProfileWrapper>
                            <TextWrapper marginLeft={10} width={45}>
                              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#777777' hover='default'>{data.name}</Text>
                            </TextWrapper>
                            <TextWrapper marginLeft={4} width={98}>
                              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#777777' hover='default'>{data.phone === '' ? '없음' : data.phone.slice(0, 3) + '-' + data.phone.slice(3, 7) + '-' + data.phone.slice(7, 11)}</Text>
                            </TextWrapper>
                            <DeleteButtonWrapper onClick={() => onClickDeleteButton(data)}>
                              <DeleteButton>
                                <Stick width={10} height={2} radius={0} backgroundColor='#969696' />
                                <Stick width={2} height={10} radius={0} backgroundColor='#969696' />
                              </DeleteButton>
                            </DeleteButtonWrapper>
                          </SelectedMemberInfo>
                        ))
                      }
                    </SelectedMemberInfoWrapper>
                  </SelectedMemberBox>
                </SelectedMemberBoxWrapper>
              </TotalMemberBoxWrapper>
            </AdditionMemberView>
          }
        </ModalInnerWrapper>
      </Modal>
    </TopLevelWrapper>
  )
}

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const Modal = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 20px 0px;

  width: 1053px;
  // height: 662px;
  // height: ${props => props.isClickedAdditionMember ? `889px` : `457px`};
  height: ${props => props.isClickedAdditionMember ? `869px` : `417px`};

  overflow: ${props => props.isClickedCalendar ? `none` : `hidden`};

  border-radius: 10px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FDFCFB;

  transition: all 0.3s ease-in-out;
`;

const ModalInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: calc(100% - 40px);
  // height: 626px;
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 4px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 10px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 62px;
  height: 40px;

  border-radius: 10px;
  background-color: #F5F6F8;

  :hover {
    cursor: pointer;
  }
`;

const AdditionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 10px;
  background-color: #5BD98A;

  :hover {
    cursor: pointer;
  }
`;

const AdditionMemberView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const SearchInputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // margin-top: 20px;

  width: 100%;
`;

const ResetAndSortingButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 12px;

  width: 100%;
`;

const SortingButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;
`;

const FilterResetButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 83px;
  height: 20px;

  border: 1px solid #DBDDE4;
  border-radius: 5px;
  background-color: #F5F6F8;

  :hover {
    cursor: pointer;
  }
`;

const TotalMemberBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 20px;

  width: 1013px;
  // height: 473px;
  height: 427px;
`;

const TotalMemberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 752px;
  height: 430px;
`;

const TotalMemberColumnWrppaer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  // padding: 0px 6px;
  padding-left: 6px;

  width: calc(100% - 6px);
`;

const MemberCheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;

  border: ${props => props.isClicked ? `1px solid #5BD98A` : `1px solid #969696`};
  border-radius: 5px;
  background-color: ${props => props.isClicked ? `#5BD98A` : `#FFFFFF`};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-left: ${props => props.marginLeft}px;

  width: ${props => props.width}px;
  height: 20px;

  overflow: hidden;
`;

const SortingButton = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  margin-left: 4px;

  width: 20px;
  height: 20px;

  background: url(${props => props.img}) no-repeat center center;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const TotalMemberListWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 15px;

  width: 100%;
  min-height: 395px;
  max-height: 395px;

  gap: 5px;
`;

const TotalMemberInfo = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 6px;

  width: calc(100% - 12px);
  height: 35px;

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border-radius: 10px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const PaginationTotalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 16px;

  width: 100%;
`;

const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const PaginationButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  border-radius: 4px;
  background-color: ${props => props.isAvailableClick ? '#FF8B48' : '#C9C9C9'};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const LeftPaginationTriangle = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 7px 5px 0px;
  border-color: transparent #FFFFFF transparent transparent;
`;

const RightPaginationTriangle = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0px 5px 7px;
  border-color: transparent transparent transparent #FFFFFF;
`;

const SelectedMemberBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 241px;
  height: 378px;
`;

const SelectedTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const SelectedMemberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 10px;
  
  width: 241px;
  height: 333px;

  overflow-x: hidden;
  overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #DFDFDF;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const SelectedMemberInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 234px;

  border-radius: 10px;
  background-color: #FFFFFF;
`;

const SelectedMemberInfo = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 37px;
`;

const ProfileWrapper = styled.div`
  width: 37px;
  height: 37px;

  // border-radius: 4px;
  border-top-left-radius: ${props => props.isFirst ? `4px` : `0px`};
  border-bottom-left-radius: ${props => props.isLast ? `4px` : `0px`};

  overflow: hidden;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  // margin: 10px 0px;

  margin-top: ${props => props.marginTop}px;

  // width: 194px;
  width: ${props => props.width};
  height: 1px;

  border-radius: 2px;
  background-color: #D9D9D9;
`;

const Dday = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 45px;
  height: 20px;

  border-radius: 30px;
  background-color: #FF8B48;

  font-family: NotoSansKR-Regular;
  font-size: 10px;

  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const DeleteButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-left: 10px;

  width: 23px;
  height: 23px;

  :hover {
    cursor: pointer;
  }
`;

const DeleteButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  transform: rotate(45deg);
`;

const Stick = styled.div`
  position: absolute;
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: ${props => props.radius}px;
  background-color: ${props => props.backgroundColor};
`;

const CheckImage = styled.img`
  width: 10px;
  height: 8px;
`;

const MemberInfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  margin-top: 10px;

  width: 100%;

  gap: 10px;
`;

const ExtendsDataWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: ${props => props.isClickedAdditionMember ? `36px` : `0px`};

  width: 100%;
`;

const ExtendsLeftDataWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 30px;
`;

const ExtendsReasonBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 24px;

  width: 100%;
`;

const CalendarInputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  jutify-content: flex-start;
  align-items: center;

  gap: 10px;
`;

const AdditionMemberButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-start;

  width: 100%;
`;

const AdditionMemberButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 12px;
  
  height: 40px;

  gap: 4px;

  border-radius: 10px;
  background-color: #FF8B48;

  :hover {
    cursor: pointer;
  }
`;

const SearchIcon = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const ProfileBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;

  border-radius: 2px;

  overflow: hidden;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }
`;