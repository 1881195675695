import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from 'react-router-dom';
import Lottie from 'lottie-react-web';
import lodash from 'lodash';

import ContentInput from "../Model/ContentInput";

import FetchModule from "../../../Model/Share/Network/FetchModule";

import NotRegistrationCardModal from '../../../Model/Share/Normal/NotRegistrationCardModal';
import SendCompleteModal from "../Model/SendCompleteModal";
import LoadingAnimation from '../../../Animation/LoadingAnimation.json';
import SendMemberSelectModal from "../Model/SendMemberSelectModal";
import UserProfile from "../../../Component/Normal/UserProfile";

import MockUpImage from '../../../image/MockUp.png';
import DefaultProfile from '../../../image/DefaultProfile.png';
import CheckImagePath from '../../../image/Check.png';
import CheckBox from "../Model/CheckBox";
import RegistrationSettingMessageBox from "../Model/RegistrationSettingMessageBox";
import AttendanceClassSettingMessageBox from "../Model/AttendanceClassSettingMessageBox";
import ExpiredSettingMessageBox from "../Model/ExpiredSettingMessageBox";
import AutoMessageEditModal from "../Model/AutoMessageEditModal";
import moment from "moment";
import HistoryContent from "../Model/HistoryContent";

export default function MessageSendPage() {
  const history = useHistory();

  const [isSending, setIsSending] = useState(false);
  const [storeName, setStoreName] = useState('');
  const [content, setContent] = useState('');

  const [isClickedValid, setIsClickedValid] = useState(false);
  const [isClickedInvalid, setIsClickedInvalid] = useState(false);
  const [isRegistrationCard, setIsRegistrationCard] = useState(false);

  const [isHoverMemberSelectButton, setIsHoverMemberSelectButton] = useState(false);

  const [validCount, setValidCount] = useState(0);
  const [invalidCount, setInvalidCount] = useState(0);

  const [totalMembers, setTotalMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);

  const [isProgress, setIsProcess] = useState(false);
  const [isClickedTotalSetting, setIsClickedTotalSetting] = useState(false);

  const [isViewRegistrationCard, setIsViewRegistrationCard] = useState(false);
  const [isViewCompleteSend, setIsViewCompleteSend] = useState(false);

  const [isViewMessageEditModal, setIsViewMessageEditModal] = useState(false);
  const [isClickedSelectedMember, setIsClickedSelectedMember] = useState(false);

  const [selectedData, setSelectedData] = useState(undefined);
  
  const [messageHistory, setMessageHistory] = useState([]);
  const [viewMessageHistory, setViewMessageHistory] = useState([]);
  const [nowPage, setNowPage] = useState(0);
  const [totalPage, setTotalPage] = useState(1);

  const [clickDivision, setClickDivision] = useState('SendMessage');
  // const [clickDivision, setClickDivision] = useState('MessageHistory');
  const [rendering, setRendering] = useState(false);

  const [registration, setRegistration] = useState({
    newRegistration: {
      id: 0,
      storeId: 0,
      division: 'NewRegistration',
      isUseSetting: false,
      title: '회원 신규 등록 시',
      text: '안녕하세요. {store_name} 신규회원으로 등록하신 것을 환영합니다. 즐거운 운동시간 되세요. 감사합니다.'
    },
    reRegistration: {
      id: 0,
      storeId: 0,
      division: 'ReRegistration',
      isClicked: false,
      title: '회원권 연장 및 재등록 시',
      text: '안녕하세요. {store_name}에 재등록해주신 것을 감사드립니다. 계속해서 만족을 드릴 수 있는 {store_name}이 되겠습니다. 감사합니다.'
    }
  })

  const [attendanceClass, setAttendanceClass] = useState({
    attendance: {
      id: 0,
      storeId: 0,
      division: 'Attendance',
      isUseSetting: false,
      title: '출석시',
      text: '안녕하세요. {store_name}입니다. {user_name}님이 출석하셨습니다. 감사합니다.'
    },
    lastAttendance: {
      id: 0,
      storeId: 0,
      division: 'LastAttendance',
      isUseSetting: false,
      title: '마지막 출석 0일 후 0 시에',
      text: '안녕하세요. {store_name}입니다. {user_name}님, 마지막으로 방문해 운동하신지 7일이 지났습니다. 오랜만에 운동하러 나오시는 건 어떨까요? 감사합니다.',
      applyDay: 0,
      applyTime: 0
    },
    onReserve: {
      id: 0,
      storeId: 0,
      division: 'OnReserve',
      isUseSetting: false,
      title: '수업 예약시',
      text: '안녕하세요. {store_name}입니다. 수업 예약이 완료되었습니다. 수업명: {class_name} 예약 시간: {reserve_time} 강사명: {coach_name} 수업 시간에 늦지 않게 참석해 주세요. 감사합니다.'
    },
    cancelReserve: {
      id: 0,
      storeId: 0,
      division: 'CancelReserve',
      isUseSetting: false,
      title: '예약 취소시',
      text: '안녕하세요. {store_name}입니다. 고객님의 예약이 취소되었습니다. 감사합니다.'
    },
    beforeReserve: {
      id: 0,
      storeId: 0,
      division: 'BeforeReserve',
      isUseSetting: false,
      title: '예약된 수업 시작 0시간 전',
      text: '안녕하세요. {store_name}입니다. 예약한 수업 1시간 전입니다. 수업명: {class_name} 예약 시간: {reserve_time} 강사명: {coach_name} 수업 시간에 늦지 않게 참석해 주세요. 감사합니다.',
      applyTime: 0
    },
    absence: {
      id: 0,
      storeId: 0,
      division: 'Absence',
      isUseSetting: false,
      title: '예약한 수업 결석 시',
      text: '안녕하세요. {store_name}입니다. 예약한 수업에 결석하셨습니다. 수업명: {class_name} 예약 시간: {reserve_time} 강사명: {coach_name} 감사합니다.'
    }
  })

  const [expired, setExpired] = useState({
    beExpiredMembership: [
      {
        id: 0,
        storeId: 0,
        division: 'BeExpiredMembership',
        isUseSetting: false,
        title: '만료 예정일 0 일 전 0 시에',
        text: '안녕하세요. {store_name}입니다. {membership_name}의 잔여일이 1일 남았습니다. 재등록 문의는 언제든지 연락 주세요. 감사합니다.',
        applyDay: 0,
        applyTime: 0
      }
    ],
    beExpiredLocker: [
      {
        id: 0,
        storeId: 0,
        division: 'BeExpiredLocker',
        isUseSetting: false,
        title: '락커 만료 예정일 0 일 전 0 시에',
        text: '안녕하세요. {store_name}입니다. 사용중이신 락커의 잔여일이 1일 남았습니다. 만료일 전 락커를 비워주세요. 감사합니다.',
        applyDay: 0,
        applyTime: 0
      }
    ],
    expiredMembership: {
      id: 0,
      storeId: 0,
      division: 'ExpiredMembership',
      isUseSetting: false,
      title: '회원권 만료 0 시에',
      text: '안녕하세요. {store_name}입니다. {membership_name}의 잔여 횟수가 1회 남았습니다. 재등록 문의는 언제든지 연락 주세요. 감사합니다.',
      applyTime: 0
    },
    hadBeenExpiredMembership: [
      {
        id: 0,
        storeId: 0,
        division: 'HadBeenExpiredMembership',
        isUseSetting: false,
        title: '회원권 만료 0 일 후 0 시에',
        text: '안녕하세요. {store_name}입니다. {membership_name}이 만료되었습니다. 재등록 문의는 언제든지 연락 주세요. 감사합니다.',
        applyDay: 0,
        applyTime: 0
      }
    ],
    hadBeenExpiredLocker: [
      {
        id: 0,
        storeId: 0,
        division: 'HadBeenExpiredLocker',
        isUseSetting: false,
        title: '락커 만료 0 일 후 0 시에',
        text: '안녕하세요. {store_name}입니다. 락커의 사용기한 만료일이 1일이 지났습니다. 방문해 락커를 비워주세요. 감사합니다.',
        applyDay: 0,
        applyTime: 0
      }
    ],
    applyMembershipHolding: {
      id: 0,
      storeId: 0,
      division: 'ApplyMembershipHolding',
      isUseSetting: false,
      title: '회원권 홀딩시',
      text: '안녕하세요. {store_name}입니다. 고객님의 홀딩신청이 정상적으로 완료되었습니다. 홀딩시작일: {holding_start_date} 홀딩종료일: {holding_end_date} 홀딩기간 중 취소를 원하시는 경우 편하게 연락 주세요. 감사합니다.',
    },
    startMembershipHolding: {
      id: 0,
      storeId: 0,
      division: 'StartMembershipHolding',
      isUseSetting: false,
      title: '홀딩 이후 활성화 0 일 전 0 시에',
      text: '안녕하세요. {store_name}입니다. 홀딩중인 {membership_name}의 활성화 1일 전입니다. 홀딩시작일: {holding_start_date} 홀딩종료일: {holding_end_date} 날짜 변경을 원하시는 경우 편하게 연락 주세요. 감사합니다.',
      applyDay: 0,
      applyTime: 0
    }
  })

  // API 호출 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('message/status', 'GET');

        if (responseData.status === 200) {
          setStoreName(responseData.data.storeName);
          setValidCount(responseData.data.validCount);
          setInvalidCount(responseData.data.invalidCount);
          setIsRegistrationCard(responseData.data.isRegistrationCard);
        }
      }
    )();
  }, [])

  // API 호출 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('member/status_all', 'GET');
        // console.log(responseData);
        if (responseData.status === 200) {
          let newMembers = responseData.data.slice();

          newMembers.map((data) => data.isClicked = false);

          setTotalMembers(newMembers);
          // setTotalMembers(responseData.data);
        }
        else setTotalMembers([]);
      }
    )();
  }, [])

  // API 호출 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataVersion2('message_setting/', 'GET');
        // console.log(responseData);
        if (responseData.status === 200) {
          let list = responseData.data.messageSettings.slice();
          let newRegistration = lodash.cloneDeep(registration);
          let newAttendanceClass = lodash.cloneDeep(attendanceClass);
          let newExpired = lodash.cloneDeep(expired);

          list.map((data, index) => {
            if (data.division === 'NewRegistration') {
              newRegistration.newRegistration.id = data.id;
              newRegistration.newRegistration.storeId = data.storeId;
              newRegistration.newRegistration.division = 'NewRegistration';
              newRegistration.newRegistration.isUseSetting = data.isUseSetting;
              newRegistration.newRegistration.text = data.text;
              newRegistration.newRegistration.title = data.title;
            }
            else if (data.division === 'ReRegistration') {
              newRegistration.reRegistration.id = data.id;
              newRegistration.reRegistration.storeId = data.storeId;
              newRegistration.reRegistration.division = 'ReRegistration';
              newRegistration.reRegistration.isUseSetting = data.isUseSetting;
              newRegistration.reRegistration.text = data.text;
              newRegistration.reRegistration.title = data.title;
            }
            else if (data.division === 'Attendance') {
              newAttendanceClass.attendance.id = data.id;
              newAttendanceClass.attendance.storeId = data.storeId;
              newAttendanceClass.attendance.division = 'Attendance';
              newAttendanceClass.attendance.isUseSetting = data.isUseSetting;
              newAttendanceClass.attendance.text = data.text;
              newAttendanceClass.attendance.title = data.title;
            }
            else if (data.division === 'LastAttendance') {
              newAttendanceClass.lastAttendance.id = data.id;
              newAttendanceClass.lastAttendance.storeId = data.storeId;
              newAttendanceClass.lastAttendance.division = 'LastAttendance';
              newAttendanceClass.lastAttendance.isUseSetting = data.isUseSetting;
              newAttendanceClass.lastAttendance.text = data.text;
              newAttendanceClass.lastAttendance.title = data.title;
              newAttendanceClass.lastAttendance.applyDay = data.applyDay;
              newAttendanceClass.lastAttendance.applyTime = data.applyTime;
            }
            else if (data.division === 'OnReserve') {
              newAttendanceClass.onReserve.id = data.id;
              newAttendanceClass.onReserve.storeId = data.storeId;
              newAttendanceClass.onReserve.division = 'OnReserve';
              newAttendanceClass.onReserve.isUseSetting = data.isUseSetting;
              newAttendanceClass.onReserve.text = data.text;
              newAttendanceClass.onReserve.title = data.title;
            }
            else if (data.division === 'CancelReserve') {
              newAttendanceClass.cancelReserve.id = data.id;
              newAttendanceClass.cancelReserve.storeId = data.storeId;
              newAttendanceClass.cancelReserve.division = 'CancelReserve';
              newAttendanceClass.cancelReserve.isUseSetting = data.isUseSetting;
              newAttendanceClass.cancelReserve.text = data.text;
              newAttendanceClass.cancelReserve.title = data.title;
            }
            else if (data.division === 'BeforeReserve') {
              newAttendanceClass.beforeReserve.id = data.id;
              newAttendanceClass.beforeReserve.storeId = data.storeId;
              newAttendanceClass.beforeReserve.division = 'BeforeReserve';
              newAttendanceClass.beforeReserve.isUseSetting = data.isUseSetting;
              newAttendanceClass.beforeReserve.text = data.text;
              newAttendanceClass.beforeReserve.title = data.title;
              newAttendanceClass.beforeReserve.applyTime = data.applyTime;
            }
            else if (data.division === 'Absence') {
              newAttendanceClass.absence.id = data.id;
              newAttendanceClass.absence.storeId = data.storeId;
              newAttendanceClass.absence.division = 'Absence';
              newAttendanceClass.absence.isUseSetting = data.isUseSetting;
              newAttendanceClass.absence.text = data.text;
              newAttendanceClass.absence.title = data.title;
            }
            else if (data.division === 'BeExpiredMembership') {
              // // 아직 데이터가 없는경우 덮어씌우기 //
              // if (newExpired.beExpiredMembership[0].id === 0
              //   || newExpired.beExpiredMembership[0].id === data.id) {
              //   newExpired.beExpiredMembership[0].id = data.id;
              //   newExpired.beExpiredMembership[0].storeId = data.storeId;
              //   newExpired.beExpiredMembership[0].division = 'BeExpiredMembership';
              //   newExpired.beExpiredMembership[0].isUseSetting = data.isUseSetting;
              //   newExpired.beExpiredMembership[0].text = data.text;
              //   newExpired.beExpiredMembership[0].title = data.title;
              //   newExpired.beExpiredMembership[0].applyDay = data.applyDay;
              //   newExpired.beExpiredMembership[0].applyTime = data.applyTime;
              // }
              // // 이미 데이터가 있는경우 추가 //
              // else {
              //   let newData = {
              //     id: data.id,
              //     storeId: data.storeId,
              //     division: 'BeExpiredMembership',
              //     isUseSetting: data.isUseSetting,
              //     text: data.text,
              //     title: data.title,
              //     applyDay: data.applyDay,
              //     applyTime: data.applyTime
              //   }

              //   newExpired.beExpiredMembership.push(newData);
              // }

              // 아직 데이터가 없는경우 덮어씌우기 //
              if (newExpired.beExpiredMembership[0].id === 0) {
                newExpired.beExpiredMembership[0].id = data.id;
                newExpired.beExpiredMembership[0].storeId = data.storeId;
                newExpired.beExpiredMembership[0].division = 'BeExpiredMembership';
                newExpired.beExpiredMembership[0].isUseSetting = data.isUseSetting;
                newExpired.beExpiredMembership[0].text = data.text;
                newExpired.beExpiredMembership[0].title = data.title;
                newExpired.beExpiredMembership[0].applyDay = data.applyDay;
                newExpired.beExpiredMembership[0].applyTime = data.applyTime;
              }
              // 이미 데이터가 있는경우 추가 //
              else {
                let isExist = false;

                for (let i = 0; i < newExpired.beExpiredMembership.length; i++) {
                  if (newExpired.beExpiredMembership[i].id === data.id) {
                    newExpired.beExpiredMembership[i].id = data.id;
                    newExpired.beExpiredMembership[i].storeId = data.storeId;
                    newExpired.beExpiredMembership[i].division = 'BeExpiredMembership';
                    newExpired.beExpiredMembership[i].isUseSetting = data.isUseSetting;
                    newExpired.beExpiredMembership[i].text = data.text;
                    newExpired.beExpiredMembership[i].title = data.title;
                    newExpired.beExpiredMembership[i].applyDay = data.applyDay;
                    newExpired.beExpiredMembership[i].applyTime = data.applyTime;

                    isExist = true;
                    break;
                  }
                }

                if (!isExist) {
                  let newData = {
                    id: data.id,
                    storeId: data.storeId,
                    division: 'BeExpiredMembership',
                    isUseSetting: data.isUseSetting,
                    text: data.text,
                    title: data.title,
                    applyDay: data.applyDay,
                    applyTime: data.applyTime
                  }

                  newExpired.beExpiredMembership.push(newData);
                }
              }
            }
            else if (data.division === 'BeExpiredLocker') {
              // 아직 데이터가 없는경우 덮어씌우기 //
              if (newExpired.beExpiredLocker[0].id === 0
                || newExpired.beExpiredLocker[0].id === data.id) {
                newExpired.beExpiredLocker[0].id = data.id;
                newExpired.beExpiredLocker[0].storeId = data.storeId;
                newExpired.beExpiredLocker[0].division = 'BeExpiredLocker';
                newExpired.beExpiredLocker[0].isUseSetting = data.isUseSetting;
                newExpired.beExpiredLocker[0].text = data.text;
                newExpired.beExpiredLocker[0].title = data.title;
                newExpired.beExpiredLocker[0].applyDay = data.applyDay;
                newExpired.beExpiredLocker[0].applyTime = data.applyTime;
              }
              // 이미 데이터가 있는경우 추가 //
              else {
                let isExist = false;

                for (let i = 0; i < newExpired.beExpiredLocker.length; i++) {
                  if (newExpired.beExpiredLocker[i].id === data.id) {
                    newExpired.beExpiredLocker[i].id = data.id;
                    newExpired.beExpiredLocker[i].storeId = data.storeId;
                    newExpired.beExpiredLocker[i].division = 'BeExpiredLocker';
                    newExpired.beExpiredLocker[i].isUseSetting = data.isUseSetting;
                    newExpired.beExpiredLocker[i].text = data.text;
                    newExpired.beExpiredLocker[i].title = data.title;
                    newExpired.beExpiredLocker[i].applyDay = data.applyDay;
                    newExpired.beExpiredLocker[i].applyTime = data.applyTime;

                    isExist = true;
                    break;
                  }
                }

                if (!isExist) {
                  let newData = {
                    id: data.id,
                    storeId: data.storeId,
                    division: 'BeExpiredLocker',
                    isUseSetting: data.isUseSetting,
                    text: data.text,
                    title: data.title,
                    applyDay: data.applyDay,
                    applyTime: data.applyTime
                  }

                  newExpired.beExpiredLocker.push(newData);
                }
              }
            }
            else if (data.division === 'ExpiredMembership') {
              newExpired.expiredMembership.id = data.id;
              newExpired.expiredMembership.storeId = data.storeId;
              newExpired.expiredMembership.division = 'ExpiredMembership';
              newExpired.expiredMembership.isUseSetting = data.isUseSetting;
              newExpired.expiredMembership.text = data.text;
              newExpired.expiredMembership.title = data.title;
              newExpired.expiredMembership.applyDay = data.applyDay;
              newExpired.expiredMembership.applyTime = data.applyTime;
            }
            else if (data.division === 'HadBeenExpiredMembership') {
              // 아직 데이터가 없는경우 덮어씌우기 //
              if (newExpired.hadBeenExpiredMembership[0].id === 0
                || newExpired.hadBeenExpiredMembership[0].id === data.id) {
                newExpired.hadBeenExpiredMembership[0].id = data.id;
                newExpired.hadBeenExpiredMembership[0].storeId = data.storeId;
                newExpired.hadBeenExpiredMembership[0].division = 'HadBeenExpiredMembership';
                newExpired.hadBeenExpiredMembership[0].isUseSetting = data.isUseSetting;
                newExpired.hadBeenExpiredMembership[0].text = data.text;
                newExpired.hadBeenExpiredMembership[0].title = data.title;
                newExpired.hadBeenExpiredMembership[0].applyDay = data.applyDay;
                newExpired.hadBeenExpiredMembership[0].applyTime = data.applyTime;
              }
              // 이미 데이터가 있는경우 추가 //
              else {
                let isExist = false;

                for (let i = 0; i < newExpired.hadBeenExpiredMembership.length; i++) {
                  if (newExpired.hadBeenExpiredMembership[i].id === data.id) {
                    newExpired.hadBeenExpiredMembership[i].id = data.id;
                    newExpired.hadBeenExpiredMembership[i].storeId = data.storeId;
                    newExpired.hadBeenExpiredMembership[i].division = 'HadBeenExpiredMembership';
                    newExpired.hadBeenExpiredMembership[i].isUseSetting = data.isUseSetting;
                    newExpired.hadBeenExpiredMembership[i].text = data.text;
                    newExpired.hadBeenExpiredMembership[i].title = data.title;
                    newExpired.hadBeenExpiredMembership[i].applyDay = data.applyDay;
                    newExpired.hadBeenExpiredMembership[i].applyTime = data.applyTime;

                    isExist = true;
                    break;
                  }
                }

                if (!isExist) {
                  let newData = {
                    id: data.id,
                    storeId: data.storeId,
                    division: 'HadBeenExpiredMembership',
                    isUseSetting: data.isUseSetting,
                    text: data.text,
                    title: data.title,
                    applyDay: data.applyDay,
                    applyTime: data.applyTime
                  }

                  newExpired.hadBeenExpiredMembership.push(newData);
                }
              }
            }
            else if (data.division === 'HadBeenExpiredLocker') {
              // 아직 데이터가 없는경우 덮어씌우기 //
              if (newExpired.hadBeenExpiredLocker[0].id === 0
                || newExpired.hadBeenExpiredLocker[0].id === data.id) {
                newExpired.hadBeenExpiredLocker[0].id = data.id;
                newExpired.hadBeenExpiredLocker[0].storeId = data.storeId;
                newExpired.hadBeenExpiredLocker[0].division = 'HadBeenExpiredLocker';
                newExpired.hadBeenExpiredLocker[0].isUseSetting = data.isUseSetting;
                newExpired.hadBeenExpiredLocker[0].text = data.text;
                newExpired.hadBeenExpiredLocker[0].title = data.title;
                newExpired.hadBeenExpiredLocker[0].applyDay = data.applyDay;
                newExpired.hadBeenExpiredLocker[0].applyTime = data.applyTime;
              }
              // 이미 데이터가 있는경우 추가 //
              else {
                let isExist = false;

                for (let i = 0; i < newExpired.hadBeenExpiredLocker.length; i++) {
                  if (newExpired.hadBeenExpiredLocker[i].id === data.id) {
                    newExpired.hadBeenExpiredLocker[i].id = data.id;
                    newExpired.hadBeenExpiredLocker[i].storeId = data.storeId;
                    newExpired.hadBeenExpiredLocker[i].division = 'HadBeenExpiredLocker';
                    newExpired.hadBeenExpiredLocker[i].isUseSetting = data.isUseSetting;
                    newExpired.hadBeenExpiredLocker[i].text = data.text;
                    newExpired.hadBeenExpiredLocker[i].title = data.title;
                    newExpired.hadBeenExpiredLocker[i].applyDay = data.applyDay;
                    newExpired.hadBeenExpiredLocker[i].applyTime = data.applyTime;

                    isExist = true;
                    break;
                  }
                }

                if (!isExist) {
                  let newData = {
                    id: data.id,
                    storeId: data.storeId,
                    division: 'HadBeenExpiredLocker',
                    isUseSetting: data.isUseSetting,
                    text: data.text,
                    title: data.title,
                    applyDay: data.applyDay,
                    applyTime: data.applyTime
                  }

                  newExpired.hadBeenExpiredLocker.push(newData);
                }
              }
            }
            else if (data.division === 'ApplyMembershipHolding') {
              newExpired.applyMembershipHolding.id = data.id;
              newExpired.applyMembershipHolding.storeId = data.storeId;
              newExpired.applyMembershipHolding.division = 'ApplyMembershipHolding';
              newExpired.applyMembershipHolding.isUseSetting = data.isUseSetting;
              newExpired.applyMembershipHolding.text = data.text;
              newExpired.applyMembershipHolding.title = data.title;
            }
            else if (data.division === 'StartMembershipHolding') {
              newExpired.startMembershipHolding.id = data.id;
              newExpired.startMembershipHolding.storeId = data.storeId;
              newExpired.startMembershipHolding.division = 'StartMembershipHolding';
              newExpired.startMembershipHolding.isUseSetting = data.isUseSetting;
              newExpired.startMembershipHolding.text = data.text;
              newExpired.startMembershipHolding.title = data.title;
              newExpired.startMembershipHolding.applyDay = data.applyDay;
              newExpired.startMembershipHolding.applyTime = data.applyTime;
            }
          });

          setIsClickedTotalSetting(responseData.data.isUseAutoMessageSetting);
          setRegistration(newRegistration);
          setAttendanceClass(newAttendanceClass);
          setExpired(newExpired);
        }
      }
    )();
  }, [rendering])


  // API 호출 //
  useEffect(() => {
    if (clickDivision === 'MessageHistory') {
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.getDataVersion2('message_setting/history', 'GET');
          // console.log(responseData.data);
          if (responseData.status === 200) {
            setMessageHistory(responseData.data);
            setTotalPage(Math.ceil(responseData.data.length/10));
            setNowPage(0);
          }
          else {
            setMessageHistory([]);
            setTotalPage(1);
            setNowPage(0);
          }
        }
      )();
    }
  }, [clickDivision])

  useEffect(() => {
    setViewMessageHistory(messageHistory.slice((nowPage * 10), (nowPage * 10) + 10));
  }, [messageHistory, nowPage])


  // 회원수 계산 //
  function decideMemberCount() {
    // let count = 0;

    // if (isClickedValid) count += validCount;
    // if (isClickedInvalid) count += invalidCount;

    // return count.toLocaleString('ko-KR');
    // let count = 0;

    // selectedMembers.map((data) => {
    //   if (data.isClicked) count += 1;
    // })

    return selectedMembers.length.toLocaleString('ko-KR') + ' 명 회원에게 메세지 발송시';
  }

  // 결제 금액 //
  function decideCalcPrice() {
    // let count = 0;

    // if (isClickedValid) count += validCount;
    // if (isClickedInvalid) count += invalidCount;

    // return (60 * count).toLocaleString('ko-KR');

    // let count = 0;

    // selectedMembers.map((data) => {
    //   if (data.isClicked) count += 1;
    // })

    return (60 * selectedMembers.length).toLocaleString('ko-KR') + ' 원';
  }

  // 완료모달 텍스트 결정 //
  function decideCompleteMemberCount() {
    // if (isClickedValid && isClickedInvalid) return '유효 회원, 만료 회원 ' + (validCount + invalidCount) + '명 에게';
    // else if (isClickedValid) return '유효 회원 ' + (validCount) + '명 에게';
    // else if (isClickedInvalid) return '만료 회원 ' + (invalidCount) + '명 에게';
    // else return '회원 0명 에게';

    // let count = 0;

    // selectedMembers.map((data) => {
    //   if (data.isClicked) count += 1;
    // })

    return '회원 ' + selectedMembers.length.toLocaleString('ko-KR') + ' 명 에게';
  }

  // 회원 선택 추가 모달에서 회원추가 버튼 클릭시 //
  function onClickAdditionButton(selectedMembers) {
    let newTotalMembers = totalMembers.slice();
    let members = lodash.cloneDeep(selectedMembers);

    newTotalMembers.map((data) => data.isClicked = false);
    members.map((data) => data.isClicked = false);

    setTotalMembers(newTotalMembers);
    setSelectedMembers(members);
    setIsClickedSelectedMember(false);
  }

  // 전송회원 체크박스 선택시 //
  function onClickMemberInfo(data) {
    let newSelectedMembers = selectedMembers.slice();
    let index = newSelectedMembers.findIndex(item => item.memberId === data.memberId);

    newSelectedMembers[index].isClicked = !newSelectedMembers[index].isClicked;

    setSelectedMembers(newSelectedMembers);
  }

  // 선택삭제 버튼 클릭시 //
  function onClickSelectedDelete() {
    // let newTotalMembers = totalMembers.slice();
    // let newSelectedMembers = selectedMembers.slice();
    // let saveMembers = [];
    // let deleteMembers = [];

    // newSelectedMembers.map((data) => {
    //   if (data.isClicked) deleteMembers.push(data);
    //   else saveMembers.push(data);
    // })

    // // newTotalMembers.map((data) => data.isClicked = false);

    // for (let i = 0; i < deleteMembers.length; i++) {
    //   for (let j = 0; j < newTotalMembers.length; j++) {
    //     if (deleteMembers[i].memberId === newTotalMembers[j].memberId) {
    //       newTotalMembers[i].isClicked = false;
    //       break;
    //     }
    //   }
    // }

    // setTotalMembers(newTotalMembers);
    // setSelectedMembers(saveMembers);

    let newSelectedMembers = selectedMembers.slice();
    let saveMembers = [];

    newSelectedMembers.map((data) => {
      if (!data.isClicked) saveMembers.push(data);
    })

    setSelectedMembers(saveMembers);
  }

  // 전체삭제 버튼 클릭시 //
  function onClickAllDelete() {
    let newMembers = totalMembers.slice();

    newMembers.map((data) => data.isClicked = false);

    setTotalMembers(newMembers);
    setSelectedMembers([]);
  }

  // 회원목록에서 불러오기 //
  function onClickMemberLoading() {
    let newTotalMembers = totalMembers.slice();
    let newSelectedMembers = selectedMembers.slice();

    newTotalMembers.map((data) => data.isClicked = false);

    for (let i = 0; i < newSelectedMembers.length; i++) {
      for (let j = 0; j < newTotalMembers.length; j++) {
        if (newSelectedMembers[i].memberId === newTotalMembers[j].memberId) {
          newTotalMembers[j].isClicked = true;
          break;
        }
      }
    }

    setTotalMembers(newTotalMembers);
    setIsClickedSelectedMember(true);
  }

  // 입력하기 버튼 클릭시 //
  function onClickRegistrationCard() {
    const obj = {};

    obj.PCD_PAY_TYPE = 'card'
    obj.PCD_PAY_WORK = 'AUTH'
    // obj.PCD_PAY_WORK = 'PAY'
    obj.PCD_CARD_VER = '01';
    obj.PCD_PAYER_AUTHTYPE = 'sms';
    // obj.PCD_RST_URL = process.env.REACT_APP_TEST_HOSTNAME + '/subscribe/success';
    // obj.PCD_RST_URL = 'https://link-soft.io/';

    // obj.PCD_PAY_GOODS = 'Link-Zone 정기 구독권 (1개월)';
    // obj.PCD_PAY_TOTAL = 100;
    // obj.PCD_PAY_OID = responseData.data[0].nextMerchantUid;


    obj.callbackFunction = getResult;
    obj.clientKey = '0F44C92BFB07F1BE60C5235A7254316D';
    // obj.clientKey = 'test_DF55F29DA654A8CBC0F0A9DD4B556486';

    window.PaypleCpayAuthCheck(obj);
  }

  // 결제 이후 콜백 //
  function getResult(res) {
    if (res.PCD_PAY_RST === 'success' && (res.PCD_PAY_CODE === '0000' || res.PCD_PAY_CODE === 'CDAU1000')) {
      let requestData = {
        billingKey: res.PCD_PAYER_ID,
        cardName: res.PCD_PAY_CARDNAME,
        cardNum: res.PCD_PAY_CARDNUM
      }
      // console.log(requestData);
      // console.log(res);
      // console.log(res.PCD_PAYER_ID);
      // console.log(res.PCD_PAYER_NO);
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('billing/registry', 'POST', requestData);

          if (responseData.status === 200) {
            alert('카드번호 등록이 완료되었습니다!');
            setIsRegistrationCard(true);
            setIsViewRegistrationCard(false);
          }
          else if (responseData.status === 201) {
            alert('이미 등록된 정보입니다\n새로고침 후 다시시도해 주세요');
            setIsViewRegistrationCard(false);
          }
        }
      )();
    }
    else {
      if (res.PCD_PAY_CODE === undefined) {
        console.log(res.PCD_PAY_MSG);
        alert(res.PCD_PAY_MSG);
      }
      else {
        console.log('[' + res.PCD_PAY_CODE + ']' + ' ' + res.PCD_PAY_MSG);
        alert('[' + res.PCD_PAY_CODE + ']' + ' ' + res.PCD_PAY_MSG);
      }
    }
  }

  // // 메시지 보내기 버튼 클릭시 //
  // function onClickSendMessage() {
  //   let count = 0;

  //   if (isClickedValid) count += validCount;
  //   if (isClickedInvalid) count += invalidCount;

  //   if (!isClickedValid && !isClickedInvalid) {
  //     alert('메시지를 전송할 회원 유형을 선택해주세요');
  //     return;
  //   }
  //   else if (count === 0) {
  //     alert('전송가능한 회원이 없습니다');
  //     return;
  //   }

  //   if (isRegistrationCard) {
  //     if (isSending) {
  //       alert('메세지를 전송중입니다');
  //       return;
  //     }

  //     setIsSending(true);

  //     let requestData = {
  //       title: storeName,
  //       content: content,
  //       isSendValid: isClickedValid,
  //       isSendInvalid: isClickedInvalid
  //     }

  //     const fetchModule = new FetchModule();
  //     (
  //       async () => {
  //         const responseData = await fetchModule.postDataVersion2('message/send', 'POST', requestData);

  //         if (responseData.status === 200) {
  //           // alert('메시지 전송이 완료되었습니다.');

  //           setTimeout(() => {
  //             setIsViewCompleteSend(true);
  //             setIsSending(false);
  //             setContent('');
  //             setIsClickedValid(false);
  //             setIsClickedInvalid(false);
  //           }, 600)
  //         }
  //         else {
  //           alert('메시지 전송에 실패하였습니다\n잠시 후 다시시도해주세요');
  //           setIsSending(false);
  //         }
  //       }
  //     )();

  //     // setIsViewCompleteSend(true);
  //     // setIsSending(false);
  //   }
  //   else setIsViewRegistrationCard(true);
  // }

  // 메시지 보내기 버튼 클릭시 //
  function onClickSendMessage() {
    let memberIds = [];

    selectedMembers.map((data) => {
      memberIds.push(data.memberId);
    })

    if (memberIds.length === 0) {
      alert('전송가능한 회원이 없습니다');
      return;
    }
    else if (content === '') {
      alert('전송할 내용을 작성해주세요');
      return;
    }

    if (isRegistrationCard) {
      if (isSending) {
        alert('메세지를 전송중입니다');
        return;
      }

      setIsSending(true);

      let requestData = {
        title: storeName,
        content: content,
        memberIds: memberIds
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataVersion2('message/send_target', 'POST', requestData);

          if (responseData.status === 200) {
            // alert('메시지 전송이 완료되었습니다.');

            setTimeout(() => {
              setIsViewCompleteSend(true);
              setIsSending(false);
              setContent('');
              setIsClickedValid(false);
              setIsClickedInvalid(false);
            }, 600)
          }
          else {
            alert('메시지 전송에 실패하였습니다\n잠시 후 다시시도해주세요');
            setIsSending(false);
          }
        }
      )();

      // setIsViewCompleteSend(true);
      // setIsSending(false);
    }
    else setIsViewRegistrationCard(true);
  }

  // 자동메세지 사용 버튼 전환시 //
  function onClickUseSettingBox() {
    if (isProgress) {
      alert('저장을 진행중 입니다');
      return;
    }

    setIsProcess(true);

    let requestData = {
      isUseAutoMessage: !isClickedTotalSetting
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('message_setting/switch', 'POST', requestData);

        if (responseData.status === 200) {
          // alert('메시지 전송이 완료되었습니다.');
          setIsClickedTotalSetting(!isClickedTotalSetting);
          setIsProcess(false);
        }
        else {
          // alert('메시지 전송에 실패하였습니다\n잠시 후 다시시도해주세요');
          setIsProcess(false);
        }
      }
    )();

  }

  // 수정하기 버튼 클릭시 //
  function onClickEditText(data) {
    let newData = lodash.cloneDeep(data);

    setSelectedData(newData);
    setIsViewMessageEditModal(true);
  }

  // 자동메세지 수정모달 취소버튼 클릭시 //
  function onClickExitInEditModal() {
    setSelectedData(undefined);
    setIsViewMessageEditModal(false);
  }

  // 자동메세지 수정모달 저장 버튼 클릭시 //
  function onClickSave(data) {
    if (isProgress) {
      alert('저장중을 진행중입니다');
      return;
    }

    setIsProcess(true);

    let requestData = {
      id: selectedData.id,
      storeId: selectedData.storeId,
      division: selectedData.division,
      text: data,
      title: selectedData.title,
      applyDay: selectedData.applyDay,
      applyTime: selectedData.applyTime,
      isUseSetting: selectedData.isUseSetting
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataVersion2('message_setting/save', 'POST', requestData);

        if (responseData.status === 200) {
          // let newData = cloneDeep(expired);

          // if (data.division === 'BeExpiredMembership') newData.beExpiredMembership[index] = responseData.data;
          // else if (data.division === 'BeExpiredLocker') newData.beExpiredLocker[index] = responseData.data;
          // else if (data.division === 'ExpiredMembership') newData.expiredMembership.isUseSetting = responseData.data;
          // else if (data.division === 'HadBeenExpiredMembership') newData.hadBeenExpiredMembership[index] = responseData.data;
          // else if (data.division === 'HadBeenExpiredLocker') newData.hadBeenExpiredLocker[index] = responseData.data;
          // else if (data.division === 'ApplyMembershipHolding') newData.applyMembershipHolding = responseData.data;
          // else if (data.division === 'StartMembershipHolding') newData.startMembershipHolding = responseData.data;

          // setExpired(newData);
          setRendering(!rendering);
          setIsViewMessageEditModal(false);
          setIsProcess(false);
        }
      }
    )();
  }

  // 이전 페이지 클릭시 //
  function onClickPrev() {
    if (nowPage > 0) setNowPage(nowPage - 1);
  }

  // 다음 페이지 클릭시 //
  function onClickNext() {
    if (nowPage < totalPage - 1) setNowPage(nowPage + 1);
  }

  return (
    <TopLevelWrapper>
      {isViewCompleteSend && <SendCompleteModal text={decideCompleteMemberCount()} onClickExit={() => setIsViewCompleteSend(false)} />}
      {isViewRegistrationCard && <NotRegistrationCardModal division='Notice' setIsViewModal={setIsViewRegistrationCard} onClickRegistCard={onClickRegistrationCard} />}
      {isClickedSelectedMember && <SendMemberSelectModal memberList={totalMembers} onClickAdditionButton={onClickAdditionButton} onClickExit={() => setIsClickedSelectedMember(false)} />}
      {isViewMessageEditModal && <AutoMessageEditModal data={selectedData} onClickExit={onClickExitInEditModal} onClickSave={onClickSave} />}
      <TitleBar>
        <TextWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' hover='default'>문자 발송</Text>
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777' hover='default'>일정, 회원관리 등을 공지하고 확인할 수 있어요</Text>
        </TextWrapper>
        {/* <SendButton onClick={onClickSendMessage}>문자 전송</SendButton> */}
      </TitleBar>
      <ColumnWrapper>
        <ColumnButton isClicked={clickDivision === 'SendMessage'} onClick={() => setClickDivision('SendMessage')}>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={clickDivision === 'SendMessage' ? '#FFFFFF' : '#CBCBCB'} hover='pointer'>문자 발송</Text>
        </ColumnButton>
        <ColumnButton isClicked={clickDivision === 'SettingAutoMessage'} onClick={() => setClickDivision('SettingAutoMessage')}>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={clickDivision === 'SettingAutoMessage' ? '#FFFFFF' : '#CBCBCB'} hover='pointer'>자동 메세지</Text>
        </ColumnButton>
        <ColumnButton isClicked={clickDivision === 'MessageHistory'} onClick={() => setClickDivision('MessageHistory')}>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={clickDivision === 'MessageHistory' ? '#FFFFFF' : '#CBCBCB'} hover='pointer'>전송 내역</Text>
        </ColumnButton>
      </ColumnWrapper>
      {
        clickDivision === 'SendMessage' &&
        <MessageBoxWrapper>
          {
            isSending &&
            <LoadingView>
              <LottieWrapper>
                <Lottie options={{ animationData: LoadingAnimation }} />
              </LottieWrapper>
            </LoadingView>
          }
          <MessageBox>
            <WriteCommponentWrapper style={{ marginTop: '28px' }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#232323' hover='default'>제목</Text>
              <TitleInputView>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#595959' hover='default'>{storeName}</Text>
              </TitleInputView>
            </WriteCommponentWrapper>
            <WriteCommponentWrapper style={{ marginTop: '28px', marginBottom: '28px' }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#232323' hover='default'>내용</Text>
              <ContentInput
                value={content}
                setValue={setContent}
                placeholder='내용을 입력해주세요' />
            </WriteCommponentWrapper>
            {/* <MessageSettingWrapper>
                <SettingInnerWrapper>
                  <TotalCheckBoxWrapper>
                    <CheckBoxWrapper onClick={() => setIsClickedValid(!isClickedValid)}>
                      <CheckBox isClicked={isClickedValid} />
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' hover='pointer'>유효 회원</Text>
                    </CheckBoxWrapper>
                    <CheckBoxWrapper onClick={() => setIsClickedInvalid(!isClickedInvalid)}>
                      <CheckBox isClicked={isClickedInvalid} />
                      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' hover='pointer'>만료 회원</Text>
                    </CheckBoxWrapper>
                  </TotalCheckBoxWrapper>
                  <SettingTextWrapper>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' hover='default'>{decideMemberCount() + ' 명 회원에게 문자 메시지 발송시'}</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' hover='default'>{decideCalcPrice() + '원'}</Text>
                  </SettingTextWrapper>
                </SettingInnerWrapper>
              </MessageSettingWrapper> */}
          </MessageBox>
          {/* <MockUpWrapper>
              <MockUp src={MockUpImage}/>
              <MockUpComponent>
                <MockUpComponentInnerWrapper>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={10} color='#A4A4A4' hover='default'>단말기 설정에 따라 다르게 보일 수 있습니다</Text>
                  <MockUpTitleBox>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#000000' hover='default'>{storeName}</Text>
                  </MockUpTitleBox>
                  <MockUpContentBox>
                    <PreText fontFamily='NotoSansKR-Medium' fontSize={12} color='#000000' hover='default'>{content}</PreText>
                  </MockUpContentBox>
                </MockUpComponentInnerWrapper>
              </MockUpComponent>
            </MockUpWrapper> */}
          <MemberSelectBoxWrapper>
            <MemberFindButton
              isHover={isHoverMemberSelectButton} 
              onMouseOver={() => setIsHoverMemberSelectButton(true)}
              onMouseLeave={() => setIsHoverMemberSelectButton(false)}
              onClick={onClickMemberLoading}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color={isHoverMemberSelectButton ? `#FFFFFF` : '#6DD49E'} hover='pointer'>회원목록에서 불러오기</Text>
            </MemberFindButton>
            {
              selectedMembers.length === 0 ?
                <NoneSelectedBox>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' hover='default'>아직 선택된 회원님이 없습니다</Text>
                </NoneSelectedBox>
                :
                <MemberSelectBox>
                  <MemberSelectBoxButtonWrapper>
                    <DeleteButton onClick={onClickSelectedDelete}>
                      <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#3E4148' hover='pointer'>선택 삭제</Text>
                    </DeleteButton>
                    <DeleteButton onClick={onClickAllDelete}>
                      <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#3E4148' hover='pointer'>전체 삭제</Text>
                    </DeleteButton>
                  </MemberSelectBoxButtonWrapper>
                  <SelectedMemberBox>
                    <SelectedMemberInfoWrapper height={37 * selectedMembers.length}>
                      {
                        selectedMembers.map((data, index) => (
                          <SelectedMemberInfo key={'FinalSelectedMember-' + index}>
                            <ProfileWrapper isFirst={index === 0} isLast={selectedMembers.length - 1 === index}>
                              <UserProfile profile={data.profile} defaultProfile={DefaultProfile} />
                            </ProfileWrapper>
                            <SelectedMemberTextWrapper marginLeft={10} width={45}>
                              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#777777' hover='default'>{data.name}</Text>
                            </SelectedMemberTextWrapper>
                            <SelectedMemberTextWrapper marginLeft={4} width={98}>
                              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#777777' hover='default'>{data.phone === '' ? '없음' : data.phone.slice(0, 3) + '-' + data.phone.slice(3, 7) + '-' + data.phone.slice(7, 11)}</Text>
                            </SelectedMemberTextWrapper>
                            <MemberCheckBox isClicked={data.isClicked} onClick={() => onClickMemberInfo(data)}>
                              {
                                data.isClicked && <CheckImage src={CheckImagePath} />
                              }
                            </MemberCheckBox>
                          </SelectedMemberInfo>
                        ))
                      }
                    </SelectedMemberInfoWrapper>
                  </SelectedMemberBox>
                  <SettingTextWrapper>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#777777' hover='default'>{decideMemberCount()}</Text>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000' hover='default'>{decideCalcPrice()}</Text>
                  </SettingTextWrapper>
                  <SendMessageButton onClick={onClickSendMessage}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' hover='pointer'>문자 전송</Text>
                  </SendMessageButton>
                </MemberSelectBox>
            }
          </MemberSelectBoxWrapper>
        </MessageBoxWrapper>
      }
      {
        clickDivision === 'SettingAutoMessage' &&
        <MessageBoxWrapper style={{ flexDirection: 'column', marginLeft: '28px', width: '1001px' }}>
          <TotalUseSettingWrapper>
            <TotalUseSettingBox
              isClicked={isClickedTotalSetting}
              onClick={onClickUseSettingBox}>
              <CheckBox
                isClicked={isClickedTotalSetting}
                onClickCheckBox={onClickUseSettingBox}

                boxWidth={15}
                boxHeight={15}
                boxRadius={5}

                imageWidth={10}
                imageHeight={7} />
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color={isClickedTotalSetting ? '#FF8B48' : '#FFFFFF'} hover='pointer'>설정대로 자동 메시지를 전송합니다</Text>
            </TotalUseSettingBox>
            <TotalUseTextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={10} color='#595959' hover='default'>자동메시지 전송 기능을 켜두시면 아래 설정의 조건이 충족될 때</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={10} color='#595959' hover='default'>회원님들께 자동으로 메시지를 전송해 편하고 효과적인 마케팅을 진행하실 수 있습니다.</Text>
            </TotalUseTextWrapper>
          </TotalUseSettingWrapper>
          <DivisionSettingBoxWrapper>
            <RegistrationSettingMessageBox
              isActivate={isClickedTotalSetting}
              registration={registration}
              setRegistration={setRegistration}
              onClickEdit={onClickEditText} />

            <AttendanceClassSettingMessageBox
              isActivate={isClickedTotalSetting}
              attendanceClass={attendanceClass}
              setAttendanceClass={setAttendanceClass}
              onClickEdit={onClickEditText} />

            <ExpiredSettingMessageBox
              isActivate={isClickedTotalSetting}
              expired={expired}
              setExpired={setExpired}
              onClickEdit={onClickEditText} />
          </DivisionSettingBoxWrapper>
        </MessageBoxWrapper>
      }
      {
        clickDivision === 'MessageHistory' &&
        // <MessageBoxWrapper style={{ flexDirection: 'column', marginLeft: '28px', width: '1025px', gap: '0px' }}>
        <MessageBoxWrapper style={{ flexDirection: 'column', marginLeft: '28px', width: '1001px', gap: '0px' }}>
          <HistoryColumnWrapper>
            <HistoryTextWrapper width={93}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>발신 시간</Text>
            </HistoryTextWrapper>
            <DivisionLine backgroundColor='rgba(150, 150, 150, 0.5)'/>
            <HistoryTextWrapper width={60}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>구분</Text>
            </HistoryTextWrapper>
            <DivisionLine backgroundColor='rgba(150, 150, 150, 0.5)'/>
            <HistoryTextWrapper width={611}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>내용</Text>
            </HistoryTextWrapper>
            <DivisionLine backgroundColor='rgba(150, 150, 150, 0.5)'/>
            <HistoryTextWrapper width={52}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>발신건수</Text>
            </HistoryTextWrapper>
            <DivisionLine backgroundColor='rgba(150, 150, 150, 0.5)'/>
            <HistoryTextWrapper width={44}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>성공</Text>
            </HistoryTextWrapper>
            <DivisionLine backgroundColor='rgba(150, 150, 150, 0.5)'/>
            <HistoryTextWrapper width={44}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='default'>실패</Text>
            </HistoryTextWrapper>
          </HistoryColumnWrapper>
          {
            viewMessageHistory.length === 0 ? 
                <HistoryTotalWrapper style={{ justifyContent: 'center' }}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#969696' hover='default'>전송 내역이 없습니다</Text>
                </HistoryTotalWrapper>
                :
                <HistoryTotalWrapper>
                  {
                    viewMessageHistory.map((data, index) => (
                      <HistoryContentWrapper key={'MessageHistory-' + index}>
                        <HistoryContent index={index} data={data} />
                      </HistoryContentWrapper>
                    ))
                  }
                </HistoryTotalWrapper>
          }
          <PaginationTotalWrapper>
            <PaginationWrapper>
              <PaginationButtonWrapper onClick={onClickPrev}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={nowPage === 0 ? '#A7A7A7' : '#232323'} hover='pointer'>이전</Text>
              </PaginationButtonWrapper>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#232323' hover='default'>{nowPage + 1}/{totalPage === 0 ? 1 : totalPage}</Text>
              <PaginationButtonWrapper onClick={onClickNext}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={nowPage === totalPage - 1 ? '#A7A7A7' : '#232323'} hover='pointer'>다음</Text>
              </PaginationButtonWrapper>
            </PaginationWrapper>
          </PaginationTotalWrapper>
        </MessageBoxWrapper>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  
  // margin-left: 42px;
  margin: 0px 0px 252px 42px;

  width: 100%;
`;

const TitleBar = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 992px;

  margin-left: 28px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin: 20px 0px 0px 28px;
  gap: 10px;
`;

const ColumnButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 0px 20px;

  height: 30px;

  border: ${props => props.isClicked ? `1px solid #FF8B48` : `1px solid #CBCBCB`};
  border-radius: 10px;
  background-color: ${props => props.isClicked ? `#FF8B48` : `#FFFFFF`};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const SendButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: #FFFFFF;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
`;

const MessageBoxWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 16px;

  width: 1029px;
`;

const MockUpWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 28px;

  width: 246px;
  height: 477px;
`;

const MockUp = styled.img`
  position: absolute;

  width: 246px;
  height: 477px;

  z-index: 1;

  object-fit: contains;
`;

const MockUpComponent = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  z-index: 5;

  width: 246px;
  height: 477px;
`;

const MockUpComponentInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  margin-top: 36px;

  width: 80%;
`;

const MockUpTitleBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 8px 0px 8px;
  margin-top: 16px;

  width: calc(100% - 16px);
  height: 30px;

  border-radius: 5px;
  background-color: rgba(217, 217, 217, 0.30);
`;

const MockUpContentBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 8px 8px 8px 8px;
  margin-top: 16px;

  width: calc(100% - 16px);
  height: 303px;

  border-radius: 5px;
  background-color: rgba(217, 217, 217, 0.30);

  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
		border: 3px solid transparent;
		background-color: #DADADA;
		border-radius: 200px;
	}

  &::-webkit-scrollbar-track {
    // height: 439px;
		// border: 4px solid transparent;
		background-color: #F0F0F0;
		border-radius: 200px;
	}

  transition: all 0.3s ease-in-out;
`;

const MessageBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: 28px;

  width: 627px;
  // height: 511px;

  border-radius: 30px;
  background-color: #FFFFFF;
`;

const LoadingView = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  margin-top: 28px;

  justify-content: center;
  align-items: center;

  width: 627px;
  height: 526px;
  
  border-radius: 30px;
  background-color: #FFFFFF;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 50px;
  height: 50px;
`;

const WriteCommponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;

  gap: 8px;
`;

const TitleInputView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 8px 0px 8px;

  width: calc(100% - 18px);
  height: 30px;

  overflow: hidden;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #FFFFFF;
`;

const MessageSettingWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 36px;

  width: 100%;
  height: 41px;

  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: rgba(255, 139, 72, 0.30);
`;

const SettingInnerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const TotalCheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 14px;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

// const CheckBox = styled.div`
//   width: 14px;
//   height: 14px;

//   border: ${props => props.isClicked ? '1px solid #FFFFFF' : '1px solid #DFDFDF'};
//   border-radius: 16px;
//   background-color: ${props => props.isClicked ? '#FF8B48' : '#FFFFFF'};

//   transition: all 0.3s ease-in-out;

//   :hover {
//     cursor: pointer;
//   }
// `;

const SettingTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;
  
  padding: 0px 10px;
  margin-top: 16px;

  gap: 4px;

  width: calc(100% - 20px);
`;

const MemberSelectBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 30px;

  width: 386px;
`;

const MemberFindButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: calc(100% - 2px);
  height: 41px;

  border: 1px solid #6DD49E;
  border-radius: 10px;
  background-color: ${props => props.isHover ? `#6DD49E` : `#FFFFFF`};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const MemberSelectBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 10px;

  width: 100%;
`;

const MemberSelectBoxButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  padding: 0px 10px;

  width: calc(100% - 20px);

  gap: 10px;
`;

const NoneSelectedBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 10px;

  width: calc(100% - 2px);
  height: 77px;

  border: 1px solid #BFBFBF;
  border-radius: 10px;
  background-color: rgba(150, 150, 150, 0.2);
`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 57px;
  height: 22px;

  border: 1px solid #DBDDE4;
  border-radius: 5px;
  background-color: #F5F6F8;

  :hover {
    cursor: pointer;
  }
`;

const PreText = styled.pre`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  white-space: pre-wrap;

  :hover {
    cursor: ${props => props.hover};
  }
`;

const SelectedMemberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 13px;

  width: 100%;
  height: 333px;

  overflow-x: hidden;
  overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #DFDFDF;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const SelectedMemberInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: calc(100% - 2px);
  // height: ${props => props.height}px;
  width: 100%;

  // overflow: hidden;

  // border: 1px solid #BFBFBF;
  border-radius: 4px;
  background-color: #FFFFFF;
`;

const SelectedMemberInfo = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 37px;
`;

const ProfileWrapper = styled.div`
  width: 37px;
  height: 37px;

  // border-radius: 4px;

  border-top-left-radius: ${props => props.isFirst ? `4px` : `0px`};
  border-bottom-left-radius: ${props => props.isLast ? `4px` : `0px`};

  overflow: hidden;
`;


const SelectedMemberTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-left: ${props => props.marginLeft}px;

  width: ${props => props.width}px;
  height: 20px;

  overflow: hidden;
`;

const MemberCheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-left: 156px;

  width: 16px;
  height: 16px;

  border: ${props => props.isClicked ? `1px solid #5BD98A` : `1px solid #969696`};
  border-radius: 2px;
  background-color: ${props => props.isClicked ? `#5BD98A` : `#FFFFFF`};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const SendMessageButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 16px;

  width: 100%;
  height: 43px;

  border-radius: 10px;
  background-color: #5BD98A;

  :hover {
    cursor: pointer;
  }
`;

const CheckImage = styled.img`
  width: 10px;
  height: 8px;
`;

const TotalUseSettingWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  // margin: 20px 0px 0px 28px;
  margin-top: 20px;

  gap: 10px;
`;

const TotalUseSettingBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;
  
  padding: 0px 10px;

  height: 50px;

  gap: 10px;

  border-radius: 10px;
  background-color: ${props => props.isClicked ? `rgba(255, 139, 72, 0.05)` : `rgb(150, 150, 150, 0.2)`};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const TotalUseTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
`;

const DivisionSettingBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  gap: 30px;
`;

const HistoryColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  margin-top: 20px;

  width: 100%;
  height: 31px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #F5F6F8;
`;

const HistoryTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 0px 8px;

  width: ${props => props.width}px;
`;

const DivisionLine = styled.div`
  width: 1px;
  height: 100%;

  background-color: ${props => props.backgroundColor};
`;

const HistoryTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  min-height: 100px;

  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #FFFFFF;
`;

const HistoryContentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  // padding: 10px 0px;

  width: 100%;
`;

const HistoryContentTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  // padding: 0px 8px;
  
  height: 100%;

  border-right: 1px solid #969696;
`;

const PaginationTotalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 22px;

  width: 100%;
`;

const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 12px;
`;

const PaginationButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;
