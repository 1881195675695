import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';

import DefaultLogoPath from '../../image/DefaultLogo.png'

const LogoFileInput = forwardRef((props, ref) => {
  const FileInputRef = useRef();
	const [imgBase64, setImgBase64] = useState();
	const [imgBase64Thumbnail, setImgBase64Thumbnail] = useState();
  const [imgFile, setImgFile] = useState();
  const [imgLocalPath, setImgLocalPath] = useState('');
  
	const hiddenFileInput = useRef(null);
  const LogoCanvasRef = useRef();
  const LogoThumbnailCanvasRef = useRef();

  useImperativeHandle(ref, () => ({
    getImgBase64: () => {
      return imgBase64;
    },
    getImgBase64Thumbnail: () => {
      return imgBase64Thumbnail;
    },
    getValue: () => {
      return imgLocalPath;
    },
    setBold: () => {

    },
    reset: () => {
      setImgBase64();
      setImgFile();
      setImgLocalPath();
    }
  }));

  const handleClick = e => {
    hiddenFileInput.current.click();
    FileInputRef.current.style.borderColor = '#FF8B48';
	};
	
  const handleChange = e => { 
    var pathpoint = e.target.value.lastIndexOf('.');
    var filepoint = e.target.value.substring(pathpoint + 1, e.target.length);
    var filetype = filepoint.toLowerCase();

    if (filetype === 'jpg' || filetype === 'png' || filetype === 'jpeg') {

      // // 정상적인 이미지 확장자 파일인 경우
      // const reader = new FileReader();
      
      // var tmp = e.target.value.split('\\');
      // setImgLocalPath(tmp[tmp.length - 1]);
      // reader.onloadend = () => {
      //   const base64 = reader.result;
        
      //   FileInputRef.current.style.borderColor = '#E8E8E8';
      //   if (base64){
      //     setImgBase64(base64.toString());
      //   }
      // };
      // if (e.target.files[0]) {
      //   reader.readAsDataURL(e.target.files[0]);
      //   setImgFile(e.target.files[0]);
      // }


      // 정상적인 이미지 확장자 파일인 경우
      const reader = new FileReader();

      var tmp = e.target.value.split('\\');
      
      setImgLocalPath(tmp[tmp.length - 1]);

      reader.onloadend = () => {
        const base64 = reader.result;

        const naturalImage = new Image();

        naturalImage.src = reader.result.toString();

        naturalImage.onload = function (e) {
          // console.log(reader.result);
          let naturalWidth = e.target.naturalWidth;
          let naturalHeight = e.target.naturalHeight;

          let resizeWidth = 0;
          let resizeHeight = 0;

          // 원본 처리 //
          if (naturalWidth < naturalHeight) {
            resizeWidth = 600;
            resizeHeight = (600 * naturalHeight) / naturalWidth;
          }
          else if (naturalWidth > naturalHeight) {
            resizeHeight = 600;
            resizeWidth = (600 * naturalWidth) / naturalHeight;
          }
          else {
            resizeWidth = 600;
            resizeHeight = 600;
          }

          const image = new Image();
          const canvas = LogoCanvasRef.current;
          const ctx = canvas?.getContext('2d');

          if (ctx) {
            canvas.width = 600;
            canvas.height = 600;
            // canvas.backgroundColor = '#FFFFFF';

            image.src = base64.toString();

            // 사진 크기 조정후 Canvas에 그리기 //
            image.onload = function () {
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              ctx.drawImage(image, ((600 - resizeWidth) / 2), ((600 - resizeHeight) / 2), resizeWidth, resizeHeight);
            }

            // 크기 조절된 사진 데이터 정리하기 //
            canvas.toBlob(function (blob) {
              setImgBase64(canvas.toDataURL());
              // const reader = new FileReader();
              // // Canvas에 다 그려졌을때 Event //
              // reader.onload = function (e) {
              //   setImgBase64(canvas.toDataURL());
              // }
              // reader.readAsDataURL(blob);
            })
            // 원본 처리 //

            // 축소판 처리 //
            let resizeThumbnailWidth = 0;
            let resizeThumbnailHeight = 0;

            if (naturalWidth < naturalHeight) {
              resizeThumbnailWidth = 240;
              resizeThumbnailHeight = (240 * naturalHeight) / naturalWidth;
            }
            else if (naturalWidth > naturalHeight) {
              resizeThumbnailHeight = 240;
              resizeThumbnailWidth = (240 * naturalWidth) / naturalHeight;
            }
            else {
              resizeThumbnailWidth = 240;
              resizeThumbnailHeight = 240;
            }

            const thumbnailImage = new Image();
            const thumbnailCanvas = LogoThumbnailCanvasRef.current;
            const thumbnailCtx = thumbnailCanvas?.getContext('2d');

            if (thumbnailCtx) {
              thumbnailCanvas.width = 240;
              thumbnailCanvas.height = 240;

              thumbnailImage.src = base64.toString();
              
              // 사진 크기 조정후 Canvas에 그리기 //
              thumbnailImage.onload = function () {
                thumbnailCtx.clearRect(0, 0, thumbnailCanvas.width, thumbnailCanvas.height);
                thumbnailCtx.drawImage(thumbnailImage, ((240 - resizeThumbnailWidth) / 2), ((240 - resizeThumbnailHeight) / 2), resizeThumbnailWidth, resizeThumbnailHeight);
              }

              // 크기 조절된 사진 데이터 정리하기 //
              thumbnailCanvas.toBlob(function (blob) {
                setImgBase64Thumbnail(thumbnailCanvas.toDataURL());
              })
            }
            else {
              console.log("Could not get context in thumbnail");
            }
            // 축소판 처리 //
          }
          else {
            console.log("Could not get context");
          }
        }
      };
      if (e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
        setImgFile(e.target.files[0]);
      }
    } 
    else {
      alert('.jpeg .jpg .png 파일만 가능합니다!');
    }
	};
  
  return (
    <TopLevelWrapper>
      <LogoExplanationWrapper>
        <LogoExplanation>400px X 400px 사이즈 권장 / jpg, png지원 최대 3MB</LogoExplanation>
      </LogoExplanationWrapper>
			<InputWrapper>
        <canvas ref={LogoCanvasRef} style={{ display: 'none' }} />
        <canvas ref={LogoThumbnailCanvasRef} style={{ display: 'none' }} />
        <input 
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{display: 'none'}}/>
        <FileInput ref={FileInputRef}>{imgLocalPath}</FileInput>
        <InputButton onClick={handleClick}>찾아보기</InputButton>
        <LogoWrapper>
          {/* <Logo src={imgFile ? URL.createObjectURL(imgFile) : DefaultLogoPath}/> */}
          <Logo src={imgFile ? imgBase64 : DefaultLogoPath}/>
          {/* <Logo src={imgFile ? imgBase64Thumbnail : DefaultLogoPath}/> */}
        </LogoWrapper>
      </InputWrapper>
    </TopLevelWrapper>
  );
})

export default LogoFileInput;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

const LogoExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;

  // width: max(140px, 24.375vw);
`;

const LogoExplanation = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 12px;
	// font-size: max(12px, 0.625vw);
	// line-height: 17.38px;

	margin: 8px 0 0 0;

	color: rgba(203, 203, 203, 1);
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: flex-end;
`;

const FileInput = styled.div`
  font-family: NotoSansKR-Regular;
  font-size: 14px;
	// font-size: max(12px, 0.7291666666666667vw);
  // line-height: 20px;

  padding: 0 0 4px 0;
  margin-top: 16px;
	// margin-top: max(12px, 0.8333333333333333vw);

  width: 273px;
  // width: max(140px, 14.21875vw);
  height: 25px;
  // background-color: red;
  // outline: none;
  border: 1px solid;
  border-width: 0 0 1px 0;
  border-color: #E8E8E8;
  color: #000000;

  ::placeholder {
    color: ${props => props.clickCount < 1 || props.value !== '' ? `#CBCBCB` : `#FF3131;`};
  }

  transition: all 0.5s ease-in-out;
`;

const InputButton = styled.div`
  display: flex;
  flex-direction: row;

  width: 60px;
  margin-left: 9px;
  // margin-left: max(6px, 0.46875vw);

  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #777777;
  // background-color: red;
  :hover {
    cursor: pointer;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  margin-left: 61px;
  // margin-left: max(15px, 3.177083333333333vw);
`;

const Logo = styled.img`
  width: 80px;
  // width: max(65px, 4.166666666666667vw);
  height: 80px;
  // height: max(65px, 4.166666666666667vw);
  
  object-fit: contain;
`;